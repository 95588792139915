import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
// import { inject, observer } from 'mobx-react'
import {
  Layout,
  Table,
  Button,
  Modal,
  message,
  Input,
  Select
  // Icon,
} from 'antd'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import EventSider from '~/components/Events/Event/Sider'
import cancelRequestsQuery from '~/graphql/queries/cancelRequests.gql'
import eventCancellationExportMutation from '~/graphql/mutations/eventCancellationExport.gql'
import Loading from '~/components/Loading/index'
import TopContent from '~/components/Events/Event/TopContent'
import EventNotFound from '~/components/Error/Eventnotfound'
import { withTranslation } from 'react-i18next'

const Search = Input.Search

const bibNumber = number => {
  const pad = '0000'
  return pad.substr(0, pad.length - `${number}`.length) + number
}

// @inject('router')
// @observer
@withApollo
@graphql(cancelRequestsQuery, {
  options: ({ match: { params: { eventId } } }) => ({
    variables: { eventId },
    fetchPolicy: 'network-only'
  })
})
class EventCancelRequests extends Component {
  state = {
    confirmDirty: false,
    searchKey: '',
    status: 'all',
    exporting: false
  }

  handleExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventCancellationExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventCancellationExport.url')
        const fileName = _get(data, 'eventCancellationExport.fileName')
        // console.log({ response })
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  render () {
    const { data, t } = this.props
    if (data.loading) {
      return <Loading />
    }
    if (!data.event) {
      return <EventNotFound />
    }
    const { status } = this.state
    const searchKey = this.state.searchKey.toLowerCase().replace(/\s/g, '')
    const cancelRequests = _get(data, 'cancelRequests', [])
      .filter(({ticket, participant}) => {
        const e = !![_get(ticket, 'number'), _get(ticket, 'participant.profile.firstName'), _get(ticket, 'participant.profile.lastName')].find(
          k => {
            return k && k.toString().toLowerCase().replace(/\s/g, '').search(searchKey) !== -1
          }
        )
        return e
      })
      .filter((cr) => {
        if (status === 'all') return true
        return cr.status === status
      })
    // const columns = [{
    //   title: 'Order Item ID',
    //   dataIndex: '_id',
    //   key: '_id',
    // }, {
    //   title: 'Name',
    //   dataIndex: 'name.th',
    //   key: 'name'
    // }, {
    //   title: 'Description',
    //   dataIndex: 'description.th',
    //   key: 'description'
    // }, {
    //   title: 'Status',
    //   dataIndex: 'hidden',
    //   key: 'hidden',
    //   render: (hidden) => {
    //     if(hidden === false){
    //       return <span> Show </span>
    //     }else{
    //       return <span> Hidden </span>
    //     }
    //   }
    // }, {
    //   title: 'Image',
    //   dataIndex: 'imageUrl',
    //   key: 'image',
    //   render: (imageUrl) => <img style={{ width: 50 }} src={imageUrl} alt=""/>
    // }, {
    //   title: 'Price',
    //   dataIndex: 'price',
    //   key: 'price',
    // }, {
    //   title: 'Delivery Option',
    //   dataIndex: 'deliveryOption',
    //   key: 'deliveryOption',
    // }]

    return (
      <Layout>
        <Layout.Content>
          <TopContent
            title={_get(data, 'event.name.th') || _get(data, 'event.name.en')}
            nodes={{
              InputSearch:  <Input.Group compact={true}>
              <Select
                placeholder="Select Status"
                firstActiveValue={null}
                onChange={status => this.setState({status})}
                value={status}
                defaultValue={'all'}
                style={{ width: 100 }}
                className="select-status"
              >
                {['all', 'completed', 'submitted', 'revoked'].map(status =>
                  <Select.Option key={status} value={status}>{status}</Select.Option>
                )}
              </Select>
              <Search
                placeholder={`${t('refund.search')}`}
                onChange={ e => this.setState({searchKey: e.target.value})}
                style={{width: '200px'}}
              />
              </Input.Group>,
                SelectStatus: <Button
                onClick={this.handleExport}
                icon="export"
                loading={this.state.exporting}
              >
              Export
              </Button>,
              }}
            pattern='registrations'/>
            <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <Table
                style={{whiteSpace: 'nowrap'}}
                scroll={{ x: true }}
                // columns={columns}
                dataSource={cancelRequests}
                // loading={loading}
                locale={{ emptyText: `${t('locale.notFound')}` }}
                rowKey="_id"
                // onRowClick={(record) => (this.props.history.push(`/events/${data.event._id}/cancelRequests/${record._id}/edit`))}
              >
                <Table.Column title={`${t('refund.requestDate')}`} key="date" dataIndex="createdAt" width={140} render={date => moment(date).format('Do MMM HH:mm')} />
                <Table.Column title={`${t('refund.participant')}`} key="participant" align="right" width={300} dataIndex="ticket"
                  render={(ticket, rec) =>
                    <Link to={`/events/${data.event._id}/cancel-requests/${rec._id}`}>{`[${bibNumber(ticket.number)}]\n${_get(ticket, 'participant.profile.firstName')} ${_get(ticket, 'participant.profile.lastName')}`}</Link>
                  }
                />
                <Table.Column title={`${t('refund.cancellation')}`} key="method" dataIndex="method.name.th" width={100}
                  render={(name) => name || 'ขอรับเงินคืน' }
                />
                {/* <Table.Column title="การยกเลิก" key="method" dataIndex="method.name.th" width={100} /> */}
                <Table.Column title={`${t('refund.status')}`} key="status" dataIndex="status" width={100} />
                <Table.Column title={`${t('refund.processData')}`} key="refundDate" dataIndex="refund.completedAt" width={100} render={date => date && moment(date).format('Do MMM HH:mm')} />
              </Table>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default withTranslation() (EventCancelRequests)
