import React, { useState } from 'react';
import { Layout, Button, Modal, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _mergeWith from 'lodash/mergeWith'
import { useQuery } from 'react-apollo-hooks';

import Import from './Import'

import TopContent from '~/components/Events/Event/TopContent'
import EventSider from '~/components/Events/Event/Sider'
import MessageListQuery from '~/graphql/queries/messageList.gql'

const PERPAGE = 10

const ImportTracking = () => {
	const { eventId } = useParams()
  const [isImport, setIsImport] = useState(false)
	const [page, setPage] = useState(1)

	const { data, loading } = useQuery(MessageListQuery, {
    variables: {
      eventId,
      page,
      perPage: PERPAGE
    }
  })

	if (loading) {
    return <Spin />
  }

	return (
		<Layout>
		<Layout.Content>
			<TopContent
					// title={_get(eventProps, 'data.event.name.th')}
			/>
				<div className="innerMainContentWrapper">
					<EventSider />
					<div className="middleContent">
						<div style={{padding: '6px', marginLeft: '20px', marginBottom: '20px', fontSize: '1.5em'}}>
							<div style={{border: '1px solid #DADADA', borderRadius: '7px', padding: '10px', backgroundColor: '#fff'}}>
								<div style={{display: 'flex', justifyContent: "space-between"}}>
									<h3>Tracking</h3>
									<Button onClick={e => setIsImport(!isImport)}>Add Tracking</Button>
								</div>
								<p style={{fontSize: '16px'}}>Total Tracking: {_get(data, 'MessageList.count')}</p>
								<Modal
										title="Import Tracking"
										visible={isImport}
										// onOk={this.hideModal}
										onCancel={()=> setIsImport(false)}
										// okText="确认"
										// cancelText="取消"
										footer={[
											// <Button key="back" onClick={()=> setIsImport(false)}>
											//   close
											// </Button>
										]}
									>
										<Import setIsImport={setIsImport} />
								</Modal>
								
							</div>
						</div>
				</div>
			</div>
		</Layout.Content>
	</Layout>
	)
}

export default ImportTracking