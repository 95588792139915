import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { inject, observer } from 'mobx-react'
import { Layout } from 'antd'
import eventQuery from '~/graphql/queries/paymentSummaryMiniEvent.gql'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import _get from 'lodash/get'
import Loading from '~/components/Loading/index'
import MethodsTable from './MethodsTable'
import clientAuth from '~/utils/client-auth'
import _includes from 'lodash/includes'
import { Link } from 'react-router-dom'

@inject('router')
@graphql(eventQuery, {
  options: ({
    match: {
      params: {
        eventId
      }
    }
  }) => ({
    variables: {
      _id: eventId
    }
  }),
  // props: ({ data: { loading, event } }) => ({
  //   loading,
  //   event
  // })
})
@observer
@withApollo
class PaymentSummary extends Component {
  render () {
    const {roles} = clientAuth.login()
    const { data, match: { params: { eventId } } } = this.props
    const { loading, event } = data
    console.log('event', data)
    if (_includes(roles, 'organizer') && !_includes(roles, 'admin')) {
      this.props.history.replace(`/events/${eventId}/stats`)
    }
    if (loading) {
      return <Loading />
    }
    if (!event) {
      return 'event not found'
    }
    return <Layout>
      <Layout.Content>
          <TopContent
            title={_get(event, 'name.th') || _get(event, 'name.en')}
           />
          <div className="innerMainContentWrapper">
            <EventSider />
              <div className="middleContent bGwhite">
              <div className='paymentStatTableWrapper'>
                <div className='paymentStatTableWrapperInner'>
                  <MethodsTable event={event} eventId={event._id} />
                  {/* <MethodsTable event={event} eventId={event._id} paymentMethod='transfer'/> */}
                </div>
              </div>
            </div>
        </div>
      </Layout.Content>
    </Layout>
  }
}

export default PaymentSummary
