import React from 'react'
import { Form, Input } from 'antd'
// import { graphql } from 'react-apollo'
// import _get from 'lodash/get'
import Uploader from '~/components/Uploader'

const formItemLayout = {
  labelCol: { sm: { span: 10 }, xs: { span: 24 } },
  wrapperCol: { sm: { span: 14 }, xs: { span: 24 } }
}
// const bankBrands = ['bbl', 'kbank', 'ktb', 'tmb', 'scb', 'citi', 'cimb', 'uob', 'bay', 'tbank', 'ibank', 'lhb']
// @inject('i18n')
@Form.create()
// @observer
class RefundForm extends React.Component {
  render () {
    const { form, cancelRequest } = this.props
    const { getFieldDecorator } = form

    getFieldDecorator(`record.eventId`, {
      initialValue: cancelRequest.eventId,
      rules: [{ required: true }]
    })
    getFieldDecorator(`record.cancelRequestId`, {
      initialValue: cancelRequest._id,
      rules: [{ required: true }]
    })

    getFieldDecorator(`record.ticketItemId`, {
      initialValue: cancelRequest.ticketItemId,
      rules: [{ required: true }]
    })
    return <Form>
      <h3>Resolve Cancellation</h3>
      <Form.Item label="เอกสารแนบ" {...formItemLayout}>
        {getFieldDecorator('record.attachmentUrl')(<Uploader/>)}
      </Form.Item>
      <Form.Item label="หมายเหตุ" {...formItemLayout}>
        {getFieldDecorator('record.remarks')(<Input.TextArea/>)}
      </Form.Item>
    </Form>
  }
}

export default RefundForm
