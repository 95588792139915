import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'

class RegistrationRowRender extends Component {
  render () {
    const {data} = this.props
    return <Row>
      <Col span={12}>
        <b>รหัสใบสมัคร</b>: <Link target="_blank" to={`/events/${this.props.eventId}/registrations?s=${data.code}`}>{data.code}</Link>
      </Col>
      <Col span={12}>
        <b>สถานะการสมัคร</b>: {data.status}
      </Col>
    </Row>
  }
}

export default RegistrationRowRender
