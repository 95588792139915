import React, { useState } from 'react'
import {
  Form,
  Row,
  Col,
  Select,
  message
} from 'antd'
import _get from 'lodash/get'
// import eventSupplierQbSkuQuery from '~/graphql/queries/eventSupplierQbSku.gql'
import allQbSuppliersQuery from '~/graphql/queries/qb/allQbSuppliers.gql'
import updateEventSupplierQbMutation from '~/graphql/mutations/qb/updateEventSupplierQb.gql'
import { useMutation, useQuery } from '@apollo/react-hooks'
const { Option } = Select
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const EventSupplierQb = ({eventId, eventSupplierQb}) => {
  const [loading, setLoading] = useState(false)
  const [validateStatus, setValidateStatus] = useState(_get(eventSupplierQb, 'qbId')?'success':'warning')
  const {loading: suppliersLoading, data: suppliersData} = useQuery(allQbSuppliersQuery)
  const [updateEventSupplierQb] = useMutation(updateEventSupplierQbMutation)
  if(suppliersLoading) return 'loading'
  if(!suppliersData || _get(suppliersData, 'allQbSuppliers.length') === 0) return 'no suppliers'
  const allQbSuppliers = _get(suppliersData, 'allQbSuppliers')
  const onChange = async (qbId) => {
    setLoading(true)
    setValidateStatus('warning')
    const supplier = allQbSuppliers && allQbSuppliers.find(c => c.qbId === qbId)
    const qbName = _get(supplier , 'qbName')
    updateEventSupplierQb({ variables: {eventId, qbId, qbName} })
      .then(res => {
        setLoading(false)
        message.success('success')
        setValidateStatus('success')
      })
      .catch(err => {
        message.error(JSON.stringify(err))
        setValidateStatus('warning')
        setLoading(false)
      })
  }
  const previewSupplier = allQbSuppliers && allQbSuppliers.find(c => c.qbId === _get(eventSupplierQb, 'qbId'))
  return (<Row>
    <Col>
      <Form.Item 
        label="Supplier" 
        validateStatus={validateStatus}
        hasFeedback
        help={_get(previewSupplier, 'qbName')}
        {...formItemLayout} 
        style = {{ 
          float: 'left', 
          marginLeft: '20px',
        }}
      > 
        <Select
          showSearch
          style={{ width: 500 }}
          placeholder="Select a Supplier"
          optionFilterProp="children"
          onChange={onChange}
          loading={loading}
          defaultValue={_get(eventSupplierQb, 'qbId')}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={null}>-</Option>
          {allQbSuppliers.map(c => {
            return <Option key={c.qbId} value={c.qbId}>{c.qbName}</Option>
          })}
        </Select>
      </Form.Item>
    </Col>
  </Row>
  )
}

export default EventSupplierQb
