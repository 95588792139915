import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import { Link } from 'react-router-dom'
import { Layout, Table } from 'antd'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import eventTicketsQuery from '~/graphql/queries/eventTickets.gql'
import { withTranslation } from 'react-i18next'
class EventTickets extends Component {
  state = {
    current: 0,
    confirmDirty: false
  }
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     current: 0,
  //     confirmDirty: false,
  //   };
  // }

  render () {
    // console.log(this.props)
    const { match: { params: { eventId } }, loading, t } = this.props
    var event = _get(this.props.data, 'event')
    let tickets
    let expandedRowRender
    // console.log(this.props.data)
    if (!loading && event) {
      tickets = event.tickets

      // expandedRowRender = record => <p>{record.description}</p>
      expandedRowRender = (record) =>
        (<p>
          {record.options.map((option) =>
            <p>{_get(option, 'question.name.en')} / {_get(option, 'question.name.th')}  :  {option.itemId}</p>
          )}
        </p>)

      // record.tickets.options.map((option) => {
      //   <p>`${option.question.name.en} \ ${option.question.name.th} : ${option.itemId}`</p>
      // })
    }
    const columns = [{
      title: 'ID',
      dataIndex: '_id',
      key: 'id'
    }, {
      title: 'First Name',
      dataIndex: 'participant.profile.firstName',
      key: 'firstname',
      render: (firstName, record) => <Link to={`/events/${eventId}/participants/${record.participantId}`}>{firstName}</Link>
    }, {
      title: 'Last Name',
      dataIndex: 'participant.profile.lastName',
      key: 'lastName'
    }, {
      title: 'Email',
      dataIndex: 'participant.profile.email',
      key: 'email'
    }, {
      title: 'Tel',
      dataIndex: 'participant.profile.telephone',
      key: 'tel'
    }, {
      title: 'Status',
      dataIndex: 'participant.registration.status',
      key: 'status'
    }, {
      title: 'Payment Method',
      dataIndex: 'participant.registration.payments[0].method',
      key: 'paymentMethod'
    }, {
      title: 'Registration ID',
      dataIndex: 'participant.registrationId',
      key: 'registrationId',
      render: (registrationId, record) => <Link to={`/events/${eventId}/registrations/${registrationId}`}>{registrationId}</Link>
    }, {
      title: 'Ticket Type',
      dataIndex: 'ticketType.name.th',
      key: 'ticketType'
    }, {
      title: 'Amount',
      dataIndex: 'ticketType.price',
      key: 'price'
    }]

    const size = 'small'

    return (
      <Layout>
        <Layout.Sider width={200} style={{ background: '#fff' }}>
          <EventSider />
        </Layout.Sider>
        <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
          <Table columns={columns} dataSource={tickets}
            expandedRowRender={expandedRowRender}
            loading={loading}
            locale={{emptyText: `${t('locale.notFound')}`}} size={size}
            rowKey={'_id'}
            pagination={{
              defaultPageSize: 1000
            }}
          />
        </Layout.Content>
      </Layout>
    )
  }
}

export default compose(
  graphql(eventTicketsQuery, {
    options: ({ match: { params: { eventId } } }) => ({
      variables: { eventId }
    })
  }),
  withTranslation()
)(EventTickets)
