import React, { useState, useMemo } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Button, Modal, Tabs ,Card} from 'antd'
import _get from 'lodash/get'
import moment from 'moment-timezone'
// import isSendingAllEmailEventQuery from "~/graphql/queries/isSendingAllEmailEvent.gql";
import downloadPaymentsSlipOnEventMutation from '~/graphql/mutations/downloadPaymentsSlipOnEvent.gql'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'


const DownloadSlip = (props) => {
  const {eventId,TransferMethodEnableTrue} = props
  const [clickedLoading, setLoading]= useState(false)
  const [progress, setProgress] = useState(0)
  const [downloadPaymentsSlipOnEvent] = useMutation(downloadPaymentsSlipOnEventMutation)
  const handleClick = async () => {
    setLoading(true)
    const { data } = await  downloadPaymentsSlipOnEvent({variables: { eventId }})
    const progressPayments = data.downloadSlip.length
    let completed = 0
    const zip = new JSZip()
    let img = zip.folder("slip")

    const promises = data.downloadSlip.map((url, index) => fetch(url.url).then(r => r.blob().then(e => {
        img.file(`${url.name}.jpeg`, e)
    })))
    const code = await Promise.all(promises)
    await promises.forEach(promise => {
      promise.then(() => {
        // One of the promises has completed
        completed++;
        setProgress(((completed / progressPayments) * 100))
      });
    });
    const date = moment()
    if(code) {
        zip.generateAsync({type: "blob"}).then(function(content){
            saveAs(content, `${eventId}_${date.format('YYYY-MM-DD_HH-mm-ss')}.zip`)
        })
        
    }
    setProgress(100)
    setLoading(false)
  }
  const loading = _get(eventId, 'loading')
  const isLoading = useMemo(() => setIsLoading({
    loading, clickedLoading
  }),
  [loading, clickedLoading])
  console.log('isLoading', isLoading, clickedLoading)
  
  return (<div className='eventDownloadBox no-print'>
            <Card
              title='Payments Slip'
              size="small"
              style={{marginBottom: 10}}
              key='DownloadSlip'
            >
              <Button
                disabled={TransferMethodEnableTrue ? false : true}
                loading={isLoading}
                icon="export"
                onClick={handleClick}
              >
                Download {progress ? `${progress.toFixed(2)} %` : ''} 
              </Button>        
            </Card>
         </div>
         )
}
const setIsLoading = ({loading, clickedLoading}) => {
  if(loading) return loading
  if(clickedLoading) return clickedLoading
}
export default DownloadSlip