import React, { Component } from 'react'
// import { graphql, compose } from 'react-apollo'
import { graphql } from 'react-apollo'
import { Layout, Table, Button, Breadcrumb, Modal, InputNumber, message, Icon, Form } from 'antd'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import adminQuery from '~/graphql/queries/adminUser.gql'
import { inject, observer } from 'mobx-react'
import _get from 'lodash/get'
import moment from 'moment-timezone'
import compose from 'lodash/flowRight'
import registrationPaymentsQuery from '~/graphql/queries/registrationPayments.gql'
import confirmPaymentMutation from '~/graphql/mutations/confirmPayment.gql'
import adminConfirmPaymentIssueMutation from '~/graphql/mutations/adminConfirmPaymentIssue.gql'
import adminConfirmRefundSlipUrlMutation from '~/graphql/mutations/adminConfirmRefundSlipUrl.gql'
import testPaymentIssueMutation from '~/graphql/mutations/testPaymentIssue.gql'
import refundPaymentMutation from '~/graphql/mutations/refundPayment.gql'
import Loading from '~/components/Loading'
import RegistrationNotFound from '~/components/Error/Registrationnotfound'
import _includes from 'lodash/includes'
import clientAuth from '~/utils/client-auth'
import Itag from '~/components/Itag'
import UploadSlipForm from '~/components/Payments/UploadSlipForm'
import UploadRefundSlipForm from '~/components/Payments/UploadRefundSlipForm'
import { withTranslation } from 'react-i18next'
const formItemLayout = {
  labelCol: {
    xs: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 19 }
  }
}

@inject('router')
@graphql(adminConfirmPaymentIssueMutation, { name: 'adminConfirmPaymentIssue' })
@graphql(adminConfirmRefundSlipUrlMutation, { name: 'adminConfirmRefundSlipUrl' })
@graphql(testPaymentIssueMutation, { name: 'testPaymentIssue' })
@graphql(adminQuery, {
  options: (props) => ({
    variables: {
      _id: clientAuth.login()._id
    }
  }),
  props: ({ data: { loading, adminUser } }) => ({
    adminLoading: loading,
    admin: adminUser
  })
})
@observer
class Participant extends Component {
  refundInput = React.createRef(null)
  state = {
    confirming: null,
    refunding: false,
    isMobile: window.innerWidth <= 750,
    submiting: false
  }
  handleCloseModal = () => {
    const { location } = this.props.router
    location.query = ''
  }
  handleTestPaymentIssue = (_id) => {
    const { location } = this.props.router
    const { data } = this.props
    this.setState({ submiting: true })
    this.props.testPaymentIssue({
      variables: {
        _id
      }
    }).then(res =>
      Modal.success({
        title: 'Updated',
        onOk: () => {
          data.fetchMore({
            updateQuery: (pre, { fetchMoreResult }) => {
              this.setState({ submiting: false })
              return fetchMoreResult
            }
          })
          location.query = ''
        }
      })
    ).catch(e => {
      console.log(e)
      Modal.error({
        title: JSON.stringify(e),
        onOk: () => {
          this.setState({ submiting: false })
        }
      })
    })
  }
  handleAdminConfirmSlipUrl = (e) => {
    const { form } = this.formRef.props
    const { location } = this.props.router
    const { data } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll((err, record) => {
      this.setState({ submiting: true })
      if (err) {
        this.setState({ submiting: false })
        return console.error(err)
      }
      this.props.adminConfirmPaymentIssue({
        variables: {
          ...record
        }
      }).then(res =>
        Modal.success({
          title: 'Updated',
          onOk: () => {
            data.fetchMore({
              updateQuery: (pre, { fetchMoreResult }) => {
                this.setState({ submiting: false })
                return fetchMoreResult
              }
            })
            location.query = ''
          }
        })
      ).catch(e => {
        Modal.error({
          title: JSON.stringify(e),
          onOk: () => {
            this.setState({ submiting: false })
          }
        })
      })
    })
  }
  handleAdminConfirmRefundSlipUrl = (e) => {
    const { form } = this.formRef.props
    const { location } = this.props.router
    const { data } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll((err, record) => {
      this.setState({ submiting: true })
      if (err) {
        this.setState({ submiting: false })
        return console.error(err)
      }
      this.props.adminConfirmRefundSlipUrl({
        variables: {
          ...record
        }
      }).then(res =>
        Modal.success({
          title: 'Updated',
          onOk: () => {
            data.fetchMore({
              updateQuery: (pre, { fetchMoreResult }) => {
                this.setState({ submiting: false })
                return fetchMoreResult
              }
            })
            location.query = ''
          }
        })
      ).catch(e => {
        Modal.error({
          title: JSON.stringify(e),
          onOk: () => {
            this.setState({ submiting: false })
          }
        })
      })
    })
  }
  handleAdminUploadSlipUrl = (paymentId) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      paymentId
    }
  }
  handleAdminUploadRefundSlipUrl = (refundPaymentId) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      refundPaymentId
    }
  }
  confirmPayment = ({ _id }) => {
    this.setState({
      confirming: _id
    })
    this.props.confirmPayment({
      variables: {
        _id,
        data: {}
      }
    }).then(result => {
      this.setState({
        confirming: null
      })
    })
    message.success('Payment Confirmed')
  }
  refundPayment = ({ _id, amount }) => {
    this.setState({
      refunding: true
    })
    this.props.refundPayment({
      variables: {
        _id,
        amount
      }
    }).then(result => {
      this.setState({
        refunding: false
      })
      message.success('Payment Refunded')
    })
  }
  render () {
    const { isMobile } = this.state
    const buttonStyle = {
      margin: '8px'
    }
    const { match: { params: { registrationId } }, data: { registration, error }, loading, admin, adminLoading, t } = this.props
    if (loading || adminLoading) {
      return <Loading />
    }
    if (_get(error, 'networkError.type') === 'WriteError') {
      window.location.reload()
      return <Loading />
    }
    if (!registration || !admin) {
      return <RegistrationNotFound />
    }
    const { eventId, payments, event } = registration
    const {roles} = clientAuth.login()
    const comfirmPaymentIssueEnabled = _includes(roles, 'admin') || _includes(admin.abilities, 'confirmSlip')
    const columns = [{
      title: 'Transactions',
      // dataIndex: '_id',
      key: '_id',
      show: isMobile,
      render: ({ _id, method, status, amount, payAmount, data, refundable }, record) => {
        const slipUrl = _get(data, 'transfer.slipUrl')
        let $action = <div></div>
        if (method === 'transfer' && slipUrl) {
          $action = (
            <span>
              <a href={slipUrl} target="_blank"><Button style={buttonStyle}>Transfer Slip</Button></a>
              {
                status === 'pending' && (_includes(roles, 'admin') || _includes(admin.abilities, 'confirmSlip')) && <Button
                  style={buttonStyle}
                  type="primary"
                  loading={this.state.confirming === _id}
                  onClick={() => this.confirmPayment({ _id: _id })}
                >
                  Confirm
                </Button>
              }
            </span>
          )
        }
        if (refundable) {
          $action = (<Button
            icon="rollback"
            style={buttonStyle}
            type="primary"
            loading={this.state.refunding}
            onClick={() => {
              const modal = Modal.confirm({
                content: <React.Fragment>
                  Amount: <InputNumber defaultValue={payAmount} step={0.01} ref={this.refundInput} />
                </React.Fragment>,
                onOk: () => {
                  modal.destroy()
                  this.refundPayment({
                    _id: _id,
                    amount: parseFloat(this.refundInput.current.inputNumberRef.input.value)
                  })
                }
              })
            }}
          >
            Refund
          </Button>)
        }

        const $id = (_id === registration.paymentId
          ? <span>{_id}<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> </span>
          : _id)
        return (<div className="payments-mobile">
          {!isMobile && <div>
            {$id}
          </div>}
          {isMobile && <Form {...formItemLayout} >
            <Form.Item label="id" >
              {$id}
            </Form.Item>
            <Form.Item label="method">
              <Itag type='method' text={method} />
            </Form.Item>
            <Form.Item label="status">
              <Itag type={status} text={status} />
            </Form.Item>
            <Form.Item label="amount">
              <Itag type='amount' text={amount} />
            </Form.Item>
            <Form.Item label="paid">
              <Itag type='amount' text={payAmount} />
            </Form.Item>
            <Form.Item label="action">
              {$action}
            </Form.Item>
            <Form.Item>
              {['scb_qr', 'omise_card', 'linepay', 'omise_truemoney', 'payatall_cash', 'omise_promptpay'].includes(record.method) &&
                <span>
                  {['pending', 'blank', 'failed'].includes(record.status) && registration.status === 'submitted' && comfirmPaymentIssueEnabled && <Button icon="edit"
                    onClick={() => this.handleAdminUploadSlipUrl(record._id)}
                  >
                  </Button>}
                  {record.method === 'scb_qr' &&
                    <div>
                      ref2: {_get(record, 'data.scb.invoice.ref2')}
                    </div>
                  }
                  {record.method === 'linepay' &&
                    <div>
                      transactionId: {_get(record, 'data.linepay.transactionId')}
                    </div>
                  }
                  {/* {record.method === 'omise_card' && */}
                    <div>
                      paymentId: {_get(record, '_id')}
                    </div>
                  {/* } */}
                  {record.adminSlipUrl && <div>
                    <img src={record.adminSlipUrl} alt="adminSlipUrl"
                      style={{
                        maxWidth: '358px',
                        marginLeft: '-23px'
                      }}
                    />
                  </div>}
                  {_get(record, 'refund.data.transfer.slipUrl') && <div>
                    refund:<br />
                    <img src={_get(record, 'refund.data.transfer.slipUrl')} alt="slipUrl"
                      style={{
                        maxWidth: '358px',
                        marginLeft: '-23px'
                      }}
                    />
                  </div>}
                  {record.method.search('omise') > -1 &&
                    <div>
                      chargeId: {_get(record, 'data.omise.charge.id')}
                    </div>
                  }
                  {record.createdAt &&
                    <div>
                      createdAt: {moment(_get(record, 'createdAt')).format('DD/MM/YYYY HH:mm')}
                    </div>
                  }
                  {record.completedAt &&
                    <div>
                      completedAt: {moment(_get(record, 'completedAt')).format('DD/MM/YYYY HH:mm')}
                    </div>
                  }
                </span>
              }
              {!['omise_card', 'omise_truemoney'].includes(record.method) &&
                <span>
                  {['completed'].includes(record.status) && comfirmPaymentIssueEnabled && <Button icon="edit"
                    onClick={() => this.handleAdminUploadRefundSlipUrl(record._id)}
                  >
                    Refund offsite
                  </Button>}
                </span>
              }
            </Form.Item>
          </Form>}
        </div>)
      }
    }, {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      show: !isMobile
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      show: !isMobile,
      render: (status) => <Itag type={status} text={status} />
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      show: !isMobile
      // render: ({ user: { profile: { firstName, lastName }, email } }) => <span>{firstName} {lastName} ({email})</span>
    }, {
      title: 'Amount Paid',
      dataIndex: 'payAmount',
      key: 'payAmount',
      show: !isMobile
      // render: ({ user: { profile: { firstName, lastName }, email } }) => <span>{firstName} {lastName} ({email})</span>
    }, {
      title: 'Actions',
      // dataIndex: 'actions',
      key: 'actions',
      show: !isMobile,
      render: (record) => {
        const slipUrl = _get(record, 'data.transfer.slipUrl')
        return <div>
          {/* <span>
            {['pending', 'blank', 'failed'].includes(record.status) &&
            ['linepay'].includes(record.method) &&
            comfirmPaymentIssueEnabled &&
            ['submitted', 'paid'].includes(registration.status) &&
            comfirmPaymentIssueEnabled && <Button icon="question"
              onClick={() => this.handleTestPaymentIssue(record._id)}
            >
            </Button>}
          </span> */}
          {['scb_qr', 'omise_card', 'linepay', 'omise_truemoney', 'payatall_cash', 'omise_promptpay', 'midtrans_card', 'midtrans_snap'].includes(record.method) &&
            <span>
              {['pending', 'blank', 'failed'].includes(record.status) && registration.status === 'submitted' && comfirmPaymentIssueEnabled && <Button icon="edit"
                onClick={() => this.handleAdminUploadSlipUrl(record._id)}
              >
              </Button>}
              {record.method === 'scb_qr' &&
                <div>
                  ref2: {_get(record, 'data.scb.invoice.ref2')}
                </div>
              }
              {record.method === 'linepay' &&
                <div>
                  transactionId: {_get(record, 'data.linepay.transactionId')}
                </div>
              }
              {/* {record.method === 'omise_card' && */}
                <div>
                  paymentId: {_get(record, '_id')}
                </div>
              {/* } */}
              {record.adminSlipUrl && <div>
                <img src={record.adminSlipUrl} alt="adminSlipUrl"
                  style={{
                    maxWidth: '399px'
                  }}
                />
              </div>}
              {_get(record, 'refund.data.transfer.slipUrl') && <div>
                refund:<br />
                <img src={_get(record, 'refund.data.transfer.slipUrl')} alt="slipUrl"
                  style={{
                    maxWidth: '399px'
                  }}
                />
              </div>}
              {record.method.search('omise') > -1 &&
                <div>
                  chargeId: {_get(record, 'data.omise.charge.id')}
                </div>
              }
              {record.createdAt &&
                <div>
                  createdAt: {moment(_get(record, 'createdAt')).format('DD/MM/YYYY HH:mm')}
                </div>
              }
              {record.completedAt &&
                <div>
                  completedAt: {moment(_get(record, 'completedAt')).format('DD/MM/YYYY HH:mm')}
                </div>
              }
            </span>
          }
          {!['omise_card', 'omise_truemoney'].includes(record.method) &&
            <span>
              {['completed'].includes(record.status) && comfirmPaymentIssueEnabled && <Button icon="edit"
                onClick={() => this.handleAdminUploadRefundSlipUrl(record._id)}
              >
                Refund offsite
              </Button>}
            </span>
          }
          {record.method === 'transfer' && slipUrl &&
            <span>
              <a href={slipUrl} target="_blank"><Button style={buttonStyle}>Transfer Slip</Button></a>
              {
                record.status === 'pending' && (_includes(roles, 'admin') || _includes(admin.abilities, 'confirmSlip')) && <Button
                  style={buttonStyle}
                  type="primary"
                  loading={this.state.confirming === record._id}
                  onClick={() => this.confirmPayment({ _id: record._id })}
                >
                  Confirm
                </Button>
              }
            </span>
          }
          {record.refundable && comfirmPaymentIssueEnabled &&
            <Button
              icon="rollback"
              style={buttonStyle}
              type="primary"
              loading={this.state.refunding}
              onClick={() => {
                const modal = Modal.confirm({
                  content: <React.Fragment>
                    Amount: <InputNumber defaultValue={record.payAmount} step={0.01} ref={this.refundInput} />
                  </React.Fragment>,
                  onOk: () => {
                    modal.destroy()
                    this.refundPayment({
                      _id: record._id,
                      amount: parseFloat(this.refundInput.current.inputNumberRef.input.value)
                    })
                  }
                })
              }}
            >
              Refund
            </Button>
          }
        </div>
      }
    }]

    const size = 'small'
    const { paymentId, refundPaymentId } = this.props.router.location.query
    return (
      <Layout>
        {/* <Layout.Sider width={200} style={{ background: '#fff' }}>
        </Layout.Sider> */}
        <EventSider />
        <Layout.Content style={{ background: '#fff', padding: 8, margin: 0, minHeight: 280 }}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item><Link to={`/events`}>Events</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/events/${eventId}`}>{eventId}</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/events/${eventId}/registrations`}>Registrations</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/registrations/${registrationId}`}>{registrationId}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Payments</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{float: 'left', marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {event.name.en}
          </div>
          <Table columns={columns.filter(c => c.show)}
            dataSource={payments}
            loading={loading}
            locale={{emptyText: `${t('locale.notFound')}`}} size={size}
            rowKey={'_id'}
          />
          <div style={{textAlign: 'right'}}>
            <Link to={`/events/${eventId}/registrations/${registrationId}`}>
              <Button className="btn" type="ghost" icon="undo" >{t('regis')}</Button>
            </Link>
          </div>
        </Layout.Content>
        {comfirmPaymentIssueEnabled && <Modal visible={!!paymentId} onOk={this.handleAdminConfirmSlipUrl}
          onCancel={this.handleCloseModal}
          footer={[
            <Button key="back" onClick={this.handleCloseModal}
              icon="arrow-left"
              loading={this.state.submiting}>
            {t('back')}
            </Button>,
            <Button key="submit" type="primary" loading={this.state.submiting}
              onClick={this.handleAdminConfirmSlipUrl}
              icon="save">
            {t('submit')}
            </Button>
          ]}>
          <UploadSlipForm paymentId={paymentId} payments={payments} event={event} wrappedComponentRef={ref => { this.formRef = ref }} />
        </Modal>}
        {comfirmPaymentIssueEnabled && <Modal visible={!!refundPaymentId} onOk={this.handleAdminConfirmRefundSlipUrl}
          onCancel={this.handleCloseModal}
          footer={[
            <Button key="back" onClick={this.handleCloseModal}
              icon="arrow-left"
              loading={this.state.submiting}>
            ย้อนกลับ
            </Button>,
            <Button key="submit" type="primary" loading={this.state.submiting}
              onClick={this.handleAdminConfirmRefundSlipUrl}
              icon="save">
            ยืนยัน
            </Button>
          ]}>
          <UploadRefundSlipForm refundPaymentId={refundPaymentId} payments={payments} event={event} wrappedComponentRef={ref => { this.formRef = ref }} />
        </Modal>}
      </Layout>
    )
  }
}

export default compose(
  graphql(registrationPaymentsQuery, {
    options: ({ match: { params: { registrationId } } }) => ({
      variables: { registrationId }
    })
  }),
  graphql(confirmPaymentMutation, {
    name: 'confirmPayment'
  }),
  graphql(refundPaymentMutation, {
    name: 'refundPayment'
  }),
  withTranslation()
)(Participant)
