import React, { Component } from 'react'
import {
  Button,
  Card
} from 'antd'
import { withTranslation } from 'react-i18next'

class Runs extends Component {
  handleExport = async () => {
    this.props.onExport()
  }
  handleExpandExport = async () => {
    this.props.onExpandExport()
  }
  handleDateExport = async () => {
    this.props.onDateExport()
  }
  render () {
    const {runDistanceMode, t} = this.props
    return (
      <div className='eventDownloadBox no-print'>
        <Card
          title={t('download.virtualrun')}
          size="small"
          style={{marginBottom: 10}}
          key='Runs'
        >
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handleExport}
              icon="export"
              loading={this.props.exporting}
            >
              {t('download.download')}
            </Button>
          </div>
          {runDistanceMode === 'cumulative' && <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handleExpandExport}
              icon="export"
              loading={this.props.exporting}
            >
              {t('download.download')}
            </Button>{' (ส่งผลรายครั้ง)'}
          </div>}
          {runDistanceMode === 'cumulative' && <div>
            <Button
              onClick={this.handleDateExport}
              icon="export"
              loading={this.props.exporting}
            >
            {t('download.download')}
            </Button>{' (ส่งผลรายครั้ง เลือกวันที่ได้)'}
          </div>}
        </Card>
      </div>
    )
  }
}

export default withTranslation() (Runs)
