import React, { Component } from 'react'
import {
  Button,
  Card
} from 'antd'
import { withTranslation } from 'react-i18next'

class RiskScreen extends Component {
  
  handleRiskScreeenExport = async () => {
    this.props.onPaidRiskScreenExport()
  }

  render () {
    const { t } = this.props
    return (
      <div className='eventDownloadBox no-print'>
        <Card
          title='Risk Screen'
          size="small"
          style={{marginBottom: 10}}
          key='RiskScreen'
        >
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handleRiskScreeenExport}
              icon="export"
              loading={this.props.exporting}
            >
              Download
            </Button>
          </div>
        </Card>
      </div>
    )
  }
}

export default withTranslation() (RiskScreen)
