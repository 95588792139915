import React, { useState, useMemo } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Button, Modal, Tabs ,Card} from 'antd'
import _get from 'lodash/get'
import moment from 'moment-timezone'
// import isSendingAllEmailEventQuery from "~/graphql/queries/isSendingAllEmailEvent.gql";
import downloadEbibOnEventMutation from '~/graphql/mutations/downloadEbibOnEvent.gql'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'


const DownloadEbib = (props) => {
    
  const {eventId,getEbibEnable} = props
  const [clickedLoading, setLoading]= useState(false)
  const [progress, setProgress] = useState(0)
  const [downloadEbibOnEvent] = useMutation(downloadEbibOnEventMutation)
  const handleClick = async () => {
    setLoading(true)
    const { data } = await  downloadEbibOnEvent({variables: { eventId }})
    const progressEbib = data.downloadEbib.length
    let completed = 0

    const zip = new JSZip()
    let img = zip.folder("ebib")
    const bucketName = process.env.REACT_APP_GCLOUND_BUCKETNAME
    const gcsUri = `https://storage.googleapis.com/${bucketName}`
    const filePath = `events/${eventId}/media/ebib/`
    const promises = data.downloadEbib.map((number, index) => fetch(`${gcsUri}/${filePath}${number.number}.jpg`).then(r => r.blob().then(e => {
            img.file(`${number.number}.jpeg`, e)
    })))
    const code = await Promise.all(promises)
    await promises.forEach(promise => {
      promise.then(() => {
        // One of the promises has completed
        completed++;
        setProgress(((completed / progressEbib) * 100))
      });
    });
    const date = moment()
    if(code) {
        zip.generateAsync({type: "blob"}).then(function(content){
            saveAs(content, `${eventId}_${date.format('YYYY-MM-DD_HH-mm-ss')}.zip`)
        })
    }
    
    setProgress(100)
    setLoading(false)
  }
  const loading = _get(eventId, 'loading')
  const isLoading = useMemo(() => setIsLoading({
    loading, clickedLoading
  }),
  [loading, clickedLoading])
  console.log('isLoading', isLoading, clickedLoading)
  
  return (<div className='eventDownloadBox no-print'>
            <Card
                title='Ebib'
                size="small"
                style={{marginBottom: 10}}
                key='DownloadEbib'
            >  
                <Button
                disabled={getEbibEnable ? false : true}
                loading={isLoading}
                icon="export"
                onClick={handleClick}
                >
                Download {progress ? `${progress.toFixed(2)} %` : ''} 
                </Button>        
            </Card>
            </div>
        )
}
const setIsLoading = ({loading, clickedLoading}) => {
  if(loading) return loading
  if(clickedLoading) return clickedLoading
}
export default DownloadEbib