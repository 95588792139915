import _get from 'lodash/get'
const setQuestionOptionName = ({kind, value, items = [], locale = 'th'}) => {
  let _value = value
  if (kind === 'Choice') {
    _value = items.find(i => `${i._id}` === `${value}`)
    return _get(_value, ['value', locale])
  } else if (kind === 'MultiChoice') {
    _value = value.map(v => {
      v = items.find(i => `${i._id}` === `${v}`)
      return _get(v, ['value', locale])
    })
    return _value.join(', ')
  }
  return _value
}
export default setQuestionOptionName
