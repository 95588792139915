import React from 'react'
import {
    Card,
    Table,
    Row,
    Col
} from 'antd'
import ShirtsData from '../../Statistics/ShirtItemsTable'

import RApaxChart from "react-apexcharts";
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'

// const objCount = (obj) => {
//     let counter = 0;
//     if(typeof obj === 'object')for (const rowdata of Object.keys(obj)) {
//       rowdata
//       counter++
//     }
//     return counter
// }

const objCount = (obj) => {
    let counter = 0;
    if (typeof obj === 'object') for (const rowdata of Object.keys(obj)) {
        rowdata
        counter++
    }
    return counter
}

const GraphApex = ({ param }) => {

    var shirtTypesMap = []

    const datasource = param.datasource
    const chartRes = param.chart
    const shirtsIndex = param.shirtsIndex

    var finisherShirtTypesMap = param.typemap
    console.log(finisherShirtTypesMap)
    const dataColors = ['#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',
        //------------------------------------------------------------------------------
        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066'
    ]

    const val = {
        "row": [], "label": [], "value": [],
        "chart": {
            "bar": {
                "bgcolor": []
            },
        },
        "rows": 0,
        "total": 0,
        "max": 0
    }
    shirtTypesMap = finisherShirtTypesMap
    // finisherShirtTypesMap.forEach(shirtType => {
    //     shirtTypesMap[shirtType._id] = shirtType.name.th
    // }) 
    console.log(shirtTypesMap)

    const indexOfShirts = {
        "shirtTypeId": { data: [], rows: 0 },
        "shirtId": { data: [], rows: 0 }
    }
    for (const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, 'shirtTypeId'))) {
        const _name = shirtTypesMap[keyi]
        indexOfShirts.shirtTypeId.data[keyi] = { order: indexOfShirts.shirtTypeId.rows++, _id: keyi, name: _name }
    }
    for (const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, '_id'))) {
        indexOfShirts.shirtId.data[keyi] = { order: indexOfShirts.shirtId.rows++, name: valuei[0].name.en }
    }

    const dsRebuild = { groupShirtType: [] }
    const dsRebuildx = []
    let groupVal = []
    var row = 0
    if (datasource) {

        console.log('-finisher-datasource', datasource)
        const byShirtTypes = _groupBy(datasource, 'shirtTypeId')

        let byShirtType = []
        for (const [keyTypeId, TypeItem] of Object.entries(byShirtTypes)) {
            if (keyTypeId !== null && TypeItem) {
                for (const [keyj, valuej] of Object.entries(TypeItem)) if (valuej.shirtTypeId !== undefined) {

                    if (byShirtType[valuej.shirtTypeId] === null || byShirtType[valuej.shirtTypeId] === undefined) {
                        byShirtType[valuej.shirtTypeId] = {
                            typeId: valuej.shirtTypeId,
                            name: shirtTypesMap[valuej.shirtTypeId] || "UNKNOW",
                            data: []
                        }
                    }

                    if (valuej.count) {
                        let objData = {
                            shirtTypeId: valuej.shirtTypeId,
                            order: indexOfShirts.shirtId.data[valuej._id].order,
                            size: { _id: valuej._id, name: valuej.name },
                            count: valuej.count,
                            quantity: valuej.quantity,
                            __typename: "ShirtCount"
                        }

                        if (byShirtType[valuej.shirtTypeId].data[valuej._id] === null || byShirtType[valuej.shirtTypeId].data[valuej._id] === undefined) { } else {
                            objData = byShirtType[valuej.shirtTypeId].data[valuej._id]
                            objData.count += (valuej.count != undefined && valuej.count != null ? valuej.count : 0)
                            if (valuej.quantity != null)
                                objData.quantity = ((valuej.quantity != undefined && valuej.quantity != null) && (objData.quantity < valuej.quantity) ? valuej.quantity : objData.quantity)
                        }

                        byShirtType[valuej.shirtTypeId].data[valuej._id] = objData;
                    }


                }
            }
        }
        console.log(byShirtType, 'byShirtType')
        let arrIndex = 0
        for (const [key, value] of Object.entries(byShirtType)) {
            let idata = {}
            let iDataIndex = 0
            for (const [keyi, valuei] of Object.entries(value.data)) {
                valuei.key = keyi
                idata[iDataIndex++] = valuei
            }

            value.key = key
            value.data = idata
            dsRebuild.groupShirtType[arrIndex++] = value
        }
        dsRebuild.groupShirtType = _orderBy(dsRebuild.groupShirtType, 'index', 'asc')
        console.log(dsRebuild, 'dsRebuild')

        for (const [keyi, valuei] of Object.entries(dsRebuild.groupShirtType)) {

            const _val = JSON.parse(JSON.stringify(val))

            const vData = _orderBy(valuei.data, 'index', 'asc')

            let dataRow = 0
            if (vData) for (let [keyj, valuej] of Object.entries(vData)) if (valuej.shirtTypeId === valuei.key || valuei.typeId === 'TOTAL') {
                keyj = dataRow++;
                _val.value[keyj] = 0
                _val.label[keyj] = valuej.size.name.en
                _val.row[keyj] = keyj
                _val.value[keyj] = valuej.count
                _val.total += valuej.count

            }

            groupVal[keyi] = _val
        }

    }

    const apexGraphOption = []
    console.log(groupVal, 'groupVal')
    if (groupVal.length > 0) for (const [key, value] of Object.entries(groupVal)) {

        apexGraphOption[key] = {
            options: {
                chart: {
                    width: '100%',
                    type: 'donut',
                    toolbar: {
                        show: false,
                    },
                },
                labels: value.label,
                colors: dataColors,
                legend: {
                    show: false,
                    position: 'bottom'
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        customScale: 0.8,
                        hover: {
                            filter: {
                                type: 'none',
                            },
                        },
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: value.total,
                                    fontSize: '3pc',
                                    fontWeight: 'bold',

                                    formatter: () => {
                                        return `count`
                                    }

                                },
                            }
                        },
                        roseType: true
                    },
                },
                stroke: {
                    width: 5
                }
            },
            series: value.value,

        }
    }

    const finisherShirtColumns = [
        {
            title: 'NAME',
            dataIndex: 'size.name.en',
            key: `ID`,
            render: (record, row) => {
                var _style = { borderColor: '#EFEFEF' }
                if (row.count > 0) {
                    _style = { borderColor: row.color }
                }

                return <div class="div-inline div-top" >
                    <div><div class="pin-circle" style={_style} >&nbsp;</div></div><div>{record}</div>
                </div>
            }
        },
        {
            title: 'COUNT',
            dataIndex: 'count',
            key: `finisherShirtTypeCount`,
            className: "amount center small",
            render: (value) => {
                return value === null ? <span style={{ color: '#EFEFEF' }}>0</span> : value
            }
        },
        {
            title: 'STOCK',
            dataIndex: 'quantity',
            key: `quantity`,
            className: "amount center small",
            render: (value) => {
                return value === null ? <span>&infin;</span> : value//(record.count) ? 0 : 1
            }
        }
    ]
    console.log(apexGraphOption, 'apexGraphOption')
    return (
        <div>
            {(dsRebuild.groupShirtType !== null) && dsRebuild.groupShirtType.map(
                (report, index) =>
                    <Card
                        hoverable
                        cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px' }}>
                            <b class='chartHeader' > {chartRes.caption} {typeof report.name === 'undefined' ? '' : (report.name === 'Total' ? 'Total' : `test ` + report.name)}  </b>
                            {report.typeId === 'TOTAL' && (report.data) &&
                                <div style={{ display: 'flex', float: 'right', justifyContent: 'flex-end', marginTop: '5px', marginRight: '25px' }}>
                                    <Switch checkedChildren="Hide List" unCheckedChildren="Show List" onChange={param.eventHandle} />
                                </div>
                            }
                        </div>}
                        style={{ marginBottom: '20px' }}
                    >
                        {row++ ? "" : ""}
                        {dsRebuild.groupShirtType.length ?
                            <Row gutter={16}>
                                <Col sm={24} md={24} lg={9} >
                                    <RApaxChart
                                        options={apexGraphOption[index].options}
                                        series={apexGraphOption[index].series}
                                        type={apexGraphOption[index].options.chart.type}
                                        width="100%"
                                        height="325"
                                    />
                                </Col>
                                <Col sm={24} md={24} lg={15} >
                                    <ShirtsData data={report.data} mode={(report.typeId === 'TOTAL' && !totalTableActive) ? 'limitHeight' : ''}
                                        shirtTypesMap={shirtTypesMap}
                                        smrTotal={5000}
                                        index={report.key}
                                        loading={param.loading}
                                        param={{ isMobile: param.isMobile }}
                                    />
                                </Col>
                            </Row> : <div className="event-gray" style={{ textAlign: 'center' }}>NO DATA</div>}
                    </Card>
            )}
        </div>
    )


}

export default GraphApex
