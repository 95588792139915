import React, { useState, useMemo } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Button, Modal, Tabs, Card } from 'antd'
import _get from 'lodash/get'
// import isSendingAllEmailEventQuery from "~/graphql/queries/isSendingAllEmailEvent.gql";
import updateAllEcerOnEventMutation from '~/graphql/mutations/updateAllEcerOnEvent.gql'
import updateAllEcerGenerateMutation from '~/graphql/mutations/updateAllEcerGenerate.gql'

const UpdateAllEcerBtn = (props) => {
  const {eventId} = props
  const [clickedLoading, setLoading]= useState(false)
  const [progress, setProgress] = useState(0)
  const eventProps = _get(props, 'eventProps')
  const ecerV2 = _get(eventProps, 'data.event.settings.virtualRun.ecerV2')
  const [updateAllEcerOnEvent] = useMutation(updateAllEcerOnEventMutation)
  const [updateAllEcerGenerate] = useMutation(updateAllEcerGenerateMutation)
  const handleClick = async () => {
    setLoading(true)
    console.log('eventId', eventId)
    await updateAllEcerOnEvent({
      variables: {
        eventId,
      }
    }).then(async res => {
      console.log('res', eventId, res)
      res.data.updateAllEcerOnEvent
      const progressUpdateEcer = res.data.updateAllEcerOnEvent.length
      const records = res.data.updateAllEcerOnEvent

      while(records.length > 0){
        await Promise.all(records.splice(0,50).map(async (rec) => {
          return updateAllEcerGenerate({
            variables: {
              eventId,
              raceId : rec.raceId,
              participantId : rec.participantId,
              totalDistance : rec.totalDistance,
              count : rec.count,
              runId : rec.runId
            }
          }).catch((e) => {
            console.error(e)
          })
        }))
        setProgress(((progressUpdateEcer - records.length) * 100) / progressUpdateEcer)
      }
      setProgress(100)
      setLoading(false)


    }).catch(e => {
      console.log('err', e)
    })

  }
  const loading = _get(eventId, 'loading')
  const isLoading = useMemo(() => setIsLoading({
    loading, clickedLoading
  }),
  [loading, clickedLoading])
  console.log('isLoading', isLoading, clickedLoading)
  
    return (<div className='eventDownloadBox'>

                  <Button
                    disabled={ecerV2 ? false : true}
                    loading={isLoading}
                    icon="export"
                    onClick={handleClick}
                    >
                    Update All Ecer  {progress ? `${progress.toFixed(2)} %` : ''} 
                  </Button>        
              </div>
          )  
}
const setIsLoading = ({loading, clickedLoading}) => {
  if(loading) return loading
  if(clickedLoading) return clickedLoading
}

export default UpdateAllEcerBtn