import React, { Component, useState } from 'react'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import _sumBy from 'lodash/sumBy'
import _last from 'lodash/last'
import {
  Layout,
  Table,
  Button,
  Modal,
  message,
  Collapse,
  Input,
  Form,
  Popconfirm,
  Row,
  Col,
  Radio,
  Badge,
  InputNumber
} from 'antd'
import _get from 'lodash/get'
import _size from 'lodash/size'
import EventSider from '~/components/Events/Event/Sider'
import eventQuery from '~/graphql/queries/virtualRun/event.gql'
import eventTicketItemSearchQuery from '~/graphql/queries/eventTicketItemSearch.gql'
import removeRunMutation from '~/graphql/mutations/removeRun.gql'
import TopContent from '~/components/Events/Event/TopContent'
import eventRunExportMutation from '~/graphql/mutations/eventRunExport.gql'
import Loading from '~/components/Loading'
import EventNotFound from '~/components/Error/Eventnotfound'
import RunsQuery from '~/graphql/queries/virtualRun/runs.gql'
import ApproveRunMutation from '~graphql/mutations/virtualRun/approveRun.gql'
import UpdateRunMutation from '~graphql/mutations/virtualRun/updateRun.gql'
// import runsCheckedQuery from '~graphql/queries/virtualRun/runsChecked.gql'
import runsCheckedPaginationQuery from '~graphql/queries/virtualRun/runsCheckedPagination.gql'
import runsCheckedSearchQuery from '~graphql/queries/virtualRun/runsCheckedSearch.gql'
const Panel = Collapse.Panel
const FormItem = Form.Item

@withApollo

@graphql(removeRunMutation, { name: 'removeRunMutation' })
@graphql(eventQuery, {
  name: 'eventData',
  options: ({ match: { params: { eventId } } }) => ({
    variables: { _id: eventId },
    fetchPolicy: 'network-only'
  })
})
@graphql(eventTicketItemSearchQuery, {
  name: 'eventticketItem',
  options: ({ match: { params: { eventId } } }) => {
    return {
      variables: {
        search: '',
        eventId: eventId,
        fetchPolicy: 'network-only'
      }
    }
  }
})
@graphql(RunsQuery, {
  name: 'RunsData',
  options: ({ match: { params: { eventId } } }) => {
    return {
      variables: { _id: eventId },
      fetchPolicy: 'network-only',
    }
  },
})
@graphql(runsCheckedPaginationQuery, {
  name: 'RunsCheckData',
  options: ({ match: { params: { eventId } } }) => {
    return {
      variables: { _id: eventId },
      page: 1,
      perPage: 50,
      fetchPolicy: 'network-only',
    }
  },
})
@graphql(runsCheckedSearchQuery, {
  name: 'RunsChecSearchkData',
  options: ({ match: { params: { eventId } } }) => {
    return {
      variables: { _id: eventId },
      bib: '0',
      fetchPolicy: 'network-only',
    }
  },
})
@graphql(ApproveRunMutation, { name: 'ApproveRunMutation' })
@graphql(UpdateRunMutation, { name: 'UpdateRunMutation' })

class EventRunRequests extends Component {
  state = {
    confirmDirty: false,
    exporting: false,
    isOpen: [],
    runner: null,
    runnerAllEvent: null,
    bibSearch: '',
    loading: false,
    TicketItem: null,
    visible: false,
    runData: null,
    selected: {},
    runChecksData: null,
    perPage: 50,
    page: 1,
    checkedCount: 0,
    isSearch: false,
    checkedSearchCount: 0,
  }

  handleExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRunExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventRunExport.url')
        const fileName = _get(data, 'eventRunExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }

  checkBib = (searchBib) => {
    const bib = searchBib
    if (bib === undefined) {
      return message.error('Not found data')
    }
    this.setState({
      bibSearch: bib
    })
    this.props.eventticketItem.refetch({
      search: bib,
      eventId: this.props.match.params.eventId
    }).then(({ data }) => {
      if (!data.eventTicketItemSearch || data.eventTicketItemSearch.length === 0) {
        return message.error('Not found')
      }
      this.setState({
        TicketItem: data.eventTicketItemSearch
      })
    })
  }

  handleRunnerImageClick = (run) => (e) => {
    Modal.warning({
      width: '50vw',
      maskClosable: true,
      bodyStile: { padding: '6px' },
      content: <React.Fragment>
        <div style={{ marginLeft: '-38px' }}>
          {run && <img alt="runner" src={_get(run, 'runnerImage.original.url')} style={{ maxWidth: '100%' }} /> }
        </div>
      </React.Fragment>
    })
  }

  handleActivityImageClick = (run) => (e) => {
    Modal.warning({
      width: '50vw',
      maskClosable: true,
      bodyStile: { padding: '6px' },
      content: <React.Fragment>
        <div style={{ marginLeft: '-38px' }}>
          {run && <img alt="activity" src={_get(run, 'activityImage.original.url')} style={{ maxWidth: '100%' }} /> }
        </div>
      </React.Fragment>
    })
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false
    })
  }

  componentDidMount() {
    this.props.RunsData.refetch({
      eventId: this.props.match.params.eventId
    }).then(({ data }) => {
      this.setState({
        runData: data.runs
      })
    })
    this.props.RunsCheckData.refetch({
      _id: this.props.match.params.eventId,
      page: this.state.page,
      perPage: this.state.perPage,
    }).then(({ data }) => {
      this.setState({
        runChecksData: data.raceRunsChecked.items,
        checkedCount: data.raceRunsChecked.count
      })
    })
  }

  onFilterBib = async(searchBib) => {
    this.setState({
      loading: true,
      isSearch: true
    })
    
    await this.props.RunsChecSearchkData.refetch({
      _id: this.props.match.params.eventId,
      bib: Number(searchBib),
    }).then(({ data }) => {
      this.setState({
        runChecksData: data.raceRunsChecked.items,
        checkedSearchCount: data.raceRunsChecked.count,
        perPage: data.raceRunsChecked.count,
      })
    })
    // if(searchBib && searchBib !== ''){
    //   const filteredRuns = this.state.runChecksData.filter(({ bibNumber }) => {
    //     return parseInt(bibNumber) === parseInt(searchBib)
    //   });
  
    //   this.setState({
    //     runChecksData: filteredRuns
    //   });
    // }

    this.setState({
      loading: false
    });
  }
  
  onLoadMore = async (page) => {
    this.setState({
      loading: true,
    })

    await this.props.RunsCheckData.refetch({
      _id: this.props.match.params.eventId,
      page: page,
      perPage: this.state.perPage,
    }).then(({ data }) => {
      this.setState({
        runChecksData: data.raceRunsChecked.items,
        checkedCount: data.raceRunsChecked.count
      })
    })

    this.setState({
      loading: false
    });
    
  }

  render () {
    const { eventData } = this.props
    if (eventData.loading === true) {
      return <Loading />
    }
    if (!eventData.event) {
      return <EventNotFound />
    }
    const InputGroup = Input.Group
    const { runner, TicketItem } = this.state
    const EditUpdateRun = ({distance, _id , pace ,elevationGain}) => {
      const {eventData} = this.props
      // console.log(eventData);
      const isPace = _get(eventData, 'event.settings.virtualRun.isPace')
      const isElevationGain = _get(eventData, 'event.settings.virtualRun.isElevationGain')
      const [dist, setDist] = useState(distance)
      const [pac, setPace] = useState(pace)
      const [elev, setElev] = useState(elevationGain)
      return <div>
        <InputGroup compact>
        {/* {_id} */}
        <p style={{paddingRight :'10px', width: 100}}>Distance</p>
          <InputNumber style={{ width: 100 }} onChange={value => {
            setDist(value)
            }} defaultValue={dist} />
  
        </InputGroup>
        
        { isPace &&<InputGroup compact>
        {/* {_id} */}
        <p style={{paddingRight :'10px' , width: 100}}>Pace</p>
        <InputNumber style={{ width: 100 }} onChange={value => {
            setPace(value)
            }} defaultValue={pac} />
 
        </InputGroup>}
        {isElevationGain &&<InputGroup compact>
        {/* {_id} */}
        <p style={{paddingRight :'10px', width: 100}}>ElevationGain</p>
        <InputNumber style={{ width: 100 }} onChange={value => {
            setElev(value)
            }} defaultValue={elev} />
          
        </InputGroup>}
        <Button loading={this.state.loading}
            onClick={() => handleUpdateRun({ _id, distance: dist , pace : pac , elevationGain : elev })}
            type="primary" icon='save'> update</Button>
      </div>

    }
    const RemoveRuns = ({data}) => {
      if (!data) return 'loading...'
      const distanceUnit = data.ticketType.race.distanceUnit
      const runDatas = data.runs
      const runList = runDatas.map(run => {
        const timestamp = run._id.toString().substring(0, 8)
        const date = new Date(parseInt(timestamp, 16) * 1000)
        const day = moment(date).format('LLLL')
        return <Row key={run._id}>
          <Col xs={24} md={8}>
            <p>{day}</p>
            <EditUpdateRun distance={run.distance} _id={run._id} pace={run.pace} elevationGain={run.elevationGain} />
          </Col>
          <Col xs={24} md={8} >
            <div className='preview' onClick={this.handleRunnerImageClick(run)} style={{backgroundImage: `url("${_get(run, 'runnerImage.thumbnail.url')}")`, display: 'inline-block', backgroundSize: 'cover', backgroundPosition: '50%', width: '240px', height: '208px'}}></div>
          </Col>
          {/* <Col xs={24} md={8} style={{textAlign: 'center'}} >
            <div className='preview' onClick={this.handleActivityImageClick(run)}style={{backgroundImage: `url("${_get(run, 'activityImage.thumbnail.url')}")`, display: 'inline-block', backgroundSize: 'cover', backgroundPosition: '50%', width: '240px', height: '208px'}}></div>
          </Col> */}
          <Col xs={24} md={8}>
            <Popconfirm title="ต้องการลบผลการวิ่งนี้ ?" onConfirm={() => handleRemoveRun({ run })}>
              <Button icon="delete" style={{ background: 'red', color: 'white', fontWeight: 'bold' }}>
                {`${run.distance} ${distanceUnit}`}
              </Button>
            </Popconfirm>
          </Col>
          <Col span={24}><hr/></Col>
        </Row>
      })
      return <div className="removeRuns">
        {runList}
      </div>
    }

    const handleRemoveRun = ({run}) => {
      this.setState({
        loading: true
      })
      const { _id } = run
      this.props.client.mutate({
        mutation: removeRunMutation,
        variables: {
          _id: _id
        }
      }).then(res => {
        this.props.eventticketItem.refetch({
          search: this.state.bibSearch,
          eventId: this.props.match.params.eventId
        }
        ).then(({ data }) => {
          if (!data.eventTicketItemSearch || data.eventTicketItemSearch.length === 0) {
            return message.error('Not found')
          }
          this.setState({
            loading: false,
            TicketItem: data.eventTicketItemSearch
          })
          message.success('completed')
        }).catch(err => this.setState({ loading: false }))
      }).catch(err => this.setState({ loading: false }))
    }
    const handleUpdateRun = ({distance, _id , pace , elevationGain}) => {
      this.setState({
        loading: true
      })
      this.props.client.mutate({
        mutation: UpdateRunMutation,
        variables: {
          record: {
            _id,
            distance,
            pace,
            elevationGain
          }
        }
      }).then(res => {
        this.props.eventticketItem.refetch({
          search: this.state.bibSearch,
          eventId: this.props.match.params.eventId
        }
        ).then(({ data }) => {
          if (!data.eventTicketItemSearch || data.eventTicketItemSearch.length === 0) {
            return message.error('Not found')
          }
          this.setState({
            loading: false,
            TicketItem: data.eventTicketItemSearch
          })
          message.success('completed')
        }).catch(err => this.setState({ loading: false }))
      }).catch(err => this.setState({ loading: false }))
    }

    const columns = [{
      title: 'Name',
      dataIndex: 'name.th',
      key: 'name'
    }, {
      title: 'ToTalRunDistance',
      dataIndex: 'totalRunDistance',
      key: 'totalRunDistance',
      render: (totalRunDistance) => {
        return totalRunDistance.toFixed(2)
      }
    }, {
      title: 'ToTalRunDonation',
      dataIndex: 'totalRunDonation',
      key: 'totalRunDonation'
    }, {
      title: 'RunSubmittedCount',
      dataIndex: 'runSubmittedCount',
      key: 'runSubmittedCount'
    }]

    const Search = [{
      title: 'Number',
      dataIndex: 'number',
      key: 'number'
    }, {
      title: 'Name',
      dataIndex: 'participant.profile.firstName',
      key: 'participant.profile.firstName'
    }, {
      title: 'Email',
      dataIndex: 'participant.profile.email',
      key: 'participant.profile.email'
    }, {
      title: 'Telephone',
      dataIndex: 'participant.profile.telephone',
      key: 'participant.profile.telephone'
    }, {
      title: 'Race',
      dataIndex: 'ticketType.race.distance',
      key: 'ticketType.race.distance'
    }, {
      title: 'ToTalDistance',
      dataIndex: 'runs',
      render: (runs, event) => {
        const distanceMode = _get(event, 'event.settings.virtualRun.distanceMode')
        const isAutoApproved = _get(event, 'event.settings.virtualRun.isAutoApproved')
        if (distanceMode === 'cumulative') {
          if(isAutoApproved === false){
            const run = runs.filter(record => record.status === 'approved' && record.checked !== null)
            return parseFloat(_sumBy(run, 'distance').toFixed(2))
          } else {
            return parseFloat(_sumBy(runs, 'distance').toFixed(2))
          }
          
        }
        if (distanceMode === 'recent') {
          const run = _last(runs)
          const distance = run.distance
          return distance
        }
      }
    }, {
      title: 'DeletedDistance',
      dataIndex: 'deletedRuns',
      render: (deletedRuns, event) => {
        const distanceMode = _get(event, 'event.settings.virtualRun.distanceMode')
        if (distanceMode === 'cumulative') {
          return parseFloat(_sumBy(deletedRuns, 'distance').toFixed(2))
        }
        if (distanceMode === 'recent') {
          return '-'
        }
      }
    }, {
      title: 'ToTalAllDistance',
      dataIndex: 'allRuns',
      render: (allRuns, event) => {
        const distanceMode = _get(event, 'event.settings.virtualRun.distanceMode')
        const isAutoApproved = _get(event, 'event.settings.virtualRun.isAutoApproved')
        const isPace = _get(event, 'event.settings.virtualRun.isPace')
        const isElevationGain = _get(event, 'event.settings.virtualRun.isElevationGain')
        console.log(isAutoApproved);
        if (distanceMode === 'cumulative') {
          if(isAutoApproved === false) {
            console.log(allRuns);
            const allRunApproved = allRuns.filter(record => record.status === 'approved' && record.checked !== null)
            return parseFloat(_sumBy(allRunApproved, 'distance').toFixed(2))
          }else {
            return parseFloat(_sumBy(allRuns, 'distance').toFixed(2))
          }
          
        }
        if (distanceMode === 'recent') {
          return '-'
        }
      }
    // }, {
    //   title: 'Completion',
    //   dataIndex: 'racecompletion',
    //   key: 'racecompletion.completedAt',
    //   render: (racecompletion) => {
    //     const date = racecompletion && moment(racecompletion.completedAt).format('LLLL')
    //     return date
    //   }
    }, {
      title: 'status',
      dataIndex: 'runs',
      render: (runs, ticketType) => {
        const totalDistance = parseFloat(_sumBy(runs, 'distance').toFixed(2))
        const distance = ticketType.ticketType.race.distance
        if (totalDistance >= distance) {
          return 'Completed'
        }
        return 'Not Completed'
      }
    }]
    const customPanelStyle = {
      border: 0,
      borderRadius: 4,
      marginBottom: 10
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }

    const onRadioChange = (e, index) => {
      let value = e.target.value
      let runs = [...this.state.runData]
      runs[index].status = value
      this.setState({
        runData: runs
      })
    }
    const onRadioUpdateChange = (e, index) => {
      let value = e.target.value
      let runs = [...this.state.runChecksData]
      runs[index].status = value
      this.setState({
        runChecksData: runs
      })
    }

    const onTextAreaChange = (e, index) => {
      let value = e.target.value
      let runs = [...this.state.runData]
      runs[index].remarks = value
      this.setState({
        runData: runs
      })
    }
    const onTextAreaUpdateChange = (e, index) => {
      let value = e.target.value
      let runs = [...this.state.runChecksData]
      runs[index].remarks = value
      this.setState({
        runChecksData: runs
      })
    }

    const onApproveRun = (_id, index) => {
      this.setState({
        loading: true
      })
      this.props.client.mutate({
        mutation: ApproveRunMutation,
        variables: {
          record: {
            _id: _id,
            status: this.state.runData[index].status,
            remarks: this.state.runData[index].remarks,
          }
        }
      }).then(res => {
        this.props.RunsData.refetch({
          eventId: this.props.match.params.eventId
        }
        ).then(({ data }) => {
          this.setState({
            loading: false,
            runData: data.runs
          })
          this.props.RunsCheckData.refetch({
            _id: this.props.match.params.eventId
          }
          ).then(({ data }) => {
            this.setState({
              loading: false,
              runChecksData: data.runsCheck
            })
            message.success('completed')
          })
        }
        ).catch(err => this.setState({ loading: false }))
      }).catch(err => this.setState({ loading: false }))
    }
    const onApproveUpdateRun = (_id, index) => {
      this.setState({
        loading: true
      })
      this.props.client.mutate({
        mutation: ApproveRunMutation,
        variables: {
          record: {
            _id: _id,
            status: this.state.runChecksData[index].status,
            remarks: this.state.runChecksData[index].remarks,
          }
        }
      }).then(res => {
        this.props.RunsCheckData.refetch({
          _id: this.props.match.params.eventId
        }
        ).then(({ data }) => {
          this.setState({
            loading: false,
            runChecksData: data.runsCheck
          })
          message.success('completed')
        }).catch(err => this.setState({ loading: false }))
      }).catch(err => this.setState({ loading: false }))
    }

    const runColumn = [
      {
        title: 'BIB',
        dataIndex: 'bibNumber',
        key: 'bibNumber'
      },
     
      {
        title: 'img',
        dataIndex: 'runnerImage',
        key: 'img',
        render: (runnerImage, record) => (<React.Fragment><img onClick={this.handleRunnerImageClick(record)} style={{width: '100%', height: 'auto'}} src={_get(runnerImage, 'original.url')} /> <p>Submitted: {moment(record.createdAt).format('LLLL')}</p></React.Fragment>) 
      },
      {
        title: 'distance',
        dataIndex: 'distance',
        key: 'distance',
        render: (distance) => {
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <h1>{distance}</h1>
          }
        }
      },
      {
        title: 'pace',
        dataIndex: 'pace',
        key: 'pace',
        render: (pace) => {
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <h1>{pace}</h1>
          }
        }
      },
      {
        title: 'elevationGain',
        dataIndex: 'elevationGain',
        key: 'elevationGain',
        render: (elevationGain) => {
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <h1>{elevationGain}</h1>
          }
        }
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        render: (row, record) => {
          const index = this.state.runData.indexOf(record)
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <Radio.Group value={this.state.runData[index].status} onChange={(e) => onRadioChange(e, index)} style={{display: 'flex', flexDirection: 'column'}}>
            <Radio value={'approved'}>
              Approve
            </Radio>
            <Radio value={'rejected'}>
              Reject
              {this.state.runData[index].status === 'rejected'? <Input.TextArea required style={{ width: 100, marginLeft: 10 }} onChange={(e) => onTextAreaChange(e, index)} /> : null}
            </Radio>
          </Radio.Group>
          } 
        }
      },
      {
        title: 'action',
        dataIndex: '_id',
        key: 'action',
        render: (_id, record) =>{ 
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <Button onClick={e=>onApproveRun(_id, this.state.runData.indexOf(record))}>submit</Button>
          }
        }
      },
    ]
    const runChangeColumn = [
      {
        title: 'number',
        dataIndex: 'bibNumber',
        key: 'bibNumber'
      },
      {
        title: 'approved date',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (record) => moment(record).format('LLLL')
      },
      {
        title: 'img',
        dataIndex: 'runnerImage',
        key: 'img',
        render: (runnerImage, record) => <img onClick={this.handleRunnerImageClick(record)} style={{width: '100px'}} src={_get(runnerImage, 'original.url')} />
      },
      {
        title: 'distance',
        dataIndex: 'distance',
        key: 'distance'
      },
      {
        title: 'pace',
        dataIndex: 'pace',
        key: 'pace',
        render: (pace) => {
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <h1>{pace}</h1>
          }
        }
      },
      {
        title: 'elevationGain',
        dataIndex: 'elevationGain',
        key: 'elevationGain',
        render: (elevationGain) => {
          return {
            props: {
              style:{verticalAlign: 'top'}
            },
            children: <h1>{elevationGain}</h1>
          }
        }
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        render: (row, record) => {
          const index = this.state.runChecksData.indexOf(record)
          return <Radio.Group value={this.state.runChecksData[index].status} onChange={(e) => onRadioUpdateChange(e, index)}>
            <Radio value={'approved'}>
              Approved
            </Radio>
            <Radio value={'rejected'}>
              Rejected
              {this.state.runChecksData[index].status === 'rejected'? <Input.TextArea value={this.state.runChecksData[index].remarks} required style={{ width: 100, marginLeft: 10 }} onChange={(e) => onTextAreaUpdateChange(e, index)} /> : null}
            </Radio>
          </Radio.Group>
        }
      },
      {
        title: 'action',
        dataIndex: '_id',
        key: 'action',
        render: (_id, record) => <Button onClick={e=>onApproveUpdateRun(_id, this.state.runChecksData.indexOf(record))}>submit</Button>
      },
    ]

    return (
      <Layout>
        <Layout.Content>
          <TopContent
            title={eventData.event.name.en}
            nodes={{
              InputSearch: '',
              SelectStatus: <Button
              onClick={this.handleExport}
              icon="export"
              loading={this.state.exporting}
            >
            Export
            </Button>,
            }}
            pattern='registrations'/>
          <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <Collapse bordered={false} accordion>
                <Panel header="Overall Running Result" key="1" style={customPanelStyle}>
                  <Table rowKey='_id' dataSource={[eventData.event]} columns={columns} />
                </Panel>
                <Panel header="Runner search" key="2" style={customPanelStyle}>
                  <FormItem hasFeedback {...formItemLayout}>
                    <Input.Search
                      placeholder="Bib,Phone number,E-mail"
                      disabled={!!runner}
                      onSearch={this.checkBib}
                      enterButton={!runner && 'ค้นหา'}
                    />
                  </FormItem>
                  <Table
                    rowKey='_id'
                    dataSource={TicketItem}
                    columns= {Search}
                    loading= {this.state.loading}
                    expandedRowRender={ record => <RemoveRuns data={record}/> }
                  />
                </Panel>
                {
                  _get(eventData, 'event.settings.virtualRun.isAutoApproved') === false ? (
                    <Panel header={<Badge overflowCount={999999} offset={[8, -8]} count={_size(this.state.runData)}>Result List (Pending)</Badge>} key="3" style={customPanelStyle}>
                      <Table
                      rowKey=''
                      dataSource={this.state.runData}
                      columns= {runColumn}
                      loading= {this.state.loading}
                      scroll={{x:true}}
                     
                    />
                    </Panel>
                  ) : ""
                }
                {
                  _get(eventData, 'event.settings.virtualRun.isAutoApproved') === false ? (
                    <Panel header={<Badge overflowCount={999999} style={{ backgroundColor: '#52c41a' }} offset={[8, -8]} count={this.state.checkedCount}>Result List (Approved)</Badge>} key="4" style={customPanelStyle}>
                      <FormItem hasFeedback {...formItemLayout}>
                        <Input.Search
                          placeholder="เลขบิบ"
                          onSearch={this.onFilterBib}
                        />
                      </FormItem>
                      <Table
                        rowKey=''
                        dataSource={this.state.runChecksData}
                        columns= {runChangeColumn}
                        loading= {this.state.loading}
                        scroll={{x:true}}
                        pagination={this.state.isSearch === false ? { pageSize: this.state.perPage, total: this.state.checkedCount, onChange: this.onLoadMore }: {total: this.state.checkedSearchCount, pageSize: this.state.perPage}}
                      />
                    </Panel>
                  ) : ""
                }
              </Collapse>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default EventRunRequests
