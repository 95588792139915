import React from 'react'
import { Button, Row, Col } from 'antd'
import _get from 'lodash/get'

const Item = ({ shirtType, shirts, onShirtClick, onDeleteShirtType, loading }) => {
  const { _id, name } = shirtType
  const _shirts = shirts.filter(shirt => shirt.shirtTypeId === shirtType._id)
  return (
    <Row style={{ background: 'white' }} className="borderBottomItem eventItem">
      <Col xs={18} md={12}>{name.th}<br/>{name.en}</Col>
      <Col xs={6} md={12} style={{ textAlign: 'right' }}>
        <Button icon="edit" className="edit"
          loading={loading}
          onClick={() => onShirtClick(_id)}
        />{' '}
        <Button icon="delete" className="delete" loading={loading}
          onClick={() => onDeleteShirtType({shirtType})}
        />
      </Col>
      <Col xs={24} md={24}>
        <ul>
          <li>
            {_shirts.map(shirt => {
              return <div>
                => {shirt.name.en}
                <br/>({shirt.name.th})
                {_get(shirt, 'quantity') && <span style={{color: 'blue'}}>
                  {` จำกัด: ${_get(shirt, 'quantity')} `}
                </span>}
                {_get(shirt, 'isFull') && <span style={{color: 'red'}}>
                {' '} ปิดการขาย  
                </span>}
              </div>
            })}
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default Item
