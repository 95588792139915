import React from 'react'
import {
    //Card,
    Table,
} from 'antd'

import '../Styles/Custom.css'
//const { Meta } = Card
import ExpandIcon from '../Icons/expandIcon';

import _get from 'lodash/get'

const GraphApex = ({param}) => {

    //const datasource = param.datasource 
    const dataquery = param.dataqueries     
    const chartRes = param.chart
    const loading = param.loading

    const opt   = { 
        title: chartRes.caption,
        subtitle: param.subTitle,
        width: param.width,
        height: (param.height ? param.height : '300px'),
    }
    
    const columns = [
        {
            title: 'PRODUCT',
            // dataIndex: 'name.en',
            key: `productName`,
            className: 'align-left fullx',
            render: ({product}) => {
                                       
              return (
                  _get(product, 'name.th') || 'TOTAL'
              );
            }
          }, //{title: 'Submitted',dataIndex: 'submitted',key: `submitted`}, 
          {
            title: 'PAID',
            dataIndex: 'paid',
            key: `paid`,
            className: 'center amount small'
          }, {
            title: 'TOTAL',
            dataIndex: 'total',
            key: `total`,
            className: 'center amount small'
          }
    ]

    const orderItemTypeColumns = [
        {
        title: 'TYPE',
        // dataIndex: 'name.en',
        key: `type`,
        className: 'align-left fullx string',
        render: (record) => _get(record, 'orderItemType.name.th') || '-'
        }, {
    //      title: 'Submitted',
    //      dataIndex: 'submitted',
    //      key: `submitted`
    //    }, {
        title: 'PAID',
        dataIndex: 'paid',
        key: `paid`,
        className: 'center amount small'
        }, {
        title: 'TOTAL',
        dataIndex: 'total',
        key: `total`,
        className: 'center amount small'
        }
    ]

    //console.log("orderProduct", dataquery)

    return (
            <div hoverable class='fgma-card' style={{ backgroundColor:'#FFF', border:'1px solid #EBEBEB', boxShadow:'0px 0px 1px rgb(0,0,0,0.1)' }}>
                <div className='fgma-chart' style={{ margin: '20px', marginBottom: '25px', fontSize: '1.3em', paddingLeft: '5px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>
                <Table
                    style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF', minHeight: opt.height}}
                    scroll={{ x: true }}
                    columns={columns}
                    className="eventFgma"
                    rowClassName={record=>{
                      return record.orderItemTypes === undefined ? " row-hide-expand" : ""
                    }}
                    dataSource={dataquery}            
                    expandedRowRender={record => {
                      const { orderItemTypes } = record             
                      const table = <Table
                        dataSource={orderItemTypes}
                        loading={loading}
                        columns={orderItemTypeColumns}
                        className="subEventFgma"
                        locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
                        rowKey={record => record._id}
                        pagination={false}
                      />
                      return <div>{table}</div>

                    }}
                    // defaultExpandAllRows
                    loading={loading}
                    locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
                    rowKey={record => {
                      return record._id
                    }}
                    pagination={false}
                  />                
                <div>&nbsp;</div>
            </div>
    )


}

export default GraphApex
