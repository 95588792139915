import React, { useState } from 'react'
import _get from 'lodash/get'
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  DatePicker,
  Form,
  message,
  Table
} from 'antd'
import { useLazyQuery } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import paymentOrganizerDailySlip from '~/graphql/queries/paymentOrganizerDailySlip/paymentOrganizerDailySlip.gql'
import _sortBy from 'lodash/sortBy'
import _first from 'lodash/first'
import _last from 'lodash/last'
const {RangePicker} = DatePicker
const dateFormat = 'DD/MM/YYYY HH:mm:ss'
const toNP = value => (value && `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
const toN = value => (value && `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
const OrganizerDailySlip = (props) => {
  const { event } = props
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [visible, setVisible] = useState(false)
  const [isList, setIsList] = useState(false)
  const charge = event.settings.charge
  // const chargeParticipantMin = _get(charge, 'participantMin') || 0
  const twoSectionParticipantInitNumber = _get(charge, 'twoSectionParticipantInitNumber') || 0
  const twoSectionParticipantInitBasePrice = _get(charge, 'twoSectionParticipantInitBasePrice') || 0
  const twoSectionParticipantNextBasePrice = _get(charge, 'twoSectionParticipantNextBasePrice') || 0
  // const participantCount = _get(tickets, [0, 'participantCount']) || 0
  const [getData, { called, loading, data }] = useLazyQuery(paymentOrganizerDailySlip, {
    variables: {
      eventId: event._id,
      all: true
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const dailySlip = _get(data, ['paymentOrganizerDailySlip', 0, 'PaymentDailySlipTypeList'])
      if(dailySlip.filter(s=>s).length > 0) {
        setVisible(false)
        setTimeout(() => {
          window.print()
        }
        , 1500)
      } else {
        message.error('Data not found')
      }
      
    }
  })
  const totalAmountData = {}
  const totalAmountWVData = {}
  const sumDataMap = {
    quantity: 0,
    unitPrice: 0,
    amount: 0,
    vat: 0,
    total: 0,
    accumulate: 0,
  }
  const tableDataList = []
  const trList = {}
  // if (called && loading) return <p>Loading ...</p>
  // if (!called) {
  //   return <button onClick={() => getData()}>Load greeting</button>
  // }
  const organizerDailySlip = _get(event,'settings.organizerDailySlip')
  const tr2Data = [
    {
      td1: 1,
      td3: 'ค่า Bankfee สินค้าพวงถุงเท้าสั้น Compressport - Pro V3 "RUN BANGSEAN"',
      td4: 1,
      td5: '90.00',
      td7: '90.00',
    },
    {
      td1: 1,
      td3: 'ค่า Bankfee สินค้าพวงถุงเท้าสั้น Compressport - Pro V3 "RUN BANGSEAN"',
      td4: 1,
      td5: '90.00',
      td7: '90.00',
    },
    {
      td1: 1,
      td3: 'ค่า Bankfee สินค้าพวงถุงเท้าสั้น Compressport - Pro V3 "RUN BANGSEAN"',
      td4: 1,
      td5: '90.00',
      td7: '90.00',
    },
  ]
  
  // const tempDailySlip = [...Array(6).keys()]
  const gen1Tr = (d) => {
    return _get(d, 'description') ? <tr className='tr2 mainList'>
      <td des='No' className='td1'>{`${_get(d, 'amount') ? d.i+1 : ''}.`}</td>
      <td des='Item No' className='td2'></td>
      <td des='Description' className='td3'>{_get(d, 'description')}</td>
      <td des='Quantity' className='td4'>{
        _get(d, 'amount') ?
          toN(_get(d, 'quantity')) :
          ''
      }</td>
      <td des='Unit Price' className='td5'>
      {
        _get(d, 'amount') ?
        toNP(_get(d, 'unitPrice')) :
          ''
      }
      </td>
      <td des='Discount' className='td6'>
      {
        _get(d, 'amount') ?
        toNP(_get(d, 'discount')) :
          ''
      }
      </td>
      <td des='Amount' className='td7'>
      {
        _get(d, 'amount') ?
        toNP(_get(d, 'amount')) :
          ''
      }
      </td>
    </tr>
      : <tr className='tr2'>
      <td className='td1'>&nbsp;</td>
      <td className='td2'></td>
      <td className='td3'></td>
      <td className='td4'></td>
      <td className='td5'></td>
      <td className='td6'></td>
      <td className='td7'></td>
    </tr>
  }
  const paymentMethods = ['transfer', 'omise_card', 'omise_3ds', 'omise_netbank', 'omise_alipay', 'omise_tesco', 'omise_promptpay', 'omise_truemoney', 'omise_points_citi', 'paypal', 'linepay', 'paysbuy_netbank', 'paysbuy_cash', 'payatall_cash', 'couponcode', 'prepaid', 'free', 'scb_qr', 'scb_slip']
  const genTr2List = (s) => 
  {
    totalAmountData[s._id] = 0
    totalAmountWVData[s._id] = 0
    const mapData = []
    if (_get(charge, 'percentage') > 0) {
      const ticketAmount = _get(s, 'ticketAmount') || 0
      const productAmount = _get(s, 'productAmount') || 0
      const deliveryAmount = _get(s, 'deliveryAmount') || 0
      const amount = (ticketAmount + productAmount + deliveryAmount) * _get(charge, 'percentage')
      totalAmountData[s._id] += Math.round(amount * 100) / 100
      const vat =  Math.round(amount * 100 * 0.07)/ 100
      totalAmountWVData[s._id] = totalAmountData[s._id] + vat
      if(amount > 0) {
        const data = {
          description: `ค่าบริการ (แบบคิด ${_get(charge, 'percentage')*100} %)`,
          quantity: ticketAmount + productAmount + deliveryAmount,
          unitPrice: _get(charge, 'percentage'),
          amount
        }
        mapData.push(data)
        tableDataList.push({
          ...data,
          date: s._id,
          vat
        })
      }
    }
    if(_get(s, 'perTicketThairunFees') > 0) {
      const amount = _get(s, `perTicketThairunFees`)
      totalAmountData[s._id] += Math.round(amount * 100) / 100
      const vat =  Math.round(amount * 100 * 0.07)/ 100
      totalAmountWVData[s._id] = totalAmountData[s._id] + vat
      const data = {
        description: `ค่าบริการ (แบบคิด ต่อหัว)`,
        quantity: _get(s, 'perTicketCount'),
        unitPrice: charge.perTicket,
        amount
      }
      mapData.push(data)
      tableDataList.push({
        ...data,
        date: s._id,
        vat
      })
    }

    if(_get(s, 'perTicketCount') > 0) {
      if(
        _get(s, 'nextDateString') &&
        twoSectionParticipantInitNumber > 0 &&
        twoSectionParticipantInitBasePrice > 0 &&
        moment(s._id).isBefore(s.nextDateString)
      ) {
        totalAmountData[s._id] += Math.round(
            twoSectionParticipantInitBasePrice *
            _get(s, 'perTicketCount') *
            100
          ) / 100
        const amount = twoSectionParticipantInitBasePrice * _get(s, 'perTicketCount')
        const vat =  Math.round(amount * 100 * 0.07)/ 100
        totalAmountWVData[s._id] = totalAmountData[s._id] + vat
        const data = {
          description: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครขั้นต่ำ ${twoSectionParticipantInitNumber} คนแรก)`,
          quantity: _get(s, 'perTicketCount'),
          unitPrice: twoSectionParticipantInitBasePrice,
          amount
        }
        mapData.push(data)
        tableDataList.push({
          ...data,
          date: s._id,
          vat
        })
      }

      if(
        _get(s, 'nextDateString') &&
        twoSectionParticipantInitNumber > 0 &&
        twoSectionParticipantInitBasePrice > 0 &&
        s._id === s.nextDateString &&
        _get(s, 'remainNPD') > 0
      ) {
        const amount = twoSectionParticipantInitBasePrice * _get(s, 'remainNPD')
        totalAmountData[s._id] += Math.round(
          amount *
          100
        ) / 100
        const vat =  Math.round(amount * 100 * 0.07)/ 100
        totalAmountWVData[s._id] = totalAmountData[s._id] + vat
        const data = {
          description: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครขั้นต่ำ ${twoSectionParticipantInitNumber} คนแรก)`,
          quantity: _get(s, 'remainNPD'),
          unitPrice: twoSectionParticipantInitBasePrice,
          amount
        }
        mapData.push(data)
        tableDataList.push({
          ...data,
          date: s._id,
          vat
        })
      }
      const npd = _get(s, 'perTicketCount') - _get(s, 'remainNPD')
      if(
        _get(s, 'nextDateString') &&
        twoSectionParticipantNextBasePrice > 0 &&
        s._id === s.nextDateString &&
        npd > 0
      ) {
        const amount = npd * twoSectionParticipantNextBasePrice
        // console.log('twoSectionParticipantInitBasePrice', twoSectionParticipantInitBasePrice, _get(s, 'perTicketCount'), s)
        totalAmountData[s._id] += Math.round(
          amount *
          100
        ) / 100
        const vat =  Math.round(amount * 100 * 0.07)/ 100
        totalAmountWVData[s._id] = totalAmountData[s._id] + vat
        const data = {
          description: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครคนที่ ${twoSectionParticipantInitNumber + 1})`,
          quantity: npd,
          unitPrice: twoSectionParticipantNextBasePrice,
          amount
        }
        mapData.push(data)
        tableDataList.push({
          ...data,
          date: s._id,
          vat
        })
      }

      if(
        _get(s, 'nextDateString') &&
        twoSectionParticipantNextBasePrice > 0 &&
        moment(s._id).isAfter(s.nextDateString)
      ) {
        totalAmountData[s._id] += Math.round(
          twoSectionParticipantNextBasePrice *
            _get(s, 'perTicketCount') *
            100
          ) / 100
        const amount = twoSectionParticipantNextBasePrice * _get(s, 'perTicketCount')
        const vat =  Math.round(amount * 100 * 0.07)/ 100
        totalAmountWVData[s._id] = totalAmountData[s._id] + vat
        const data = {
          description: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครคนที่ ${twoSectionParticipantInitNumber + 1})`,
          quantity: _get(s, 'perTicketCount'),
          unitPrice: twoSectionParticipantNextBasePrice,
          amount
        }
        mapData.push(data)
        tableDataList.push({
          ...data,
          date: s._id,
          vat
        })
      }

    }
    if(_get(s, 'perCouponCodeCount') > 0 && _get(charge, 'perCouponCode') > 0) {
      const perCouponCodeThairunFees = s.perCouponCodeCount * charge.perCouponCode
      const amount = perCouponCodeThairunFees
      totalAmountData[s._id] += Math.round(amount * 100) / 100
      const vat =  Math.round(amount * 100 * 0.07)/ 100
      totalAmountWVData[s._id] = totalAmountData[s._id] + vat
      const data = {
        description: `ค่าบริการ Code`,
        quantity: _get(s, 'perCouponCodeCount'),
        unitPrice: charge.perCouponCode,
        amount
      }
      mapData.push(data)
      tableDataList.push({
        ...data,
        date: s._id,
        vat
      })
    }
    if(_get(s, 'organizerGatewayFeesAmount') > 0) {
      paymentMethods.forEach(method => {
        if(_get(s, `ticket_${method}`) > 0) {
          const amount = _get(s, `ticket_${method}`)
          totalAmountData[s._id] += Math.round(amount * 100) / 100
          const vat =  Math.round(amount * 100 * 0.07)/ 100
          totalAmountWVData[s._id] = totalAmountData[s._id] + vat
          const data = {
            description: `ค่าบริการตั๋ว Bank fee [${method}]`,
            quantity: 1,
            unitPrice: _get(s, `ticket_${method}`),
            amount
          }
          mapData.push(data)
          tableDataList.push({
            ...data,
            date: s._id,
            vat
          })
        }
      })
    }
    if(_get(s, 'organizerGatewayDeliveryFeesAmount') > 0) {
      paymentMethods.forEach(method => {
        if(_get(s, `delivery_${method}`) > 0) {
          const amount = _get(s, `delivery_${method}`)
          totalAmountData[s._id] += Math.round(amount * 100) / 100
          const vat =  Math.round(amount * 100 * 0.07)/ 100
          totalAmountWVData[s._id] = totalAmountData[s._id] + vat
          const data = {
            description: `ค่าบริการ Bank fee รับ ทางไปรษณีย์ [${method}]`,
            quantity: 1,
            unitPrice: _get(s, `delivery_${method}`),
            amount
          }
          mapData.push(data)
          tableDataList.push({
            ...data,
            date: s._id,
            vat
          })
        }
      })
    }
    if(_get(s, 'productCharge') > 0) {
      const amount = _get(s, `productCharge`)
      totalAmountData[s._id] += Math.round(amount * 100) / 100
      const vat =  Math.round(amount * 100 * 0.07)/ 100
      totalAmountWVData[s._id] = totalAmountData[s._id] + vat
      const data = {
        description: `ค่าบริการสินค้าพวง`,
        quantity: 1,
        unitPrice: amount,
        amount
      }
      mapData.push(data)
      tableDataList.push({
        ...data,
        date: s._id,
        vat
      })
    }
    if(_get(s, 'organizerGatewayProductFeesAmount') > 0) {
      paymentMethods.forEach(method => {
        if(_get(s, `product_${method}`) > 0) {
          const amount = _get(s, `product_${method}`)
          totalAmountData[s._id] += Math.round(amount * 100) / 100
          const vat =  Math.round(amount * 100 * 0.07)/ 100
          totalAmountWVData[s._id] = totalAmountData[s._id] + vat
          const data = {
            description: `ค่าบริการสินค้าพวง [${method}]`,
            quantity: 1,
            unitPrice: _get(s, `product_${method}`),
            amount
          }
          mapData.push(data)
          tableDataList.push({
            ...data,
            date: s._id,
            vat
          })
        }
      })
    }
    
    // tableDataList.push({
    //   description: 'ภาษีมูลค่าเพิ่ม/VAT',
    //   date: s._id,
    //   isEnd: true,
    //   amount: Math.round(totalAmountData[s._id] * 100 * 0.07)/ 100
    // })
    mapData.push({
      description: `งาน ${event.name.th}`,
    })
    const initTrLength = 19
      const remainTrLength = initTrLength - tr2Data.length
      let tr2TempData = mapData
      if (remainTrLength > 0) {
        tr2TempData = [...Array(initTrLength).keys()].map(d => {
          const data = _get(mapData, [d])
          return data ? data : {}
        })
      }
    return tr2TempData.map((data, i) => {
      return gen1Tr({...data, i})
    })
    tr2TempData.map((d, i) => {
      return _get(d, 'td3')
      ? <tr className='tr2'>
        <td des='No' className='td1'>{`${i+1}.`}</td>
        <td des='Item No' className='td2'></td>
        <td des='Description' className='td3'>{_get(d, 'td3')}</td>
        <td des='Quantity' className='td4'>{_get(d, 'td4')}</td>
        <td des='Unit Price' className='td5'>{_get(d, 'td5')}</td>
        <td des='Discount' className='td6'>{_get(d, 'td6')}</td>
        <td des='Amount' className='td7'>{_get(d, 'td7')}</td>
      </tr>
      : <tr className='tr2'>
        <td className='td1'>&nbsp;</td>
        <td className='td2'></td>
        <td className='td3'></td>
        <td className='td4'></td>
        <td className='td5'></td>
        <td className='td6'></td>
        <td className='td7'></td>
      </tr>
    })
  }
  const genTr2Temp = () => tr2TempData.map((d, i) => {
    return _get(d, 'td3')
    ? <tr className='tr2'>
      <td className='td1'>{`${i+1}.`}</td>
      <td className='td2'></td>
      <td className='td3'>{_get(d, 'td3')}</td>
      <td className='td4'>{_get(d, 'td4')}</td>
      <td className='td5'>{_get(d, 'td5')}</td>
      <td className='td6'>{_get(d, 'td6')}</td>
      <td className='td7'>{_get(d, 'td7')}</td>
    </tr>
    : <tr className='tr2'>
      <td className='td1'>&nbsp;</td>
      <td className='td2'></td>
      <td className='td3'></td>
      <td className='td4'></td>
      <td className='td5'></td>
      <td className='td6'></td>
      <td className='td7'></td>
    </tr>
  })
  const handleExport = () => {
    // this.props.onExport()
    setIsList(false)
    getData()
  }
  const handleListExport = () => {
    // this.props.onExport()
    setIsList(true)
    getData()
  }
  const handleModalVisible = () => {
    setIsList(false)
    setVisible(true)
  }
  const handleModalVisibleListExport = () => {
    setIsList(true)
    setVisible(true)
  }
  const handleModalCancel = () => {
    setVisible(false)
  }
  const handlePickDate = () => {
    getData({
      variables: {
        startDate,
        endDate,
        all: false,
        eventId: event._id
      }
    })
  }
  const handleChange = (val) => {
    setStartDate(val[0])
    setEndDate(val[1])
  }
  const dailySlipRes = _get(data, ['paymentOrganizerDailySlip', 0, 'PaymentDailySlipTypeList']) || []
  const dailySlip = _sortBy(dailySlipRes, '_id')
  const dailySlipList = ({}) => {
    const columns = [
      {
        title: 'date',
        // dataIndex: 'date',
        key: 'date',
        render: ({date, isSum}) => {
          return <div className='alignRight'>
            {!isSum && date}
          </div>
        }
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'quantity',
        // dataIndex: 'quantity',
        key: 'quantity',
        render: ({quantity}) => {
          return <div className='alignRight'>
            {toN(quantity)}
          </div>
        }
      },
      {
        title: 'unitPrice',
        // dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: ({unitPrice}) => {
          return <div className='alignRight'>
            {toNP(unitPrice)}
          </div>
        }
      },
      {
        title: 'amount',
        // dataIndex: 'amount',
        key: 'amount',
        render: ({amount}) => {
          return <div className='alignRight'>
            {toNP(amount)}
          </div>
        }
      },
      {
        title: 'vat',
        // dataIndex: 'vat',
        key: 'vat',
        render: ({vat}) => {
          return <div className='alignRight'>
            {toNP(vat)}
          </div>
        }
      },
      {
        title: 'total',
        // dataIndex: 'vat',
        key: 'total',
        render: ({total}) => {
          return <div className='alignRight'>
            {toNP(total)}
          </div>
        }
      },
      {
        title: <div className='alignRight'>accumulate</div>,
        // dataIndex: 'accumulate',
        key: 'accumulate',
        render: ({accumulate}) => {
          return <div className='alignRight'>
            {toNP(accumulate)}
          </div>
        }
      },
    ]
    let accumulate = 0
    let lastAccumulate = 0
    const tableDataListMap = tableDataList
      .map(d => {
        accumulate += (d.amount + d.vat)
        let total = (d.amount + d.vat)
        sumDataMap['quantity'] += d.quantity
        sumDataMap['unitPrice'] += d.unitPrice
        sumDataMap['amount'] += d.amount
        sumDataMap['vat'] += d.vat
        sumDataMap['total'] += total
        sumDataMap['accumulate'] += accumulate
        lastAccumulate = accumulate
        return {
          ...d,
          total,
          accumulate
        }
      })
    tableDataListMap
      .push({
        ...sumDataMap,
        isSum: true,
        description: 'รวม',
        accumulate: lastAccumulate
      })
    const firstDate = moment(_first(tableDataList).date)
      .format('DD/MM/YYYY')
    const lastDate = moment(_last(tableDataList).date)
      .format('DD/MM/YYYY')
    return <div
      className='dailySlipListTable'
    >
      <h3>
        {event.name.th} {firstDate}-{lastDate}
      </h3>
      <Table
        dataSource={tableDataListMap}
        columns={columns}
        pagination={false}
      />
    </div>
  }
  // const dailySlipFiltered = dailySlip && dailySlip
  return <div className='print-payment-daily-wrapper-outter'>
  <div className='eventDownloadBox no-print'>
    <Card
      title='Daily Receipt'
      size="small"
      style={{marginBottom: 10}}
      key='Summary'
    >
      <div style={{marginBottom: '9px'}}>
        <Button
          onClick={handleExport}
          icon="export"
          loading={loading}
        >
          Download
        </Button>{' (All)'}
      </div>
      <div style={{marginBottom: '9px'}}>
        <Button
          onClick={handleModalVisible}
          icon="export"
          loading={loading}
        >
        Download
        </Button>{' (Select Date)'}
      </div>
      <div style={{marginBottom: '9px'}}>
        <Button
          onClick={handleListExport}
          icon="export"
          loading={loading}
        >
          Download
        </Button>{' Label (All)'}
      </div>
      <div style={{marginBottom: '9px'}}>
        <Button
          onClick={handleModalVisibleListExport}
          icon="export"
          loading={loading}
        >
        Download
        </Button>{' Label (select date)'}
      </div>
    </Card>
    <Modal
      visible={visible}
      title="Export"
      // onOk={this.handleOk}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
      Return
        </Button>,
        <Button type="primary" onClick={handlePickDate}>
      Ok
        </Button>
      ]}>
      <Form onSubmit={handlePickDate}>
        <Form.Item>
          {/* {getFieldDecorator('range-picker', this.state.config)(<RangePicker format={dateFormat} showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}/>)} */}
          {/* {getFieldDecorator('range-picker', this.state.config)(
            )} */}
            <RangePicker
              format={dateFormat}
              onChange={handleChange}
              showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}
            />
        </Form.Item>
      </Form>
    </Modal>
  </div>
  <div className='print-payment-daily-wrapper'>
    {dailySlip &&
      dailySlip.filter(s=> {
        trList[s._id] = genTr2List(s)
        return totalAmountData[s._id]
      }).length > 0 &&
      isList ?
      dailySlipList({}) :
      dailySlip
        .filter(s=> {
          // trList[s._id] = genTr2List(s)
          return totalAmountData[s._id]
        })
        .map(s => {
      return <div className='print-payment-daily'>
      <div className='line1'>
      <img src="/images/logonews.png" alt="logotr" className='line1-logo'/>
      <span className='line1-b2'>
        <div className='head-l1'>
          บริษัท ไทยดอทรัน จำกัด
        </div>
        <div>
        เลขที่ 803, 805, 807, 809  ถนน พระราม 6 แขวงวังใหม่ เขตปทุมวัน กรุงเทพฯ 10330<br/> เลขประจำตัวผู้เสียภาษี 0105560034567 (สาขาสำนักงานใหญ่)
        </div>
      </span>
      <hr/>
      </div>
      <div className='line2'>
        <div className='line2-1'>
          ใบเสร็จรับเงิน / ใบกำกับภาษี
        </div>
        <div className='line2-2'>
          RECEIPT / TAX INVOICE
        </div>
      </div>
      <div className='line3'>
        <table className='table1'>
          <tr className='tr1'>
            <td className='td1'>
              <Row>
                <Col span={5}>
                ขายให้แก่/SoldTo
                </Col>
                <Col span={24-5}>
                : {_get(organizerDailySlip, 'soldTo')}
                </Col>

                <Col span={5}>
                ที่อยู่/Address
                </Col>
                <Col span={24-5} style={{"wordBreak": "break-word", "whiteSpace": "pre-wrap"}}>
                : {_get(organizerDailySlip, 'address')}
                </Col>

                <Col span={24}>
                เลขประจำตัวผู้เสียภาษี / Tax Id. : {_get(organizerDailySlip, 'taxId')}
                </Col>
              </Row>
            </td>
            <td className='td2'>
              <div>
                เลขที่ใบเสร็จรับเงิน / ReceiptNo. : {`HS${event.code}${moment(s._id).format('YYYYMMDD')}`}
              </div>
              <div>
                วันที่ใบเสร็จรับเงิน / Receipt Date. : {moment(s._id).format('DD/MM/YYYY')}
              </div>
              <div>
                พนักงานขาย / Salesperson : {_get(organizerDailySlip, 'salesperson')}
              </div>
              {/* HS EVENTCODE 65 10 19 date */}
            </td>
          </tr>
        </table>
        <table className='table2'>
          <tr className='tr1'>
            <td className='td1'>
              ลำดับที่<br/>
              No.
            </td>
            <td className='td2'>
              รหัสสินค้า<br/>
              Item No.
            </td>
            <td className='td3'>
              รายการ<br/>
              Description
            </td>
            <td className='td4'>
              จำนวน<br/>
              Quantity
            </td>
            <td className='td5'>
              ราคาต่อหน่วย<br/>
              Unit Price
            </td>
            <td className='td6'>
              ส่วนลด<br/>
              Discount
            </td>
            <td className='td7'>
              จำนวนเงิน<br/>
              Amount
            </td>
          </tr>
          {/* {genTr2List(s)} */}
          {trList[s._id]}
          {/* {genTr2Temp()} */}
        </table>
        <table className='table3'>
          <tr className='tr1'>
            <td className='td1'>
              หมายเหตุ/Remark: {_get(organizerDailySlip, 'remark')}
            </td>
            <td className='td2'>
              รวมจำนวนเงิน/Total Amount
            </td>
            <td className='td3'>
              {toNP(totalAmountData[s._id])}
            </td>
          </tr>
          <tr className='tr2'>
            <td className='td1'></td>
            <td className='td2'>
              ส่วนลด/Discount จำนวนเงิน
            </td>
            <td className='td3'>
              0.00
            </td>
          </tr>
          <tr className='tr3'>
            <td className='td1'></td>
            <td className='td2'>
              หลังหักส่วนลด/Amount After Discount
            </td>
            <td className='td3'>
              {toNP(totalAmountData[s._id])}
            </td>
          </tr>
          <tr className='tr3'>
            <td className='td1'></td>
            <td className='td2'>
              ภาษีมูลค่าเพิ่ม/VAT
            </td>
            <td className='td3'>
              7%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {toNP(Math.round(totalAmountData[s._id] * 0.07 * 100) / 100)}
            </td>
          </tr>
          <tr className='tr4'>
            <td className='td1'></td>
            <td className='td2'>
              รวมทั้งสิ้น/Grand Total
            </td>
            <td className='td3'>
            {toNP(
              totalAmountData[s._id] +
              (
                Math.round(totalAmountData[s._id] * 0.07 * 100) / 100
              )
            )}
            </td>
          </tr>
        </table>
        <table className='table4'>
          <tr className='tr1'>
            <td className='td1'>
              <div className='context1'>
                ในนาม : บริษัท ไทยดอทรัน จำกัด
              </div>
              <div className='context2'>
                FOR : THAI.RUN Company Limited
              </div>
              <Row>
                <Col span={12}>
                  <div className='authby'>
                    ผู้รับมอบอำนาจ<br/>
                    Authorized By
                  </div>
                  <div className='authby2'>
                  <hr/>
                  {moment(s._id).format('DD / MM / YYYY')}
                  <hr/>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='authby'>
                    ผู้ส่งสินค้าหรือผู้ให้บริการ<br/>
                    Delivered By
                  </div>
                  <div className='authby2'>
                  <hr/>
                  <span className='slash1'>/</span>/
                  <hr/>
                  </div>
                </Col>
              </Row>
            </td>
            <td className='td2'>
              <div className='context1'>
                ข้าพเจ้าได้รับสินค้าหรือบริการข้างต้นจำนวนถูกต้องและสภาพเรียบร้อย
              </div>
              <div className='context2'>
                Receive the above goods or services in good order and condition
              </div>
              <div className='authby'>
                ผู้รับสินค้าหรือบริการ<br/>
                Received By
              </div>
              <div className='authby2'>
              <hr/>
              <span className='slash1'>/</span>/
              <hr/>
              </div>
            </td>
          </tr>
        </table>
      </div>
      </div>
    })}
  </div>
</div>
}

export default OrganizerDailySlip