import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import {
  Button,
} from 'antd'
// import { CSVLink } from 'react-csv'
import FileSaver from 'file-saver'
import Papa from 'papaparse'
import _get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import exportRegistrationShirt from '~/graphql/queries/exportRegistrationShirt.gql'
import Loading from '~/components/Loading';

@inject('router')
// @graphql(exportRegistrationShirt, {
//   options: ({
//     match:{
//         params: {
//             eventId
//         }
//     }
//   }) => ({
//     variables: {
//       eventId
//     }
//   })
// })
@withApollo
@observer
class EventSummaryTable extends Component {
  state = {
    confirmDirty: false
  }

  handleExport = () => {
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      this.props.client.query({
        query: exportRegistrationShirt,
        variables: { eventId }
      }).then((result) => {
        // console.log('then', data, this.props)
        const event = result.data.event
        const exportRegistrationShirt = result.data.exportRegistrationShirt
        let exportRegistrationShirtMod = []
        exportRegistrationShirt.forEach((e) => {
          // console.log(e.count,Math.floor((e.count-1)/3)+1)
          for (let i = 0; i < Math.floor((e.count-1)/3)+1; i++) {
            let tmp = {
              _id: e._id,
              count: e.count,
              refund: 0,
              deliverToRecipient: e.deliverToRecipient,
              deliverToStreet: e.deliverToStreet,
              deliverToLocality: e.deliverToLocality,
              deliverToCity: e.deliverToCity,
              deliverToRegion: e.deliverToRegion,
              deliverToPostalCode: e.deliverToPostalCode,
              data: [],
              payments: e.payments[0]
            }
            let cnt = 0
            for (let j = i*3; j < e.count && j < (i*3)+3; j++) {
              tmp.data.push(e.data[j])
              cnt = cnt+1
              // console.log(e.data[j])
            }
            tmp.count = cnt
            tmp.refund = (cnt-1)*50
            exportRegistrationShirtMod.push(tmp)
          }
        })
        var shirtTypes = _get(event, 'shirtTypes')
        var shirts = _get(event, 'shirts')
        if (shirtTypes && shirts) {
          var shirtTypesMap = []
          shirtTypes.forEach(shirtType => {
            shirtTypesMap[shirtType._id] = shirtType.name.th
          })
          var shritsMap = []
          shirts.forEach(shirt => {
            shritsMap[shirt._id] = shirt.name.th
          })
        }
        // console.log(exportRegistrationShirtMod)

        const columns = [{
          title: <span>Shirt Amount</span>,
          dataIndex: 'count',
          key: `ShirtAmount`,
        }, {
          title: <span>BIB</span>,
          // dataIndex: 'data.',
          key: `bib`,
          render: (record) => {
            let bib = ""
            record.data.forEach((d) => {
              if(!bib){
                bib = d.bib
              }else{
                bib = bib+"\n"+d.bib
              }
            })
            return bib
          }
        }, {
          title: <span>Name</span>,
          dataIndex: 'deliverToRecipient',
          key: `deliverToRecipient`,
        }, {
          title: <span>Street</span>,
          dataIndex: 'deliverToStreet',
          key: `deliverToStreet`,
        }, {
          title: <span>City</span>,
          dataIndex: 'deliverToCity',
          key: `deliverToCity`,
        }, {
          title: <span>Locality</span>,
          dataIndex: 'deliverToLocality',
          key: `deliverToLocality`,
        }, {
          title: <span>Region</span>,
          dataIndex: 'deliverToRegion',
          key: `deliverToRegion`,
        }, {
          title: <span>Postal Code</span>,
          dataIndex: 'deliverToPostalCode',
          key: `deliverToPostalCode`,
        }, {
          title: <span>Region Type</span>,
          dataIndex: 'data.0.regionType',
          key: `regionType`
        }, {
          title: <span>Shirt</span>,
          key: `shirt`,
          render: (record) => {
            let shritDetail = ""
            record.data.forEach((d) => {
              if(!shritDetail){
                shritDetail = shritsMap[d.shirtId]
              }else{
                shritDetail = shritDetail+"\n"+shritsMap[d.shirtId]
              }
            })
            return shritDetail
          }
        }, {
          title: <span>Telephone</span>,
          key: `telephone`,
          render: (record) => {
            let telephoneDetail = ""
            record.data.forEach((d) => {
              let tl = d.telephone
              if(tl === null) tl = "-"
              let fn = " ("+d.firstName+")"
              if(d.firstName === null) fn = ""
              if(!telephoneDetail){
                telephoneDetail = tl+fn
              }else{
                telephoneDetail = telephoneDetail+"\n"+tl+fn
              }
            })
            return telephoneDetail
          }
        }, {
          title: <span>Email</span>,
          // dataIndex: 'data[0].email',
          key: `email`,
          render: (record) => {
            let email = ""
            record.data.forEach((d) => {
              if(!email){
                email = d.email
              }else{
                email = email+"\n"+d.email
              }
            })
            return email
          }
        }, {
          title: <span>payment</span>,
          key: `payment`,
          render: (record) => {
            let paymentDetail = ""
            if(record.payments.status === 'completed'){
              paymentDetail = record.payments.method
              switch(record.payments.method){
                case 'linepay':
                  paymentDetail = paymentDetail+" ("+record.payments.data.linepay.transactionId+")"
                  break;
                case "omise_card":
                case "omise_netbank":
                  paymentDetail = paymentDetail+" ("+record.payments.data.omise.charge.id+")"
                  break;
                default:
                  break;
              }
            }
            return paymentDetail
          }
        }, {
          title: <span>refund</span>,
          dataIndex: 'refund',
          key: `refund`
        }]

        const flatData = exportRegistrationShirtMod.map(row => {
          const newRow = {}
            columns.forEach(column => {
              newRow[column.key] = (
                column.render
                  ? (column.render(
                    column.dataIndex
                      ? _get(row, column.dataIndex)
                      : row
                    ))
                  : _get(row, column.dataIndex)) || '-'
          })
          return newRow
        })
        // console.log(flatData)
        const csvData = Papa.unparse(flatData)
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
        FileSaver.saveAs(blob, 'data.csv')
      }).catch((err) => {
        console.log('catch', err)
      })
    }
  }

  render () {
    const { loading } = this.props
    const exportRegistrationShirt = _get(this.props.data, 'exportRegistrationShirt')
    if( loading ) return <Loading />
    if( exportRegistrationShirt ) return <Loading />

    // const groupbyAddress = Object.values(exportRegistrationShirt.reduce((result, row) => {
    //     result[]
    // }) )
    return (
      <div>
        <Button
            onClick={() => this.handleExport()}>
            Export as CSV
            {/* <CSVLink data={flatData} >Export as Excel</CSVLink> */}
        </Button>
      </div>
    )
  }
}

export default EventSummaryTable
