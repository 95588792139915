import React, { Component } from 'react'
import { Tag } from 'antd'
const colors = {
  paid: 'green',
  submitted: 'gold',
  payment_pending: 'gold',
  payment_verifying: 'gold',
  cancelled: 'volcano',
  rejected: 'red',
  expired: 'gray',
  pending: 'orange',
  completed: 'green',
  failed: 'red',
  testMode: 'red',
  amount: 'blue',
  facebook: 'blue',
  LINE: 'green',
  backlog: 'gray',
  in_progress: 'orange',
  done: 'green'
}
class Itag extends Component {
  render () {
    const {type, text} = this.props
    return <Tag color={colors[type]}>
      {text}
    </Tag>
  }
}
export default Itag