import React, { useState } from 'react'
import { Button, Upload, Icon, Progress } from 'antd'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-apollo-hooks';
import promiseRetry from 'promise-retry'

import createOrUpdateMessageMutation from '~/graphql/mutations/createMessage.gql'

const Import = (props) => {
	const { eventId } = useParams()
	const { setIsImport } = props
	const [data, setData] = useState([])
	const [importing, setImporting] = useState(false)
	const [progress, setProgress] = useState(0)

	const [createOrUpdateMessage] = useMutation(createOrUpdateMessageMutation)

	const selectFile = async (info) => {
    await info.fileList[0].originFileObj.arrayBuffer().then((arrayBuffer) => {
      const wb = XLSX.read(arrayBuffer, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const jsonObj = XLSX.utils.sheet_to_json(ws)
      setData(jsonObj)
    })
  }

	const handleImport = async () => {
    setImporting(true)
    const recordCount = data.length
    let records = [...data]
    

    while(records.length > 0){
      await Promise.all(records.splice(0, 5).map(async (record) => {
        const {
					trackingCode = record.trackingcode || record['Tracking No'],
					regCode = record.registrationcode || record.registration_code || record['รายการ'],
					deliveryCarrier = record.deliveryCarrier || 'flash'
        } = record
				const text = `BIB: ${regCode} กำลังจัดส่ง | Tracking Code: ${trackingCode} ด้วย ${deliveryCarrier}`
        const mappedData = {
          eventId,
          text,
					type: 'updateTracking',
					regCode,
					deliveryCarrier,
					trackingCode
        }
        // console.log(mappedData);

				await promiseRetry(async (retry, number) => {
					if(number > 1) console.log('attemp=> ', number)
					const tracking = createOrUpdateMessage({
						variables: {
            	...mappedData
          	}
					}).catch(async err => {
						await new Promise(resolve => setTimeout(() => resolve(console.log('retry')), 5000))
            await retry(err)
					})
					return tracking
				}, { retries: 25 })
       
      }))
      setProgress(((recordCount - records.length) * 100) / recordCount)
    }
    setProgress(100)
    // onLoadMore(1)
  }



	const handleCloseImport = () => {
    setIsImport(false)
    setData([])
    setProgress(0)
  }

	return (
		<div style={{padding: '10px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      {
        progress !== 0 ? <>
          <Progress type="circle" percent={parseInt(progress)} />
          {progress === 100 && <Button style={{marginTop: '10px'}} onClick={() => handleCloseImport()}>Close</Button>}
        </>: <>
      {data.length === 0 && progress === 0 ? <Upload accept=".xlsx" showUploadList={false} onChange={selectFile} beforeUpload={()=> {return false; }}>
        <Button type="primary">
          <Icon type="upload" /> Import Tracking
        </Button>
      </Upload>: <><p>
        Do you Want to import {data.length} items?
        </p>
        <Button onClick={() => handleImport()}>Import</Button>
        </>
      }
      </>
      }
      
    </div>
	)
}
export default Import