import React, { Component } from 'react'
import { Layout, Tooltip, Table, Divider, Button, message, Modal } from 'antd'
import { graphql, withApollo } from 'react-apollo'
import _get from 'lodash/get'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import couponCodeTypesQuery from '~/graphql/queries/couponCodeTypes.gql'
import eventCouponCodeExportMutation from '~/graphql/mutations/eventCouponCodeExport.gql'
import eventQuery from '~/graphql/queries/miniEvent.gql'
// import DeleteConfirmModal from './DeleteConfirmModal'
import _filter from 'lodash/filter'
import config from '~/config'
import {
  Link
} from 'react-router-dom'
import { withTranslation } from 'react-i18next'

@withApollo
@graphql(eventQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.eventId
    }
  }),
  props: ({ data: { loading, event } }) => ({
    eventLoading: loading,
    event: event
  })
})
@graphql(couponCodeTypesQuery, {
  options: (props) => ({
    variables: {
      eventId: props.match.params.eventId
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, couponCodeTypes } }) => ({
    couponCodeTypesLoading: loading,
    couponCodeTypes: couponCodeTypes
  })
})
class List extends Component {
  state = {
    exporting: false,
    loading: false
  }
  handleExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventCouponCodeExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventCouponCodeExport.url')
        const fileName = _get(data, 'eventCouponCodeExport.fileName')
        // console.log({ response })
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  render () {
    let { couponCodeTypes, couponCodeTypesLoading, event, eventLoading, t } = this.props
    if (couponCodeTypesLoading) return ''
    if (eventLoading) return ''
    couponCodeTypes = _filter(couponCodeTypes, { deleted: false })
    const eventId = this.props.match.params.eventId
    const dataSource = []

    const columns = [{
      title: `${t('coupon.name')}`,
      dataIndex: 'name',
      key: 'name'
    }, {
      title: `${t('coupon.discount')}`,
      key: 'discount',
      render: (data) => {
        const Type = data && data.discountType
        let discount
        if (Type === 'percentage') {
          const discountco = data && data.discount * 100
          return discountco
        }
        if (Type === 'flat') {
          const discountco = data && data.discount
          return discountco
        }
        return discount
      }
    }, {
      title: `${t('coupon.discountType')}`,
      key: 'discountType',
      render: (data) => {
        const Type = data && data.discountType
        let discount
        if (Type === 'percentage') {
          const percentage = '%'
          return percentage
        }
        if (Type === 'flat') {
          const flat = config.platform === 'mas' ? 'Fixed' : 'บาท'
          return flat
        }
        return discount
      }
    }, {
      title: `${t('coupon.action')}`,
      key: 'action',
      render: (text, record) => {
        return <span>
          <Tooltip title="Edit CouponcodeType">
            <Link to={`${record.key}/edit`}><Button type="primary" icon="edit" className="edit" ></Button></Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Gen Coupon codes">
            <Link to={`${record.key}/coupon-codes`}><Button type="primary" icon="file-add" className="add" ></Button></Link>
          </Tooltip>
          {/* <Divider type="vertical" /> */}
          {/* <DeleteConfirmModal loading={this.props.loading}
            couponCodeTypeId={record.key} history={this.props.history}
            name={record.name} /> */}
        </span>
      }
    }]

    couponCodeTypes && couponCodeTypes.forEach(({ _id, name: {th, en}, discountType, discount }) => {
      dataSource.push({
        key: _id,
        name: th || en,
        discountType: discountType,
        discount: discount
      })
    })

    return <Layout>
      <Layout.Content>
      <TopContent
        title={_get(event, 'name.th') || _get(event, 'name.en')}
        nodes={{
          InputSearch: '',
          SelectStatus: <Button.Group>
          <Button type="primary" className="create">
            <Link to={`/events/${eventId}/coupon-code-types/create`}>
            {t('coupon.createCoupon')}
            </Link>
          </Button>
          <Button
            onClick={this.handleExport}
            icon="export"
            loading={this.state.exporting}
          >
          Export
          </Button>
        </Button.Group>,
        }}
        pattern='registrations'/>
        <div className="innerMainContentWrapper">
          <EventSider />
          <div className="middleContent">
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
        {/* </div> */}
      </Layout.Content>
    </Layout>
  }
}

export default withTranslation() (List)
