import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
// import { inject, observer } from 'mobx-react'
import {
  Layout,
  Table,
  Button,
  Modal,
  message
  // Icon,
} from 'antd'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import EventSider from '~/components/Events/Event/Sider'
import refundRequestsQuery from '~/graphql/queries/refundRequests.gql'
import eventRefundExportMutation from '~/graphql/mutations/eventRefundExport.gql'
import Loading from '~/components/Loading'
import EventNotFound from '~/components/Error/Eventnotfound'
import { withTranslation } from 'react-i18next'

const bibNumber = number => {
  const pad = '0000'
  return pad.substr(0, pad.length - `${number}`.length) + number
}

// @inject('router')
// @observer
@withApollo
@graphql(refundRequestsQuery, {
  options: ({ match: { params: { eventId } } }) => ({
    variables: { eventId },
    fetchPolicy: 'network-only'
  })
})
class EventRefundRequests extends Component {
  state = {
    confirmDirty: false,
    exporting: false
  }

  handleExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRefundExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventRefundExport.url')
        const fileName = _get(data, 'eventRefundExport.fileName')
        // console.log({ response })
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  render () {
    const { data, loading, t } = this.props
    console.log({ data })
    if (loading) {
      return <Loading />
    }
    if (!data.event) {
      return <EventNotFound />
    }
    const refundRequests = _get(data, 'refundRequests', [])
    // const columns = [{
    //   title: 'Order Item ID',
    //   dataIndex: '_id',
    //   key: '_id',
    // }, {
    //   title: 'Name',
    //   dataIndex: 'name.th',
    //   key: 'name'
    // }, {
    //   title: 'Description',
    //   dataIndex: 'description.th',
    //   key: 'description'
    // }, {
    //   title: 'Status',
    //   dataIndex: 'hidden',
    //   key: 'hidden',
    //   render: (hidden) => {
    //     if(hidden === false){
    //       return <span> Show </span>
    //     }else{
    //       return <span> Hidden </span>
    //     }
    //   }
    // }, {
    //   title: 'Image',
    //   dataIndex: 'imageUrl',
    //   key: 'image',
    //   render: (imageUrl) => <img style={{ width: 50 }} src={imageUrl} alt=""/>
    // }, {
    //   title: 'Price',
    //   dataIndex: 'price',
    //   key: 'price',
    // }, {
    //   title: 'Delivery Option',
    //   dataIndex: 'deliveryOption',
    //   key: 'deliveryOption',
    // }]

    return (
      <Layout>
        <EventSider />
        <Layout.Content
          style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}
        >
          {data.event && <div style={{float: 'left', marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {data.event.name.en}
          </div>}
          <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
            <Button
              onClick={this.handleExport}
              icon="export"
              loading={this.state.exporting}
            >
            Export
              {/* <CSVLink data={flatData} >Export as Excel</CSVLink> */}
            </Button>
          </div>
          <Table
            style={{whiteSpace: 'nowrap'}}
            scroll={{ x: true }}
            // columns={columns}
            dataSource={refundRequests}
            // loading={loading}
            locale={{ emptyText: `${t('locale.notFound')}` }}
            rowKey="_id"
            // onRowClick={(record) => (this.props.history.push(`/events/${data.event._id}/refundRequests/${record._id}/edit`))}
          >
            <Table.Column title="วันที่ร้องขอ" key="date" dataIndex="createdAt" width={140} render={date => moment(date).format('Do MMM HH:mm')} />
            <Table.Column title="ผู้ที่เข้าร่วม" key="participant" align="right" width={300} dataIndex="ticket"
              render={(ticket, rec) =>
                <Link to={`/events/${data.event._id}/refund-requests/${rec._id}`}>{`[${bibNumber(ticket.number)}]\n${_get(ticket, 'participant.profile.firstName')} ${_get(ticket, 'participant.profile.lastName')}`}</Link>
              }
            />
            <Table.Column title="สถานะ" key="status" dataIndex="status" width={100} />
            <Table.Column title="วันที่คืนเงิน" key="refundDate" dataIndex="refund.completedAt" width={100} render={date => date && moment(date).format('Do MMM HH:mm')} />
          </Table>
        </Layout.Content>
      </Layout>
    )
  }
}

export default withTranslation() (EventRefundRequests)
