import React, { useState } from 'react'
import {
  Form,
  Row,
  Col,
  Select,
  message
} from 'antd'
import _get from 'lodash/get'
// import eventQbSkuQuery from '~/graphql/queries/eventQbSku.gql'
import allQbCustomersQuery from '~/graphql/queries/qb/allQbCustomers.gql'
import updateEventQbMutation from '~/graphql/mutations/qb/updateEventQb.gql'
import { useMutation, useQuery } from '@apollo/react-hooks'
const { Option } = Select
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const EventQb = ({eventId, eventQb}) => {
  const [loading, setLoading] = useState(false)
  const [validateStatus, setValidateStatus] = useState(_get(eventQb, 'qbId')?'success':'warning')
  const {loading: customersLoading, data: customersData} = useQuery(allQbCustomersQuery)
  const [updateEventQb] = useMutation(updateEventQbMutation)
  if(customersLoading) return 'loading'
  if(!customersData || _get(customersData, 'allQbCustomers.length') === 0) return 'no customers'
  const allQbCustomers = _get(customersData, 'allQbCustomers')
  const onChange = async (qbId) => {
    setLoading(true)
    setValidateStatus('warning')
    const customer = allQbCustomers && allQbCustomers.find(c => c.qbId === qbId)
    const qbName = _get(customer , 'qbName')
    updateEventQb({ variables: {eventId, qbId, qbName} })
      .then(res => {
        setLoading(false)
        message.success('success')
        setValidateStatus('success')
      })
      .catch(err => {
        message.error(JSON.stringify(err))
        setValidateStatus('warning')
        setLoading(false)
      })
  }
  const previewCustomer = allQbCustomers && allQbCustomers.find(c => c.qbId === _get(eventQb, 'qbId'))
  return (<Row>
    <Col>
      <Form.Item 
        label="Customer" 
        validateStatus={validateStatus}
        hasFeedback
        help={_get(previewCustomer, 'qbName')}
        {...formItemLayout} 
        style = {{ 
          float: 'left', 
          marginLeft: '20px',
        }}
      > 
        <Select
          showSearch
          style={{ width: 500 }}
          placeholder="Select a customer"
          optionFilterProp="children"
          onChange={onChange}
          loading={loading}
          defaultValue={_get(eventQb, 'qbId')}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={null}>-</Option>
          {allQbCustomers.map(c => {
            return <Option key={c.qbId} value={c.qbId}>{c.qbName}</Option>
          })}
        </Select>
      </Form.Item>
    </Col>
  </Row>
  )
}

export default EventQb
