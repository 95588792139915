import React, { Component } from 'react'
import {
  Switch, Route
} from 'react-router-dom'
import Privileges from './Privileges/privileges'
import Error404 from '../../../Error/404'
import Tracking from './Tracking'

const Imports = () => {
  return (
    <div>
      <Switch>
        <Route 
          exact
          path="/events/:eventId/imports/privilege"
          component={Privileges}
        />
        <Route 
          exact
          path="/events/:eventId/imports/tracking"
          component={Tracking}
        />
        <Route 
          exact
          path="*"
          component={Error404} 
        />
      </Switch>
    </div>
  )
}

export default Imports
