import React from 'react'
import {
    Card, 
} from 'antd'

import RApaxChart from "react-apexcharts";
import _orderBy from 'lodash/orderBy'
import { color } from '@amcharts/amcharts4/core';

const { Meta } = Card

const GraphApex = ({param}) => {

    const datasource = param.datasource 
    const chartRes = param.chart  
    
    const opt   = { 
                    title: chartRes.caption,
                    subtitle: param.subTitle,
                    width: param.width,
                    height: (param.height ? param.height : '300px'),
                }
    
    var horizontalBar = false

    const graphhight  = (row) =>{
        var height = 150
        if(row > 5){
            height = (row * 30) 
        } 
        return(height)    
    }

    const setBarWidth  = (row, _min, _max) =>{
        let Width = 30
        if(row < 5){
            Width = 1
        }else if(row < 10){
            Width = 10
        }else if(row < 20){
            Width = 20
        }else if(row < 30){
            Width = 30
        }else{
            Width = 10
        }        

        if(_max > 0 && Width > _max) Width = _max 

        return Width < _min ? _min : Width       
    }

    const _total = (value, max) => {
        var pc = (100/parseInt(max, 10)) * parseInt(value, 10)
        let _color = '#009933'
        if(pc < 1) _color = '#FF3300'
        else if(pc < 10) _color = '#FF9933'
        else if(pc < 30) _color = '#D7D700'
        else if(pc < 50) _color = '#CCCC33'
        else if(pc < 80) _color = '#99CC33'

        return '<span style="color:' + _color + ';">' + value + '</span>'
    }

    const setMax  = (value) =>{
        var val = value
        const valArr = val.toString().split('')

        var _valArr = []

        valArr.forEach(function(val,index,array){
            array[index]    = parseInt(val, 10)
            _valArr[index]  = 0
        })

        if(value < 9){
            val = 20    
        }else if(value < 70){
            val = 80               
        }else if(value < 99){
            val = 120
        }else if(value < 500){
            val = (valArr[0] * 100) + (valArr[1] > 80 ? 150 : 100)            
        }else if(value < 999){
            val = 1000 + (valArr[1] > 8 ? 200 : 0) 
        }else{          
            _valArr[1] = parseInt(valArr[1], 10) + (valArr[2] >= 6 ? 2 : 1)                         
            _valArr[0] = parseInt(valArr[0], 10) + (_valArr[1] >= 8 ? 1 : 0) 

            _valArr[1] = _valArr[1] > 9 ? 0 : _valArr[1]  

            val = parseInt(_valArr.join(''), 10)
        }

        return(val)    
    }
     
    var val = {"row":[], "label":[], "desc":[], "value":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,
                    "series":[] 
                }
    const _series = {
                        "draft":{"name":"DRAFT", "data":[], "total":0},
                        "submitted":{"name":"SUBMITTED", "data":[], "total":0},
                        "paid":{"name":"PAID", "data":[], "total":0}, 
                        "space":{"name":"", "data":[], "total":0},                   
                    }

    var dataRow = 0   
    if(!(datasource === null)){ 

        datasource.data = _orderBy(datasource.data, 'distance', 'asc');
        const groupByRace = {data:[], rowData:0}
        let tmp_groupByRace = []
        for(const [keyi, valuei] of Object.entries(datasource.data)){
            if(tmp_groupByRace[valuei.distance] === undefined){ 
                tmp_groupByRace[valuei.distance] = {draft:0, 
                                                        submitted:0, 
                                                        paid:0, 
                                                        desc:valuei.distance + ' ' + valuei.distanceUnit,
                                                        distance: valuei.distance + ' ' + valuei.distanceUnit
                                                    }
                
            }

            tmp_groupByRace[valuei.distance].draft += valuei.draft
            tmp_groupByRace[valuei.distance].submitted += valuei.submitted
            tmp_groupByRace[valuei.distance].paid += valuei.paid
        }
        for(const [keyi, valuei] of Object.entries(tmp_groupByRace)){
                groupByRace.data[groupByRace.rowData++] = valuei
        }

        for(const [keyi, valuei] of Object.entries(groupByRace.data)){
            dataRow = (dataRow + 1)
            val.chart.bar.bgcolor[keyi] = '#F7F7F7'

            val.label[keyi] = valuei.distance
            val.desc[keyi] = valuei.name

            _series.draft.data[keyi] = valuei.draft
            _series.submitted.data[keyi] = valuei.submitted
            _series.paid.data[keyi] = valuei.paid      

            _series.draft.total += parseFloat(valuei.draft)
            _series.submitted.total += parseFloat(valuei.submitted)
            _series.paid.total += parseFloat(valuei.paid)           

            //const total = _series.draft.total + _series.submitted.total + _series.paid.total
            const total = valuei.draft + valuei.submitted + valuei.paid 
            if(val.max < total){
                val.max = total    
            }

        }
        //console.log('race-max-valie', ' ('+val.max+' / ' + setMax(val.max)+') ')
/*
        for(const [keyi, valuei] of Object.entries(datasource.data)){
            dataRow = (dataRow + 1)
            val.chart.bar.bgcolor[keyi] = '#F7F7F7'

            val.label[keyi] = valuei.distance + ' ' + valuei.distanceUnit
            val.desc[keyi] = valuei.name.en

            _series.draft.data[keyi] = valuei.draft
            _series.submitted.data[keyi] = valuei.submitted
            _series.paid.data[keyi] = valuei.paid      

            _series.draft.total += parseFloat(valuei.draft)
            _series.submitted.total += parseFloat(valuei.submitted)
            _series.paid.total += parseFloat(valuei.paid)           

            //const total = _series.draft.total + _series.submitted.total + _series.paid.total
            const total = valuei.draft + valuei.submitted + valuei.paid 
            if(val.max < total){
                val.max = total    
            }

        }
*/
        /*
        val.label[dataRow] = "Total"

        _series.draft.data[dataRow] = _series.draft.total
        _series.submitted.data[dataRow] = _series.submitted.total
        _series.paid.data[dataRow] = _series.paid.total                
        */
        val.series = [         
                        {"name":_series.draft.name, "data":_series.draft.data, "color":"#0496FF"},
                        {"name":_series.submitted.name, "data":_series.submitted.data, "color":"#F17105"},                        
                        {"name":_series.paid.name, "data":_series.paid.data, "color":"#6665DD"},                                                
                    ]

        if(param.isMobile && dataRow>10)
            horizontalBar = true    

    }

    //console.log("a,b", setBarWidth(dataRow, 5, 0) + ', ' + setBarWidth(dataRow, 5, 0))

    const valueList = val
    const apexChartConfig = {
                                    options: {
                                        chart: {
                                            id: "smmrCntRace",
                                            toolbar: {
                                                show: false,
                                            },            
                                            stacked: true,                                                 
                                        },
                                        dataLabels: {
                                            enabled: false,
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: (param.isMobile ? true : false),
                                                startingShape: 'rounded',
                                                endingShape: 'rounded',
                                                columnWidth: (horizontalBar ? 30 : setBarWidth(dataRow, 5, 0)),
                                                barHeight: (horizontalBar ? 30 : setBarWidth(dataRow, 5, 0)),
                                                distributed: false,
                                                colors: {
                                                    backgroundBarColors: val.chart.bar.bgcolor,
                                                    backgroundBarOpacity: 1,
                                                    backgroundBarRadius: 5,   
                                                },                                                                                                                                   
                                            },
                                        },                                     
                                        xaxis: {
                                            categories: valueList.label, 
                                            labels: {
                                                show: 'show'
                                            },    
                                            axisBorder: {
                                                show: false,
                                            },
                                            lines: {
                                                show: false
                                            },      
                                            axisTicks: {
                                                show: false
                                            },  
                                            label:{
                                                style:{
                                                    colors: '#B1B1B1'
                                                }   
                                            }                                                                                                      
                                        },
                                        yaxis: {
                                            min: 0,
                                            max: setMax( val.max ),
                                            show: true,
                                            labels: {
                                                /*
                                                formatter: function (val) {
                                                  //const vlb = (val/1000).toFixed(1) 
                                                  const vlb = (val/1).toFixed(0) 
                                                  const unit = "" //"K"
                                                  return (vlb > 0 ? vlb + unit : "0")
                                                },
                                                */
                                                style:{
                                                    colors: '#B1B1B1'
                                                }   
                                            },
                                                                                           
                                        },                                      
                                        legend:{
                                            show: true,
                                            offsetX: 0,
                                            offsetY: (param.isMobile ? 0 : 10),
                                            labels: {
                                                colors: '#C0C0C0',
                                                useSeriesColors: false
                                            },
                                            itemMargin: {
                                                horizontal: (param.isMobile ? 5: 25),
                                                vertical: 0
                                            },
                                            markers: {
                                                width: 16,
                                                height: 16,
                                                strokeWidth: 20,
                                                shape: "donut",
                                                strokeColor: ["#0496FF", "#F17105", "#6665DD"],
                                                fillOpacity: 1,
                                                fillColors: undefined,
                                                colors: ["#000", "#000000", "#FFFFFF"],
                                                radius: 20,
                                                customHTML: undefined,
                                                onClick: undefined,
                                                offsetX: 0,
                                                offsetY: 0
                                            }, 
                                            height: 40,                                           
                                        },
                                        grid: {
                                            yaxis: {
                                                lines: {
                                                    show: false
                                                }
                                            }
                                        }, 
                                        tooltip: {
                                            onDatasetHover: {
                                                highlightDataSeries: false,
                                            },
                                            shared: true, intersect: false,
                                            // shared: true,
                                            // shared: false,
                                            x: {
                                                show: true,
                                                formatter: undefined,
                                                /*
                                                formatter: function(value, { series, dataPointIndex, w }) {
                                                    let total = ( series[0][dataPointIndex] + series[1][dataPointIndex] + series[2][dataPointIndex] )  
                                                    let ttt = _total(total, val.max) 
                                                    return '<b>'+value + '</b> : ' + valueList.desc[dataPointIndex] + 
                                                            '&nbsp;&nbsp;<br>' + '[ <b>Total</b> ' + ttt + ' ]'
                                                }   
                                                */                                           
                                            },
                                            y: {
                                                show: true,
                                                formatter: undefined,
                                            },
                                            z: {
                                                show: true,
                                                formatter: undefined,
                                                title: 'Count: '
                                            },
                                            marker: {
                                                show: true,
                                            },
                                            items: {
                                               display: 'flex',
                                            }, 
                                            /*
                                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                                return '<div class="arrow_box">' +
                                                  '<div>' + valueList.label[dataPointIndex] + '</div>' +
                                                  '<div>' + series[seriesIndex][dataPointIndex] + '/'+dataPointIndex+'</div>' +                                                  
                                                  '<div>' + series[seriesIndex][dataPointIndex] + '/'+dataPointIndex+'</div>' +
                                                  '<div>' + series[seriesIndex][dataPointIndex] + '/'+dataPointIndex+'</div>' +
                                                  '</div>'
                                            } 
                                            */
                                            

                                        }                                                                    
                                    },
                                    series: valueList.series,
                                }
    
    //console.log("race-data", datasource)
    //console.log("race-graph", val)

    return (<Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>}
                style={{}}
                        
            >
                <Meta
                    description={<div className="" style={{ verticalAlign: 'top', minHeight: opt.height }}
                    >
                    <RApaxChart
                        options={apexChartConfig.options}
                        series={apexChartConfig.series}
                        type= "bar"
                        width= "100%"
                        height= {horizontalBar && dataRow>10 ? graphhight(dataRow) : opt.height}
                    />                     
                    </div>}
                />
            </Card>
    )


}

export default GraphApex
