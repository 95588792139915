import React from 'react'
import { Upload, Button } from 'antd'
const signatureUri = process.env.REACT_APP_UPLOAD_SIGNATURE_URI
class Uploader extends React.Component {
  state = {
    loading: false
  }
  handleChange = ({ file, fileList, event }) => {
    console.log('change', { file })
    this.setState({ loading: true })
    const { onChange } = this.props
    if (file.status === 'done') {
      const { fileUrl } = file.response
      onChange(fileUrl)
      this.setState({ loading: false })
    }
  }
  beforeUpload = async file => {
    // console.log('bu', { file })
  }
  customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    // TODO: use axios
    const res = await fetch(`${signatureUri}?mimetype=${file.type}`, { method: 'POST' })
    const { signedUrl, fileUrl } = await res.json()
    // console.log({ signedUrl })
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onprogress = ({ loaded, total }) => {
      onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file)
      // console.log({ progress })
    }
    xhr.onload = (response) => {
      const status = xhr.status
      if (status === 200) {
        // console.log({ response })
        onSuccess({ fileUrl }, file)
        // alert('File is uploaded')
      } else {
        onError('Something went wrong')
        // alert('Something went wrong!')
      }
    }

    xhr.onerror = (err) => {
      console.error(err)
      onError('Something went wrong')
    }
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.setRequestHeader('x-goog-acl', 'public-read')
    xhr.send(file)
  }
  render () {
    const { value } = this.props
    return <Upload onChange={this.handleChange} beforeUpload={this.beforeUpload} customRequest={this.customRequest} showUploadList={false}>
      {value
        ? <img src={value} alt="logo" style={{ width: 300 }}/>
        : <Button icon="upload" loading={this.state.loading}>Upload</Button>
      }
    </Upload>
  }
}

export default Uploader
