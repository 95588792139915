import React, { Component } from 'react'
import { Select, Row, Col } from 'antd'
import moment from 'moment-timezone'

class DatePickerDropDown extends Component {
  constructor (props) {
    super(props)
    // console.log({ ddValue: props.value })
    const { value } = props
    const $value = moment(value)
    const $validDate = $value.isValid() ? $value : moment().subtract({ years: 20 })
    const { date, months, years } = $validDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }).toObject()
    this.state = {
      date,
      months,
      years
    }
  }
  render () {
    const { onChange, startYear, endYear = moment().year() + 50 } = this.props
    const {
      date, months, years
    } = this.state
    let yearRange = 100
    let _startYear = startYear
    if (startYear && endYear) {
      yearRange = endYear - startYear
    }
    if (!startYear) {
      _startYear = endYear - yearRange + 1
    }

    const handleDayChange = (value, label) => {
      const $newValue = moment({ date: value, months, years })
      const newValue = $newValue.toISOString()
      this.setState({
        date: value
      })

      onChange(newValue)
    }
    const handleMonthChange = (value, label) => {
      const $newValue = moment({ date, months: value, years })
      const newValue = $newValue.toISOString()
      this.setState({
        months: value
      })
      onChange(newValue)
    }
    const handleYearChange = (value, label) => {
      const $newValue = moment({ date, months, years: value })
      const newValue = $newValue.toISOString()
      this.setState({
        years: value
      })
      onChange(newValue)
    }
    return <Row gutter={8}>
      <Col span={6}>
        <Select key="day" defaultValue={`${date}`} onChange={handleDayChange} >
          {[...Array(31)].map((_, i) => (
            <Select.Option value={`${i + 1}`} key={i + 1}>{i + 1}</Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={10}>
        <Select key="month" defaultValue={`${months}`} onChange={handleMonthChange} >
          {moment.months().map((month, i) => (
            <Select.Option value={`${i}`} key={i}>{month}</Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={8}>
        <Select key="year" defaultValue={`${years}`} onChange={handleYearChange} >
          {[...Array(yearRange)].map((_, i) => (i + _startYear)).reverse().map(year =>
            <Select.Option defaultValue={`${year}`} key={year}>{year}</Select.Option>
          )}
        </Select>
      </Col>
    </Row>
  }
}

export default DatePickerDropDown
