import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { inject, observer } from 'mobx-react'
import { 
  Layout, 
  Table,
  Button,
  Modal,
  message
  // Icon,
} from 'antd'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import EventSider from '~/components/Events/Event/Sider'
import orderItemsQuery from '~/graphql/queries/orderItems.gql'
import eventRefundExportMutation from '~/graphql/mutations/eventRefundExport.gql'
import Loading from '~/components/Loading';
import EventNotFound from '~/components/Error/Eventnotfound';
import { withTranslation } from 'react-i18next'
// const bibNumber = number => {
//   const pad = '0000'
//   return pad.substr(0, pad.length - `${number}`.length) + number
// }

@inject('router')
@withApollo
@graphql(orderItemsQuery, {
  options: ({
    match: { params: { eventId } },
    router: {
      location: {
        query: { s: search, status, p: page }
      }
    }
  }) => ({
    variables: {
      eventId,
      search,
      status,
      page
    },
    fetchPolicy: 'network-only'
  })
})
@observer
class EventorderItems extends Component {
  state = {
    confirmDirty: false,
    exporting: false
  }
  handleStatus = (status) => {
    const { location } = this.props.router
    const { p, ...query } = location.query
    location.query = _pickBy({
      ...query,
      status: status
    })
  }
  handleSearch = (search) => {
    const { location } = this.props.router
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: search
    }
  }
  handlePageChange = (page) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      p: page
    }
  }
  handleExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRefundExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventRefundExport.url')
        const fileName = _get(data, 'eventRefundExport.fileName')
        // console.log({ response })
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  render () {
    const { data, loading, t } = this.props
    if (loading) {
      return <Loading />
    }
    if (!data.event) {
      return <EventNotFound />
    }
    // const { location } = this.props.router
    // const { s: search, status } = location.query
    const orderItems = _get(data, 'orderItemSearchPagination.items')
    const pageInfo = _get(data, 'orderItemSearchPagination.pageInfo')
    const { currentPage, itemCount, perPage } = pageInfo
    // const orderItems = _get(data, 'orderItems', [])
    // const columns = [{
    //   title: 'Order Item ID',
    //   dataIndex: '_id',
    //   key: '_id',
    // }, {
    //   title: 'Name',
    //   dataIndex: 'name.th',
    //   key: 'name'
    // }, {
    //   title: 'Description',
    //   dataIndex: 'description.th',
    //   key: 'description'
    // }, {
    //   title: 'Status',
    //   dataIndex: 'hidden',
    //   key: 'hidden',
    //   render: (hidden) => {
    //     if(hidden === false){
    //       return <span> Show </span>
    //     }else{
    //       return <span> Hidden </span>
    //     }
    //   }
    // }, {
    //   title: 'Image',
    //   dataIndex: 'imageUrl',
    //   key: 'image',
    //   render: (imageUrl) => <img style={{ width: 50 }} src={imageUrl} alt=""/>
    // }, {
    //   title: 'Price',
    //   dataIndex: 'price',
    //   key: 'price',
    // }, {
    //   title: 'Delivery Option',
    //   dataIndex: 'deliveryOption',
    //   key: 'deliveryOption',
    // }]

    return (
      <Layout>
        <EventSider />
        <Layout.Content
          style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}
        >
          {data.event && <div style={{float: 'left', marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {data.event.name.en}
          </div>}
          <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
          <Button
            onClick={this.handleExport}
            icon="export"
            loading={this.state.exporting}
          >
            Export
            {/* <CSVLink data={flatData} >Export as Excel</CSVLink> */}
          </Button>
          </div>
          <Table
            style={{whiteSpace: 'nowrap'}}
            scroll={{ x: true }}
            // columns={columns}
            dataSource={orderItems}
            // loading={loading}
            locale={{ emptyText: `${t('locale.notFound')}` }}
            rowKey="_id"
            pagination={{
              defaultPageSize: perPage,
              showQuickJumper: true,
              defaultCurrent: 1,
              current: currentPage,
              total: itemCount,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              onChange: this.handlePageChange
            }}
            // onRowClick={(record) => (this.props.history.push(`/events/${data.event._id}/orderItems/${record._id}/edit`))}
          >
            <Table.Column title="ID" key="_id" dataIndex="_id" width={140} />
            <Table.Column title="Registration" key="registration" dataIndex="registration.code" width={140} />
            <Table.Column title="Order" key="order" dataIndex="order.code" width={140} />
            <Table.Column title="Product" key="product" dataIndex="product.name.en" width={140} />
            <Table.Column title="Participant" key="participant" dataIndex="participant" width={140} render={participant => `${participant.profile.firstName} ${participant.profile.lastName}`}/>
            <Table.Column title="Quantity" key="quantity" dataIndex="quantity" width={140} />
            <Table.Column title="Status" key="status" dataIndex="source.status" width={100} />
          </Table>
        </Layout.Content>
      </Layout>
    )
  }
}

export default withTranslation() (EventorderItems)
