import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import th from './locales/th/translation.json'
import en from './locales/en/translation.json'


const resources = {
  en: {
		translation: {
			...en
		}
	},
	th: {
		translation: {
			...th
		}
	}
}

i18n
  .use(initReactI18next)
	.use(LanguageDetector)
  .init({
		resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
			format: (value, rawFormat, lng) => {

				const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim())
				switch (format) {
					case 'uppercase' :
						return value.price.toUpperCase()
					case 'price':
						return Intl.NumberFormat(lng, {
							style: 'currency',
							currency: value.currency || 'THB'
						}).format(value.price)
				}
			}
    }
  })


export default i18n