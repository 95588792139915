import React, { Component } from 'react'
import { Modal, Button, message } from 'antd'
import _get from 'lodash/get'
import { graphql } from 'react-apollo'
import deleteCouponCodeTypeMutation from '~/graphql/mutations/deleteCouponCodeType.gql'
import { withTranslation } from 'react-i18next'

@graphql(deleteCouponCodeTypeMutation, { name: 'deleteCouponCodeTypeMutation' })
class DeleteConfirmModal extends Component {
  state = {
    loading: false
  }
  handleDelete = () => {
    const couponCodeTypeId = _get(this.props, 'couponCodeTypeId')
    const args = {
      _id: couponCodeTypeId
    }

    this.props.deleteCouponCodeTypeMutation({
      variables: args
    }).then((response) => {
      message.success(`ลบสำเร็จ`)
      const eventId = _get(response, 'data.deleteCouponCodeType.eventId') 
      if (eventId){
        this.props.history.push(`/events/${eventId}/coupon-code-types/list`)
      } else {
        message.error(`${this.props.t('coupon.modal.messageError')}`)  
      }
    }).catch((e) => {
      message.error(e)
    })
  }

  showConfirm = () => {
    Modal.confirm({
      title: `${this.props.t('coupon.modal.delete')}`,
      content: `${this.props.t('coupon.modal.message')} "${this.props.name}" ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.handleDelete()
      },
      onCancel () {}
    })
  }

  render () {
    return <span>
      <Button type="primary" icon="delete" loading={this.state.loading} className="delete" onClick={this.showConfirm}></Button>
    </span>
  }
}

export default withTranslation() (DeleteConfirmModal)
