import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import { withTranslation } from 'react-i18next'

class EventNotFound extends Component {
  render () {
    const { t } = this.props 
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: 'calc(100vh - 69px - 37px - 20px)', marginTop: 20 }}>
        <Icon type="exclamation-circle" style={{ fontSize: '100px' }} />
        <h1 style={{ marginTop: '10px' }} >{t('page.title')}</h1>
        <Button type="ghost" onClick={() => window.history.back()} >{t('page.back')}</Button>
      </div>
    )
  }
}

export default withTranslation() (EventNotFound)
