import styled from 'styled-components'
import { Layout } from 'antd'

const { Content } = Layout

export const AppWrapper = styled.div`
  background: #f6f6f6;
  min-height: 1000px;
  .desktop {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .ant-layout-sider-children {
    .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
      color: #f8a01c;
    }
  }
  .layout-content {
    display: flex;
    padding: 0;
    /* background: #E5E5E5; */
    background: #f6f6f6;
    div.maincontent {
      padding-left: 64px;
      width: 100%;
      div.topContent {
        border-bottom: 1px solid #EAEAEA;
        .desktop {
          background: white;
          height: 87px;
          padding-top: 24px;
          span.title {
            font-size: 24px;
            font-family: Sukhumvit Set;
            font-weight: 600;
            margin-left: 24px;
          }
        }
        .mobile {

        }
      }
      div.innerMainContentWrapper {
        background: rgb(246, 246, 246);
        display: flex;
        width: 100%;
        div.middleContent {
          margin: 10px auto;
          width: 100%;
          max-width: 1300px;
          background: rgb(246, 246, 246);
          padding: 20px;
          .innerMiddleContent {
          }
        }
        div.middleContent.bGwhite {
          background: white;
          .paymentSummary {
            margin: auto;
          }
        }
      }
    }
  }
  /* .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: #F9A01B !important;
  } */
  .ant-menu-item,
  .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span,
  .ant-menu-item > a
  {
    font-family: 'SukhumvitSet'!important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(249, 160, 27, 0.08);
  }
  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #F9A01B;
  }
  .ant-layout-header {
    padding: 0 13px;
  }
  .code-box {
    border: 1px solid #ebedf0;
    border-radius: 2px;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0 0 16px;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  div.listContent {
    background: white;
    margin: 2px;
  }
  
  div.editContent {
    div.modDeleteButton {
      text-align: right;
      height: 0;
      position: relative;
      right: 10px;
      top: 3px;
    }
  }

  h2.title {
    color: #0d1a26;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 8px;
    font-family: Lato,Monospaced Number,Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 30px;
    line-height: 38px;
  }
  @media only screen and (max-width: 750px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inherit;
    }
    .layout-content {
      div.maincontent {
        padding-left: 0;
        .topContent.topContent {
          border-bottom: 0;
        }
        .topContentA {
          background: white;
          height: 100px;
          border-bottom: 1px solid #EAEAEA;
          .noCreateEvent span.createEventIcon {
            display: none;
          }
          span.createEventIcon {
            position: absolute;
            right: 20px;
            top: 20px;
          }
          
          span.title {
            font-size: 20px;
            font-family: Sukhumvit Set;
            font-weight: bold;
            position: relative;
            left: 20px;
            top: 52px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 40px);
            display: block;
            white-space: nowrap;
          }
        }
        div.searchWrapper {
          text-align: center;
          span.ant-input-affix-wrapper {
            margin-top: 23px;
            height: 40px;
            width: calc(100% - 32px);
            input {
              border: 0;
            }
          }
        }
      }
    }
  }
`
export const Logo = styled.div`
  /* float: left; */
  padding-left: 11px;
  padding-top: 23px;
  margin-bottom: 20px;
  img {
    width: 42px;
    height: auto;
  }
`
export const GenerateCouponCodesFormStyle = styled.div`
  span.ant-input-group.ant-input-group-compact {
    input {
      width: 99px;
    }
  }
`

export const RepeaterItem = styled.div`
  position: relative;
  ${({ deleted }) => deleted && `display: none;`}
`
export const StyledContent = styled(Content)`
  background: #f0f2f5;
  padding: 50px;
  div.inner-content {
    max-width: 1000px;
  }
`
export const StyledForm = styled(Content)`
  padding: 50px;
  form {
    background: white;
    max-width: 1000px;
    margin: auto;
    padding: 10px;
  }
`
