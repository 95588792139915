import React, { useState } from 'react'
import {
  Input,
  message,
  Form
} from 'antd'
import _get from 'lodash/get'
import updateShirtQbSkuMutations from '~/graphql/mutations/qb/updateShirtQbSku.gql'
import { useMutation } from '@apollo/react-hooks'
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const { Search } = Input
const shirtForm = ({shirt, shirtType, eventId, shirtQbSku}) => {
  const [loading, setLoading] = useState(false)
  const [qbName, setQbName] = useState(_get(shirtQbSku, 'qbName'))
  const [validateStatus, setValidateStatus] = useState(_get(shirtQbSku, 'qbId')?'success':'warning')
  const [updateShirtQbSku] = useMutation(updateShirtQbSkuMutations)
  const onSearch = (qbSku) => {
    setLoading(true)
    updateShirtQbSku({ variables: { qbSku, eventId, shirtId: shirt._id } })
      .then(res => {
        setLoading(false)
        setQbName(_get(res, 'data.updateShirtQbSku.qbName'))
        message.success('success')
        setValidateStatus('success')
      })
      .catch(err => {
        message.error(JSON.stringify(err))
        setValidateStatus('warning')
        setLoading(false)
      })
  }
  return <Form.Item
    key={shirt._id} 
    {...formItemLayout}
    label={`${shirtType.name.en} : ${shirt.name.en}`}
    validateStatus={validateStatus}
    hasFeedback
    onChange={() => setValidateStatus('warning')}
    help={qbName || 'incorrect sku !!!'}
  > 
    <Search
      placeholder="Sku"
      enterButton="Save"
      defaultValue={_get(shirtQbSku, 'qbSku')}
      style={{ width: 300 }}
      loading={loading}
      onSearch={onSearch}
    />
  </Form.Item>
}

const ShirtQb = ({
  eventId,
  shirtTypes,
  shirts,
  shirtQbSkus
}) => {
  return <div>
    {shirts.map(shirt => {
      const shirtType = shirtTypes.find(({_id}) => _id === shirt.shirtTypeId )
      const shirtQbSku = shirtQbSkus.find(({shirtId})=> shirt._id === shirtId)
    return shirtForm({shirt, shirtType, shirtQbSku, eventId})
  })}
  </div>
}

export default ShirtQb
