import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import { Link } from 'react-router-dom'
import {
  Form,
  Modal,
  Button,
  Tag,
  Layout
} from 'antd'
import Itag from '~/components/Itag'
import EventSider from '~/components/Events/Event/Sider'
// const FormItem = Form.Item
import moment from 'moment-timezone'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import { inject, observer } from 'mobx-react'
import cancelRequestQuery from '~/graphql/queries/cancelRequest.gql'
import deleteCancelRequestMutation from '~/graphql/mutations/Event/CancelRequest/delete.gql'
import localeGet from '~/utils/localeGet'
// import eventQuery from '~/graphql/queries/event.gql'
// import ImageUpload from './ImageUpload'
import CancelResponseForm from './CancelResponseForm'
import respondCancelRequestMutation from '~/graphql/mutations/respondCancelRequest.gql'
import Loading from '~/components/Loading/index'
import Error404 from '~/components/Error/404'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}

const bibNumber = number => {
  const pad = '0000'
  return pad.substr(0, pad.length - `${number}`.length) + number
}

const responseDataFields = [{
  title: 'Attachment',
  key: 'attachment',
  dataIndex: 'response.attachmentUrl',
  render: (attachmentUrl) => <img src={attachmentUrl} alt="Attachment" />
}, {
  title: 'Remarks',
  key: 'remarks',
  dataIndex: 'response.remarks',
  render: (remarks) =>
    <div style={{ whiteSpace: 'pre-line' }}>{remarks}</div>
}]

@inject('router')
@graphql(respondCancelRequestMutation, { name: 'respondCancelRequest' })
@graphql(deleteCancelRequestMutation, { name: 'deleteCancelRequest' })
@observer
class CancelRequest extends Component {
  state = {
    confirmDirty: false,
    disabled: true,
    loading: false
  }
  refundForm = null
  handleRevokeStatus = ({cancelRequest, status}) => {
    const { match: { params: {eventId, cancelRequestId} }} = this.props
    Modal.confirm({
      title: 'ยกเลิกสถานะนี้',
      content: <div>ยกเลิกสถานะนี้  <Itag type={status} text={status}/>?`</div>,
      onOk: () => {
        this.setState({ loading: true })
        console.log('g', cancelRequest._id, this.props.deleteCancelRequest)
        this.props.deleteCancelRequest({
          variables: {
            _id: cancelRequest._id,
          }
        }).then(res => {
          this.props.history.push(`/fetchPage?link=/events/${eventId}/cancel-requests`)
        }).catch(e => {
          Modal.error({
            title: JSON.stringify(e),
            onOk: () => {
              this.setState({ loading: false })
            }
          })
        })
      },
      onCancel () {}
    })
  }
  handleResponseModalOpen = () => {
    const { router: { location } } = this.props
    location.query = {
      response: true
    }
  }
  handleResponseModalClose = () => {
    const { location } = this.props.router
    const { response, ...query } = location.query
    location.query = query
  }
  handleCreateRefund = () => {
    this.setState({
      responding: true
    })
    const { form } = this.refundForm.props
    form.validateFields((err, values) => {
      if (err) {
        return console.error(err)
      }
      const { record } = values
      this.props.respondCancelRequest({
        variables: {
          record
        },
        refetchQueries: [{ // can use updateQuery
          query: cancelRequestQuery,
          variables: {
            _id: record.cancelRequestId
          }
        }]
      }).then((result) => {
        this.setState({ responding: false })
        this.handleResponseModalClose()
      })
    })
  }
  render () {
    const { router: { location } } = this.props
    const { response } = location.query
    const cancelRequestLoading = _get(this.props, 'cancelRequestLoading')
    const cancelRequest = _get(this.props, 'cancelRequest')
    if (cancelRequestLoading) return <Loading />
    if (!cancelRequest) return <Error404 />
    // const inputCols = {
    //   labelCol: { span: 4 },
    //   wrapperCol: { span: 20 }
    // }
    const dataFields = [{
      title: 'Requested Date',
      key: 'requestedDate',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('Do MMM HH:mm')
    }, {
      title: 'Participant',
      key: 'participant',
      dataIndex: 'ticket',
      render: (ticket) =>
        `[${bibNumber(ticket.number)}]\n${_get(ticket, 'participant.profile.firstName')} ${_get(ticket, 'participant.profile.lastName')}`
    }, {
      title: 'Ticket Type',
      key: 'ticketType',
      dataIndex: 'ticket.ticketType.name.en'
    }, {
      title: 'Ticket Price',
      key: 'price',
      dataIndex: 'ticket',
      render: (ticket) => <Tag color="blue">
        {ticket.ticketType.price} THB
      </Tag>
    }, {
      title: 'Delivery Price',
      key: 'delivery',
      dataIndex: 'registration',
      render: (registration) => <Tag color="gold">
        {_get(registration, 'amount.deliveryPrice', 0)} THB
      </Tag>  
    }, {
      title: 'Registration Code',
      key: 'regCode',
      dataIndex: 'registration',
      render: (registration) => <Link target="_blank" to={`/events/${registration.eventId}/registrations?s=${registration.code}`}>
        {registration.code}
      </Link>
    }, {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        return <div>
          <Itag type={status} text={status}/>
          {status === 'submitted' && <Button icon="edit" className="edit" loading={this.state.loading}
              onClick={() => this.handleRevokeStatus({cancelRequest, status})}
          >ยกเลิกสถานะนี้</Button>}
        </div>
      }
    }, {
      title: 'Method',
      key: 'method',
      dataIndex: 'method.name.th',
      render: (name) => name || 'ขอรับเงินคืน'
    }, {
      title: 'Data',
      key: 'data',
      dataIndex: 'options',
      render: (options, record) =>
        <div style={{ whiteSpace: 'pre-line' }}>
          {options.map(option => {
            const items = _get(option, 'question.items', [])
            const optionValue = {
              Open: option.value,
              Choice: localeGet(items.find(i => i._id === option.itemId), 'value', 'th') || option.itemId,
              MultiChoice: items.filter(i => option.itemIds && option.itemIds.includes(i._id)).map(i => localeGet(i, 'value', 'th')).join(', ')
            }[option.question.kind]
            const valueFormatted = option.question.inputType === 'date' ? moment(optionValue).format('D MMMM YYYY') : optionValue
            // return <div key={option._id}>{localeGet(option, 'question.name', 'th')}: {valueFormatted}</div>
            return <div key={option._id}>{localeGet(option, 'question.name', 'th')}: {<Itag type={valueFormatted} text={valueFormatted} />}</div>
          })}
        </div>
    // }, {
    //   title: 'Details',
    //   key: 'details',
    //   dataIndex: 'data',
    //   render: ({ transfer }) => <div style={{ whiteSpace: 'pre-line' }}>
    //     {`${_get(transfer, 'bankAccount.brand')}
    //     ${_get(transfer, 'bankAccount.name')}
    //     ${_get(transfer, 'bankAccount.number')}`}
    //   </div>
    }]
    return <Layout>
        <EventSider />
        <Layout.Content
          style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}
        >
    <Form onSubmit={this.handleSubmit}>
      {dataFields.map(f =>
        <Form.Item key={f.key} label={f.title} {...formItemLayout}>
          {(f.render && _isFunction(f.render))
            ? f.render(_get(cancelRequest, f.dataIndex), cancelRequest)
            : _get(cancelRequest, f.dataIndex)
          }
        </Form.Item>)}
      <hr />
      {cancelRequest.response
        ? responseDataFields.map(f =>
          <Form.Item key={f.key} label={f.title} {...formItemLayout}>
            {(f.render && _isFunction(f.render))
              ? f.render(_get(cancelRequest, f.dataIndex), cancelRequest)
              : _get(cancelRequest, f.dataIndex)
            }
          </Form.Item>)
        : <Form.Item key="response" label="ดำเนินการ" {...formItemLayout} >
          <Button onClick={this.handleResponseModalOpen}>ดำเนินการ</Button>
        </Form.Item>
      }
      <Modal
        visible={!!response}
        onCancel={this.handleResponseModalClose}
        onOk={this.handleCreateRefund}
        confirmLoading={this.state.responding}
        // okText={this.state.submitting ? 'Submitting' : 'Submit'}
      >
        <CancelResponseForm wrappedComponentRef={ref => { this.refundForm = ref }} cancelRequest={cancelRequest} />
      </Modal>
    </Form>
    </Layout.Content>
    </Layout>
  }
}

export default compose(
  Form.create(),
  graphql(cancelRequestQuery, {
    options: ({ match: { params: { eventId, cancelRequestId } } }) => ({
      variables: { _id: cancelRequestId },
      fetchPolicy: 'network-only'
    }),
    props: ({ data }) => ({
      cancelRequest: data.cancelRequest,
      cancelRequestLoading: data.loading
    })
  }),
  // graphql(eventQuery, {
  //   options: ({ match: { params: { eventId } } }) => ({
  //     variables: { _id: eventId }
  //   }),
  //   props: ({ data }) => ({
  //     event: data.event,
  //     eventLoading: data.loading
  //   })
  // })
)(CancelRequest)
