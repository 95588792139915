import React from 'react'
import { Form, Input, InputNumber, DatePicker } from 'antd'
// import { graphql } from 'react-apollo'
import _get from 'lodash/get'
import ImageUpload from './ImageUpload'
// import _keyBy from 'lodash/keyBy'
// import ticketItemQuery from '~/App/graphql/queries/ticketItem.gql'
// import raceQuery from '~/App/graphql/queries/race.gql'
// import ImageUploader from '../ImageUploader'
// import { inject, observer } from 'mobx-react'
// import { FormattedMessage } from 'react-intl'

// const bibNumber = number => {
//   const pad = '0000'
//   return pad.substr(0, pad.length - `${number}`.length) + number
// }

const formItemLayout = {
  labelCol: { sm: { span: 10 }, xs: { span: 24 } },
  wrapperCol: { sm: { span: 14 }, xs: { span: 24 } }
}
// const bankBrands = ['bbl', 'kbank', 'ktb', 'tmb', 'scb', 'citi', 'cimb', 'uob', 'bay', 'tbank', 'ibank', 'lhb']
// @inject('i18n')
@Form.create()
// @observer
class RefundForm extends React.Component {
  render () {
    const { form, refundRequest } = this.props
    const { getFieldDecorator } = form
    getFieldDecorator(`record.method`, {
      initialValue: 'transfer',
      rules: [{ required: true }]
    })
    getFieldDecorator(`record.eventId`, {
      initialValue: refundRequest.eventId,
      rules: [{ required: true }]
    })
    getFieldDecorator(`record.refundRequestId`, {
      initialValue: refundRequest._id,
      rules: [{ required: true }]
    })
    getFieldDecorator(`record.paymentId`, {
      initialValue: _get(refundRequest, 'paymentId'),
      rules: [{ required: true }]
    })
    getFieldDecorator(`record.ticketItemId`, {
      initialValue: refundRequest.ticketItemId,
      rules: [{ required: true }]
    })
    return <Form>
      <h3>Refund</h3>
      <Form.Item label="Refund Slip" {...formItemLayout}>
        {getFieldDecorator('record.data.transfer.slipUrl')(<ImageUpload />)}
      </Form.Item>
      <Form.Item label="Amount" {...formItemLayout}>
        {getFieldDecorator('record.amount')(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Refund Date" {...formItemLayout}>
        {getFieldDecorator('record.completedAt')(<DatePicker />)}
      </Form.Item>
      <Form.Item label="Remarks" {...formItemLayout}>
        {getFieldDecorator('record.remarks')(<Input.TextArea/>)}
      </Form.Item>
    </Form>
  }
}

export default RefundForm
