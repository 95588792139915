import React, { Component, Fragment } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import {
  Layout,
  Table,
  Button,
  Icon,
  Tooltip,
  Divider,
  Modal
} from 'antd'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import productsQuery from '~/graphql/queries/products.gql'
import updateProductMutation from '~/graphql/mutations/updateProduct.gql'
import removeProductMutation from '~/graphql/mutations/product/removeProduct.gql'
import SortItems from '~/components/SortItems'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
import { inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

class Products extends Component {
  state = {
    loading: false
  }
  handleSortabled = () => {
    const { location } = this.props.router
    // window.location.reload()
    location.query = {
      sortabled: 'true'
    }
  }
  handleDeleteProduct = ({product}) => {
    const { location } = this.props.router
    const {data} = this.props
    Modal.confirm({
      title: 'Delete',
      content: `Delete "${product.name.th}" ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.props.removeProduct({
          variables: {
            _id: product._id
          }
        }).then(res => {
          data.fetchMore({
            updateQuery: (pre, { fetchMoreResult }) => {
              this.setState({ loading: false })
              return fetchMoreResult
            }
          })
          location.query = ''
        }).catch(e => {
          Modal.error({
            title: JSON.stringify(e),
            onOk: () => {
              this.setState({ loading: false })
            }
          })
        })
      },
      onCancel () {}
    })
  }
  render () {
    const { data, loading, router, history, updateProduct, t} = this.props
    const { sortabled } = router.location.query
    
    if (loading) {
      return <Loading />
    }
    if (!data.event) {
      return <EventNotFound />
    }
    const isTestMode = data.event.testMode
    const products = _get(data, 'products')
    // console.log('products', products)
    const columns = [{
      title: `${t('products.action')}`,
      key: 'action',
      render: (record) => {
        return <span>
          <Tooltip title="Edit Product">
            <Link to={`/events/${data.event._id}/products/${record._id}/edit`}>
              <Button type="primary" icon="edit" className="edit" />
            </Link>
          </Tooltip> {` `}
          {isTestMode && <Button icon="delete" className="delete" loading={loading}
            onClick={() => this.handleDeleteProduct({product: record})}
          />}
          {record.openorderitemtype ? <Fragment>
            <Divider type="vertical" />
            <Tooltip title="add ProductType">
              <Link to={`/events/${data.event._id}/products/${record._id}/addType`}>
                <Button type="primary" icon="file-add" className="add" />
              </Link>
            </Tooltip>
          </Fragment> : ''}
        </span>
      }
    }, {
      title: `${t('products.index')}`,
      dataIndex: 'order',
      key: 'order'
    // }, {
    //   title: 'รหัสสินค้า',
    //   dataIndex: '_id',
    //   key: '_id'
    },
    {
      title: `${t('products.status')}`,
      dataIndex: 'hidden',
      key: 'hidden',
      render: (hidden) => {
        if (hidden === false) {
          return <span> {t('products.display')} </span>
        } else {
          return <span> {t('products.hide')} </span>
        }
      }
    }, {
      title: `${t('products.image')}`,
      dataIndex: 'imageUrl',
      key: 'image',
      render: (imageUrl) => <img style={{ width: 50 }} src={imageUrl} alt=""/>
    },
    {
      title: `${t('products.price')}`,
      key: 'price',
      render: (record) => {
        if (record.openorderitemtype) {
          return <span> - </span>
        }
        return record.price
      }
    },
    {
      title: `${t('products.name')}`,
      dataIndex: 'name.th',
      key: 'name'
    }, {
      title: `${t('products.description')}`,
      dataIndex: 'description.th',
      key: 'description'
    }
    ]
    let $items = <Table
      clasName="innerMiddleContent"
      style={{whiteSpace: 'nowrap'}}
      scroll={{ x: true }}
      columns={columns}
      dataSource={products}
      loading={loading}
      pagination={{ pageSize: 100 }}
      locale={{ emptyText: `${t('products.noData')}` }}
      rowKey={'_id'}
      // onRowClick={(record) => (this.props.history.push(`/events/${data.event._id}/products/${record._id}/edit`))}
    />
    if (sortabled === 'true') {
      $items = <SortItems
        refetch={() => window.location.reload()}
        items={products}
        updateOrderItems={updateProduct}
        history={history}
        toPath={`/events/${data.event._id}/products`}/>
    }
    return (
      <Layout>
        <Layout.Content>
          <TopContent
            title={_get(data, 'event.name.th') || _get(data,'event.name.en')}
            nodes={{
              InputSearch: '',
              SelectStatus: <div>
                <Link
                  to={`/events/${data.event._id}/products/create`}
                  style={{marginLeft: '100px'}}
                >
                  <Button type="primary" size="large">
                    <Icon type="plus" /> {t('products.add')}
                  </Button>
                </Link>{' '}
                <Button
                  style={{marginLeft: '10px'}}
                  onClick={this.handleSortabled}
                  className="action inline" icon="sort-ascending"
                  loading={loading}
                />
            </div>
            ,
            }}
            pattern='registrations'/>
          <div className="innerMainContentWrapper">
            <EventSider />
              <div className="middleContent">
                {$items}
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default compose(
  graphql(productsQuery, {
    options: ({ match: { params: { eventId } } }) => ({
      variables: { eventId },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(updateProductMutation, { name: 'updateProduct' }),
  graphql(removeProductMutation, { name: 'removeProduct' }),
  inject('router'),
  withTranslation()
)(Products)
