import React from 'react'
import {
    //Card,
    Table,
    Switch
} from 'antd'

import '../Styles/Custom.css'
//const { Meta } = Card
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'

// function TicketType (props) {
const GraphApexTicketType = ({param, ticketTypeCountBackEnd, ticketTypeOriginal, loading}) => {

    const chartRes = param.chart

    let _showDetail = param.tableEvent['ticketTypeGdetail'] === undefined ? false : param.tableEvent['ticketTypeGdetail']   
    // console.log('_showDetail', _showDetail)
    const opt   = { 
        title: chartRes.caption,
        subtitle: param.subTitle,
        width: param.width,
        height: (param.height ? param.height : '300px'),
    } 

    if (!(!loading && param.event && ticketTypeCountBackEnd /* && orderItemCount */)) {
      return <Loading />
    } else {
      // Modify TicketTypeCount
      ticketTypeCountBackEnd = _orderBy(ticketTypeCountBackEnd, ['order', 'price', 'distance'], ['asc', 'asc', 'desc'])
      const totalTTC = {
        _id: 'total',
        name: {
          en: 'TOTAL'
        },
        draft: 0,
        submitted: 0,
        pending: 0,
        expired: 0,
        payment_verifying: 0,
        cancelled: 0,
        paid: 0,
        total: 0
      }
      ticketTypeCountBackEnd.forEach((tt) => {
        totalTTC.draft += tt.draft
        totalTTC.submitted += tt.submitted
        totalTTC.pending += tt.pending
        totalTTC.expired += tt.expired
        totalTTC.payment_verifying += tt.payment_verifying
        totalTTC.cancelled += tt.cancelled
        totalTTC.paid += tt.paid
        totalTTC.total += tt.total
      })
      ticketTypeCountBackEnd = ticketTypeCountBackEnd.concat(totalTTC)
    }    

    if (!ticketTypeCountBackEnd) return 'loading...'
    const ticketNameGroup = _groupBy(ticketTypeOriginal, 'groupName')
    let rowData = 0
    const filterGroupTicketName = Object.keys(ticketNameGroup).map((groupname) => ({
      text: `${groupname}`,
      value: groupname
    }))

    rowData = filterGroupTicketName.length

    const ticketTypeCountColumns = [
      {
        title: 'TICKET TYPE NAME',
        dataIndex: 'name.en',
        key: `ticketTypeName`
      }, {
        title: 'PAID',
        dataIndex: 'paid',
        key: `ticketTypePaid`,
        className: 'center amount vvsmall'          
      }
    ]

    const ticketTypeCountShowDetailColumns = [
      {
          title: 'TICKET TYPE NAME',
        dataIndex: 'name.en',
        key: `ticketTypeName`
      }, {
        title: 'GROUPNAME',
        dataIndex: 'groupName',
        key: `_id`,
        filters: filterGroupTicketName,
        onFilter: (value, record) => record.groupName === value,
        filterMultiple: false
      }, {
        title: 'DRAFT',
        dataIndex: 'draft',
        key: `ticketTypeDraft`,
        className: 'center amount vvsmall'        
      }, {
        // title: 'Submitted (expired + payment_verifying + cancelled)',
        title: ()=>(<div className="center">
                SUBMITTED
                <div>(PENDING&nbsp;+&nbsp;EXPIRED&nbsp;+&nbsp;PAYMENT&nbsp;VERIFYING&nbsp;+&nbsp;CANCELED)</div>
                </div>)
        ,
        dataIndex: 'submitted',
        key: `ticketTypeSubmitted`,
        render: (submitted, { pending, expired, payment_verifying, cancelled }) => (<div>
            {submitted} 
            <div style={{fontSize: 'smaller'}}> 
              ({pending} + {expired} + {payment_verifying} + {cancelled}) 
            </div>
            </div>),
        className: 'center amount vsmall'
        // render: (submitted, { expired, payment_verifying, cancelled }) => `${submitted} (${expired} + ${payment_verifying} + ${cancelled})`
      }, {
        title: 'PAID',
        dataIndex: 'paid',
        key: `ticketTypePaid`,
        className: 'center amount vvsmall'      
      }, {
        title: 'TOTAL',
        dataIndex: 'total',
        key: `ticketTypeTotal`,
        className: 'center amount vvsmall'
      }
    ]

    return (
      /*
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch 
            checked={!!this.state.show}
            onChange={this.handleShow} 
            checkedChildren="No Detail" 
            unCheckedChildren="Show Detail"
          />
        </div>

        <div> */
        <div hoverable className='fgma-chart' style={{ backgroundColor:'#FFF', border:'1px solid #EBEBEB', boxShadow:'0px 2px 5px rgb(0,0,0,0.1)' }}>
                <div style={{ margin: '20px', marginBottom: '25px', fontSize: '1.3em', paddingLeft: '5px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        {(rowData>0) && <div style={{display:'flex', float:'right', justifyContent: 'flex-end', marginTop:'5px'}}>
                            <Switch  checkedChildren="Details" unCheckedChildren="Paid Only" onChange={param.eventHandle} /> 
                        </div>}                            
                        </div>                    
          {!_showDetail ? <Table
            style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF', minHeight: opt.height}}
            scroll={{ x: true }}
            columns={ticketTypeCountColumns}
            className= "eventFgma"
            dataSource={ticketTypeCountBackEnd}
            loading={loading}
            locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
            rowKey={record => record._id}
            pagination={false}
          />: <Table
            style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF', minHeight: opt.height}}
            scroll={{ x: true }}
            columns={ticketTypeCountShowDetailColumns}
            className= "eventFgma"            
            dataSource={ticketTypeCountBackEnd}
            loading={loading}
            locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
            rowKey={record => record._id}
            pagination={false}
          /> }
          <div>&nbsp;</div>
        </div>
    )
}

export default GraphApexTicketType
