import React, { useState } from 'react';
import { Layout, Spin, Collapse, Table, Button, Modal } from 'antd';
import { useQuery } from 'react-apollo-hooks';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _mergeWith from 'lodash/mergeWith'

import TopContent from '~/components/Events/Event/TopContent'
import EventSider from '~/components/Events/Event/Sider'
import ImportPrivileges from './ImportPrivileges'

import RegistrationTypeByEventId from '~/graphql/queries/registrationTypeByEventId.gql';
import PrivilegeListQuery from '~/graphql/queries/privilegeList.gql'

const { Panel } = Collapse
const PERPAGE = 10

const Privileges = (props) => {
  const { eventId } = useParams()
  const [isImport, setIsImport] = useState(false)
  const [page, setPage] = useState(1)

  const { data, loading } = useQuery(RegistrationTypeByEventId, {
    variables: {
      eventId
    }
  })
  const { data: data2, loading: loading2, refetch } = useQuery(PrivilegeListQuery, {
    variables: {
      eventId,
      page,
      perPage: PERPAGE
    }
  })
  if (loading || loading2) {
    return <Spin />
  }

  const onLoadMore = (page) => {
    setPage(page)
    refetch({
      eventId,
      page,
      perPage: PERPAGE
    });
  }

  const columns2 = [
    {
      title: 'privilegeKey',
      dataIndex: 'privilegeKey',
      key: 'privilegeKey'
    },
    {
      title: 'firstname',
      dataIndex: 'profile.firstName',
      key: 'firstname'
    },
    {
      title: 'lastname',
      dataIndex: 'profile.lastName',
      key: 'lastname'
    }
  ]

  let privilegeKeyList = []
  if(data.eventRegistrationTypes.length > 0){
    data.eventRegistrationTypes.forEach(element => {
      privilegeKeyList.push(...element.privilegeKeys)
    })
  }

  return(
    <Layout>
      <Layout.Content>
        <TopContent
            // title={_get(eventProps, 'data.event.name.th')}
        />
          <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <div style={{padding: '6px', marginLeft: '20px', marginBottom: '20px', fontSize: '1.5em'}}>
                <div style={{border: '1px solid #DADADA', borderRadius: '7px', padding: '10px', backgroundColor: '#fff'}}>
                  <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <h3>Privileges</h3>
                    <Button onClick={e => setIsImport(!isImport)}>Add Privileges</Button>
                  </div>
                  <p style={{fontSize: '16px'}}>Total Privileges: {_get(data2, 'PrivilegeList.count')}</p>
                  <Table 
                    columns={columns2}
                    // dataSource={_get(registrationType, 'privilegeKeys')}
                    dataSource={_get(data2, 'PrivilegeList.items')}
                    pagination={{
                      size: 'small',
                      current: page,
                      pageSize: PERPAGE,
                      total: _get(data2, 'PrivilegeList.count'),
                      onChange: onLoadMore
                    }}
                  />
                  <Modal
                      title="Import Privileges"
                      visible={isImport}
                      // onOk={this.hideModal}
                      onCancel={()=> setIsImport(false)}
                      // okText="确认"
                      // cancelText="取消"
                      footer={[
                        // <Button key="back" onClick={()=> setIsImport(false)}>
                        //   close
                        // </Button>
                      ]}
                    >
                      <ImportPrivileges privilegeKeyList={privilegeKeyList} setIsImport={setIsImport} onLoadMore={onLoadMore} />
                  </Modal>
                  
                </div>
              </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
export default Privileges;