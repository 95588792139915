import React, { Component } from 'react'
import {
  Button,
  Card
} from 'antd'
import { withTranslation } from 'react-i18next'

class Summary extends Component {
  handlePaidExport = async () => {
    this.props.onPaidExport()
  }
  handleExport = async () => {
    this.props.onExport()
  }
  handleDateExport = async () => {
    this.props.onDateExport()
  }
  handleSimpleExport = async () => {
    this.props.onPaidSimpleExport()
  }

  render () {
    const { t } = this.props
    return (
      <div className='eventDownloadBox no-print'>
        <Card
          title={t('download.runner.title')}
          size="small"
          style={{marginBottom: 10}}
          key='Summary'
        >
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handlePaidExport}
              icon="export"
              loading={this.props.exporting}
            >
              {t('download.download')}
            </Button>{t('download.runner.success')}
          </div>
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handleDateExport}
              icon="export"
              loading={this.props.exporting}
            >
            {t('download.download')}
            </Button>{t('download.runner.date')}
          </div>
          <div style={{marginBottom: '9px'}}>
          <Button
            onClick={this.handleExport}
            icon="export"
            loading={this.props.exporting}
          >
            {t('download.download')}
          </Button>{t('download.runner.all')}
          </div>
            <Button
              onClick={this.handleSimpleExport}
              icon="export"
              loading={this.props.exporting}
            >
              {t('download.download')}
            </Button>{t('download.runner.simple')}
        </Card>
      </div>
    )
  }
}

export default withTranslation() (Summary)
