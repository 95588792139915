import React, { Component } from 'react'
import {
  Switch, Route
} from 'react-router-dom'
// import EventSider from '~/components/Events/Event/Sider'
// import {
//   Layout
// } from 'antd'
import List from './List'
// import Error
import Error404 from '../Error/404'

class Orders extends Component {
  render () {
    return (
    <Switch>
      <Route
        exact
        path="/events/:eventId/orders"
        component={List}
      />
      <Route
        exact
        path="*"
        component={Error404}
      />
    </Switch>
    )
  }
}

export default Orders
