import React from 'react'
import { Form } from 'antd'
import _get from 'lodash/get'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import Uploader from '~/components/Uploader'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
}

@Form.create()
@withRepeaterHandler
class UploadSlipForm extends React.Component {
  render () {
    const { form, payments, paymentId } = this.props
    const { getFieldDecorator } = form
    const locale = this.props.i18n.language
    console.log(locale)
    if (!payments && !paymentId) {
      return 'payments not found'
    }
    const payment = payments.find(p => p._id === paymentId)
    if (!payment) {
      return 'payment not found'
    }
    getFieldDecorator('_id', {
      initialValue: _get(payment, '_id')
    })
    return <Form>
      <h2>{{"th": "Admin อัพโหลดหลักฐานการจ่ายเงิน", "en": "Upload the Proof of Payment"}[locale]}</h2>
      Amount: {payment.amount} <br/>
      Amount paid: {payment.payAmount} <br/>
      {payment.method === 'scb_qr' && <div>
        scb_qr <br/>
        ref2: {_get(payment, 'data.scb.invoice.ref2')}
      </div>}
      {payment.method === 'omise_card' && <div>
        omise_card <br/>
        paymentId: {_get(payment, '_id')}
      </div>}
      {payment.method === 'linepay' && <div>
        linepay <br/>
        transactionId: {_get(payment, 'data.linepay.transactionId')}
      </div>}
      <Form.Item {...formItemLayout} label="">
        {getFieldDecorator('adminSlipUrl', {
          initialValue: _get(payment, 'adminSlipUrl'),
          rules: [{ required: true, message: {"th":'กรุณาอัพโหลดรูปหลักฐานการจ่ายเงิน', "en": "Please Upload the Proof of Payment"}[locale] }]
        })(
          <Uploader />
        )}
      </Form.Item>
    </Form>
  }
}

export default withTranslation() (UploadSlipForm)
