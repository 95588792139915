import React from 'react'
import {
  Layout,
  Select,
  Form,
  Row,
  Col,
  Collapse,
} from 'antd'
import _get from 'lodash/get'
import EventSider from '~/components/Events/Event/Sider'
import eventQuery from '~/graphql/queries/qb/event.gql'
import Loading from '../Loading/index'
import EventNotFound from '../Error/Eventnotfound'
import EventQb from './EventQb'
import EventSupplierQb from './EventSupplierQb'
import ShirtQb from './ShirtQb'
import FixedExpenseSku from './fixedExpense'
import ExtraProduct from './ExtraProduct'
import { useQuery } from '@apollo/react-hooks'
import TopContent from '~/components/Events/Event/TopContent'

const { Option } = Select
const Panel = Collapse.Panel

const children = [];
    for (let i = 10; i < 36; i++) {
      children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

const QuickBooks = (props) => {
  const { match: { params: { eventId } } } = props
  const {
    loading: eventLoading,
    data: eventData
  } = useQuery(eventQuery, {
    variables: {
      _id: eventId,
    },
    fetchPolicy: "network-only",
    skip: !eventId
  })
    if (eventLoading) {
      return <Loading />
    }
    if (!eventData) {
      return <EventNotFound />
    }
    const organizerName = _get(eventData, 'event.organizer.name')
    const isThairun = organizerName === 'ThaiRun'
    const customPanelStyle = {
      border: 0,
      borderRadius: 4,
      marginBottom: 10
    }

    return (
      <Layout>
        <Layout.Content>
          <TopContent
              title={eventData.event.name.en}
          />
          <div className="innerMainContentWrapper">
            <EventSider />
            <Row type="flex" justify="start" className="middleContent">
              <Col span={24}>
                <Collapse bordered={false} defaultActiveKey={['event', 'shirt', 'makeMerit', 'ticketType', 'extraProduct']} >
                  <Panel header="Event" key="event" style={customPanelStyle}>
                    <EventQb eventId={eventId}
                      eventQb={_get(eventData, 'event.eventQb')}/>
                    <EventSupplierQb eventId={eventId}
                      eventSupplierQb={_get(eventData, 'event.eventSupplierQb')}/>
                  </Panel>
                  {isThairun && <Panel header="เสื้อ" key="shirt" style={customPanelStyle}>
                    <ShirtQb
                      eventId={eventId}
                      shirtQbSkus={_get(eventData, 'event.shirtQbSkus')}
                      shirtTypes={_get(eventData, 'event.shirtTypes')}
                      shirts={_get(eventData, 'event.shirts')}
                    />
                    {_get(eventData, ['event', 'finisherShirtTypes', 0]) && <div>
                      <Form.Item wrapperCol={{span: 24}}>
                        <h3>Finisher</h3>
                      </Form.Item>
                      <ShirtQb
                        eventId={eventId}
                        shirtQbSkus={_get(eventData, 'event.shirtQbSkus')}
                        shirtTypes={_get(eventData, 'event.finisherShirtTypes')}
                        shirts={_get(eventData, 'event.finisherShirts')}
                      />
                    </div>}
                  </Panel>}
                  {isThairun && <Panel header="ทำบุญ" key="makeMerit" style={customPanelStyle}>
                    <FixedExpenseSku
                      eventId={eventId}
                      fixedExpenseQbSku={_get(eventData, 'event.fixedExpenseQbSku')}
                    />  
                  </Panel>}
                  {isThairun && <Panel header="สินค้าแถมผูกกับตํ๋ว" key="extraProduct" style={customPanelStyle}>
                    <ExtraProduct
                      eventId={eventId}
                      ticketTypes={_get(eventData, 'event.ticketTypes')}
                      productQbSkus={_get(eventData, 'event.productQbSkus')}
                    />
                  </Panel>}
                </Collapse>
              </Col>
            </Row>
          </div>
        </Layout.Content>
      </Layout>
    )
  
}

export default QuickBooks
