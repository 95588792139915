import React, { Component } from 'react'
import { Button } from 'antd'

class Loading extends Component {
  render () {
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: 'calc(100vh - 69px - 37px)' }}>
        <Button type="primary" shape="circle" loading style={{ marginTop: 200 }}/>
        {/* Loading */}
      </div>
    )
  }
}
export default Loading
