import React, { useState } from 'react'
import { Input, Button, Upload, Icon, Modal, Progress, Select } from 'antd'
import { Dialog } from '@material-ui/core'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { useMutation } from 'react-apollo-hooks';
import promiseRetry from 'promise-retry'

import createOrUpdatePrivilegeMutation from '~/graphql/mutations/createPrivileges.gql'


const Imports = (props) => {
  const { eventId } = useParams()
  const { privilegeKeyList, setIsImport, onLoadMore } = props
  const [data, setData] = useState([])
  const [importing, setImporting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [privilegeKey, setPrivilegeKey] = useState('')



  const [createOrUpdatePrivilege] = useMutation(createOrUpdatePrivilegeMutation)

  const selectFile = async (info) => {
    await info.fileList[0].originFileObj.arrayBuffer().then((arrayBuffer) => {
      const wb = XLSX.read(arrayBuffer, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const jsonObj = XLSX.utils.sheet_to_json(ws)
      setData(jsonObj)
    })
  }

  const handleImport = async () => {
    setImporting(true)
    const recordCount = data.length
    let records = [...data]
    

    while(records.length > 0){
      await Promise.all(records.splice(0, 5).map(async (record) => {
        const {
          firstName = record.firstName || record.firstname || record.first_name,
          lastName = record.lastName || record.lastname || record.last_name,
          nationalId = record.nationalId ? `${record.nationalId}`: record.national_id ? `${record.national_id}`: record.id ? `${record.id}`: '',
          birthDate = null, 
          gender = null, 
          name = ''
        } = record
        const mappedData = {
          eventId,
          privilegeKey: privilegeKey,
          profile: {
            firstName: firstName.replace(/[^a-zA-Z0-9ก-๙]/g, ''),
            lastName: lastName.replace(/[^a-zA-Z0-9ก-๙]/g, ''),
            ...(birthDate && {birthDate: moment(birthDate, 'DD/MM/YYYY')}),
            ...(gender && {gender: gender || undefined}),
            nationalId: nationalId.replace(/[^a-zA-Z0-9]/g, '')
          },
          identity: {
            mergedName: name.length > 0 ? name.replace(/[^a-zA-Z0-9ก-๙]/g, '').toUpperCase() : (firstName + lastName).replace(/[^a-zA-Z0-9ก-๙]/g, '').toUpperCase(),
            documentNumber: nationalId.toString().replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
          }
        }
        // console.log(mappedData);
       
        await promiseRetry(async (retry, number) => {
          if(number > 1) console.log('attemp=> ', number)
          const privilege = createOrUpdatePrivilege({
            variables: {
              record: mappedData
            }
          }).catch(async err => {
            await new Promise(resolve => setTimeout(() => resolve(console.log('retry')), 5000))
            retry(err)
          })
          return privilege
        }, { retries: 25 })

      }))
      setProgress(((recordCount - records.length) * 100) / recordCount)
    }
    setProgress(100)
    onLoadMore(1)
  }

  const handleSelectChange = (value) => {
    setPrivilegeKey(value)
  }

  const handleCloseImport = () => {
    setIsImport(false)
    setData([])
    setProgress(0)
  }


  return(
    <div style={{padding: '10px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      {
        progress !== 0 ? <>
          <Progress type="circle" percent={parseInt(progress)} />
          {progress === 100 && <Button style={{marginTop: '10px'}} onClick={() => handleCloseImport()}>Close</Button>}
        </>: <>
        <Select
        placeholder='privilegeKey'
        onChange={handleSelectChange}
        style={{ width: '100%', marginBottom: '10px' }}
        showSearch
      >
        {privilegeKeyList.map((item) => (
          <Option
            key={item}
            value={item}
          >{`${item}`}</Option>
        ))}
      </Select>
      {data.length === 0 && progress === 0 ? <Upload accept=".xlsx" showUploadList={false} onChange={selectFile} beforeUpload={()=> {return false; }}>
        <Button type="primary" disabled={privilegeKey===''}>
          <Icon type="upload" /> Import Privileges
        </Button>
      </Upload>: <><p>
        Do you Want to import {data.length} items?
        </p>
        <Button onClick={() => handleImport()}>Import</Button>
        </>
      }
      </>
      }
      
    </div>
  )

}

export default Imports