import React from 'react'
import {
  Select,
  Radio,
  Checkbox,
  Input,
  InputNumber
  // DatePicker,
  // TimePicker
} from 'antd'
import _get from 'lodash/get'
import DatePickerDropDown from '~/components/DatePickerDropDown'
import CloudUploader from '~/components/CloudUploader'
// import _get from '~/utils/_get'
const date = ({ question, locale = 'en', ...props }) => (
  <DatePickerDropDown {...props} />
)

const select = ({ question, locale = 'en', ...props }, containerRefFn) => (
  <Select {...props} placeholder={_get(question, ['name', locale])} getPopupContainer={containerRefFn}>
    {question.items.map((item, itemIndex) => (
      <Select.Option key={item._id} value={item._id}>
        {_get(item, ['value', locale])}
      </Select.Option>
    ))}
  </Select>
)
const image = ({ question, locale, ...props }) => (
  <CloudUploader
    provider="s3" object="questionprops"
    label={`${question.name[locale]}`}
    maxDimension={720} />
)
const radio = ({ question, locale = 'en', ...props }) => (
  <Radio.Group {...props}>
    {question.items.map((item, itemIndex) => (
      <Radio key={item._id} value={item._id}>
        <span style={{ whiteSpace: 'pre-line' }}>
          {_get(item, ['value', locale])}
        </span>
      </Radio>
    ))}
  </Radio.Group>
)

const checkbox = ({ question, locale = 'en', ...props }) => (
  <Checkbox key={question.items[0]._id} {...props}>
    {_get(question.items[0], ['value', locale])}
  </Checkbox>
)

const checkboxes = ({ question, locale = 'en', ...props }) => (
  <Checkbox.Group {...props}>
    {question.items.map((item, itemIndex) => (
      <Checkbox key={item._id} value={item._id}>
        {_get(item, ['value', locale])}
      </Checkbox>
    ))}
  </Checkbox.Group>
)

const text = ({ question, locale = 'en', ...props }) => (
  <Input type="text" {...props} />
)

const textarea = ({ question, locale = 'en', ...props }) => (
  <Input.TextArea {...props} rows={4} />
)

const number = ({ question, locale = 'en', ...props }) => <InputNumber {...props} />

// const date = ({ question, locale = 'en', ...props }) => <DatePicker {...props} />
// const datetime = ({ question, locale = 'en', ...props }) => <DatePicker {...props} />
// const time = ({ question, locale = 'en', ...props }) => <TimePicker {...props} />

const multiselect = ({ question, locale = 'en', ...props }, containerRefFn) => (
  <Select mode="multiple" {...props} getPopupContainer={containerRefFn}>
    {question.items.map((item, itemIndex) => (
      <Select.Option key={item._id} value={item._id}>
        {_get(item, ['value', locale])}
      </Select.Option>
    ))}
  </Select>
)

const shirtTypes = ({ question, locale = 'en', ...props }, containerRefFn) => (
  <Select mode="multiple" {...props} getPopupContainer={containerRefFn}>
    {question.items.map((item, itemIndex) => (
      <Select.Option key={item._id} value={item._id}>
        {_get(item, ['value', locale])}
      </Select.Option>
    ))}
  </Select>
)

class DurationInput extends React.Component {
  state = {
    hours: 0,
    minutes: 0
  }
  handleHourChange = (hours) => {
    // console.log({ hours })
    this.setState({
      hours
    }, () => {
      const { hours, minutes } = this.state
      const hh = ('0' + hours).slice(-2)
      const mm = ('0' + minutes).slice(-2)
      this.props.onChange(`${hh}:${mm}`)
    })
  }
  handleMinuteChange = (minutes) => {
    // console.log({ minutes })
    this.setState({
      minutes
    }, () => {
      const { hours, minutes } = this.state
      const hh = ('0' + hours).slice(-2)
      const mm = ('0' + minutes).slice(-2)
      this.props.onChange(`${hh}:${mm}`)
    })
  }
  componentWillMount () {
    const { value, defaultValue } = this.props
    const time = value || '00:00'
    console.log('receive', { value, time, defaultValue })
    const [hours, minutes] = time.split(':')
    this.setState({
      hours: parseInt(hours || 0, 10),
      minutes: parseInt(minutes || 0, 10)
    })
  }
  componentWillReceiveProps (nextProps) {
    // const { value, defaultValue } = nextProps
    // const time = value || '00:00'
    // console.log('receive', { value, time, defaultValue })
    // const [hours, minutes] = time.split(':')
    // this.setState({
    //   hours: parseInt(hours || 0, 10),
    //   minutes: parseInt(minutes || 0, 10)
    // })
  }
  render () {
    const { locale = 'en' } = this.props
    const { hours, minutes } = this.state
    return <Input.Group compact>
      <InputNumber
        onChange={this.handleHourChange}
        value={hours}
        min={0}
        max={100}
        formatter={value => ('0' + value).slice(-2)}
        style={{ width: 60, textAlign: 'right' }}
      />
      <Input style={{ width: 10, padding: 0, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff' }} placeholder=":" disabled />
      <InputNumber
        onChange={this.handleMinuteChange}
        value={minutes}
        min={0}
        max={59}
        formatter={value => ('0' + value).slice(-2)}
        style={{ width: 60, textAlign: 'right', borderLeft: 0 }}
      />
      <Input style={{ width: 100, pointerEvents: 'none', backgroundColor: '#fff' }} placeholder={{ en: 'hour:minutes', th: 'ชั่วโมง:นาที', zh: 'hour:minutes' }[locale]} disabled />
    </Input.Group>
  }
}

const duration = ({ question, locale = 'en', ...props }) => <DurationInput locale={locale} {...props} />

const heading = ({ question, locale, ...props }) => <div />

const rendererMap = {
  select,
  radio,
  checkbox,
  checkboxes,
  text,
  textarea,
  number,
  multiselect,
  duration,
  date,
  heading,
  shirtTypes,
  image
}

const renderQuestionInput = (props, containerRefFn) =>
  rendererMap[props.question.inputType](props, containerRefFn)
// console.log('props.inputKey', props.inputKey)
// console.log('props.key', props.key)
// console.log('props.question', props.question)

export default renderQuestionInput
