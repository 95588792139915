import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import flatten from 'flat'
import withHandlers from 'recompose/withHandlers'
const withRepeaterHandlers = withHandlers({
  addItemHandler: props => ({ path, defaultValues, initialValue }) => (e) => {
    const { getFieldsValue, getFieldDecorator, setFieldsValue } = props.form
    const items = _get(getFieldsValue(), path, defaultValues)
    const subFields = Object.keys(flatten(initialValue))
    const fieldsValue = subFields.reduce((result, subField) => {
      const value = _get(initialValue, subField)
      result[`${path}[${_get(items, 'length', 0)}].${subField}`] = _isFunction(value) ? value() : value
      return result
    }, {})
    for (const field in fieldsValue) {
      getFieldDecorator(field, {
        initialValue: fieldsValue[field]
      })
    }
    setFieldsValue(fieldsValue)
  },
  removeItemHandler: props => ({ path }) => (index) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    getFieldDecorator(`${path}[${index}].__deleted`, {
      initialValue: true
    })
    setFieldsValue({
      [`${path}[${index}].__deleted`]: true
    })
  },
  removeIndexItemHandler: props => ({ path }) => (index) => {
    const { setFieldsValue, getFieldDecorator } = props.form
    getFieldDecorator(`${path}[${index}].__deleted`, {
      initialValue: true
    })
    setFieldsValue({
      [`${path}[${index}].__deleted`]: true
    })
    setFieldsValue({
      [`${path}[${index}].name.en`]: 'deleted'
    })
    setFieldsValue({
      [`${path}[${index}].name.th`]: 'deleted'
    })
  },
  getItemsHandler: props => ({ path }) => () => {
    const { getFieldsValue } = props.form
    const record = getFieldsValue()
    const items = _get(record, path)
    return items
  }
})

export default withRepeaterHandlers
