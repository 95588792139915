import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { Form,
  Button,
  message,
  Input,
  Layout,
  InputNumber
} from 'antd'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import productQuery from '~/graphql/queries/product.gql'
import createProductTypeMutation from '~/graphql/mutations/createProductType.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
import ProductNotFound from '~/components/Error/Productnotfound'
import { inject, observer } from 'mobx-react'
import withRepeaterHandler from '~/utils/withRepeaterHandler'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}

@inject('router')
@observer
@Form.create()
@withRepeaterHandler
@graphql(createProductTypeMutation, { name: 'createProductType' })
@graphql(productQuery, { // for create
  options: ({ match: { params: { productId, eventId } } }) => ({
    variables: { _id: productId, eventId }
  }),
  skip: ({ match: { params: { productId } } }) => !productId
})
class ProductForm extends Component {
  state = {
    loading: false
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFieldsAndScroll((err, values) => {
      const eventId = _get(this.props, 'match.params.eventId')
      const productId = _get(this.props, 'match.params.productId')
      const { name, quantity, price, limitId } = values

      const record = {
        productId,
        eventId,
        name,
        quantity,
        price,
        limitId
      }
      if (err) {
        message.error(err)
        this.setState({ loading: false })
        return
      }
      this.props.createProductType({
        variables: {
          record,
          eventId
        }
      }).then(result => {
        message.success('Saved!')
        this.setState({ loading: false })
        this.props.history.push(`/events/${eventId}/products/${productId}/addType`)
      }).catch(err => {
        message.error(err.message)
        this.setState({ loading: false })
      })
    })
  }
  render () {
    const { data } = this.props
    const { product } = data
    const productTypeId = _get(this.props, 'match.params._id')
    if (data.loading) return <Loading />
    if (!product) return <EventNotFound />
    if (!product && productTypeId) return <ProductNotFound />
    const { getFieldDecorator } = this.props.form
    getFieldDecorator(`productTypeId`, {
      initialValue: _get(productTypeId, '_id')
    })
    getFieldDecorator(`_id`, {
      initialValue: _get(product, '_id')
    })
    return (<div style={{margin: '10px'}}>
      <Layout style={{maxWidth: '1000px', margin: 'auto'}} >
        <EventSider />
        <Layout.Content
          style={{background: '#fff',
            padding: 24,
            minHeight: 280}}
        >
          <div style={{marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {product && product.name.en}
          </div>
          <Form>
            <Input.Group key={`product`}>
              <Form.Item {...formItemLayout}>
                <span style={{fontSize: '18px'}}>
                  {productTypeId ? 'Edit' : 'New'}  ProductType
                </span>
              </Form.Item>
              <Form.Item label={'English Name'} {...formItemLayout}>
                {getFieldDecorator(`name.en`, {
                  rules: [{ required: true, message: 'Please input english name' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={'Thai Name'} {...formItemLayout}>
                {getFieldDecorator(`name.th`, {
                  rules: [{ required: true, message: 'Please input thai name' }]
                })(<Input/>)}
              </Form.Item>
              <i>*จำกัดจำนวน: 0 แปลว่า "ไม่จำกัดสินค้าทั้งหมด"</i>
              <Form.Item
                {...formItemLayout}
                label="จำกัดสินค้าทั้งหมด"
                hasFeedback
              >
                {getFieldDecorator('quantity', {
                })(
                  <InputNumber min={0} step={1}/>
                )}
              </Form.Item>
              <Form.Item label={'Price'} {...formItemLayout}>
                {getFieldDecorator(`price`, {
                  rules: [{ required: true, message: 'Please input price' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={'limitId'} {...formItemLayout}>
                {getFieldDecorator(`limitId`, {
                })(<Input/>)}
              </Form.Item>
            </Input.Group>
            <Form.Item style={{textAlign: 'center'}}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
              Save
              </Button>{' '}
              <Button loading={this.state.loading} type="ghost" onClick={() => window.history.back()}>
              ย้อนกลับ
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>
      </Layout>
    </div>)
  }
}

export default ProductForm
