import React from 'react'
import {
    //Card,
    Table,
} from 'antd'

import '../Styles/Custom.css'
//const { Meta } = Card

const GraphApex = ({param}) => {

    //const datasource = param.datasource 
    let dataquery = param.dataqueries     
    const chartRes = param.chart

    const opt   = { 
        title: chartRes.caption,
        subtitle: param.subTitle,
        width: param.width,
        height: (param.height ? param.height : '300px'),
    }

    if(!dataquery === null){}else{

        const totalCC = {
            counponCodeType:{
                    _id: 'total',
                    name: {
                    en: 'TOTAL'
                    }
            },
            count: 0
          }
                 

        dataquery.forEach((tt) => {
            totalCC.count += tt.count
        })
        dataquery = dataquery.concat(totalCC)   

    } 

    
    const columns = [{
      title: 'NAME',
      dataIndex: 'counponCodeType.name.en',
      key: 'counponCodeType.name.en'
    }, {
      title: 'COUNT',
      dataIndex: 'count',
      key: 'count',
      className: 'center'
    }]

    return (
            <div hoverable class='fgma-card' style={{ backgroundColor:'#FFF', border:'1px solid #EBEBEB', boxShadow:'0px 0px 1px rgb(0,0,0,0.1)' }}>
                <div className='fgma-chart' style={{ margin: '20px', marginBottom: '25px', fontSize: '1.3em', paddingLeft: '5px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>
                <Table
                    style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF', minHeight: opt.height}}
                    scroll={{ x: true }}
                    className="eventFgma"
                    columns={columns}
                    dataSource={dataquery}
                    loading={param.loading}
                    locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
                    rowKey={record => record._id}
                    pagination={false}                    
                />
                <div>&nbsp;</div>
            </div>
    )


}

export default GraphApex
