import React, { useState } from 'react'
import {
  message,
  Form,
  Select,
  Button
} from 'antd'
import _get from 'lodash/get'
import updateProductQbSkuMutation from '~/graphql/mutations/qb/updateProductQbSku.gql'
import removeProductQbSkuMutation from '~/graphql/mutations/qb/removeProductQbSku.gql'
import { useMutation } from '@apollo/react-hooks'
const ExtraProductForm = ({productQbSku, ticketType, eventId}) => {
  const qbs = _get(productQbSku, 'qbs')
  const initQbSkus = qbs && qbs.map(qb => _get(qb, 'qbSku'))
  const initQbNames = qbs && qbs.map(qb => _get(qb, 'qbName'))
  const [loading, setLoading] = useState(false)
  const [qbNames, setQbName] = useState(initQbNames)
  const [qbSkus, setQbSkus] = useState(initQbSkus)
  const [productQbSkuId, setProductQbSkuId] = useState(_get(productQbSku, '_id'))
  const [validateStatus, setValidateStatus] = useState(_get(qbs, [0])?'success':'warning')
  const [updateProductQbSku] = useMutation(updateProductQbSkuMutation)
  const [removeProductQbSku] = useMutation(removeProductQbSkuMutation)
  const handleSubmit = () => {
    setLoading(true)
    if(qbSkus.length === 0 && productQbSkuId) {
      console.log('remove')
      return removeProductQbSku({ variables: { _id: productQbSkuId } })
      .then(res => {
        const resQbNames = []
        setLoading(false)
        setQbName(resQbNames)
        setProductQbSkuId(null)
        message.success('clear sku success')
        setValidateStatus('success')
      })
      .catch(err => {
        message.error(JSON.stringify(err))
        setValidateStatus('warning')
        setLoading(false)
      })
    } else {
      updateProductQbSku({ variables: { qbSkus, eventId, ticketTypeId: ticketType._id } })
        .then(res => {
          const qbs = _get(res, 'data.updateProductQbSku.qbs')
          const resQbNames = qbs && qbs.map(qb => _get(qb, 'qbName'))
          const resQbSkus = qbs && qbs.map(qb => _get(qb, 'qbSku'))
          setLoading(false)
          setQbName(resQbNames)
          setQbSkus(resQbSkus)
          setProductQbSkuId(_get(res, 'data.updateProductQbSku._id'))
          console.log({res})
          message.success('success')
          setValidateStatus('success')
        })
        .catch(err => {
          message.error(JSON.stringify(err))
          setValidateStatus('warning')
          setLoading(false)
        })
    }
  }
  const handleChangeSkus = (skus) => {
    setValidateStatus('warning')
    setQbSkus(skus)
  }
  // console.log('qbSkus', qbSkus)
  return <Form layout="inline" onSubmit={handleSubmit}>
  <Form.Item
    key={ticketType._id} 
    label={`${ticketType.name.en}`}
    validateStatus={validateStatus}
    hasFeedback
    help={(qbNames && qbNames.join(', ')) || 'incorrect skus !!!'}
  > 
    <Select
      mode="tags" 
      placeholder="Skus"
      tokenSeparators={[',']}
      enterButton="Save"
      defaultValue={qbSkus}
      value={qbSkus}
      style={{ width: 300 }}
      loading={loading}
       onChange={handleChangeSkus}
    />
  </Form.Item>
  <Form.Item
  >
    <Button 
      type="primary"
      loading= {loading}
      onClick= {handleSubmit}
    >
      Save
    </Button>
  </Form.Item>
  </Form>}

const ExtraProduct = ({
  eventId,
  ticketTypes,
  productQbSkus
}) => {
  return <div>
    {ticketTypes.map(ticketType => {
      const productQbSku = productQbSkus.find(({ticketTypeId}) => ticketTypeId === ticketType._id)
    return ExtraProductForm({productQbSku, ticketType, eventId})
  })}
  </div>
}

export default ExtraProduct
