import React, { Component } from 'react'
import {
  Button,
  Card
} from 'antd'

class DeliverySummary extends Component {
  handlePaidExport = async () => {
    this.props.onPaidExport()
  }
  handleExport = async () => {
    this.props.onExport()
  }
  handleDateExport = async () => {
    this.props.onDateExport()
  }

  render () {
    return (
      <div className='eventDownloadBox no-print'>
        <Card
          title='รายงานเสื้อหลายตัวเข้าระบบ Thairun Tracking'
          size="small"
          style={{marginBottom: 10}}
          key='DeliverySummary'
        >
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handlePaidExport}
              icon="export"
              loading={this.props.exporting}
            >
              ดาวน์โหลด
            </Button>{' (เฉพาะที่จ่ายสำเร็จ)'}
          </div>
          <div style={{marginBottom: '9px'}}>
            <Button
              onClick={this.handleDateExport}
              icon="export"
              loading={this.props.exporting}
            >
            ดาวน์โหลด
            </Button>{' (เฉพาะที่จ่ายสำเร็จ เลือกวันที่ได้)'}
          </div>
          <Button
            onClick={this.handleExport}
            icon="export"
            loading={this.props.exporting}
          >
            ดาวน์โหลด
          </Button>{' (ทุกสถานะการสมัคร)'}
        </Card>
      </div>
    )
  }
}

export default DeliverySummary
