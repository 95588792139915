import React from 'react'
import {
    //Collapse, 
    Card, 
    //Select
} from 'antd'

//import ApaxChart from 'apexcharts'
import RApaxChart from "react-apexcharts";
//import { controllers } from 'chart.js';
//import { ArrowRight } from '@material-ui/icons';

//const Panel = Collapse.Panel
//const Option = Select.Option
const { Meta } = Card

const monthList = ["", "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

//const minDayMonth = 28

const GraphApexPartcpByMonth = ({param}) => {

    const datasources = param.datasource
    // console.log(datasource, 'datasourceDay')
    

    //const chartRes = param.chart
    const defaultVal = param.defaultVal
    const [year, month] = defaultVal.queryMonth ? defaultVal.queryMonth.split('-') : [0, 0]
    const datasource  = datasources.filter((item) => item.year === parseInt(year))
    defaultVal.queryMonth = month

    const monthDayList = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]    

    const val = {"row":[], "label":[], "value":[], "group":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,
                    "rows":0,
                    "month": defaultVal.queryMonth
                }
                const monthVal = JSON.parse(JSON.stringify(val));             

    var horizontalBar = false

    var columnWidth = '30%';
    // console.log('x1', datasource)                            
    if(!(datasource === null)){ 
        defaultVal.queryMonth   = parseInt(defaultVal.queryMonth, 10)
        let dayStep = 1 
        if( !(defaultVal.queryMonth === 'All') ){
            let ml = monthDayList[defaultVal.queryMonth] 
            if(!defaultVal.queryMonth){
                ml   = ml + 1 
                dayStep = 0
            } 
            for(var x=0; x<ml; x++){
                monthVal.rows += 1
                monthVal.value[x] = 0
                monthVal.label[x] = (parseInt(x, 10) + dayStep) //+ ' ' + monthList[parseInt(defaultVal.queryMonth)-1] 
                monthVal.row[x] = x  
                monthVal.chart.bar.bgcolor[x] = '#efefef'         
            }
                        
        }              

        if(datasource){
            var groupRow = 0
            var dataRow = 0   
            var inxrow = 0                       
            val.max = 0 
            for(const [keyi, valuei] of Object.entries(datasource)){       
                dataRow++
                const lb = valuei             
                const monthInx  = parseInt(lb.month, 10) > 0 ? parseInt(lb.month, 10) : 0
                var datacount = valuei.count

                if(defaultVal.queryMonth === 'All'){
                    inxrow = groupRow
                    if( val.group[ monthInx ] === undefined ){
                        val.group[ monthInx ] = inxrow
                        val.label[inxrow] = monthList[ monthInx ] ? monthList[ monthInx ] : 'Null'
                        val.value[inxrow] = 0
                        groupRow++;
                        val.rows++  
                    }           
                }else{
                    inxrow = keyi
                    val.label[inxrow] = (lb.day === null ? 'Unknow' : lb.day) + ' ' + (lb.month === null ? '' : monthList[parseInt(lb.month, 10)])                    
                    val.value[inxrow] = 0  
                    val.rows++                    
                }

                val.chart.bar.bgcolor[inxrow] = '#efefef'

                if( (defaultVal.queryMonth === 'All') 
                        || ( defaultVal.queryMonth === 'null' && valuei.month === null) 
                        || ( !(valuei.month === 'null') && parseInt(defaultVal.queryMonth, 10) === parseInt(valuei.month, 10) ) ){
                    
                    if(defaultVal.queryMonth === 'All'){
                        val.row[val.group[ monthInx ]] = lb.month
                        val.value[val.group[ monthInx ]] += valuei.count

                        datacount = val.value[val.group[ monthInx ]]
                    }else{  
                        val.row[inxrow] = keyi
                        val.value[inxrow] = valuei.count                          
                    }

                    if(val.max < parseFloat( datacount, 10 )){
                        val.max = parseFloat( datacount, 10 )    
                    }

                    monthVal.value[(!(lb.day === null) ? (parseInt(lb.day, 10)-dayStep) : keyi)] = valuei.count
                }           
            
            }
        
            if( !(defaultVal.queryMonth === 'All') ){
                val.row = monthVal.row
                val.rows = monthVal.rows                
                val.value = monthVal.value   
                val.label = monthVal.label 
                val.chart.bar.bgcolor = monthVal.chart.bar.bgcolor                                    
            }
        

        }

        if(param.isMobile && val.rows>10)
            horizontalBar = true        

        if(!param.isMobile && val.rows<10) 
            columnWidth = '5'

    }
    const valueList = val   
    //console.log('valueList', valueList) 

    const graphhight  = (row) =>{
        var height = 150
        if(row > 5){
            height = (row * 20) 
        } 
        return(height)    
    }

    const setBarWidth  = (row, _min) =>{
        let Width = 30
        if(row === 0){
            Width = 10
        }else if(row < 2){
            Width = 3
        }else if(row < 5){
            Width = 8
        }else if(row < 10){
            Width = 10
        }     
        return Width < _min ? _min : Width       
    }

    const setMax  = (value) =>{
        var val = value
        const valArr = val.toString().split('')

        var _valArr = []

        valArr.forEach(function(val,index,array){
            array[index]    = parseInt(val, 10)
            _valArr[index]  = 0
        })

        if(value < 9){
            val = 20    
        }else if(value < 70){
            val = 80               
        }else if(value < 99){
            val = 120
        }else if(value < 999){                   
            val = (valArr[0] * 100) + (valArr[1] > 80 ? 150 : 100)  
        }else{
            _valArr[2] = parseInt(valArr[2],10) + (valArr[3] >= 6 ? 1 : 0)              
            _valArr[1] = parseInt(valArr[1],10) + (_valArr[2] >= 6 ? 1 : 0)               
            _valArr[0] = parseInt(valArr[0],10) + (_valArr[1] >= 8 ? 1 : 0) 

            _valArr[1] = _valArr[1] > 9 ? 0 : _valArr[1]  
            
            val = parseInt(_valArr.join(''), 10)
            
        }

        return(val)    
    } 
 
    const apexParticipantByMonth = {
            options: {
                chart: {
                    id: "bar",
                    toolbar: {
                        show: false,
                    },                       
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        horizontal: horizontalBar,
                        startingShape: 'rounded',
                        endingShape: 'rounded',
                        columnWidth: (horizontalBar ? columnWidth : setBarWidth(val.rows, 0)),
                        barHeight: (horizontalBar ? 30 : setBarWidth(val.rows, 30)),
                        distributed: false,
                        rangeBarOverlap: true,
                        rangeBarGroupRows: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: undefined
                            }],
                            backgroundBarColors: val.chart.bar.bgcolor,
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 5,
                        },
                    }
                },
                xaxis: {
                    categories: valueList.label, 
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    lines: {
                        show: false
                    },      
                    axisTicks: {
                        show: false
                    },      
                    labels:{
                        style:{
                            colors: '#B1B1B1'
                        }   
                    }                                          
                },
                yaxis: {
                    min: 0,
                    max: setMax( val.max ), 
                    labels:{
                        style:{
                            colors: '#B1B1B1'
                        }   
                    }                                         
                },
                legend:{
                    show: false
                },
                grid: {
                    yaxis: {
                      lines: {
                        show: false
                      }
                    }
                }
                          
                
        
            },
            series: [
                {
                name: "Number",
                data: valueList.value, 
                color: '#EB5757'
                }
            ]
    };

    //console.log('W',  horizontalBar + ' : ' + dataRow + ' / ' + setBarWidth(dataRow, 5));
    //console.log('Val', val)    
    //console.log('horizontalBar', param.isMobile + ' And ' +  val.rows)

    return (
                <Meta
                    description={<div className="" style={{ }} >
                    <RApaxChart
                        options={apexParticipantByMonth.options}
                        series={apexParticipantByMonth.series}
                        type="bar"
                        width="100%"
                        height={horizontalBar && val.rows>10 ? graphhight(val.rows) : '350'}
                    />                            
                    </div>}
                />
    )


}

export default GraphApexPartcpByMonth
