import React, { Component } from 'react'
import {
  Button,
  Card
} from 'antd'
import { withTranslation } from 'react-i18next'

class CouponCodeTypes extends Component {
  handleExport = async () => {
    this.props.onExport()
  }

  render () {
    const { t } = this.props
    return (
      <div className='eventDownloadBox no-print'>
        <Card
          title={t('download.couponCode')}
          size="small"
          style={{marginBottom: 10}}
          key='CouponCodeTypes'
        >
          <Button
            onClick={this.handleExport}
            icon="export"
            loading={this.props.exporting}
          >
            {t('download.download')}
          </Button>
        </Card>
      </div>
    )
  }
}

export default withTranslation() (CouponCodeTypes)
