import React, { Component } from 'react'
import { withApollo, graphql } from 'react-apollo'
import {
  // Modal,
  Layout,
  // DatePicker,
  // message,
  // Button,
  // Form,
  Card
} from 'antd'
// import compose from 'lodash/flowRight'
// import moment from 'moment'
// import eventQuery from '~/graphql/queries/event.gql'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import { useQuery } from 'react-apollo-hooks'
import SendAllEmailBtn from '~/components/Events/Event/Tools/SendAllEmailBtn'
import UpdateAllEcerBtn from '~/components/Events/Event/Tools/UpdateAllEcerBtn'
import toolsEventQuery from "~/graphql/queries/toolsEvent.gql";
import clientAuth from '~/utils/client-auth'
import _includes from 'lodash/includes'
import TopContent from '~/components/Events/Event/TopContent'
// const {RangePicker} = DatePicker
// const dateFormat = 'DD/MM/YYYY HH:mm:ss'

const EventTools = (props) => {
    const { loading, event } = props
    const { eventId } = props.match.params
    const {roles} = clientAuth.login()
    if (loading) {
      return <div>Loading...</div>
    } else {
      const eventProps = useQuery(toolsEventQuery, {
        variables: {
          _id: eventId
        },
        // fetchPolicy: 'network-only',
        // pollInterval: 3000,
      })
      return (
        <Layout>
          <Layout.Content>
          <TopContent
            title={_get(eventProps, 'data.event.name.th') || _get(eventProps, 'data.event.name.en')}/>
          <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <div style={{padding: '6px', marginLeft: '20px', marginBottom: '20px', fontSize: '1.5em'}}>
              Tools
              </div>
              <div className='eventDownloadBox'>
              <Card
                title='Action'
                size="small"
                style={{marginBottom: 10}}
                key='Summary'
              >
                <div style={{marginBottom: '9px'}}>
                  {/* <Button
                    onClick={handlePaidExport}
                    icon="export"
                    loading={props.exporting}
                  >
                    ดาวน์โหลด
                  </Button>{' (เฉพาะที่จ่ายสำเร็จ)'} */}
                  <SendAllEmailBtn {...{eventId, eventProps}}/>
                </div>
                <div style={{marginBottom: '9px'}}>
                  <UpdateAllEcerBtn {...{eventId, eventProps}}/>
                </div>
                {/* <div style={{marginBottom: '9px'}}>
                  <Button
                    onClick={handleDateExport}
                    icon="export"
                    loading={props.exporting}
                  >
                  ดาวน์โหลด
                  </Button>{' (เฉพาะที่จ่ายสำเร็จ เลือกวันที่ได้)'}
                </div>
                <Button
                  onClick={handleExport}
                  icon="export"
                  loading={props.exporting}
                >
                  ดาวน์โหลด
                </Button>{' (ทุกสถานะการสมัคร)'} */}
              </Card>
            </div>
          </div>
          </div>
        </Layout.Content>
          
      </Layout>

    )
  }
}

// export default compose(

// )(EventTools)
export default EventTools
