import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import {
  Layout,
  Table,
  Button,
  Icon
} from 'antd'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import withdrawRequestsQuery from '~/graphql/queries/eventWithdrawRequests.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
import moment from 'moment-timezone'
import TopContent from '~/components/Events/Event/TopContent'
import Itag from '~/components/Itag'

import ConvertValue from '~/components/Events/Event/PaymentSummary/ConvertValue'
import { withTranslation } from 'react-i18next'
const {
  toOrganizerBalance
} = ConvertValue

class WithdrawRequests extends Component {
  render () {
    const { data, loading, t } = this.props
    if(`${data.error}`.search('Network error') > -1) {
      data.refetch()
    }
    if (loading) {
      return <Loading />
    }
    if (!data.event) {
      return <EventNotFound />
    }
    const withdrawRequests = _get(data, 'eventWithdrawRequests')
    const columns = [{
      title: 'Id',
      dataIndex: '_id',
      key: '_id'
    }, {
      title: `${t('transfer.date')}`,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).locale('th').format('L')
    }, {
      title: `${t('transfer.amount')}`,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => toOrganizerBalance(amount)
    }
    ]
    const status = _get(data, 'event.settings.payment.status')
    const completedAt = _get(data, 'event.settings.payment.completedAt')
    const isCompleted = status === 'completed'
    const eventPaymentStatus = isCompleted && <span>
      status: <Itag type={status} text={status} />
      {moment(completedAt).locale('th').format('L')}
    </span>
    const createLink = <Link
      to={`/events/${data.event._id}/withdraw-requests/create`}
      style={{marginLeft: '100px'}}
    >
      <Button type="primary" size="large">
        <Icon type="plus" /> {t('transfer.add')}
      </Button>
    </Link>
    return (
      <Layout>
        <Layout.Content>
        <TopContent
            title={_get(data.event, 'name.th') || _get(data.event, 'name.en')}
            nodes={{
              SelectStatus: '',
              InputSearch: isCompleted ? eventPaymentStatus : createLink,
            }}
            pattern='registrations'/>
          <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <Table
                style={{whiteSpace: 'nowrap'}}
                scroll={{ x: true }}
                columns={columns}
                dataSource={withdrawRequests}
                loading={loading}
                locale={{ emptyText: `${t('locale.notFound')}` }}
                rowKey={'_id'}
                onRowClick={(record) => (this.props.history.push(`/events/${data.event._id}/withdraw-requests/${record._id}/edit`))}
              />
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default compose(
  graphql(withdrawRequestsQuery, {
    options: ({ match: { params: { eventId } } }) => ({
      variables: { eventId },
      fetchPolicy: 'network-only',
      skip: !eventId
    })
  }),withTranslation()
)(WithdrawRequests)
