import React, { Component, useState } from 'react'
import { Layout, Table, Input, Button, Icon, Modal, InputNumber, message } from 'antd'
import { graphql } from 'react-apollo'
import Highlighter from 'react-highlight-words'
import EventSider from '~/components/Events/Event/Sider'
import RegistrationRowRender from './RegistrationRowRender'
import couponCodeTypeQuery from '~/graphql/queries/couponCodeType.gql'
import couponCodesUsedQuery from '~/graphql/queries/couponCodesUsed.gql'
import updateCouponCodeMutation from '~/graphql/mutations/coupon/updateCouponCode.gql'
import GenerateCouponCodesForm from './GenerateCouponCodesForm'
import { observer } from 'mobx-react'
import editCouponCode from '~/graphql/mutations/editCouponCode.gql'
import { withTranslation } from 'react-i18next'
import config from '~/config'

const InputGroup = Input.Group
@observer
@graphql(updateCouponCodeMutation, { name: 'updateCouponCodeMutation' })
@graphql(editCouponCode, {name: 'editCouponCode'})
@graphql(couponCodeTypeQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.couponCodeTypeId,
      eventId: props.match.params.eventId
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, couponCodeType } }) => ({
    couponCodeTypeloading: loading,
    couponCodeType: couponCodeType
  })
})
@graphql(couponCodesUsedQuery, {
  options: (props) => ({
    variables: {
      couponCodeTypeId: props.match.params.couponCodeTypeId
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, couponCodeUsage, refetch } }) => ({
    couponCodeUsageloading: loading,
    couponCodes: couponCodeUsage,
    couponCodeUsageRefetch: refetch
  })
})
class CouponCodes extends Component {
  state = {
    loading: false,
    searchText: '',
    couponCodes: []
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  handleDisable = (record) => {
    Modal.confirm({
      title: `${t('couponCode.disable')}`,
      content: `${t('couponCode.message')} "${record.code}" ?`,
      onOk: () => {
        this.props.editCouponCode({
          variables: {
            'record': {
              '_id': record.key,
              'copies': 0
            }
          }}).then(({ data }) => {
          this.props.couponCodeUsageRefetch()
        })
      },
      onCancel () {}
    })
  }
  handleUpdateCouponCode = ({_id, copies}) => {
    this.setState({
      loading: true
    })
    console.log('', {
      _id,
      copies
    })
    this.props.updateCouponCodeMutation({
      variables: {
        record: {
          _id,
          copies
        }
      }
    }).then((response) => {
      message.success('updated')
      this.props.couponCodeUsageRefetch()
    }).catch((e) => {
      message.error(`${e}`)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }
  render () {
    const { couponCodeType, couponCodes, couponCodeUsageRefetch, t } = this.props

    if (!couponCodeType) {
      return <div>couponCodeType not found</div>
    }
    const dataSource = []

    const columns = [{
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...this.getColumnSearchProps('code')
    }, {
      title: `${t('couponCode.table.discount')}`,
      dataIndex: 'couponCodeType',
      key: 'couponCodeType.discount',
      render: (couponCodeType) => {
        const discountType = couponCodeType && couponCodeType.discountType
        let discount
        if (discountType === 'percentage') {
          const discountco = couponCodeType && couponCodeType.discount * 100
          return discountco
        }
        if (discountType === 'flat') {
          const discountco = couponCodeType && couponCodeType.discount
          return discountco
        }
        return discount
      }
    }, {
      title: `${t('couponCode.table.discountType')}`,
      dataIndex: 'couponCodeType',
      key: 'couponCodeType.discountType',
      render: (couponCodeType) => {
        const discountType = couponCodeType && couponCodeType.discountType
        let Type
        if (discountType === 'percentage') {
          const percentage = '%'
          return percentage
        }
        if (discountType === 'flat') {
          const flat =  config.platform === 'mas' ? 'Fixed' : 'บาท'
          return flat
        }
        return Type
      }
    }, {
      title: `${t('couponCode.table.redeemed')}`,
      dataIndex: 'usedCount',
      key: 'usedCount',
      sorter: (a, b) => a.usedCount - b.usedCount
    }, {
      title: 'จำนวนที่ใช้งานได้',
      // dataIndex: 'copies',
      key: 'copies',
      render: (data) => {
        // console.log('data', data.key)
        const EditUpdateCopies = (data) => {
          const { copies, _id } = data
          const [limit, setLimit] = useState(copies)
          return <div>
            <p>
            <InputGroup compact>
              <InputNumber style={{ width: 60 }} onChange={value => {
                setLimit(value)
                }} defaultValue={limit} />
              <Button loading={this.state.loading}
                onClick={() => this.handleUpdateCouponCode({ _id, copies: limit })}
                type="primary" icon='save'></Button>
            </InputGroup>
            </p>
          </div>
    
        }
        return <EditUpdateCopies {...data} />
      }
    }, {
      title: `${t('couponCode.table.codeType')}`,
      dataIndex: 'couponCodeType',
      key: 'couponCodeType',
      render: (couponCodeType) => {
        const name = couponCodeType && couponCodeType.name.th
        return name
      }
    }, {
      title: 'Action',
      render: (text, record, index) => {
        return {
          children: <span style={{display: 'flex'}}>
            <Button type='link' onClick={() => this.handleDisable(record)}>ปิด</Button>
          </span>,
          props: {}
        }
      }
    }]

    couponCodes && couponCodes.forEach(({_id, code, copies, usedCount, couponCodeType, registrations}) => {
      dataSource.push({
        _id: _id,
        key: _id,
        code: code,
        copies: copies,
        usedCount: usedCount,
        couponCodeType: couponCodeType,
        registrations: registrations
      })
    })
    return <Layout>
      <EventSider />
      <Layout.Content
        className="content"
      >
        {couponCodeType && <div style={{float: 'left', marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
          Coupon code type: {couponCodeType.name.en}
        </div>}
        <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
          <GenerateCouponCodesForm
            couponCodeType={couponCodeType}
            history={this.props.history}
            couponCodes={couponCodes}
            onGenerateCouponCodes={couponCodeUsageRefetch}
          />
        </div>
        <Table
          dataSource={dataSource}
          pagination={{ pageSize: 100 }}
          columns={columns}
          loading= {this.props.couponCodeUsageloading}
          expandedRowRender={ record => record.registrations.map(reg => <RegistrationRowRender key={reg._id} data={reg} eventId={this.props.match.params.eventId} />) }
        />
      </Layout.Content>
    </Layout>
  }
}

export default withTranslation() (CouponCodes)
