import React from 'react'
import { Form } from 'antd'
import _get from 'lodash/get'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import Uploader from '~/components/Uploader'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
}

@Form.create()
@withRepeaterHandler
class UploadRefundSlipForm extends React.Component {
  render () {
    const { form, payments, refundPaymentId } = this.props
    const { getFieldDecorator } = form
    if (!payments && !refundPaymentId) {
      return 'payments not found'
    }
    const payment = payments.find(p => p._id === refundPaymentId)
    if (!payment) {
      return 'payment not found'
    }
    getFieldDecorator('paymentId', {
      initialValue: _get(payment, '_id')
    })
    // getFieldDecorator('amount', {
    //   initialValue: _get(payment, 'payAmount')
    // })
    // getFieldDecorator('eventId', {
    //   initialValue: _get(payment, 'eventId')
    // })
    // getFieldDecorator('masterId', {
    //   initialValue: _get(payment, 'masterId')
    // })
    // getFieldDecorator('masterType', {
    //   initialValue: _get(payment, 'masterType')
    // })
    // getFieldDecorator('registrationId', {
    //   initialValue: _get(payment, 'registrationId')
    // })
    return <Form>
      <h2>Admin อัพโหลดหลักฐานการคืนเงิน</h2>
      {/* Amount: {payment.amount} <br/> */}
      Amount paid: {payment.payAmount} <br/>
      {payment.method === 'scb_qr' && <div>
        scb_qr <br/>
        ref2: {_get(payment, 'data.scb.invoice.ref2')}
      </div>}
      {/* {payment.method === 'omise_card' && <div>
        omise_card <br/>
        refundPaymentId: {_get(payment, '_id')}
      </div>} */}
      {payment.method === 'linepay' && <div>
        linepay <br/>
        transactionId: {_get(payment, 'data.linepay.transactionId')}
      </div>}
      <Form.Item {...formItemLayout} label="">
        {getFieldDecorator('slipUrl', {
        // {getFieldDecorator('data.transfer.slipUrl', {
          // initialValue: _get(payment, 'data.transfer.slipUrl'),
          rules: [{ required: true, message: 'กรุณาอัพโหลดรูปหลักฐานการคืนเงิน' }]
        })(
          <Uploader />
        )}
      </Form.Item>
    </Form>
  }
}

export default UploadRefundSlipForm
