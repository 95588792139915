import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import { Layout, Table } from 'antd'
import EventSider from '~/components/Events/Event/Sider'
import registrationParticipantsQuery from '~/graphql/queries/registrationParticipants.gql'
import Loading from '~/components/Loading'
import RegistrationNotFound from '~/components/Error/Registrationnotfound'
import { withTranslation } from 'react-i18next'
// import moment from 'moment'
// import _get from 'lodash/get'

// const _ = require('lodash')

class Participant extends Component {
  state = {
    // confirming: null
  }
  // confirmParticipant = ({ _id }) => {
  //   this.setState({
  //     confirming: _id
  //   })
  //   this.props.confirmParticipant({
  //     variables: {
  //       _id,
  //       data: {}
  //     }
  //   }).then(result => {
  //     this.setState({
  //       confirming: null
  //     })
  //   })
  // }
  render () {
    // const buttonStyle = {
    //   margin: '8px'
    // }
    const { data: { registration }, loading, t } = this.props
    const columns = [{
      title: 'Name',
      key: 'method',
      render: (record) => `${record.profile.firstName} ${record.profile.lastName}`
    }]

    const size = 'small'
    if (loading) {
      return <Loading />
    } else if (!registration) {
      return <RegistrationNotFound />
    }
    const { participants } = registration

    return (
      // <div>EventRegistrations</div>
      <Layout>
        <Layout.Sider width={200} style={{ background: '#fff' }}>
          <EventSider />
        </Layout.Sider>
        <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
          <Table columns={columns}
            dataSource={participants}
            loading={loading}
            locale={{emptyText: `${t('locale.notFound')}` }} size={size}
            rowKey={'_id'}
          >
          </Table>
        </Layout.Content>
      </Layout>
    )
  }
}

export default compose(
  graphql(registrationParticipantsQuery, {
    options: ({ match: { params: { registrationId } } }) => ({
      variables: { registrationId }
    })
  }),
  withTranslation()
  // graphql(confirmParticipantMutation, {
  //   name: 'confirmParticipant'
  // })
)(Participant)
