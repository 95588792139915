import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import {
  Layout,
  Table,
  Button,
  Icon
} from 'antd'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import OrderItemTypeQuery from '~/graphql/queries/orderItemTypes.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
import { withTranslation } from 'react-i18next'

class ProductTypes extends Component {
  render () {
    const { data, loading, t } = this.props
    if (loading) {
      return <Loading />
    }
    if (!data.product) {
      return <EventNotFound />
    }
    const orderItemTypes = _get(data, 'orderItemTypes')
    const columns = [{
      title: 'รหัสประเภทสินค้า',
      dataIndex: '_id',
      key: '_id'
    }, {
      title: 'ชื่อประเภทสินค้า',
      dataIndex: 'name.th',
      key: 'name'
    }, {
      title: 'ราคา',
      dataIndex: 'price',
      key: 'price'
    }]

    return (
      <Layout>
        <EventSider />
        <Layout.Content
          style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}
        >
          <div style={{float: 'left', marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {data.product.name.en}
          </div>
          <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
            <Link
              to={`/events/${data.product.eventId}/products/${data.product._id}/addType/create`}
              style={{marginLeft: '100px'}}
            >
              <Button type="primary" size="large">
                <Icon type="plus" /> เพิ่มประเภทสินค้า
              </Button>
            </Link>
          </div>
          <Table
            style={{whiteSpace: 'nowrap'}}
            scroll={{ x: true }}
            columns={columns}
            dataSource={orderItemTypes}
            loading={loading}
            locale={{ emptyText: `${t('locale.notFound')}` }}
            rowKey={'_id'}
            onRowClick={(record) => (this.props.history.push(`/events/${data.product.eventId}/products/${data.product._id}/addType/${record._id}/edit`))}
          />
        </Layout.Content>
      </Layout>
    )
  }
}

// export default ProductTypes
export default compose(
  graphql(OrderItemTypeQuery, {
    options: ({ match: { params: { productId } } }) => ({
      variables: { productId },
      fetchPolicy: 'network-only'
    })
  }),
  withTranslation()
)(ProductTypes)
