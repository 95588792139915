import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import { Link } from 'react-router-dom'
import { Form, Button } from 'antd'
import moment from 'moment-timezone'
import _get from 'lodash/get'
import ticketQuery from '~/graphql/queries/ticket.gql'
import Loading from '~/components/Loading'

class Ticket extends Component {
  state = {
    confirmDirty: false
  };
  render () {
    const { loading } = this.props

    const dateFormat = 'DD/MM/YYYY'
    var ticketItem = _get(this.props.data, 'ticketItem')
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 14,
          offset: 6
        }
      }
    }

    if (!(!loading && ticketItem)) {
      return <Loading />
    } else {
      // var address = _get(ticketItem.participant.profile, 'address')
      // var addressObj
      // if(address){
      //   addressObj = (<Form.Item>Address :
      //       <Form.Item>Locality : {address.locality}</Form.Item>
      //       <Form.Item>Country : {address.Country}</Form.Item>
      //       <Form.Item>Pastal Code : {address.pastalCode}</Form.Item>
      //       <Form.Item>Region : {address.region}</Form.Item>
      //     </Form.Item>)
      // }
      var ticketOptions = ticketItem.options.map((option) =>
        <Form.Item {...formItemLayout} key={option._id} label={`${_get(option, 'question.name.en', '-')} / ${_get(option, 'question.name.th', '-')}`}>
          {(option.itemId || option.itemIds || option.value || '')}
        </Form.Item>
      )
      return (
        <div>
          <Form>
            <Form.Item
              {...formItemLayout}
              label="First Name"
            >
              {ticketItem.participant.profile.firstName}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Last Name"
            >
              {ticketItem.participant.profile.lastName}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Gender"
            >
              {ticketItem.participant.profile.gender}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Birth Date"
            >
              {moment(ticketItem.participant.profile.birthDate).format(dateFormat)}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Email"
            >
              {ticketItem.participant.profile.email}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Telephone"
            >
              {ticketItem.participant.profile.telephone}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Health Issues"
            >
              {ticketItem.participant.profile.healthIssues}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Province"
            >
              {ticketItem.participant.profile.province}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Country"
            >
              {ticketItem.participant.profile.country}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Nationality"
            >
              {ticketItem.participant.profile.nationality}
            </Form.Item>
            {ticketOptions}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" size="large"><Link to={`/events/${ticketItem.eventId}/tickets/${ticketItem._id}/edit`}>Edit</Link></Button>
            </Form.Item>
          </Form>
        </div>
      )
    }
  }
}

export default compose(
  Form.create(),
  graphql(ticketQuery, {
    options: ({ match: { params: { eventId, id } } }) => ({
      variables: { eventId, id }
    })
  })
)(Ticket)
