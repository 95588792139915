import React from 'react'
import {
    Card, 
} from 'antd'

import RApaxChart from "react-apexcharts";
const { Meta } = Card


const GraphApexPartcpByMonth = ({param}) => {

    const datasource = param.datasource

    const chartRes = param.chart

    const opt   = { 
        title: chartRes.caption,
        subtitle: param.subTitle,
        width: param.width,
        height: (param.height ? param.height : '300px'),
    }

    const genderList = []
            genderList['male'] = 'MEN'
            genderList['female'] = 'WOMEN'
            genderList['lgbtq'] = 'LGBTQ'

    const val = {"row":[], "label":[], "value":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,  
                }           
    
                
    if(datasource !== null){ 

        if(datasource.data){
            val.max = 0 
            for(const [keyi, valuei] of Object.entries(datasource.data)){
                val.value[keyi] = 0
                val.label[keyi] = genderList[valuei.gender]
                val.chart.bar.bgcolor[keyi] = '#efefef'
                val.row[keyi] = keyi
                val.value[keyi] = valuei.count
            }      

        }
    }

    const valueList = val 
    
    //console.log("G3-datasource", datasource);

    const apexParticipantByMonth = {
            options: {      
                chart: {
                    width: '100%',
                    type: 'pie', 
                    toolbar: {
                        show: false,
                    },                                         
                },
                labels: valueList.label,           
                colors: ['#29E7BF', '#E9E9E9'],          
                legend: {
                    position: 'bottom'
                },     
                dataLabels: {
                    enabled: false
                },                  
                plotOptions: {
                    pie: {
                        customScale: 0.8,
                        hover: {
                            filter: {
                                type: 'none',
                            },
                        },                          
                        donut: {
                            size: "65%",                                              
                        }                                                               
                    },
                    roseType : true,                         
                },                    
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: false,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: true,
                    theme: false,                    
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                },
                items: {
                    display: 'flex',
                },  
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                }                                   
            },          
            series: valueList.value,   
                   
        }

    return (<Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px' }}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>}
            >
                <Meta
                    description={<div className="" style={{ }} >
                    <RApaxChart
                        options={apexParticipantByMonth.options}
                        series={apexParticipantByMonth.series}
                        type={apexParticipantByMonth.options.chart.type}
                        width="100%"
                        height={opt.height}
                    />                            
                    </div>}
                />
            </Card>
    )


}

export default GraphApexPartcpByMonth
