import React, { Component } from 'react'
import { Button, Icon } from 'antd'

class PartisipantNotFound extends Component {
  render () {
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: 'calc(100vh - 69px - 37px - 20px)', marginTop: 20 }}>
        <Icon type="exclamation-circle" style={{ fontSize: '100px', marginTop: '20px' }} />
        <h1 style={{ marginTop: '10px' }} >ขออภัย ไม่พบผู้เข้าแข่งขันบุคคลนี้</h1>
        <Button type="ghost" onClick={() => window.history.back()}>ย้อนกลับ</Button>
      </div>
    )
  }
}

export default PartisipantNotFound
