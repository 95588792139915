import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { Form, Modal, Upload, Button } from 'antd'

class TransferModal extends Component {
  render () {
    // console.log({ form })
    const {
      // registration,
      // amount,
      visible,
      loading,
      onCheckout,
      onClose,
      form
    } = this.props
    const {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
      validateFields
    } = form
    const beforeUpload = (file, files) => {
      if (file) {
        const fileReader = new FileReader()
        fileReader.onload = e => {
          file.thumbUrl = fileReader.result
          setFieldsValue({
            file
          })
        }
        fileReader.readAsDataURL(file)
        return false
      }
    }
    // TODO: move omise logic near to project root (for easier config import too)
    const fileDecorator = getFieldDecorator('file', {
      rules: [{ required: true, message: 'Please select payment slip' }]
    })
    const file = getFieldValue('file')
    const fileProps = {
      name: 'file',
      action: '',
      beforeUpload,
      fileList: file ? [file] : [],
      showUploadList: false
    }

    const handleOnSubmit = e => {
      e.preventDefault()
      validateFields((err, formData) => {
        // console.error(err)
        if (!err) {
          e.preventDefault()
          // console.log(formData)
          const { thumbUrl } = formData.file
          const [, mimeType, base64data] = thumbUrl.match(
            /^data:(.+);base64,(.*)$/
          )
          // TODO: send file name
          onCheckout({
            slip: {
              mimeType,
              base64data
            }
          })
        }
      })
    }
    return (
      <Modal visible={visible} footer={null} onCancel={onClose}>
        <Form onSubmit={handleOnSubmit} layout="vertical">
          <Form.Item>
            {fileDecorator(
              <Upload {...fileProps}>
                <Button style={{ height: 'inherit', padding: '8px' }}>
                  {file ? (
                    <img
                      src={file.thumbUrl}
                      style={{ maxWidth: '450px', maxHeight: '450px' }}
                      alt=""
                    />
                  ) : (
                    'แนบไฟล์  / Select Attachment'
                  )}
                </Button>
              </Upload>
            )}
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={loading}>
              ส่งหลักฐานการชำระเงิน / Submit Payment Slip
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
export default compose(Form.create())(TransferModal)
