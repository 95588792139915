import React from 'react'
import { Upload, Button } from 'antd'
// import loadImage from 'blueimp-load-image'
// const MAX_DIMENSION = 2400
const uploadUri = process.env.REACT_APP_API_UPLOAD_URI
class ImageUpload extends React.Component {
  state = {
    loading: false
  }
  handleFileChange = ({ file, event }) => {
    this.setState({ loading: true })
    if (file.status === 'done') {
      const { link } = file.response
      this.props.onChange(link)
      this.setState({ loading: false })
    }
  }

  render () {
    const { value } = this.props
    return <Upload name="file" action={uploadUri} onChange={this.handleFileChange} showUploadList={false}>
      {value
        ? <img src={value} alt="logo" style={{ width: 200 }}/>
        : <Button icon="upload" loading={this.state.loading}>Upload</Button>
      }
    </Upload>
  }
}

export default ImageUpload
