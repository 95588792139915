import React, { Component } from 'react'
import {
  Switch, Route
} from 'react-router-dom'
import List from './List'
// import New from './New'
import Edit from './Edit'
import CouponCodes from './CouponCodes/CouponCodes'
// import Error
import Error404 from '../Error/404'
import { graphql } from 'react-apollo'
import adminQuery from '~/graphql/queries/adminUser.gql'
import clientAuth from '~/utils/client-auth'
import _includes from 'lodash/includes'
import Loading from '~/components/Loading/index'

@graphql(adminQuery, {
  options: (props) => ({
    variables: {
      _id: clientAuth.login()._id
    }
  }),
  props: ({ data: { loading, adminUser } }) => ({
    adminLoading: loading,
    admin: adminUser
  })
})
class CouponCodeTypes extends Component {
  render () {
    const {admin, adminLoading, match} = this.props
    const { eventId } = match.params
    if (adminLoading) {
      return <Loading />
    } else if (!admin) {
      return 'admin not found'
    }

    const {roles} = clientAuth.login()
    if ((!_includes(roles, 'admin') && !_includes(admin.abilities, 'coupon'))) {
      this.props.history.replace(`/events/${eventId}/stats`)
    }
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/events/:eventId/coupon-code-types/list"
            component={List}
          />
          <Route
            exact
            path="/events/:eventId/coupon-code-types/create"
            component={Edit}
          />
          <Route
            exact
            path="/events/:eventId/coupon-code-types/:couponCodeTypeId/edit"
            component={Edit}
          />
          <Route
            exact
            path="/events/:eventId/coupon-code-types/:couponCodeTypeId/coupon-codes"
            component={CouponCodes}
          />
          <Route
            exact
            path="*"
            component={Error404}
          />
        </Switch>
      </div>
    )
  }
}

export default CouponCodeTypes
