import React from 'react'
import { Button, Row, Col } from 'antd'
// import _get from 'lodash/get'

const Item = ({ finisherShirtType, finisherShirts, onFinisherShirtClick, onDeleteFinisherShirtType, loading }) => {
  const { _id, name } = finisherShirtType
  const _finisherShirts = finisherShirts.filter(finisherShirt => finisherShirt.shirtTypeId === finisherShirtType._id)
  return (
    <Row style={{ background: 'white' }} className="borderBottomItem eventItem">
      <Col xs={18} md={12}>{name.th}<br/>{name.en}</Col>
      <Col xs={6} md={12} style={{ textAlign: 'right' }}>
        <Button icon="edit" className="edit"
          loading={loading}
          onClick={() => onFinisherShirtClick(_id)}
        />{' '}
        <Button icon="delete" className="delete" loading={loading}
          onClick={() => onDeleteFinisherShirtType({finisherShirtType})}
        />
      </Col>
      <Col xs={24} md={24}>
        <ul>
          <li>
            {_finisherShirts.map(finisherShirt => {
              return <div>
                => {finisherShirt.name.en}
                <br/>({finisherShirt.name.th})
              </div>
            })}
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default Item
