import React, { useState, useMemo } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Button, Modal, Tabs ,Card} from 'antd'
import _get from 'lodash/get'
import moment from 'moment-timezone'
// import isSendingAllEmailEventQuery from "~/graphql/queries/isSendingAllEmailEvent.gql";
import downloadEcerOnEventMutation from '~/graphql/mutations/downloadEcerOnEvent.gql'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'


const DownloadEcer = (props) => {
    
  const {eventId,ecerV2} = props
  console.log(ecerV2,'ever');
  const [clickedLoading, setLoading]= useState(false)
  const [downloadEcerOnEvent] = useMutation(downloadEcerOnEventMutation)
  const handleClick = async () => {
    setLoading(true)
    const { data } = await  downloadEcerOnEvent({variables: { eventId }})
    const zip = new JSZip()
    let img = zip.folder("ecer")
    const bucketName = process.env.REACT_APP_GCLOUND_BUCKETNAME
    const gcsUri = `https://storage.googleapis.com/${bucketName}`
    const filePath = `events/${eventId}/media/ecer/race`
    const promises = data.downloadEcer.map((number, index) => fetch(`${gcsUri}/${filePath}/${number.raceId}/${number.number}.jpg`).then(r => r.blob().then(e => {
            img.file(`${number.number}.jpeg`, e)
    })))
    const code = await Promise.all(promises)
    const date = moment()
    if(code) {
        zip.generateAsync({type: "blob"}).then(function(content){
            saveAs(content, `${eventId}_${date.format('YYYY-MM-DD_HH-mm-ss')}.zip`)
        })
        
    }
    setLoading(false)
  }
  const loading = _get(eventId, 'loading')
  const isLoading = useMemo(() => setIsLoading({
    loading, clickedLoading
  }),
  [loading, clickedLoading])
  console.log('isLoading', isLoading, clickedLoading)
  
  return (<div className='eventDownloadBox no-print'>
            <Card
                title='Ecer'
                size="small"
                style={{marginBottom: 10}}
                key='DownloadEcer'
            >  
                <Button
                disabled={ecerV2 ? false : true}
                loading={isLoading}
                icon="export"
                onClick={handleClick}
                >
                Download 
                </Button>        
            </Card>
            </div>
        )
}
const setIsLoading = ({loading, clickedLoading}) => {
  if(loading) return loading
  if(clickedLoading) return clickedLoading
}
export default DownloadEcer