import React, { Component } from 'react'
import { Button, Icon } from 'antd'

class RegistrationNotFound extends Component {
  render () {
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: 'calc(100vh - 69px - 37px - 20px)', marginTop: 20 }}>
        <Icon type="exclamation-circle" style={{ fontSize: '100px' }} />
        <h1 style={{ marginTop: '10px' }} >ขออภัย ไม่พบใบสมัครนี้</h1>
        <Button type="ghost" onClick={() => window.history.back()} >ย้อนกลับ</Button>
      </div>
    )
  }
}

export default RegistrationNotFound
