import React from 'react'
import { Upload, Button } from 'antd'
import loadImage from 'blueimp-load-image'
const signatureUri = process.env.REACT_APP_UPLOAD_SIGNATURE_URI
const s3signatureUri = process.env.REACT_APP_S3_UPLOAD_SIGNATURE_URI
const providerURIs = {
  gs: signatureUri,
  s3: s3signatureUri
}

const resizeImg = async (file, { width, height }, quolity = 1) => {
  return new Promise((resolve, reject) => {
    loadImage(file, canvas => {
      if (canvas.type === 'error') {
        return Promise.reject(new Error('Incorrect Image'))
      }
      canvas.toBlob((blob) => {
        const result = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now()
        })
        resolve(result)
      }, file.type, 1)
    }, {
      maxWidth: width,
      maxHeight: height,
      contain: true,
      orientation: true,
      canvas: true
    })
  })
}

class CloudUploader extends React.Component {
  state = {
    loading: false
  }
  handleChange = ({ file, fileList, event }) => {
    console.log('change', { file })
    this.setState({ loading: true })
    const { onChange } = this.props
    if (file.status === 'done') {
      const { fileUrl } = file.response
      onChange(fileUrl)
      this.setState({ loading: false })
    }
  }
  // beforeUpload = async file => {
  //   // console.log('bu', { file })
  // }
  customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    let uploadFile = file
    const { maxDimension } = this.props
    if (maxDimension) {
      uploadFile = await resizeImg(file, { width: maxDimension, height: maxDimension })
    }
    // TODO: use axios
    const provider = this.props.provider || 'gs'
    const uri = providerURIs[provider]
    const extraQuery = this.props.object ? `&object=${this.props.object}` : ''
    const res = await fetch(`${uri}?mimetype=${uploadFile.type}` + extraQuery, { method: 'POST' })
    const { signedUrl, fileUrl } = await res.json()
    // console.log({ signedUrl })
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onprogress = ({ loaded, total }) => {
      onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, uploadFile)
      // console.log({ progress })
    }
    xhr.onload = (response) => {
      const status = xhr.status
      if (status === 200) {
        console.log({ response })
        onSuccess({ fileUrl }, uploadFile)
        // alert('File is uploaded')
      } else {
        onError('Something went wrong')
        // alert('Something went wrong!')
      }
    }

    xhr.onerror = (err) => {
      console.error(err)
      onError('Something went wrong')
    }
    xhr.setRequestHeader('Content-Type', uploadFile.type)
    if (provider === 'gs') {
      xhr.setRequestHeader('x-goog-acl', 'public-read')
    }
    xhr.send(uploadFile)
  }
  render () {
    const { value } = this.props
    return <Upload onChange={this.handleChange} customRequest={this.customRequest} showUploadList={false}>
      {this.state.loading
        ? <Button disabled icon="loading">Uploading...</Button>
        : value
          ? <img src={value} alt="logo" style={{ width: 300 }} />
          : <Button icon="upload" loading={this.state.loading}>Upload</Button>

      }
    </Upload>
  }
}

export default CloudUploader
