import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
// import { Link } from 'react-router-dom'
import {
  Form,
  Modal,
  Button
  // message
} from 'antd'
// const FormItem = Form.Item
import moment from 'moment-timezone'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import { inject, observer } from 'mobx-react'
import refundRequestQuery from '~/graphql/queries/refundRequest.gql'
// import eventQuery from '~/graphql/queries/event.gql'
// import ImageUpload from './ImageUpload'
import RefundForm from './RefundForm'
import issueRefundMutation from '~/graphql/mutations/issueRefund.gql'
import Loading from '~/components/Loading'
import Error404 from '~/components/Error/404'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}

const bibNumber = number => {
  const pad = '0000'
  return pad.substr(0, pad.length - `${number}`.length) + number
}

const dataFields = [{
  title: 'Requested Date',
  key: 'requestedDate',
  dataIndex: 'createdAt',
  render: (date) => moment(date).format('Do MMM HH:mm')
}, {
  title: 'Participant',
  key: 'participant',
  dataIndex: 'ticket',
  render: (ticket) =>
    `[${bibNumber(ticket.number)}]\n${_get(ticket, 'participant.profile.firstName')} ${_get(ticket, 'participant.profile.lastName')}`
}, {
  title: 'Ticket Type',
  key: 'ticketType',
  dataIndex: 'ticket.ticketType.name.en'
}, {
  title: 'Registration Code',
  key: 'regCode',
  dataIndex: 'registration.code'
}, {
  title: 'Status',
  key: 'status',
  dataIndex: 'status'
}, {
  title: 'Details',
  key: 'details',
  dataIndex: 'data',
  render: ({ transfer }) => <div style={{ whiteSpace: 'pre-line' }}>
    {`${_get(transfer, 'bankAccount.brand')}
    ${_get(transfer, 'bankAccount.name')}
    ${_get(transfer, 'bankAccount.number')}`}
  </div>
}]

const refundDataFields = [{
  title: 'Refund',
  key: 'refund',
  dataIndex: 'refund',
  render: (refund, rec) => rec.status === 'refunded' && refund && <div style={{ whiteSpace: 'pre-line' }}>
    {`${_get(refund, 'amount')} THB
  ${moment(refund.completedAt).format('Do MMM HH:mm')}`}
  </div>
}, {
  title: 'Refund Slip',
  key: 'slip',
  dataIndex: 'refund.data.transfer.slipUrl',
  render: (slipUrl) =>
    <img src={slipUrl} alt="refund slip" style={{ width: 300 }}/>
}]

@inject('router')
@graphql(issueRefundMutation, { name: 'issueRefund' })
@observer
class RefundRequestEdit extends Component {
  state = {
    confirmDirty: false,
    disabled: true
  }
  refundForm = null
  handleRefundModalOpen = () => {
    const { router: { location } } = this.props
    location.query = {
      refund: 'transfer'
    }
  }
  handleRefundModalClose = () => {
    const { location } = this.props.router
    const { refund: refundMethod, ...query } = location.query
    location.query = query
  }
  handleCreateRefund = () => {
    // console.log('refund')
    this.setState({
      refunding: true
    })
    const { form } = this.refundForm.props
    form.validateFields((err, values) => {
      if (err) {
        return console.error(err)
      }
      const { record } = values
      this.props.issueRefund({
        variables: {
          record
        },
        refetchQueries: [{ // can use updateQuery
          query: refundRequestQuery,
          variables: {
            _id: record.refundRequestId
          }
        }]
      }).then((result) => {
        this.setState({ refunding: false })
        this.handleRefundModalClose()
      })
    })
  }
  render () {
    const { router: { location } } = this.props
    // const { getFieldDecorator } = form
    const { refund: refundMethod } = location.query
    // console.log(this.props)
    const refundRequestLoading = _get(this.props, 'refundRequestLoading')
    const refundRequest = _get(this.props, 'refundRequest')
    // const event = _get(this.props, 'event')
    if (refundRequestLoading) return <Loading />
    if (!refundRequest) return <Error404 />
    // const inputCols = {
    //   labelCol: { span: 4 },
    //   wrapperCol: { span: 20 }
    // }
    return <Form onSubmit={this.handleSubmit}>
      {dataFields.map(f =>
        <Form.Item key={f.key} label={f.title} {...formItemLayout}>
          {(f.render && _isFunction(f.render))
            ? f.render(_get(refundRequest, f.dataIndex), refundRequest)
            : _get(refundRequest, f.dataIndex)
          }
        </Form.Item>)}
      <hr />
      {refundRequest.refund
        ? refundDataFields.map(f =>
          <Form.Item key={f.key} label={f.title} {...formItemLayout}>
            {(f.render && _isFunction(f.render))
              ? f.render(_get(refundRequest, f.dataIndex), refundRequest)
              : _get(refundRequest, f.dataIndex)
            }
          </Form.Item>)
        : <Form.Item key="issueRefund" label="Issue Refund">
          <Button onClick={this.handleRefundModalOpen}>Refund</Button>
        </Form.Item>
      }
      <Modal
        visible={refundMethod && refundMethod === 'transfer'}
        onCancel={this.handleRefundModalClose}
        onOk={this.handleCreateRefund}
        confirmLoading={this.state.refunding}
        // okText={this.state.submitting ? 'Submitting' : 'Submit'}
      >
        <RefundForm wrappedComponentRef={ref => { this.refundForm = ref }} refundRequest={refundRequest} />
      </Modal>
    </Form>
  }
}

export default compose(
  Form.create(),
  graphql(refundRequestQuery, {
    options: ({ match: { params: { eventId, refundRequestId } } }) => ({
      variables: { _id: refundRequestId },
      fetchPolicy: 'network-only'
    }),
    props: ({ data }) => ({
      refundRequest: data.refundRequest,
      refundRequestLoading: data.loading
    })
  })
  // graphql(eventQuery, {
  //   options: ({ match: { params: { eventId } } }) => ({
  //     variables: { _id: eventId }
  //   }),
  //   props: ({ data }) => ({
  //     event: data.event,
  //     eventLoading: data.loading
  //   })
  // })
)(RefundRequestEdit)
