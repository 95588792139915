import React, {Component} from 'react'
import {
    //Card, 
} from 'antd'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_thaiLow from "@amcharts/amcharts4-geodata/thailandLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import participantProvince from '~/graphql/queries/participantProvince.gql'

import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

am4core.useTheme(am4themes_animated);

class GraphAmMap extends Component {

    constructor (props) {
        super(props)
        this.state = { 
            param: props.param,
            datasource:null,
            provinceData: null,  
            provinceList: null, 
            mapData: null,
            provinceLimit: 10,
            currentMap: "thailandLow",
        }
    }
    renderChart = () => {
        if(this.chart){
            this.chart.dispose();
        }

        const chart = am4core.create("chartdiv", am4maps.MapChart);
        this.chart = chart 

        chart.geodata = am4geodata_thaiLow;
        

        // Set projection
        chart.projection = new am4maps.projections.Mercator()
        
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
        polygonSeries.heatRules.push({
            property: "fill",
            
            target: polygonSeries.mapPolygons.template,
            min: am4core.color("#FF6600").brighten(1),
            max: am4core.color("#FF6600").brighten(-0.3)

        });
        this.polygonSeries = polygonSeries
    
        // Make map load polygon data (state shapes and names) from GeoJSON
        polygonSeries.useGeodata = true;    

        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;
        chart.maxZoomLevel = 1;           
        chart.chartContainer.wheelable = false
        // Configure series tooltip
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}: {value}";
        polygonTemplate.nonScalingStroke = true;
        polygonTemplate.strokeWidth = 0.5;        

        // Create hover state and set alternative fill color
        let hs = polygonTemplate.states.create("hover")

        hs.properties.fill = am4core.color("#9966FF")

        console.log('mapLoadData', 'Init');
        this.loadData(['mapData']);
        this.renderMapData()
    }
    componentDidMount = () => {
        this.renderChart()
    }

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    loadMapData = () => {

        const param = this.state.param
        const provinceList = param.loadTableEvent('proviceList')
        const datasource = param.loadDatasource()
        let mapData = []
        let row = 0

        
        if(datasource === null){   
        }else{

            let _datasource = datasource === null ? [{count:0, province:''}] : datasource;            
            _datasource = _orderBy(_datasource, 'count', 'desc') 
            const limit = this.state.provinceLimit     

            const provinceData = require("~/data/provincesData.json")     
            if(_datasource === null || (_datasource.length > 0 && _datasource[0] === false)){}else _datasource.map(key => { row++
                var pvMap = provinceData.find(dt=>(dt.name.en===key.province))
                var code = (pvMap === undefined) ? "" : pvMap.code
                key.code = code
    
                mapData['TH-' + code] = (provinceList && (row>=limit)) ? null : key.count
                //return true
            }) 
             
        }

        mapData = row > 0 ? mapData : null;
        return mapData
    }

    renderMapData = () => {
        /*
        console.log('param', this.state.param.loadTableEvent('proviceList')); 
        console.log('mapData', this.loadMapData()); 
        //console.log('geodataSource', chart.geodataSource); 

        //chart.geodataSource. = [{'TH-10': 7, 'TH-13': 1}]
        chart.geodataSource.url = "/resources/amcharts4/geodata/json/" + currentMap + ".json"         
        chart.geodataSource.events.on("parseended", function(ev) {
            console.log('length', ev);
            let data = [];
            for(var i = 0; i < ev.target.data.features.length; i++) {
              data.push({
                id: ev.target.data.features[i].id,
                value: Math.round( Math.random() * 10000 )
              })
            }
            polygonSeries.data = data;
            console.log(data)
        })  
        */
       
        if (this.chart) {
            this.chart.dispose();
        }
        
        const {currentMap} = this.state
        
        const chart = am4core.create("chartdiv", am4maps.MapChart)

        chart.geodata = am4geodata_thaiLow; 
        chart.chartContainer.wheelable = false

        // Set projection
        chart.projection = new am4maps.projections.Mercator()

        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
        polygonSeries.heatRules.push({
            property: "fill",
            
            target: polygonSeries.mapPolygons.template,
            //min: chart.colors.getIndex(2).brighten(1),
            //max: chart.colors.getIndex(2).brighten(-0.3)
            min: am4core.color("#FF6600").brighten(1),
            max: am4core.color("#FF6600").brighten(-0.3)

        });
        this.polygonSeries = polygonSeries
    
        // Make map load polygon data (state shapes and names) from GeoJSON
        polygonSeries.useGeodata = true;    

        /*
        // Set up heat legend
        let heatLegend = chart.createChild(am4maps.HeatLegend);
        heatLegend.series = polygonSeries;
        heatLegend.align = "right";
        heatLegend.width = am4core.percent(25);
        heatLegend.marginRight = am4core.percent(4);
        heatLegend.minValue = 0;
        heatLegend.maxValue = 40000000;
        heatLegend.valign = "bottom";

        // Blank out internal heat legend value axis labels
        heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
            return "";
        });
        */
    
        // Configure series tooltip
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}: {value}";
        polygonTemplate.nonScalingStroke = true;
        polygonTemplate.strokeWidth = 0.5;        

        // Create hover state and set alternative fill color
        let hs = polygonTemplate.states.create("hover")

        hs.properties.fill = am4core.color("#9966FF")

        let mapData = this.state.mapData === null ? this.loadMapData() : this.state.mapData

        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;
        chart.maxZoomLevel = 1;                    
       
        chart.geodataSource.url = "/resources/amcharts4/geodata/json/" + currentMap + ".json"     
        chart.geodataSource.events.on("parseended", function(ev) {  
            let data = []
            for(var i = 0; i < ev.target.data.features.length; i++) {
                var cid = ev.target.data.features[i].id
                data.push({
                    id: cid, 
                    value: (mapData[cid] === undefined ? null : mapData[cid]), 
                })
            }
            
            polygonSeries.data = data 
            
        }) 

        console.log('mapLoadData', 'Complete');    
        return chart

    }

    loadData = (key) => {
        if (key.includes('mapData') && this.state.mapData === null)
        {    
            this.loadMapData()
        }
    }

    render() {
     
        if( !(this.loadMapData() === null) ){
            this.chart = this.renderMapData()
        }   

        return (
            
            <div id="chartdiv" style={{ width: "100%", height: "350px", minWidth: "300px", border: "0px solid #000" }}></div>

        );
    }

}

export default GraphAmMap

