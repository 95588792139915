import React from 'react'
import {
    Card, Row, Col, Switch
} from 'antd'
import { useTranslation } from 'react-i18next';

import RApaxChart from "react-apexcharts";
import ShirtsData from '../../Statistics/ShirtItemsTable'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import _get from 'lodash/get'

//const { Meta } = Card

const GraphApex = ({param}) => {
    const { t, i18n } = useTranslation()
    const locale = i18n.language.split('-')[0]

    var shirtTypesMap = []

    const datasource = param.datasource 

    const chartRes = param.chart  
    const shirtTypes = param.shirtTypes 
    const totalTableActive  = param.tableEvent
    const shirtsIndex  = param.shirtsIndex     

    const dataColors = ['#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',
    //------------------------------------------------------------------------------
                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                        '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                        '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                        '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066'
                        ] 

   const val = {"row":[], "label":[], "value":[], "total":0, 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,  
                }
    const total = JSON.parse(JSON.stringify(val))

    let groupVal = []
    var row = 0

    shirtTypes.forEach(shirtType => {
        shirtTypesMap[shirtType._id] = shirtType.name[locale]
    })   

    const indexOfShirts = {"shirtTypeId":{data:[], rows:0},
                            "shirtId":{data:[], rows:0}
                            }
    for(const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, 'shirtTypeId'))){
        const _name = shirtTypesMap[keyi]
        indexOfShirts.shirtTypeId.data[keyi] = {order:indexOfShirts.shirtTypeId.rows++, _id:keyi, name:_name}
    }      
    for(const [keyi, valuei] of Object.entries(_groupBy(shirtsIndex, '_id'))){
        indexOfShirts.shirtId.data[keyi] = {order:indexOfShirts.shirtId.rows++, name:valuei[0].name.en}
    }   
    //console.log('indexOfShirts.shirtTypeId', indexOfShirts.shirtTypeId)
    //console.log('indexOfShirts.shirtId', indexOfShirts.shirtId)          

    const dsRebuild = {groupShirtType:[]}
    const objTotal =  {typeId: 'TOTAL',  name: 'TOTAL', index:999, data:[]}      
    if(datasource){
        //console.log('datasource-Org', datasource)              
        let byShirtType = []   
        // for(const [keyi, valuei] of Object.entries(datasource))if(valuei.name && valuei.name.en !== 'Total'){
        for(const [keyi, valuei] of Object.entries(datasource))if(_get(valuei, 'name.en') !== 'Total'){
            if(valuei.shirts){
                for(const [keyj, valuej] of Object.entries(valuei.shirts)){

                    if(byShirtType[valuej.shirtTypeId] === null || byShirtType[valuej.shirtTypeId] === undefined){
                        byShirtType[valuej.shirtTypeId] = {typeId: valuej.shirtTypeId,
                                                            index: indexOfShirts.shirtTypeId.data[valuej.shirtTypeId].order,  
                                                            name: shirtTypesMap[valuej.shirtTypeId] || "UNKNOW", 
                                                            data:[]
                                                            }
                    }
                    let objData = {shirtTypeId:valuej.shirtTypeId, 
                        order:0,
                        index: indexOfShirts.shirtId.data[valuej._id].order, 
                        size:{_id:valuej._id,name:valuej.name}, 
                        count:valuej.count,
                        isFull: valuej.isFull,
                        quantity:valuej.quantity,
                        __typename: "ShirtCount"}  

                    if(byShirtType[valuej.shirtTypeId].data[valuej._id] === null || byShirtType[valuej.shirtTypeId].data[valuej._id] === undefined){}else{
                        objData = byShirtType[valuej.shirtTypeId].data[valuej._id]
                        objData.count += (valuej.count!=undefined && valuej.count!=null ? valuej.count : 0)
                        if(valuej.quantity != null)
                            objData.quantity = ((valuej.quantity!=undefined && valuej.quantity!=null) && (objData.quantity < valuej.quantity) ? valuej.quantity : objData.quantity)
                    }

                    byShirtType[valuej.shirtTypeId].data[valuej._id] = objData;

                    if(objTotal.data[valuej._id] === null || objTotal.data[valuej._id] === undefined){
                        objTotal.data[valuej._id] = JSON.parse(JSON.stringify(objData)); 
                        objTotal.index = (indexOfShirts.shirtTypeId.rows)

                    }else{

                        if(valuej.quantity != null)
                            objTotal.data[valuej._id].quantity = (objTotal.data[valuej._id].quantity > valuej.quantity ? objTotal.data[valuej._id].quantity : valuej.quantity)                       

                        objTotal.data[valuej._id].count += valuej.count      

                    }

                }

            }

            byShirtType['total'] = objTotal
            
        }
        //console.log('shirts' , datasource);
        //console.log('shirt-byShirtType', byShirtType);
        //console.log('602f386b583486218ff575d6', txt);

        let arrIndex = 0
        for(const [key, value] of Object.entries(byShirtType)){
            let idata = {}
            let iDataIndex = 0
            for(const [keyi, valuei] of Object.entries(value.data)){
                valuei.key = keyi
                idata[iDataIndex++]  = valuei
            }

            value.key = key
            value.data = idata
            dsRebuild.groupShirtType[arrIndex++] = value
        }
        dsRebuild.groupShirtType = _orderBy(dsRebuild.groupShirtType, 'index', 'asc')
        //console.log('dsRebuild.groupShirtType', dsRebuild.groupShirtType)
  
        for(const [keyi, valuei] of Object.entries(dsRebuild.groupShirtType)){

            const _val = JSON.parse(JSON.stringify(val))               

            const vData = _orderBy(valuei.data, 'index', 'asc')

            let dataRow = 0
            if(vData)for(let [keyj, valuej] of Object.entries(vData))if(valuej.shirtTypeId===valuei.key || valuei.typeId==='TOTAL'){
                keyj = dataRow++;
                _val.value[keyj] = 0
                _val.label[keyj] = valuej.size.name.en
                _val.row[keyj] = keyj
                _val.value[keyj] = valuej.count
                _val.total += valuej.count
            
            }

            groupVal[keyi] = _val
        }
    }

    const apexGraphOption = []
    if(groupVal.length > 0)for(const [key, value] of Object.entries(groupVal)){

        apexGraphOption[key] = {
                options: {      
                    chart: {
                        width: '100%',
                        type: 'donut', 
                        toolbar: {
                            show: false,
                        },                                         
                    },                
                    labels: value.label,           
                    colors: dataColors,          
                    legend: {
                        show: false,
                        position: 'bottom'
                    },     
                    dataLabels: {
                        enabled: false
                    },    
                    plotOptions: {
                        pie: {
                            customScale: 0.8,
                            hover: {
                                filter: {
                                    type: 'none',
                                },
                            },     
                            donut: {
                                size: '65%',                      
                                labels: {
                                    show: true,
                                    total: {
                                      showAlways: true,
                                      show: true,
                                      label: value.total,
                                      fontSize: '3pc',  
                                      fontWeight: 'bold',

                                      formatter: () => {
                                          return `${t('menu.registrationStat.count')}`
                                        }  
                                                                                                   
                                    },                          
                                }                                
                            }, 
                            roseType : true                                                                
                        }, 
                    },   
                    stroke:{
                        width: 5
                    }                      
                },          
                series: value.value,   
            
        }
    }
    return (
        <div>
        {(dsRebuild.groupShirtType!==null) && dsRebuild.groupShirtType.map(
            report =>
            <Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px'}}>
                        <b class='chartHeader' > {chartRes.caption} {typeof report.name === 'undefined'? '' : (report.name === 'Total' ? 'Total' : `${t('menu.registrationStat.type')} ` + report.name)}  </b>
                        {report.typeId === 'TOTAL' && (report.data) && 
                            <div style={{display:'flex', float:'right', justifyContent: 'flex-end', marginTop:'5px', marginRight:'25px'}}>
                                    <Switch checkedChildren="Hide List" unCheckedChildren="Show List" onChange={param.eventHandle} /> 
                                </div>
                                }    
                        </div>}
                style={{marginBottom: '20px'}}
            >
                {row++ ? "":""}             
                {dsRebuild.groupShirtType.length ?
                <Row gutter={16}>
                    <Col sm={24} md={24} lg={9} >  
                        <RApaxChart
                            options={apexGraphOption[(row-1)].options}
                            series={apexGraphOption[(row-1)].series}
                            type={apexGraphOption[(row-1)].options.chart.type}
                            width="100%"
                            height="325"
                        />           
                    </Col>       
                    <Col sm={24} md={24} lg={15} > 
                        <ShirtsData data={report.data} mode={(report.typeId === 'TOTAL' && !totalTableActive) ? 'limitHeight' : ''} 
                                    shirtTypesMap={shirtTypesMap} 
                                    smrTotal={5000} 
                                    index={report.key} 
                                    loading={param.loading} 
                                    param={{isMobile: param.isMobile}}
                                    />                     
                    </Col>
                </Row> : <div className="event-gray" style={{textAlign:'center'}}>NO DATA</div>}                
            </Card>
        )}   
        </div>
    )


}

export default GraphApex
