import React from 'react'
const toNumPrecision = value => (value && `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
const toFeesAmount = value => {
  let val = value
  let color = 'green'
  if (value > 0) {
    val = value * -1
    color = 'red'
  }
  return <span style={{color: color}}>
    {toNumPrecision(val)}
  </span>
}
const toChargedAmount = value => {
  let val = value
  let color = 'green'
  if (value < 0) {
    val = value
    color = 'red'
  }
  return <span style={{color: color}}>
    {toNumPrecision(val)}
  </span>
}
const toOrganizerBalance = value => {
  let val = value
  let color = 'green'
  if (value < 0) {
    val = value
    color = 'red'
  }
  return <span style={{color: color}}>
    {toNumPrecision(val)}
  </span>
}
const toTicketDeliveryFees = value => {
  let val = value
  let color = 'green'
  if (value < 0) {
    val = value
    color = 'red'
  }
  return <span style={{color: color}}>
    {toNumPrecision(val)}
  </span>
}
const toTicketChargeDeliveryFees = value => {
  let val = value
  let color = 'green'
  if (value > 0) {
    val = value * -1
    color = 'red'
  }
  return <span style={{color: color}}>
    {toNumPrecision(val)}
  </span>
}
const ConvertValue = {
  toNumPrecision,
  toFeesAmount,
  toChargedAmount,
  toOrganizerBalance,
  toTicketDeliveryFees,
  toTicketChargeDeliveryFees
}

export default ConvertValue
