import React from 'react'
import {
    //Collapse, 
    Card, 
    //Select
} from 'antd'

import _get from 'lodash/get'

import RApaxChart from "react-apexcharts";
import _orderBy from 'lodash/orderBy'

const { Meta } = Card

const GraphApexPartcp = ({param}) => {

    const datasource = param.datasource
    const chartRes = param.chart

    var horizontalBar = false

    const val = {"row":[], "label":[], "value":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "dataRow":0,                    
                    "max":0,  
                } 

    if(!(datasource === null) && !(datasource.data === null)){ 

        datasource.data =  _orderBy(datasource.data, ['year', 'month'], ['asc','asc'])
        //console.log('bb', datasource.data)
        for(const [keyi, valuei] of Object.entries(datasource.data)){
            val.dataRow ++;
            const monthName = chartRes.categories[(valuei.month-1)] !== undefined ? chartRes.categories[(valuei.month-1)] : "INVAID DATE" 
            const lb = monthName + (valuei.year !== undefined ? ' ' + valuei.year : '' )

            val.value[keyi] = 0
            val.label[keyi] = lb.toUpperCase()
            val.chart.bar.bgcolor[keyi] = '#efefef'
            
            if(val.max < valuei.value){
                val.max = valuei.value    
            }

            val.row[keyi] = keyi
            val.value[keyi] = valuei.value
           
        }
             
    }

    if(param.isMobile)
        horizontalBar = 1

    const graphhight  = (row) =>{
        var height = 150
        if(row > 5){
            height = (row * 30) 
        } 
        return(height)    
    }

    const setMax  = (value) =>{
        var val = value
        const valArr = val.toString().split('')

        var _valArr = []

        valArr.forEach(function(val,index,array){
            array[index]    = parseInt(val, 10)
            _valArr[index]  = 0
        })

        if(value < 9){
            val = 20    
        }else if(value < 70){
            val = 80               
        }else if(value < 99){
            val = 120
        }else if(value < 999){
            val = 1000 + (valArr[1] > 8 ? 200 : 0) 
        }else{          
            _valArr[1] = parseInt(valArr[1], 10) + (valArr[2] >= 6 ? 2 : 1)                         
            _valArr[0] = parseInt(valArr[0], 10) + (_valArr[1] >= 8 ? 1 : 0) 

            _valArr[1] = _valArr[1] > 9 ? 0 : _valArr[1]  
            
            val = parseInt(_valArr.join(''), 10)
        }

        return(val)    
    }

    const setBarWidth  = (row, _min, _max ) =>{
        let Width = 30
        if(row < 3){
            Width = 3        
        }else if(row < 5){
            Width = 10
        }else if(row < 10){
            Width = 15
        }else if(row < 20){
            Width = 20
        }        

        if(_max > 0 && Width > _max) Width = _max 

        return Width < _min ? _min : Width       
    }
    
    const valueList = val    
 
    const apexParticipantByMonth = {
            options: {
                chart: {
                    id: "bar",
                    toolbar: {
                        show: false,
                    },                       
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        horizontal: horizontalBar,
                        startingShape: 'rounded',
                        endingShape: 'rounded',
                        columnWidth: (!horizontalBar ? setBarWidth( valueList.dataRow ) : '70%'),
                        barHeight: (!horizontalBar ? setBarWidth( valueList.dataRow ) : '30%'),
                        distributed: false,
                        rangeBarOverlap: true,
                        rangeBarGroupRows: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: undefined
                            }],
                            backgroundBarColors: val.chart.bar.bgcolor,
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 5,
                        },
                    }
                },
                xaxis: {
                    categories: valueList.label, 
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    lines: {
                        show: false
                    },      
                    axisTicks: {
                        show: false
                    },  
                    labels:{
                        style:{
                            colors: '#B1B1B1'
                        }   
                    }                                     
                },
                yaxis: {
                    min: 0,
                    max: setMax( val.max ),  
                    labels:{
                        style:{
                            colors: '#B1B1B1'
                        }   
                    }                                        
                },
                legend:{
                    show: false
                },
                grid: {
                    yaxis: {
                      lines: {
                        show: false
                      }
                    }
                }
            },
            series: [
                {
                name: "Number",
                data: valueList.value, 
                color: '#56C568'
                }
            ]
    };

    //console.log("graph-data", valueList);
    //console.log("max", setMax( val.max ));

    return (<Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px' }}>
                        <b class='chartHeader' > {chartRes.caption} {false && <span>ปี{chartRes.year}</span>}</b>
                        </div>}
            >
                <Meta
                    description={<div className="" >
                    <RApaxChart
                        options={apexParticipantByMonth.options}
                        series={apexParticipantByMonth.series}
                        type= "bar"
                        width= "100%"
                        height={ param.isMobile ? graphhight(valueList.dataRow) : "350"}
                    />                            
                    </div>}
                />
            </Card>
    )


}

export default GraphApexPartcp
