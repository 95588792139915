import React, { useState, useMemo } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { Layout,
        Tabs, 
        Card, 
        Row, Col, 
        Select, 
       } from 'antd'
import compose from 'lodash/flowRight'
// import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import TopContent from '~/components/Events/Event/TopContent'
import '~/components/Events/css/custom.css'

import EventSider from '~/components/Events/Event/Sider'

import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import moment from 'moment-timezone'

import { useTranslation } from 'react-i18next'

//import ApaxChart from 'apexcharts'
//import RApaxChart from "react-apexcharts";
import config from '~/config'
import _get from 'lodash/get'
import _last from 'lodash/last'
//import _sumBy from 'lodash/sumBy'
import _orderBy from 'lodash/orderBy'
import { inject, observer } from 'mobx-react'
import eventStatisticsQuery from '~/graphql/queries/eventStatistics.gql'
import participantByMonthQuery from '~/graphql/queries/participantByMonth.gql'
import participantByDayQuery from '~/graphql/queries/participantByDay.gql'
import participantGenderQuery from '~/graphql/queries/participantGender.gql'
import participantProvinceQuery from '~/graphql/queries/participantProvince.gql'
import participantWeekQuery from '~/graphql/queries/participantWeek.gql'
// import participantTimeQuery from '~/graphql/queries/participantTime.gql'
import registrationCouponCodeQuery from '~/graphql/queries/registrationCouponCode.gql'
import eventProductStatsQuery from '~/graphql/queries/eventProductStats.gql'
import eventStatisticsRegistrationTypeCountQuery from '~/graphql/queries/eventStatisticsRegistrationTypeCount.gql'

import eventStatisticsShirtCountQuery from '~/graphql/queries/eventStatisticsShirtCount.gql'
import eventStatisticsFinisherShirtCountQuery from '~/graphql/queries/eventStatisticsFinisherShirtCount.gql'

//import G0 from '~/components/Events/Event/Statistics/Graphs/apexCharts';
import G1 from '~/components/Events/Event/Statistics/Graphs/apexParticipantByMonth';
import G2 from '~/components/Events/Event/Statistics/Graphs/apexParticipantByDay';
import G3 from '~/components/Events/Event/Statistics/Graphs/apexParticipantGender';
import G4 from '~/components/Events/Event/Statistics/Graphs/apexParticipantProvince';
import G4Map from '~/components/Events/Event/Statistics/Graphs/apexParticipantProvinceMap';
import G5 from '~/components/Events/Event/Statistics/Graphs/apexParticipantWeek';
// import G6 from '~/components/Events/Event/Statistics/Graphs/apexParticipantTime';
import G7 from '~/components/Events/Event/Statistics/Graphs/apexParticipantSmmrCntRace';

import T1 from '~/components/Events/Event/Statistics/Tables/registrationTypeCount';
import T2 from '~/components/Events/Event/Statistics/Tables/ticketCount';
import T3 from '~/components/Events/Event/Statistics/Tables/couponCode';
import T4 from '~/components/Events/Event/Statistics/Tables/orderProduct';
import T5 from '~/components/Events/Event/Statistics/Tables/TicketType'

import ST1 from '~/components/Events/Event/Statistics/Graphs/allGroupShirts';
import ST2 from '~/components/Events/Event/Statistics/Graphs/finisherShirts';

import Loading from '~/components/Loading'

//const _orderBy = require('lodash.orderBy')
//import _ from 'lodash'
const { TabPane } = Tabs
//const Panel = Collapse.Panel
const Option = Select.Option
//const { Meta } = Card
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)
const EventStatistics = (props) => {
      const { match: { params: { eventId } } } = props
      const { t, i18n } = useTranslation()
      const eventProps = useQuery(eventStatisticsQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const {
        loading,
        data: dataEvent
      } = eventProps
      const {
        participantByMonthLoading,
        data: participantByMonthData
      } = useQuery(participantByMonthQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const participantByMonth = useMemo(() => setParticipantByMonth({
        participantByMonthLoading,
        participantByMonthData
      }), [
        participantByMonthLoading,
        participantByMonthData
      ])
      const [participantMonth$, setParticipantMonth] = useState('All')
      const participantMonth = useMemo(() => setParticipantMonth$({
        participantMonth$,
        participantByMonth
      }), [
        participantMonth$,
        participantByMonth
      ])
      const {
        participantByDayLoading,
        data: participantByDayData
      } = useQuery(participantByDayQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const participantByDay = useMemo(() => setParticipantByDay({
        participantByDayLoading,
        participantByDayData
      }), [
        participantByDayLoading,
        participantByDayData
      ])
      
      const {
        participantProvinceLoading,
        data: participantProvinceData
      } = useQuery(participantProvinceQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const participantProvince = useMemo(() => setParticipantProvince({
        participantProvinceLoading,
        participantProvinceData
      }), [
        participantProvinceLoading,
        participantProvinceData
      ])

      const {
        participantGenderLoading,
        data: participantGenderData
      } = useQuery(participantGenderQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })

      const {
        participantWeekLoading,
        data: participantWeekData
      } = useQuery(participantWeekQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })

      const {
        registrationTypeCountLoading,
        data: registrationTypeCountData
      } = useQuery(eventStatisticsRegistrationTypeCountQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const registrationTypeCount = useMemo(() => setRegistrationTypeCount({
        registrationTypeCountLoading,
        registrationTypeCountData
      }), [
        registrationTypeCountLoading,
        registrationTypeCountData
      ])

      const {
        shirtCountLoading,
        data: shirtCountData
      } = useQuery(eventStatisticsShirtCountQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const {
        shirtsIndex,
        shirtLast,
        shirtSummary,
        shirtSummaryNoTotal
      } = useMemo(() => setShirtCount({
        shirtCountLoading,
        shirtCountData
      }), [
        shirtCountLoading,
        shirtCountData
      ])
      const {
        finisherShirtCountLoading,
        data: finisherShirtCountData
      } = useQuery(eventStatisticsFinisherShirtCountQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const {
        finisherShirtsIndex,
        finisherShirtSummary,
      } = useMemo(() => setFinisherShirtCount({
        finisherShirtCountLoading,
        finisherShirtCountData
      }), [
        finisherShirtCountLoading,
        finisherShirtCountData
      ])

      const {
        eventProductStatsLoading,
        data: eventProductStatsData
      } = useQuery(eventProductStatsQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })
      const {
        eventProductStats,
        eventProducts
      } = useMemo(() => setEventProductStats({
        eventProductStatsLoading,
        eventProductStatsData
      }), [
        eventProductStatsLoading,
        eventProductStatsData
      ])

      const {
        registrationCouponCodeLoading,
        data: registrationCouponCodeData
      } = useQuery(registrationCouponCodeQuery, {
        variables: {
          eventId,
        },
        // fetchPolicy: "network-only",
        skip: !eventId
      })

      const [ticketsQuota, setTicketsQuota] = useState(null)
      // const [shirtSummary, setShirtSummary] = useState(null)
      // const [finisherShirtSummary, setfinisherShirtSummary] = useState(null)
      // const [eventProductStats, setEventProductStats] = useState(null)
      // const [eventProducts, setEventProducts] = useState(null)
      const [registrationCouponCode, setRegistrationCouponCode] = useState(null)
      const [paymentCount, setPaymentCount] = useState(null)
      const [racePaymentCount, setRacePaymentCount] = useState(null)
      // const [participantByMonth, setParticipantByMonth] = useState(null)
      // const [participantByDay, setParticipantByDay] = useState(null)
      
      
      const [participantYear, setParticipantYear] = useState('2020')
      // const [participantGender, setParticipantGender] = useState(null)
      // const [participantProvinceEmpty, setParticipantProvinceEmpty] = useState(false)
      const [participantWeek, setParticipantWeek] = useState(null)
      // const [participantTime, setParticipantTime] = useState(null)
      // const [shirtSummaryNoTotal, setShirtSummaryNoTotal] = useState(null)
      const [shirtSumByRace, setShirtSumByRace] = useState(true)
      // const [shirtsIndex, setShirtsIndex] = useState(null)      
      // const [finisherShirtsIndex, setFinisherShirtsIndex] = useState(null)      
      const [tableEvent, setTableEvent] = useState([])
      const [proviceListTable, setProviceListTable] = useState(true)
      const [ticketTypeGdetailTable, setTicketTypeGdetailTable] = useState(false)
      const [ticketTypeDetailTable, setTicketTypeDetailTable] = useState(false)
      const [shirtTotalTable, setShirtTotalTable] = useState(false)
      const isMobile = window.innerWidth <= 750

  const componentDidMounts = () => {
    // this.loadData(['runnerGraphs', 'shirtCount']);
    // this.loadData(['registrationTypeCount', 'registrationTypeCount']);
    // this.loadData(['ticketsQuota', 'ticketsQuota']);
    // this.loadData(['paymentCount', 'paymentCount']);
    // this.loadData(['couponCodeCount', 'registrationCouponCode']);
    // this.loadData(['eventProductStats', 'productSummary']);
    // this.loadData(['finisherShirtCount']);
  }

  const loadData = (key) => {
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      
      if (key.includes('runnerGraphs') && participantByMonth === null) {
        this.props.client.query({
          query: participantByMonthQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result.data
          this.setState({ participantMonth: '' })
          const participantByMonth = {
            data: _get(event, 'participantByMonth').map(({month, year, count}) => ({ 
                month:(month===null?'0':month), 
                year,
                label:`${moment(month, 'MM').locale('en').format('MMM').toUpperCase()}`,
                value: count }))
          }
          if(participantByMonth.data === null || !participantByMonth.data.length){}else{
            participantByMonth.data = _orderBy(participantByMonth.data, ['year', 'month'], ['desc','desc'])
            this.setState({ participantMonth: participantByMonth.data[0].month})
          }
          this.setState({ participantByMonth })
        })
      }

      if (key.includes('runnerGraphs') && participantByDay === null) {
        this.props.client.query({
          query: participantByDay,
          variables: { eventId }
        }).then((result) => {
          let byDayData = _get(result, 'data.participantByDay')
          byDayData = byDayData.map((data)=>({day:data.day===null?0:data.day, month:data.month===null?0:data.month, count:data.count}))
          this.setState({ participantByDayData: {data: byDayData} })
        })
      }

      if (key.includes('runnerGraphs') && participantGender === null) {
        this.props.client.query({
          query: participantGender,
          variables: { eventId }
        }).then((result) => {
          try {
            _get(result, 'data.participantGender') && this.setState({ participantGender: {data:_get(result, 'data.participantGender')} })         
          } catch (e) {
            console.error(e)
          }
        })
      }

      if (key.includes('runnerGraphs') && participantProvince === null) {
        this.props.client.query({
          query: participantProvince,
          variables: { eventId }
        }).then((result) => {
          //console.log('participantProvince', result)
          let _participantProvince = _get(result, 'data.participantProvince') 
          _participantProvince = _participantProvince.map( (data) => ({count:data.count, province: (data.province === null ? "unknow" : data.province ), }) )

          if(_participantProvince === null || (_participantProvince.length === 1 && _participantProvince[0].province === 'unknow')){
            this.setState({ participantProvinceEmpty: true })
          }

          result && this.setState({ participantProvince: _participantProvince })
        })
      }      

      if (key.includes('runnerGraphs') && participantWeek === null) {
        this.props.client.query({
          query: participantWeek,
          variables: { eventId }
        }).then((result) => {
          result && _get(result, 'data.participantWeek') && this.setState({ participantWeek: {data:_get(result, 'data.participantWeek')} })        
        })
      }

      if (key.includes('runnerGraphs') && participantTime === null) {
        this.props.client.query({
          query: participantTime,
          variables: { eventId }
        }).then((result) => {
          _get(result, 'data.participantTime') && this.setState({ participantTime: {data:_get(result, 'data.participantTime')} })
        })
      }

      if (key.includes('registrationTypeCount') && registrationTypeCount === null) {
        this.props.client.query({
          query: eventStatisticsRegistrationTypeCountQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result && result.data.event
          let registrationTypeCount = _get(event, 'registrationTypeCount')
          // Modify RegistrationTypeCount
          registrationTypeCount = _orderBy(registrationTypeCount, 'order', 'desc')
          let totalRTC = {
            _id: 'total',
            name: {
              en: 'TOTAL'
            },
            draft: 0,
            submitted: 0,
            paid: 0,
            total: 0
          }
          registrationTypeCount.forEach((rt) => {
            totalRTC.draft += rt.draft
            totalRTC.submitted += rt.submitted
            totalRTC.paid += rt.paid
            totalRTC.total += rt.total
          })
          registrationTypeCount = registrationTypeCount.concat(totalRTC)
          registrationTypeCount && this.setState({ registrationTypeCount })
        })
      }

      if (key.includes('couponCodeCount') && registrationCouponCode === null) {
        this.props.client.query({
          query: registrationCouponCodeQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result && result.data
          const registrationCouponCode = _get(event, 'registrationCouponCode')
          registrationCouponCode  && this.setState({ registrationCouponCode })
        })
      }

      if (key.includes('eventProductStats') && eventProductStats === null) {
        this.props.client.query({
          query: eventProductStatsQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result.data.event
          const eventProductStats = _get(event, 'productStats')
          // const p = _groupBy(eventProductStats, '_id')
          const eventProducts = _get(result, 'data.products')
          eventProductStats && this.setState({ eventProductStats})
          eventProducts && this.setState({ eventProducts })
        })
      }

      // Load Data ShirtCount
      if (key.includes('shirtCount') && shirtSummary === null) {
        this.props.client.query({
          query: eventStatisticsShirtCountQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result.data.event
          let shirtSummary = _get(event, 'shirtCountBackEnd')
          const shirts = _get(event, 'shirts')
          this.setState({ shirtsIndex: shirts})
          if (shirtSummary && shirts) {
            const shirtTotal = shirtSummary.reduce((init, current) => {
              const initShirts = _get(init, 'shirts')
              const currentShirts = _get(current, 'shirts')
              return {
                name: {
                  th: 'Total',
                  en: 'Total'
                },
                shirts: shirts.map(s => {
                  const initShirt = initShirts && initShirts.find(iS => `${iS._id}` === `${s._id}`)
                  const currentShirt = currentShirts && currentShirts.find(cS => `${cS._id}` === `${s._id}`)
                  const initShirtCount = _get(initShirt, 'count') || 0
                  const currentShirtCount = _get(currentShirt, 'count') || 0
                  return {
                    ...s,
                    count: initShirtCount + currentShirtCount
                  }
                })
              }
            }, {})
            shirtSummary = [...shirtSummary, shirtTotal]
          }
          this.setState({ shirtLast: _get(_last(shirtSummary), 'name.th') })
          this.setState({ shirtSummary })
          this.setState({ shirtSummaryNoTotal: _get(event, 'shirtCountBackEnd')})
        })
      }

      // Load Data ShirtCount
      if (key.includes('finisherShirtCount') && finisherShirtSummary === null) {
        this.props.client.query({
          query: eventStatisticsFinisherShirtCountQuery,
          variables: { eventId }
        }).then((result) => {
          const event = result.data.event
          const finisherShirtSummary = _get(event, 'finisherShirtCountBackEnd')
          this.setState({ finisherShirtSummary })
          this.setState({ finisherShirtsIndex : _get(event, 'finisherShirts') })
        })
      }

    }

  }
    // const { loading } = props
    const event = _get(dataEvent, 'event')
    // const { participantByMonth, participantMonth, 
    //         participantByDayData,
    //         participantGender,
    //         participantProvince,
    //         participantProvinceEmpty,            
    //         participantWeek,
    //         participantTime,   
    //         registrationTypeCount, 
    //         registrationCouponCode,   
    //         //participantYear 
    //       } = this.state
    var ticketTypeCountBackEnd = _get(event, 'ticketTypeCountBackEnd')
    const ticketTypeOriginal = ticketTypeCountBackEnd

    var shirtTypes = _get(event, 'shirtTypes') || []

    // const tableEvent = tableEvent     

    const _year = '2020'

    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    const monthListFullTh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const queryMonth = participantMonth || _get(participantByMonth, ['data', 0, 'month'])

    var finisherShirtTypesMap = []    
    if(!_get(event, 'isLineLogin')){
      var finisherShirtTypes = _get(event, 'finisherShirtTypes', []) || []
      finisherShirtTypes.forEach(finisherShirtType => {
        finisherShirtTypesMap[finisherShirtType._id] = finisherShirtType.name.th
      })      
    }      
  
    if (!(!loading && event && ticketTypeCountBackEnd /* && orderItemCount */)) {
      return <Loading />
    } else {


    const finisherShirtTotal = finisherShirtSummary && {
      name: {
        en: 'TOTAL'
      },
      count: finisherShirtSummary.reduce((total, current) => {
        return total + _get(current, 'count', 0)
      }, 0)
    }

    const finisherShirtData = finisherShirtSummary && finisherShirtSummary.concat(finisherShirtTotal)
      
    const productSummary = eventProducts && eventProducts.map(product => {
      let stats = eventProductStats || []
      const item = stats.find(s => s._id === product._id)
      return item || {
        product, //submitted: 0, 
        paid: 0, total: 0
      }
    })
    if (productSummary) {
      const productSum = productSummary.reduce((init, current) => {
        return {
          //submitted: init.submitted + current.submitted,
          paid: init.paid + current.paid,
          total: init.total + current.total
        }
      }, {
        //submitted: 0, 
        paid: 0, total: 0
      })
      productSummary.push(productSum)
    }      

    let monthLs = participantByMonth && _orderBy(participantByMonth.data, ['year', 'month'], ['desc','desc']) 

    const layoutClass = "layout-events eventFgma" + (isMobile ? " fgma-mobile" : "")
    let participantFields = _get(event, 'settings.participant.fields', []) || []
    let regFields = participantFields
      .filter(f => f.enabled)
      .reduce((result, field) => {
        result[field.name] = field
        return result
      }, {})
      return (   
        <Layout>
        <Layout.Content>
        <TopContent
          title={_get(event, 'name.th') || _get(event, 'name.en')}/>
        <div className="innerMainContentWrapper">
          <EventSider />
          <div className={`${layoutClass} middleContent`}>
              <Tabs defaultActiveKey="registrationPane" type="card">
                <TabPane tab="REGISTRATION" key="registrationPane">                
                  <Row gutter={16}>
                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                      <T5 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.ticketType'),
                                    }, 
                                    "subTitle":"Table",                                    
                                    "width":"100%", "height":"300px",
                                    "tableEvent": { ticketTypeGdetail :ticketTypeGdetailTable },
                                    "datasource": null,
                                    "event":event,
                                    eventHandle: (value) => {
                                      setTicketTypeGdetailTable(value)
                                    }}} 
                          ticketTypeCountBackEnd={ticketTypeCountBackEnd} 
                          ticketTypeOriginal= {ticketTypeOriginal} 
                          loading={loading}/>                    
                    </Col>

                    <Col sm={24} md={24} lg={15} style={{ marginBottom: '20px' }}>     
                    <Card
                        hoverable
                        cover={<div style={{ marginTop: '20px' }}>
                                  <div  className='fgma-chart' style={{ fontSize: '1.3em', paddingLeft: '25px', display: 'inline-block', width: '50%', minWidth: '300px'}} >
                                  <b class='chartHeader' >{t('menu.registrationStat.day')}, {queryMonth === 'null' ? 'Unknow' : (queryMonth === 'All' ? 'ทั้งหมด' : monthListFullTh[parseInt(queryMonth, 10)-1])}</b>
                                  </div>
                                  <div style={{ marginTop: '0px', paddingRight: '30px', display: 'inline-block', width: '50%', minWidth: '300px', textAlign: 'right' }} >
                    
                                    {monthLs ? <Select size="small" width='100px'
                                      defaultValue={`${participantMonth}`}
                                      onChange={v => setParticipantMonth(v)}>
                                        {false && <Option
                                        key="All"
                                        value="All"
                                        >
                                        All
                                        </Option>}

                                        {monthLs && monthLs.map(report =>
                                        <Option
                                            key={`${report.year}-${report.month}`} 
                                            value={`${report.year}-${report.month}`}
                                            // value={`${report.month}`}
                                        >
                                            {`${report.label} ${report.year}`}
                                        </Option>
                                        )}
                                    </Select> : null}
                                  </div>
                                </div>} 
                    >                                    
                      <G2 param={{"graphNAme":"ParticpantByDay",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.day'),
                                      "year":_year,
                                      "categories": monthList,                                                                        
                                    }, 
                                    "width":"100%",
                                    "defaultVal":{
                                        "queryMonth": queryMonth,
                                        "queryYear":_year,
                                        "categories": monthList,
                                    },
                                    "isMobile": isMobile,                                    
                                    "datasource": participantByDay}}/>
                    </Card>
                    </Col>
                    <Col sm={24} md={24} lg={9} style={{ marginBottom: '20px' }}>                 
                      <G1 param={{"graphNAme":"Particpant",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.month'),
                                      "year": _year,
                                      "categories": monthList,
                                    }, 
                                    "isMobile": isMobile,
                                    "width":"100%", "datasource":participantByMonth}}/>
                    </Col>
                  </Row>

                  {config.hiddenField && participantProvince && <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col sm={24} md={24} lg={24} >
                    <Card
                        hoverable
                        style={{}}
                        cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px' }}>
                                <b class='chartHeader' > จังหวัดที่ผู้สมัครอยู่ (Province) </b>
                                </div>}
                    >                      
                    <Col sm={24} md={24} lg={12} >                     
                    <div style={{width:'100%', verticalAlign:'top', border:'0px solid #000'}} >
                        <G4Map param={{"graphNAme":"ParticpantProvinceMap",
                                        "chart": {
                                          "caption" : "จังหวัดที่ผู้สมัครอยู่ (Province) ",
                                        }, 
                                        "width":"100%", 
                                        loadDatasource:() => {
                                          return participantProvince
                                        },
                                        loadTableEvent: (index) => {
                                          const tev = tableEvent[index] === undefined ? true : tableEvent[index]
                                          return tev
                                        },
                                        "tableEvent":tableEvent
                                        }} />                       
                        </div> 
                    </Col>  
                    <Col sm={24} md={24} lg={12} >
                    <div style={{width:'100%', verticalAlign:'top', border:'0px solid #000'}} >
                        <G4 param={{"graphNAme":"ParticpantProvince",
                                    "chart": {
                                      "caption" : "จังหวัดที่ผู้สมัครอยู่ (Province) ",
                                    }, 
                                    "width":"100%", 
                                    "tableEvent": { proviceList: proviceListTable },
                                    "isMobile": isMobile,
                                    eventHandle: (value) => {
                                      setProviceListTable(value)
                                    },
                                    "datasource": participantProvince }} />                                    
                    </div>                    
                    </Col>
                    </Card>                      
                    </Col>
                  </Row>}
                  <Row gutter={16} style={{ marginBottom: '20px' }}>                 
                    {regFields['gender'] && <Col sm={24} md={24} lg={9} style={{ marginBottom: '20px' }}>                 
                      <G3 param={{"graphNAme":"ParticipantGender",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.gender'),                                                                    
                                    }, 
                                    "width":"100%", "height":"350px",
                                    "datasource": {data: _get(participantGenderData, 'participantGender')}}}/>
                    </Col>}

                    {/* {false && <Col sm={24} md={24} lg={15} style={{ marginBottom: '20px' }}>
                      <G6 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : "ลงทะเบียนช่วงเวลา (Time)",
                                    }, 
                                    "width":"100%", 
                                    "height":"300px",
                                    "datasource": participantTime}} />                   
                    </Col>} */}


                    <Col sm={24} md={24} lg={15} style={{ marginBottom: '20px' }}>
                      <G7 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.distance'),
                                    }, 
                                    "subTitle":"Stack Chart",
                                    "width":"100%", "height":"300px",
                                    "isMobile": isMobile,
                                    "datasource": {data: ticketTypeCountBackEnd}}} />                   
                    </Col>   

                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                      {!participantWeekLoading && <G5 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.date'),
                                    }, 
                                    "subTitle":"Bar Chart",                                      
                                    "width":"100%", "height":"300px", 
                                    "datasource": { data: _get(participantWeekData, 'participantWeek')}}} />}
                    </Col>       

                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                      <T1 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.registrationType'),
                                    }, 
                                    "subTitle":"Table",                                       
                                    "width":"100%", 
                                    "loading":loading,
                                    "datasource": registrationTypeCount}} />                   
                    </Col> 

                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                      <T2 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.distanceType'),
                                    }, 
                                    "subTitle":"Table",                                    
                                    "width":"100%", "height":"300px",
                                    "tableEvent": {ticketTypeDetail: ticketTypeDetailTable},
                                    "datasource": null,
                                    "event": event,
                                    eventHandle: (value) => {
                                      setTicketTypeDetailTable(value)
                                    },                                    
                                    "dataqueries":ticketTypeCountBackEnd}} 
                          loading={loading}      
                                    />                   
                    </Col>

                  </Row>     
                </TabPane>

                <TabPane tab="SHIRT" key="shirtPane">
                <Row gutter={16}>         
                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                          <ST1 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : `${t("menu.registrationStat.shirt.typeCount")}`,
                                    }, 
                                    "subTitle":"Table",                                       
                                    "width":"100%", 
                                    "loading":loading,
                                    "isMobile": isMobile,
                                    "datasource": shirtSummary, 
                                    "shirtsIndex": shirtsIndex,
                                    "shirtTypes": shirtTypes, 
                                    "tableEvent": shirtTotalTable,
                                    eventHandle: (value) => {
                                      setShirtTotalTable(value)
                                    },                                                                           
                                    "dataqueries": null}} />
                                                             
                    </Col>
                    {(!_get(event, 'isLineLogin')) &&
                    <Col sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>                        
                          <ST2 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : "Finisher Shirt",
                                    }, 
                                    "subTitle":"Table",                                       
                                    "width":"100%", 
                                    "loading":loading,
                                    "isMobile": isMobile,
                                    "datasource": finisherShirtData,  
                                    "shirtsIndex": finisherShirtsIndex, 
                                    "typemap": finisherShirtTypesMap,                                     
                                    "dataqueries": null}} />                                                            
                    </Col>
                    }
                </Row>
                </TabPane>

                <TabPane tab="OTHER" key="otherPane">
                <Row gutter={16}>         
                { !_get(event, 'isLineLogin') && 
                    <Col sm={24} md={24} lg={15} style={{ marginBottom: '20px' }}>
                          <T4 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : t('menu.registrationStat.other'),
                                    }, 
                                    "subTitle":"Table",                                       
                                    "width":"100%", 
                                    "loading":loading,
                                    "isMobile": isMobile,
                                    "datasource": null,                                    
                                    "dataqueries": productSummary}} />
                                                             
                    </Col>
                }
                    <Col sm={24} md={24} lg={!_get(event, 'isLineLogin') ? 9 : 15} style={{ marginBottom: '20px' }}>
                      <T3 param={{"graphNAme":"ChartBlank",
                                    "chart": {
                                      "caption" : "Coupon Code",
                                    }, 
                                    "subTitle":"Table",                                       
                                    "width":"100%", 
                                    "loading":loading,
                                    "isMobile": isMobile,
                                    "datasource": null,                                    
                                    "dataqueries": _get(registrationCouponCodeData, 'registrationCouponCode')}} />                        
                    </Col>     
                </Row>   
                </TabPane>
              </Tabs>
            </div>
            </div>
          </Layout.Content>
        </Layout>
      )
    }
}

const setParticipantByMonth = ({participantByMonthData, participantByMonthLoading}) => {
  if(!participantByMonthData || participantByMonthLoading) return null
  // this.setState({ participantMonth: '' })
  const participantByMonth = {
    data: _get(participantByMonthData, 'participantByMonth').map(({month, year, count}) => ({ 
        month:(month===null?'0':month), 
        year,
        label:`${moment(month, 'MM').locale('en').format('MMM').toUpperCase()}`,
        value: count }))
  }
  if(participantByMonth.data === null || !participantByMonth.data.length){}else{
    participantByMonth.data = _orderBy(participantByMonth.data, ['year', 'month'], ['desc','desc'])
    // this.setState({ participantMonth: participantByMonth.data[0].month})
  }
  return participantByMonth

}
const setParticipantMonth$ = ({
  participantMonth$,
  participantByMonth
}) => {
  if(participantMonth$ !== 'All') return participantMonth$
  if(!participantByMonth) return participantMonth$
  return `${_get(participantByMonth, ['data', 0, 'year'], 'ALL')}-${_get(participantByMonth, ['data', 0, 'month'], 'ALL')}`
}
const setParticipantByDay = ({
  participantByDayLoading,
  participantByDayData
}) => {
  if(!participantByDayData || participantByDayLoading) return null
  const participantByDay = _get(participantByDayData, 'participantByDay')
    .map((data)=>({
      day: data.day===null?
        0 : data.day,
      month: data.month===null?
        0:data.month,
      year: data.year===null?
        0:data.year,
      count:data.count
    }))
  return participantByDay
}

const setParticipantProvince = ({
  participantProvinceLoading,
  participantProvinceData
}) => {
  if(!participantProvinceData || participantProvinceLoading) return null
  const participantProvince = _get(participantProvinceData, 'participantProvince')
    .map((data) => ({
      count: data.count,
      province: (data.province === null ? "unknow" : data.province )
    }))
    if(participantProvince === null || (participantProvince.length === 1 && participantProvince[0].province === 'unknow')){
      return null
    }
  return participantProvince
}

const setEventProductStats = ({
  eventProductStatsLoading,
  eventProductStatsData
}) => {
  if(!eventProductStatsData || eventProductStatsLoading) return {
    eventProductStats: null,
    eventProducts: null
  }
  const event = eventProductStatsData.event
  const eventProductStats = _get(event, 'productStats')
  const eventProducts = _get(eventProductStatsData, 'products')
  return {
    eventProductStats,
    eventProducts
  }
}

const setRegistrationTypeCount = ({
  registrationTypeCountLoading,
  registrationTypeCountData
}) => {
  if(!registrationTypeCountData || registrationTypeCountLoading) return null
  let registrationTypeCount = _orderBy(_get(registrationTypeCountData, 'event.registrationTypeCount'), 'order', 'desc')
  let totalRTC = {
    _id: 'total',
    name: {
      en: 'TOTAL'
    },
    draft: 0,
    submitted: 0,
    paid: 0,
    total: 0
  }
  registrationTypeCount.forEach((rt) => {
    totalRTC.draft += rt.draft
    totalRTC.submitted += rt.submitted
    totalRTC.paid += rt.paid
    totalRTC.total += rt.total
  })
  registrationTypeCount = registrationTypeCount.concat(totalRTC)
  return registrationTypeCount
}

const setFinisherShirtCount = ({
  finisherShirtCountLoading,
  finisherShirtCountData
}) => {
  if(!finisherShirtCountData || finisherShirtCountLoading) return {
    finisherShirtsIndex: null,
    finisherShirtSummary: null
  }
  const event = _get(finisherShirtCountData, 'event')
  const finisherShirtSummary = _get(event, 'finisherShirtCountBackEnd')
  return {
    finisherShirtSummary,
    finisherShirtsIndex: _get(event, 'finisherShirts')
  }
}
const setShirtCount = ({
  shirtCountLoading,
  shirtCountData
}) => {
  if(!shirtCountData || shirtCountLoading) return {
    shirtsIndex: null,
    shirtLast: null,
    shirtSummary: null,
    shirtSummaryNoTotal: null
  }
  const event = shirtCountData.event
  let shirtSummary = _get(event, 'shirtCountBackEnd')
  const shirts = _get(event, 'shirts')
  if (shirtSummary && shirts) {
    const shirtTotal = shirtSummary.reduce((init, current) => {
      const initShirts = _get(init, 'shirts')
      const currentShirts = _get(current, 'shirts')
      return {
        name: {
          th: 'Total',
          en: 'Total'
        },
        shirts: shirts.map(s => {
          const initShirt = initShirts && initShirts.find(iS => `${iS._id}` === `${s._id}`)
          const currentShirt = currentShirts && currentShirts.find(cS => `${cS._id}` === `${s._id}`)
          const initShirtCount = _get(initShirt, 'count') || 0
          const currentShirtCount = _get(currentShirt, 'count') || 0
          return {
            ...s,
            count: initShirtCount + currentShirtCount
          }
        })
      }
    }, {})
    shirtSummary = [...shirtSummary, shirtTotal]
  }
  return {
    shirtsIndex: shirts,
    shirtLast: _get(_last(shirtSummary), 'name.th'),
    shirtSummary,
    shirtSummaryNoTotal: _get(event, 'shirtCountBackEnd')
  }
}


export default compose(
  inject('router'),
  // graphql(eventStatisticsQuery, {
  //   options: ({
  //     match: {
  //       params: {
  //         eventId
  //       }
  //     }
  //   }) => ({
  //     variables: {
  //       eventId
  //     }
  //   })
  // }),
  // withApollo,
  // observer
)(EventStatistics)