import React, { Component } from 'react'
import { Table, Select, message, Button, Tag, Input, Modal, Layout, Icon } from 'antd'
import { graphql, Query } from 'react-apollo'
import { withTranslation } from 'react-i18next'
import orderSearch from '~/graphql/queries/orderSearch.gql'
import moment from 'moment-timezone'
import _get from 'lodash/get'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import _defaultTo from 'lodash/defaultTo'
import eventQuery from '~/graphql/queries/miniEvent.gql'
import extendOrderExpiryHoursMutation from '~/graphql/mutations/extendOrderExpiryByHours.gql'
import eventOrderItemsExportMutation from '~/graphql/mutations/eventOrderItemsExport.gql'
import { inject, observer } from 'mobx-react'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import {
  Link
} from 'react-router-dom'

import '~/styles/global.css'
import '~/styles/List.css'
import Loading from '../Loading/index'

const statusTagColors = {
  paid: 'green',
  submitted: 'gold',
  failed: 'gray',
  payment_pending: 'gold',
  payment_verifying: 'gold',
  cancelled: 'volcano',
  rejected: 'red',
  expired: 'gray'
}
const paymentStatusTagColors = {
  pending: 'orange',
  completed: 'green',
  failed: 'red'
}
@graphql(eventQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.eventId
    },
    fetchPolicy: 'network-only',
  }),
  props: ({ data: { loading, event } }) => ({
    loading: loading,
    event: event
  })
})
@inject('router')
@graphql(eventOrderItemsExportMutation, {
  name: 'eventOrderItemsExport'
})
@graphql(extendOrderExpiryHoursMutation, {
  name: 'extendOrderExpiryHours'
})
@observer
class List extends Component {
  state = {
    loading: false,
    isMobile: window.innerWidth < 750
  }
  handleStatusChange = (status) => {
    const { location } = this.props.router
    location.query = _omitBy({
      ...location.query,
      status,
      p: 1
    }, _isEmpty)
  }
  handleSearch = (search) => {
    const { location } = this.props.router
    location.query = _omitBy({
      ...location.query,
      search,
      p: 1
    }, _isEmpty)
  }
  handlePageChange = (page) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      p: page
    }
  }
  extendExpiry24h = (orderId) => {
    this.props.extendOrderExpiryHours({
      variables: { orderId }
    }).then(message.success(`successfully`))
  }
  handleRegistrationBankTransferModalOpen = registration => {
    this.setState({
      showBankTransferModal: true,
      currentRegistration: registration
    })
  }
  handleExport = () => {
    this.setState({ exporting: true })
    const eventId = this.props.match.params.eventId
    this.props.eventOrderItemsExport({
      variables: { _id: eventId }
    }).then(({ data, errors }) => {
      this.setState({ exporting: false })
      if (errors) {
        Modal.error({
          title: 'error',
          content: JSON.stringify(errors)
        })
        return
      }

      const url = _get(data, 'eventOrderItemsExport.url')
      const fileName = _get(data, 'eventOrderItemsExport.fileName')
      Modal.success({
        width: 500,
        iconType: 'file-excel',
        content: <a href={url} download>
          <Button type="primary" icon="download">{fileName}</Button>
        </a>,
        style: { textAlign: 'center' },
        okText: 'Close',
        okType: 'default'
      })
    }).catch(errors => {
      this.setState({ exporting: false })
      Modal.error({
        title: 'error',
        content: JSON.stringify(errors)
      })
    })
  }

  render () {
    const { router, event, loading, t } = this.props
    // console.log(this.props, 'orderprops')
    const { isMobile } = this.state
    const eventId = this.props.match.params.eventId
    const { status, search, p: page } = router.location.query
    const statuses = Object.keys(statusTagColors)
    if (loading) return <Loading />
    const statusList = <Select
      className="select-status"
      defaultValue={_get(router, 'location.query.status', '')}
      onChange={this.handleStatusChange}
    >
      <Select.Option key="all" value="">All Statuses</Select.Option>
      {statuses.map(status =>
        <Select.Option key={status} value={status}>
          {status}
        </Select.Option>
      )}
    </Select>
    const searchInput = <Input.Search
      className="search-event"
      placeholder={t('orders.search')}
      defaultValue={search}
      onSearch={this.handleSearch}
      enterButton />
    // const exportButton = ''
    // const exportButton = <Button
    //   className="export-btn"
    //   onClick={this.handleExport}
    //   icon="export"
    //   loading={this.state.exporting}>
    //     Export
    // </Button>

    return <Layout>
      <Layout.Content>
        <TopContent
            title={_get(event, 'name.th') || _get(event, 'name.th')}
            nodes={{
              SelectStatus: statusList,
              InputSearch: searchInput,
            }}
            pattern='registrations'/>
          <div className="innerMainContentWrapper">
            <EventSider />
              <div className="middleContent">
                <Query query={orderSearch} variables={{ eventId, status, search, page }} fetchPolicy="cache-and-network">
                  {({ data, loading }) => {
                    if (loading) {
                      return <Loading />
                    }
                    if (_get(data, 'error.networkError.type') === 'WriteError') {
                      window.location.reload()
                    }
                    const orderSearch = _get(data, 'orderSearch.items', [])
                    const pageInfo = _get(data, 'orderSearch.pageInfo', false)

                    if (!pageInfo) {
                      return <Loading />
                    }

                    const { currentPage, itemCount, perPage } = pageInfo
                    return <Table
                      dataSource={orderSearch}
                      rowKey="_id"
                      bordered
                      className="table-data"
                      pagination={{
                        defaultPageSize: perPage,
                        showQuickJumper: true,
                        current: currentPage,
                        total: itemCount,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        onChange: this.handlePageChange
                      }}
                    >
                      {!isMobile && <Table.Column
                        title="Code"
                        key="code"
                        width={238}
                        render={({_id, code, registrationId}) => (
                          <Link to={`/events/${eventId}/registrations/${registrationId}?orderId=${_id}`}>
                            {code}
                          </Link>)}
                      />}
                      <Table.Column
                        title={t("orders.product")}
                        key="items"
                        width={200}
                        render={({_id, code, registrationId, items, amount, status}) => <div>
                          {isMobile && <div><Link to={`/events/${eventId}/registrations/${registrationId}?orderId=${_id}`}>
                            {code}
                          </Link><hr/></div>}
                          {items && items.map(({_id, quantity, product, orderitemtype}) => {
                            const openorderitemtype = _get(product, 'openorderitemtype')
                            const price = openorderitemtype ? _get(orderitemtype, 'price') : _get(product, 'price') || 0
                            return <div key={_id}>
                              {_get(product, 'name.th')} <br/>
                              {openorderitemtype && <div>
                                <i>{_get(orderitemtype, 'name.th')}</i>
                              </div>}
                              {t("orders.amount")}: {quantity} <br/>
                              {t("orders.totalPrice")}: {quantity * price} <hr/>
                            </div>
                          })}
                          {isMobile && <div>
                            {items && t('menu.registration.price', {
                              value: {
                                price: items
                                .reduce((sum, item) => {
                                  const {quantity, product, orderitemtype} = item
                                  const openorderitemtype = _get(product, 'openorderitemtype')
                                  const price = openorderitemtype ? _get(orderitemtype, 'price') : _get(product, 'price') || 0
                                  return sum + (quantity * price)
                                }, 0),
                                currency: _defaultTo( _get(event, 'settings.payment.currency', 'THB'), 'THB')
                              },
                              })
                          }
                            <Tag color={statusTagColors[status]}>{status}</Tag>
                          </div>}
                        </div>
                        }
                      />
                      {!isMobile && <Table.Column
                        title={t("orders.total")}
                        dataIndex="items"
                        key="amount.total"
                        width={200}
                        render={(items) => <div>
                          {items && t('menu.registration.price', {
                              value: {
                                price: items
                                .reduce((sum, item) => {
                                  const {quantity, product, orderitemtype} = item
                                  const openorderitemtype = _get(product, 'openorderitemtype')
                                  const price = openorderitemtype ? _get(orderitemtype, 'price') : _get(product, 'price') || 0
                                  return sum + (quantity * price)
                                }, 0),
                                currency: _defaultTo( _get(event, 'settings.payment.currency', 'THB'), 'THB')
                              },
                              })
                          }
                        </div>}
                      />}
                      {!isMobile && <Table.Column
                        title={t("orders.status")}
                        dataIndex="status"
                        key="status"
                        width={200}
                        render={status => <Tag color={statusTagColors[status]}>{status}</Tag> }
                      />}
                      <Table.Column
                        title={t("orders.payment")}
                        dataIndex="payments"
                        key="payments"
                        render={payments =>
                          payments && payments.map(({method, status, _id}) => <Tag key={_id} color={paymentStatusTagColors[status]}>
                            {method}
                          </Tag>)}
                      />
                      <Table.Column
                        title={t("orders.action")}
                        key="action"
                        width={isMobile ? 110 : 200}
                        render={({_id, expireAt, status, registrationId}) => {
                          const expired = !expireAt || moment(expireAt).diff(moment()) <= 0
                          return <div>
                            {expired && status !== 'paid' && <Button type="danger" className="action"
                              onClick={() => this.extendExpiry24h(_id)}>
                                  Extend 24h
                            </Button>}
                            <Link to={`/events/${eventId}/registrations/${registrationId}?orderId=${_id}`}>
                              {!isMobile
                                ? <Button className="edit action" icon="edit">
                                      {t("orders.edit")}
                                </Button>
                                : <span><Icon type="edit" /> {t("orders.edit")}</span>}
                            </Link>
                          </div>
                        }}
                      />
                    </Table>
                  }}
                </Query>
              </div>
        </div>
      </Layout.Content>
    </Layout>
  }
}

export default withTranslation() (List)
