import React, { useState } from 'react'
import {
  Table
} from 'antd'
import { useTranslation } from 'react-i18next'
import _sumBy from 'lodash/sumBy'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import Shirts from '../Shirts'



const dataColors = ['#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',
//------------------------------------------------------------------------------
                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066',

                    '#B698DA', '#56C568', '#56C568', '#F6C654', '#F6C654',
                    '#E27973', '#E27973', '#5FC0BD', '#5FC0BD', '#6610F2',
                    '#6665DD', '#B697DA', '#FF99CC', '#F2F2F2', '#FF0066'
                    ] 

function ShirtTable (props) {
  const {data, param, shirtTypesMap, index, mode, loading} = props
  const { i18n } = useTranslation()
  const locale = i18n.language.split('-')[0]
  if (!data) return 'loading...'
  const shirts = _orderBy(data, 'index', 'asc').map((record, index)=>{
        record.color = dataColors[index] 
          return record
        })
  const shirtsTotal = _sumBy(shirts, 'count')
  const stockTotal = _sumBy(shirts, function(o) { return o.quantity === null ? Infinity : o.quantity}) 
  const remainTotal = stockTotal - shirtsTotal
 // Sum shirt by filter -------------------------------------

  const [sumShirtFilter, setSumShirtFilter] = useState(shirtsTotal)

  const onChangefilterShirt = (pagination, filters, sorter, extra) => {
    const dataShirtFilter = extra.currentDataSource
    const sumShirt = _sumBy(dataShirtFilter, 'count')
    setSumShirtFilter(sumShirt)
  }
  /*
  const filterShirtType = Object.keys(shirtsGroup).map((shirtTypeId) => ({
    text: `${shirtTypesMap[shirtTypeId]}`,
    value: shirtTypeId
  }))
  */ 
  // -------------------------------------

  const totalAllShirts = sumShirtFilter === 0 ? shirtsTotal : sumShirtFilter
  const shirtsGroup = _groupBy(data, 'shirtTypeId')
  const total = [{shirtsTotal,stockTotal,remainTotal}]
  const  shirtData =  total.map(({ shirtsTotal, stockTotal, remainTotal}) => ({
    count: shirtsTotal,
    quantity: stockTotal ,
    shirtRemain: remainTotal  
   }));
  const summaryShirts = shirts.concat(shirtData)
  const shirtsColumns = [
    {
      title: 'SHIRT NAME',
      dataIndex: `size.name[${locale}]`,
      key: `size.name.th`,
      className: 'th-white',
      render: (record, row) => { 
        var _style = {borderColor:'#EFEFEF'}
        if(row.count>0){ 
          _style = {borderColor:row.color}
        }
        //console.log("row",row)
        return <div class="div-inline div-top" >
          <div><div class="pin-circle" style={_style} >&nbsp;</div></div><div class="row-column-value">{record}</div>
        </div> 
      },
    },{
      title: 'COUNT',
      dataIndex: 'count',
      key: `shirtTypeCount`,
      className: 'th-white amount default center',      
    },
    {
      title: 'STOCK',
      dataIndex: 'quantity',
      key: `quantity`,
      className: 'th-white amount default center',   
      render: (value) => { 
        //console.log('record-vx2', value)
        return value === null ? <span>&infin;</span> : value === Infinity ? <span>&infin;</span> :value;//(record.count) ? 0 : 1
      }
    },{
      title: 'REMAINING',
      dataIndex: 'quantity',
      key: `shirtRemain`,
      className: 'th-white amount default center',
      render: (value, record) => { 
        //console.log('record-vx2', value)
        const text = value === null ?
          <span>&infin;</span> :
          value-(record.count) === Infinity ?
            <span>&infin;</span> : value-(record.count);
        return <>
          {_get(record, 'isFull') && <span style={{color: 'red'}}>
            ปิดขาย{' '}
          </span>}
          {text}
          
        </>
      }
    },
    
    
  ]
  return (
      <div>
      {true && <Table
        style={{whiteSpace: 'nowrap'}}
        scroll={{ x: true }}
        columns={shirtsColumns}
        className={"eventFgma " + (param.isMobile ? "mobile " : "") + mode  }
        dataSource={summaryShirts}
        loading={loading}
        locale={{emptyText: shirtsTotal ? <div style={{fontSize:"10px"}}>...</div> : <div style={{fontSize:"10px"}}>NO DATA</div>},{emptyText: stockTotal ? <div style={{fontSize:"10px"}}>...</div> : <div style={{fontSize:"10px"}}>NO DATA</div>}}
        rowKey={record => record._id}
        pagination={false}
        onChange={onChangefilterShirt}
      />
      }
    </div>
    
  )
}

export default ShirtTable

