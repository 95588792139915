import React from 'react'
import {
    //Collapse, 
    Card, 
    Switch
} from 'antd'

//import ApaxChart from 'apexcharts'
import RApaxChart from "react-apexcharts";

import _orderBy from 'lodash/orderBy'

//const Panel = Collapse.Panel
//const Option = Select.Option
const { Meta } = Card

const limit = 10

const GraphApexPartcp = ({param}) => {

    let _datasource = param.datasource
    let _provinceList = param.tableEvent['proviceList'] === undefined ? true : param.tableEvent['proviceList']   

    const val = {"row":[], "label":[], "value":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,  
                }


    var dataRow = 0 
    var graphRender = false
                //console.log( "datasource",_datasource );
                //console.log( "provinceList",_provinceList );
    if(_datasource === null){}else{ 

        _datasource = _provinceList ? _orderBy(_datasource, 'count', 'desc') : _orderBy(_datasource, 'province', 'asc');   
  
        for(const [keyi, valuei] of Object.entries(_datasource))
            if( !(valuei.province==='unknow') ){
                dataRow++
                val.value[keyi] = 0
                val.label[keyi] = valuei.province
                val.chart.bar.bgcolor[keyi] = '#efefef'

                if(val.max < valuei.count){
                    val.max = valuei.count    
                } 

                val.row[keyi] = keyi
                val.value[keyi] = valuei.count 
                //if( dataRow>8 ) break

                if( _provinceList && dataRow>=limit ) break
        }

        if( dataRow ) graphRender = true
           
    }

    const setMax  = (value) =>{
        var val = value
        const valArr = val.toString().split('')

        var _valArr = []

        valArr.forEach(function(val,index,array){
            array[index]    = parseInt(val, 10)
            _valArr[index]  = 0
        })

        if(value < 9){
            val = 20    
        }else if(value < 70){
            val = 80               
        }else if(value < 99){
            val = 120
        }else if(value < 500){
            val = 500 + (valArr[1] > 5 ? 100 : 0)            
        }else if(value < 999){
            val = 1000 + (valArr[1] > 8 ? 200 : 0) 
        }else{          
            _valArr[1] = parseInt(valArr[1], 10) + (valArr[2] >= 6 ? 2 : 1)                         
            _valArr[0] = parseInt(valArr[0], 10) + (_valArr[1] >= 8 ? 1 : 0) 

            _valArr[1] = _valArr[1] > 9 ? 0 : _valArr[1]  

            val = parseInt(_valArr.join(''), 10)
        }

        return(val)    
    }

    const graphhight  = (row) =>{
        var height = 110
        if(row > 1)
            height = 60 + (row * 35) 

        return(height)    
    }
  
    const valueList = val    
 
    const apexParticipantByMonth = {
            options: {
                chart: {
                    id: "bar",
                    toolbar: {
                        show: false,
                    },                    
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        startingShape: 'rounded',
                        endingShape: 'rounded',
                        columnWidth: '10%',
                        barHeight: '20px',
                        distributed: false,
                        rangeBarOverlap: true,
                        rangeBarGroupRows: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: undefined
                            }],
                            backgroundBarColors: val.chart.bar.bgcolor,
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 5,
                        },
                    }
                },
                xaxis: {                   
                    categories: valueList.label, 
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    lines: {
                        show: false
                    },      
                    axisTicks: {
                        show: false
                    },       
                    labels:{
                        show: (param.isMobile ? false : true),                         
                        style:{
                            colors: ['#B1B1B1']
                        }
                    }         
                },
                yaxis: {
                    min: 0,
                    max: setMax( val.max ),  
                    labels:{
                        style:{
                            colors: '#B1B1B1'
                        }
                    }                      
                },
                legend:{
                    show: false
                },
                grid: {
                    yaxis: {
                      lines: {
                        show: false
                      }
                    }
                }
            },
            series: [
                {
                name: "Number",
                data: valueList.value, 
                color: '#5343EC'
                }
            ]
    }

    //console.log("datasource", datasource);
    //console.log("graph-data", valueList);
    //console.log("max", val.max + ' ' + setMax( val.max ));

    return (
                <Meta
                    description={<div className="" >
                    {graphRender && <div style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Switch checkedChildren="TOP 10" unCheckedChildren="ALL" onChange={param.eventHandle} defaultChecked /> 
                    </div>}
                    {graphRender && <div>
                    <RApaxChart
                        options={apexParticipantByMonth.options}
                        series={apexParticipantByMonth.series}
                        type= "bar"
                        width= "100%"
                        height= {graphhight(dataRow)}
                    /></div>} 

                    {!graphRender && <div className="event-gray" style={{textAlign:'center'}}>NO DATA</div>}
                                              
                    </div>}
                />
    )


}

export default GraphApexPartcp
