import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { Form,
  Button,
  message,
  Layout,
  InputNumber,
  DatePicker,
  Input,
} from 'antd'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import eventWithdrawRequestQuery from '~/graphql/queries/eventWithdrawRequest.gql'
import eventQuery from '~/graphql/queries/miniEvent.gql'
import createEventWithdrawRequestMutation from '~/graphql/mutations/createEventWithdrawRequest.gql'
import updateEventWithdrawRequestMutation from '~/graphql/mutations/updateEventWithdrawRequest.gql'
import eventWithdrawRequestPrefieldQuery from '~/graphql/queries/eventWithdrawRequestPrefield.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
// import EventWithdrawRequestNotFound from '~/components/Error/EventWithdrawRequestnotfound'
import { inject, observer } from 'mobx-react'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'

const { TextArea } = Input
const dateFormat = 'DD/MM/YYYY'
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}
@inject('router')
@observer
@Form.create()
@withRepeaterHandler
@graphql(createEventWithdrawRequestMutation, { name: 'createEventWithdrawRequest' })
@graphql(updateEventWithdrawRequestMutation, { name: 'updateEventWithdrawRequest' })
@graphql(eventWithdrawRequestQuery, { // for edit
  options: ({ match: { params: { _id, eventId } } }) => ({
    variables: { _id, eventId },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, eventWithdrawRequest } }) => ({
    eventWithdrawRequestLoading: loading,
    eventWithdrawRequest: eventWithdrawRequest
  }),
  skip: ({ match: { params: { _id } } }) => !_id
})
@graphql(eventQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.eventId
    }
  }),
  props: ({ data: { loading, event } }) => ({
    eventLoading: loading,
    event: event
  })
})
@graphql(eventWithdrawRequestPrefieldQuery, {
  options: ({ match: { params: { eventId } } }) => ({
    variables: { eventId },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, eventWithdrawRequest } }) => ({
    eventWithdrawRequestPrefieldLoading: loading,
    eventWithdrawRequestPrefield: eventWithdrawRequest
  })
})
class EventWithdrawRequestForm extends Component {
  state = {
    loading: false
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const eventWithdrawRequestId = _get(this.props, 'match.params._id')
    const eventId = _get(this.props, 'match.params.eventId')
    this.props.form.validateFieldsAndScroll((err, record) => {
      if (err) {
        message.error(err)
        this.setState({ loading: false })
        return
      }
      if (eventWithdrawRequestId) {
        this.props.updateEventWithdrawRequest({
          variables: {
            record
          }
        }).then(result => {
          message.success('Saved!')
          this.setState({ loading: false })
        }).catch(err => {
          message.error(err.message)
          this.setState({ loading: false })
          this.props.history.push(`/fetchPage?link=/events/${eventId}/withdraw-requests/${eventWithdrawRequestId}/edit`)
        })
      } else {
        this.props.createEventWithdrawRequest({
          variables: {
            record
          }
        }).then(result => {
          message.success('Saved!')
          this.setState({ loading: false })
          this.props.history.push(`/events/${eventId}/withdraw-requests`)
        }).catch(err => {
          message.error(err.message)
          this.setState({ loading: false })
        })
      }
    })
  }
  render () {
    const { event, eventLoading, eventWithdrawRequest, eventWithdrawRequestPrefield, t } = this.props
    if (eventLoading) return <Loading />
    if (!event) return <EventNotFound />
    const eventWithdrawRequestId = _get(this.props, 'match.params._id')
    if (!eventWithdrawRequest && eventWithdrawRequestId) return ''
    const { getFieldDecorator } = this.props.form
    getFieldDecorator(`eventId`, {
      initialValue: _get(event, '_id')
    })
    getFieldDecorator(`_id`, {
      initialValue: _get(eventWithdrawRequest, '_id')
    })
    return (<div style={{margin: '10px'}}>
      <Layout style={{maxWidth: '1000px', margin: 'auto'}} >
        <EventSider />
        <Layout.Content
          style={{background: '#fff',
            padding: 24,
            minHeight: 280}}
        >
          <div style={{marginLeft: '20px', padding: '6px', fontSize: '2em'}}>
            {event.name.en}
          </div>
          <Form>
            <Form.Item
              {...formItemLayout}
              label={t('transfer.date')}
              hasFeedback
            >
              {getFieldDecorator('createdAt', {
                initialValue: moment(_get(eventWithdrawRequest, 'createdAt'))
              })(
                <DatePicker
                  format={dateFormat}
                  showTime="true"
                />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}>
              <span style={{fontSize: '18px'}}>
                {eventWithdrawRequestId ? `${t('transfer.edit')}` : `${t('transfer.create')}`}  {t('transfer.add')}
              </span>
            </Form.Item>
            {eventWithdrawRequestId && <Form.Item label='วันที่' {...formItemLayout}>
              <span style={{fontSize: '18px'}}>
                {moment(eventWithdrawRequest.createdAt).locale('th').format('L')}
              </span>
            </Form.Item>}
            <Form.Item label={t('transfer.withdraw.heading')} {...formItemLayout}>
              {getFieldDecorator(`editName`, {
                initialValue: _get(eventWithdrawRequest, 'editName') || _get(eventWithdrawRequestPrefield, 'editName'),
                // rules: [{ required: true, message: 'Please input quotationNumber' }]
              })(<Input/>)}
            </Form.Item>
            <Form.Item label={t('transfer.withdraw.amount')} {...formItemLayout}>
              {getFieldDecorator(`amount`, {
                initialValue: _get(eventWithdrawRequest, 'amount'),
                rules: [{ required: true, message: 'Please input amount' }]
              })(<InputNumber/>)}
            </Form.Item>
            <Form.Item label={t('transfer.withdraw.quotation')} {...formItemLayout}>
                {getFieldDecorator(`quotationNumber`, {
                  initialValue: _get(eventWithdrawRequest, 'quotationNumber') || _get(eventWithdrawRequestPrefield, 'quotationNumber'),
                  rules: [{ required: true, message: 'Please input quotationNumber' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={t('transfer.withdraw.account')} {...formItemLayout}>
                {getFieldDecorator(`bankAccountName`, {
                  initialValue: _get(eventWithdrawRequest, 'bankAccountName') || _get(eventWithdrawRequestPrefield, 'bankAccountName'),
                  rules: [{ required: true, message: 'Please input bankAccountName' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={t('transfer.withdraw.number')} {...formItemLayout}>
                {getFieldDecorator(`bankAccountNumber`, {
                   initialValue: _get(eventWithdrawRequest, 'bankAccountNumber') || _get(eventWithdrawRequestPrefield, 'bankAccountNumber'),
                  rules: [{ required: true, message: 'Please input bankAccountNumber' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={t('transfer.withdraw.bank')} {...formItemLayout}>
                {getFieldDecorator(`bankAccountBrand`, {
                  initialValue: _get(eventWithdrawRequest, 'bankAccountBrand') || _get(eventWithdrawRequestPrefield, 'bankAccountBrand'),
                  rules: [{ required: true, message: 'Please input bankAccountBrand' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={t('transfer.withdraw.salesPerson')} {...formItemLayout}>
                {getFieldDecorator(`salesPersonName`, {
                  initialValue: _get(eventWithdrawRequest, 'salesPersonName') || _get(eventWithdrawRequestPrefield, 'salesPersonName'),
                  rules: [{ required: true, message: 'Please input salesPersonName' }]
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={t('transfer.withdraw.remark')} {...formItemLayout}>
                {getFieldDecorator(`remarks`, {
                  initialValue: _get(eventWithdrawRequest, 'remarks') || _get(eventWithdrawRequestPrefield, 'remarks'),
                  rules: [{ required: true, message: 'Please input remarks' }]
                })(<TextArea/>)}
              </Form.Item>
            <Form.Item style={{textAlign: 'center'}}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                {t('transfer.withdraw.save')}
              </Button>{' '}
              <Button loading={this.state.loading} type="ghost" onClick={() => window.history.back()}>
                {t('transfer.withdraw.back')}
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>
      </Layout>
    </div>)
  }
}

export default withTranslation() (EventWithdrawRequestForm)
