import React, { Component } from 'react'
import { withApollo, graphql } from 'react-apollo'
import {
  Modal,
  Layout,
  DatePicker,
  message,
  Button,
  Form,
  Row,
  Col
} from 'antd'
import moment from 'moment-timezone'
import eventQuery from '~/graphql/queries/event.gql'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import Registrations from './Registrations'
import Summary from './Summary'
import DeliverySummary from './DeliverySummary'
import Orders from './Orders'
import CouponCodeTypes from './CouponCodeTypes'
import PaymentStatistics from './PaymentStatistics'
import OrganizerDailySlips from './OrganizerDailySlips'
// import RefundRequests from './RefundRequests'
import CancellationRequests from './CancellationRequests'
import Runs from './Runs'
import DownloadSlip from './DownloadSlip'
import DownloadEbib from './DownloadEbib'
import DownloadEcer from './DownloadEcer'
import DowloadRiskScreen from './DowloadRiskScreen'
import clientAuth from '~/utils/client-auth'
import _includes from 'lodash/includes'
import promiseRetry from 'promise-retry'
import downloadQuery from '~/graphql/queries/download.gql'
import TopContent from '~/components/Events/Event/TopContent'
import eventRegistrationExportMutation from '~/graphql/mutations/eventRegistrationExport.gql'
import eventSummaryExportMutation from '~/graphql/mutations/eventSummaryExport.gql'
import eventRiskScreenExportMutation from '~/graphql/mutations/eventRiskScreenExport.gql'
import eventDeliverySummaryExportMutation from '~/graphql/mutations/eventDeliverySummaryExport.gql'
import eventPaidSummaryExportMutation from '~/graphql/mutations/eventPaidSummaryExport.gql'
import eventOrderItemsExportMutation from '~/graphql/mutations/eventOrderItemsExport.gql'
import eventCouponCodeExportMutation from '~/graphql/mutations/eventCouponCodeExport.gql'
import eventRefundExportMutation from '~/graphql/mutations/eventRefundExport.gql'
import eventCancellationExportMutation from '~/graphql/mutations/eventCancellationExport.gql'
import eventRunExportMutation from '~/graphql/mutations/eventRunExport.gql'
import eventExpandRunExportMutation from '~/graphql/mutations/eventExpandRunExport.gql'
import eventPaymentStatisticsExportMutation from '~/graphql/mutations/eventPaymentStatisticsExport.gql'
import eventSummarySimpleExportMutation from '~/graphql/mutations/eventSummarySimpleExport.gql'
const {RangePicker} = DatePicker
const dateFormat = 'DD/MM/YYYY HH:mm:ss'
@inject('router')
@graphql(eventQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.eventId
    }
  }),
  props: ({ data: { loading, event } }) => ({
    loading: loading,
    event: event
  })
})
@withApollo
@observer
@Form.create()
class EventSummary extends Component {
  state = {
    exporting: false,
    config: {rules: [{ type: 'array', required: true, message: 'Please select a Date!' }]},
    OrderVisible: false,
    RegistraionVisible: false,
    SummaryVisible: false,
    ExportDateExpandRunsVisible: false,
    DeliverySummaryVisible: false
  }
  handleCancel = () => {
    this.setState({
      OrderVisible: false, RegistraionVisible: false,
      SummaryVisible: false, DeliverySummaryVisible: false,
      ExportDateExpandRunsVisible: false
    })
  }
  onDatePickRegistration = async (status) => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return 0
      } else {
        const dates = fieldsValue['range-picker']
        this.setState({ exporting: true })
        const eventId = _get(this.props.match.params, 'eventId')
        if (eventId) {
          try {
            const { data, errors } = await this.props.client.mutate({
              mutation: eventRegistrationExportMutation,
              variables: {
                status,
                _id: eventId,
                startDate: dates[0],
                endDate: dates[1]
              }
            })
            if (errors) {
              // TODO: throw?
              errors.forEach(error => message.error(error.message))
              this.setState({ exporting: false })
              return
            }
            const url = _get(data, 'newEventRegistrationExport.url')
            const fileName = _get(data, 'newEventRegistrationExport.fileName')
            if (url) {
              Modal.success({
                width: 500,
                icon: 'file-excel',
                content: <a href={url} download>
                  <Button type="primary" icon="download">{fileName}</Button>
                </a>,
                style: { textAlign: 'center' },
                okText: 'Close',
                okType: 'default'
              })
            }
          } catch (err) {
            message.error(err.message)
          } finally {
            this.setState({ exporting: false })
          }
        }
      }
    })
  }
  handleExportAllRegistrations = async (status) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRegistrationExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'newEventRegistrationExport.url')
        const fileName = _get(data, 'newEventRegistrationExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportRegistrations = async () => {
    this.setState({RegistraionVisible: true})
  }
  handleExportSummary = async () => {
    this.setState({SummaryVisible: true})
  }
  handleExportDateExpandRuns = async () => {
    this.setState({ExportDateExpandRunsVisible: true})
  }
  handleExportDeliverySummary = async () => {
    this.setState({DeliverySummaryVisible: true})
  }
  onDatePickSummary = async (status) => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return 0
      } else {
        const dates = fieldsValue['range-picker']
        this.setState({ exporting: true })
        const eventId = _get(this.props.match.params, 'eventId')
        if (eventId) {
          try {
            const { data, errors } = await this.props.client.mutate({
              mutation: eventSummaryExportMutation,
              variables: {
                status,
                _id: eventId,
                startDate: dates[0],
                endDate: dates[1]
              }
            })
            if (errors) {
              errors.forEach(error => message.error(error.message))
              this.setState({ exporting: false })
            }

            const downloadId = _get(data, 'eventSummaryExport.downloadId')
            const download = await promiseRetry(async (retry, number) => {
              if (number > 1) console.log('attemp=> ', number)
              const { data, errors } = await this.props.client.query({
                query: downloadQuery,
                fetchPolicy: 'network-only',
                variables: {
                  _id: downloadId
                }
              })
              if (errors) {
                errors.forEach(error => message.error(error.message))
                this.setState({ exporting: false })
                return
              }
              const progressPercentage = _get(data, 'download.progressPercentage')
              if (progressPercentage < 100) {
                console.log({progressPercentage})
                await retry()
              } else {
                console.log({progressPercentage})
              }
              return _get(data, 'download')
            }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
            // console.log({download})
            const url = _get(download, 'url')
            const fileName = _get(download, 'fileName')

            // const url = _get(data, 'eventSummaryExport.url')
            // const fileName = _get(data, 'eventSummaryExport.fileName')
            if (url) {
              Modal.success({
                width: 500,
                icon: 'file-excel',
                content: <a href={url} download>
                  <Button type="primary" icon="download">{fileName}</Button>
                </a>,
                style: { textAlign: 'center' },
                okText: 'Close',
                okType: 'default'
              })
            }
          } catch (err) {
            message.error(err.message)
          } finally {
            this.setState({ exporting: false })
          }
        }
      }
    })
  }
  onDatePickDeliverySummary = async (status) => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return 0
      } else {
        const dates = fieldsValue['range-picker']
        this.setState({ exporting: true })
        const eventId = _get(this.props.match.params, 'eventId')
        if (eventId) {
          try {
            const { data, errors } = await this.props.client.mutate({
              mutation: eventDeliverySummaryExportMutation,
              variables: {
                status,
                _id: eventId,
                startDate: dates[0],
                endDate: dates[1]
              }
            })
            if (errors) {
              errors.forEach(error => message.error(error.message))
              this.setState({ exporting: false })
              return
            }

            const downloadId = _get(data, 'eventDeliverySummaryExport.downloadId')
            const download = await promiseRetry(async (retry, number) => {
              if (number > 1) console.log('attemp=> ', number)
              const { data, errors } = await this.props.client.query({
                query: downloadQuery,
                fetchPolicy: 'network-only',
                variables: {
                  _id: downloadId
                }
              })
              if (errors) {
                errors.forEach(error => message.error(error.message))
                this.setState({ exporting: false })
                return
              }
              const progressPercentage = _get(data, 'download.progressPercentage')
              if (progressPercentage < 100) {
                console.log({progressPercentage})
                await retry()
              } else {
                console.log({progressPercentage})
              }
              return _get(data, 'download')
            }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
            // console.log({download})
            const url = _get(download, 'url')
            const fileName = _get(download, 'fileName')

            // const url = _get(data, 'eventSummaryExport.url')
            // const fileName = _get(data, 'eventSummaryExport.fileName')
            if (url) {
              Modal.success({
                width: 500,
                icon: 'file-excel',
                content: <a href={url} download>
                  <Button type="primary" icon="download">{fileName}</Button>
                </a>,
                style: { textAlign: 'center' },
                okText: 'Close',
                okType: 'default'
              })
            }
          } catch (err) {
            message.error(err.message)
          } finally {
            this.setState({ exporting: false })
          }
        }
      }
    })
  }
  onDateExpandRuns = async () => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return 0
      } else {
        const dates = fieldsValue['range-picker']
        this.setState({ exporting: true })
        const eventId = _get(this.props.match.params, 'eventId')
        if (eventId) {
          try {
            const { data, errors } = await this.props.client.mutate({
              mutation: eventExpandRunExportMutation,
              variables: {
                _id: eventId,
                startDate: dates[0],
                endDate: dates[1]
              }
            })
            if (errors) {
              // TODO: throw?
              errors.forEach(error => message.error(error.message))
              this.setState({ exporting: false })
              return
            }
            const url = _get(data, 'eventExpandRunExport.url')
            const fileName = _get(data, 'eventExpandRunExport.fileName')
    
            if (url) {
              Modal.success({
                width: 500,
                icon: 'file-excel',
                content: <a href={url} download>
                  <Button type="primary" icon="download">{fileName}</Button>
                </a>,
                style: { textAlign: 'center' },
                okText: 'Close',
                okType: 'default'
              })
            }
          } catch (err) {
            message.error(err.message)
          } finally {
            this.setState({ exporting: false })
          }
        }
      }
    })
  }
  handleExportAllSummary = async (status) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventSummaryExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const downloadId = _get(data, 'eventSummaryExport.downloadId')
        const download = await promiseRetry(async (retry, number) => {
          if (number > 1) console.log('attemp=> ', number)
          const { data, errors } = await this.props.client.query({
            query: downloadQuery,
            fetchPolicy: 'network-only',
            variables: {
              _id: downloadId
            }
          })
          if (errors) {
            errors.forEach(error => message.error(error.message))
            this.setState({ exporting: false })
            return
          }
          const progressPercentage = _get(data, 'download.progressPercentage')
          if (progressPercentage < 100) {
            console.log({progressPercentage})
            await retry()
          } else {
            console.log({progressPercentage})
          }
          return _get(data, 'download')
        }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
        // console.log({download})
        const url = _get(download, 'url')
        const fileName = _get(download, 'fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportRiskScreen = async (status) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRiskScreenExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const downloadId = _get(data, 'eventRiskScreenExport.downloadId')
        const download = await promiseRetry(async (retry, number) => {
          if (number > 1) console.log('attemp=> ', number)
          const { data, errors } = await this.props.client.query({
            query: downloadQuery,
            fetchPolicy: 'network-only',
            variables: {
              _id: downloadId
            }
          })
          if (errors) {
            errors.forEach(error => message.error(error.message))
            this.setState({ exporting: false })
            return
          }
          const progressPercentage = _get(data, 'download.progressPercentage')
          if (progressPercentage < 100) {
            console.log({progressPercentage})
            await retry()
          } else {
            console.log({progressPercentage})
          }
          return _get(data, 'download')
        }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
        // console.log({download})
        const url = _get(download, 'url')
        const fileName = _get(download, 'fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleSimpleExportAllSummary = async (status) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventSummarySimpleExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const downloadId = _get(data, 'eventSummarySimpleExport.downloadId')
        const download = await promiseRetry(async (retry, number) => {
          if (number > 1) console.log('attemp=> ', number)
          const { data, errors } = await this.props.client.query({
            query: downloadQuery,
            fetchPolicy: 'network-only',
            variables: {
              _id: downloadId
            }
          })
          if (errors) {
            errors.forEach(error => message.error(error.message))
            this.setState({ exporting: false })
            return
          }
          const progressPercentage = _get(data, 'download.progressPercentage')
          if (progressPercentage < 100) {
            console.log({progressPercentage})
            await retry()
          } else {
            console.log({progressPercentage})
          }
          return _get(data, 'download')
        }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
        // console.log({download})
        const url = _get(download, 'url')
        const fileName = _get(download, 'fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportAllDeliverySummary = async (status) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventDeliverySummaryExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const downloadId = _get(data, 'eventDeliverySummaryExport.downloadId')
        const download = await promiseRetry(async (retry, number) => {
          if (number > 1) console.log('attemp=> ', number)
          const { data, errors } = await this.props.client.query({
            query: downloadQuery,
            fetchPolicy: 'network-only',
            variables: {
              _id: downloadId
            }
          })
          if (errors) {
            errors.forEach(error => message.error(error.message))
            this.setState({ exporting: false })
            return
          }
          const progressPercentage = _get(data, 'download.progressPercentage')
          if (progressPercentage < 100) {
            console.log({progressPercentage})
            await retry()
          } else {
            console.log({progressPercentage})
          }
          return _get(data, 'download')
        }, { retries: 300, minTimeout: 900, maxTimeout: 2000 })
        // console.log({download})
        const url = _get(download, 'url')
        const fileName = _get(download, 'fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handlePaidExportSummary = async () => {
    this.setState({ exporting: true })
    const eventId = this.props.match.params.eventId
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventPaidSummaryExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventPaidSummaryExport.url')
        const fileName = _get(data, 'eventPaidSummaryExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportPaymentStatistics = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventPaymentStatisticsExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventPaymentStatisticsExport.url')
        const fileName = _get(data, 'eventPaymentStatisticsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportAllStatistics = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventPaymentStatisticsExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventPaymentStatisticsExport.url')
        const fileName = _get(data, 'eventPaymentStatisticsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportOrders = () => {
    this.setState({OrderVisible: true})
  }
  onDatePickOrders = async (status) => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return 0
      } else {
        const dates = fieldsValue['range-picker']
        this.setState({ exporting: true })
        const eventId = _get(this.props.match.params, 'eventId')
        if (eventId) {
          try {
            const { data, errors } = await this.props.client.mutate({
              mutation: eventOrderItemsExportMutation,
              variables: {
                status,
                _id: eventId,
                startDate: dates[0],
                endDate: dates[1]
              }
            })
            if (errors) {
              errors.forEach(error => message.error(error.message))
              this.setState({ exporting: false })
              return
            }
            const url = _get(data, 'eventOrderItemsExport.url')
            const fileName = _get(data, 'eventOrderItemsExport.fileName')
            if (url) {
              Modal.success({
                width: 500,
                icon: 'file-excel',
                className: 'export-modal',
                content: <a href={url} download>
                  <Button type="primary" icon="download"
                    style={{maxWidth: '250px', overflow: 'hidden'}}
                  >
                    {fileName}
                  </Button>
                </a>,
                style: { textAlign: 'center' },
                okText: 'Close',
                okType: 'default'
              })
            }
          } catch (err) {
            message.error(err.message)
          } finally {
            this.setState({ exporting: false })
          }
        }
      }
    })
  }
  handleExportAllOrders = async (status) => {
    // this.props.form.validateFields(async (err, fieldsValue) => {
    // if (err) {
    //   return 0
    // } else {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventOrderItemsExportMutation,
          variables: {
            status,
            _id: eventId
          }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventOrderItemsExport.url')
        const fileName = _get(data, 'eventOrderItemsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
    // }
    // })
  }
  handleExportCouponCodeTypes = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventCouponCodeExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventCouponCodeExport.url')
        const fileName = _get(data, 'eventCouponCodeExport.fileName')

        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportRefundRequests = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRefundExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventRefundExport.url')
        const fileName = _get(data, 'eventRefundExport.fileName')

        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }

  handleExportCancellationRequests = async (dates) => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventCancellationExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventCancellationExport.url')
        const fileName = _get(data, 'eventCancellationExport.fileName')

        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportRuns = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRunExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventRunExport.url')
        const fileName = _get(data, 'eventRunExport.fileName')

        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleExportExpandRuns = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventExpandRunExportMutation,
          variables: {
            _id: eventId
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventExpandRunExport.url')
        const fileName = _get(data, 'eventExpandRunExport.fileName')

        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  render () {
    const { getFieldDecorator } = this.props.form
    const { loading, event, t } = this.props
    const { eventId } = this.props.match.params
    const {roles} = clientAuth.login()
    const getTicketTypes = _get(event,'ticketTypes')
    const getTransferMethod = _get(event,'settings.payment.methods')
    const ecerV2 = _get(event,'settings.virtualRun.ecerV2')
    const TransferMethodEnableTrue = _find(getTransferMethod,{ 'method': 'transfer', 'enabled': true })
    const getEbibEnable = _find(getTicketTypes,['eBibEnabled',true])
    if (loading) {
      return <div>Loading...</div>
    } else {
      const isLineLogin = _get(event, 'isLineLogin')
      const runDistanceMode = _get(event, 'settings.virtualRun.distanceMode')
      return (
        <Layout>
          <Layout.Content>
          <TopContent
            title={_get(event, 'name.th') || _get(event, 'name.en')}/>
          <div className="innerMainContentWrapper">
            <EventSider />
            <div className="middleContent">
              <div 
                className='no-print'
                style={{padding: '6px', marginLeft: '20px', marginBottom: '20px', fontSize: '1.5em'}}>
              {t('download.title')}
              </div>
              <Summary eventId={eventId} onExport={() => this.handleExportAllSummary('all')} onPaidExport={() => this.handleExportAllSummary('paid')} onDateExport={() => this.handleExportSummary()} onPaidSimpleExport={() => this.handleSimpleExportAllSummary('paid')} exporting={this.state.exporting}/>
              {_get(event, 'isPropertyShirtCount') &&
                <DeliverySummary eventId={eventId} onExport={() => this.handleExportAllDeliverySummary('all')} onPaidExport={() => this.handleExportAllDeliverySummary('paid')} onDateExport={() => this.handleExportDeliverySummary()} exporting={this.state.exporting}/>
              }
              {!isLineLogin && <Registrations eventId={eventId} onExport={() => this.handleExportAllRegistrations('all')} onPaidExport={() => this.handleExportAllRegistrations('paid')} onDateExport = {() => this.handleExportRegistrations()} exporting={this.state.exporting}/>}
              {/* {!isLineLogin && <Orders eventId={eventId} onExport={() => this.handleExportAllOrders('all')} onPaidExport={() => this.handleExportAllOrders('paid')} onDateExport={() => this.handleExportOrders()} exporting={this.state.exporting}/>} */}
              {true && <Orders eventId={eventId} onExport={() => this.handleExportAllOrders('all')} onPaidExport={() => this.handleExportAllOrders('paid')} onDateExport={() => this.handleExportOrders()} exporting={this.state.exporting}/>}
              <CouponCodeTypes eventId={eventId} onExport={this.handleExportCouponCodeTypes} exporting={this.state.exporting}/>
              {/* <RefundRequests eventId={eventId} onExport={this.handleExportRefundRequests} exporting={this.state.exporting}/> */}
              {!isLineLogin && <CancellationRequests eventId={eventId} onExport={this.handleExportCancellationRequests} exporting={this.state.exporting}/>}
              <Runs
                runDistanceMode={runDistanceMode}
                eventId={eventId}
                onExport={this.handleExportRuns}
                onDateExport={() => this.handleExportDateExpandRuns()}
                onExpandExport={this.handleExportExpandRuns} exporting={this.state.exporting}/>
              {_includes(roles, 'admin') && !isLineLogin &&
                <PaymentStatistics eventId={eventId} onExport={this.handleExportPaymentStatistics} exporting={this.state.exporting}/>
              }{_includes(roles, 'admin') &&
                <DownloadSlip eventId={eventId} TransferMethodEnableTrue={TransferMethodEnableTrue} />
              }{_includes(roles, 'admin') &&
              <DownloadEbib eventId={eventId} getEbibEnable={getEbibEnable} />
              }{_includes(roles, 'admin') &&
              <DownloadEcer eventId={eventId} ecerV2={ecerV2} />
              }
              {_includes(roles, 'admin') &&
              <OrganizerDailySlips {...{event}}/>
              }
              <DowloadRiskScreen eventId={eventId} onPaidRiskScreenExport={() => this.handleExportRiskScreen('paid')} exporting={this.state.exporting}/>
            </div>
            </div>
          </Layout.Content>
          <Modal
            visible={this.state.OrderVisible}
            title="Order Export"
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
            Return
              </Button>,
              <Button type="primary" onClick={() => this.onDatePickOrders('paid')}>
            Ok
              </Button>
            ]}>
            <Form onSubmit={() => this.onDatePickOrders('paid')}>
              <Form.Item>
                {getFieldDecorator('range-picker', this.state.config)(<RangePicker format={dateFormat} showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}/>)}
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={this.state.ExportDateExpandRunsVisible}
            title="Run Export"
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel} loading={this.state.exporting}>
            Return
              </Button>,
              <Button type="primary" onClick={this.onDateExpandRuns} loading={this.state.exporting}>
            Ok
              </Button>
            ]}>
            <Form onSubmit={this.onDateExpandRuns}>
              <Form.Item>
                {getFieldDecorator('range-picker', {
                  ...this.state.config,
                  initialValue: [moment(_get(event, 'settings.virtualRun.period.start')), moment(_get(event, 'settings.virtualRun.period.end'))]
                  })(<RangePicker
                    format={dateFormat}
                  />)}
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={this.state.RegistraionVisible}
            title="Registration Export"
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
            Return
              </Button>,
              <Button type="primary" onClick={() => this.onDatePickRegistration('paid')}>
            Ok
              </Button>
            ]}>
            <Form onSubmit={() => this.onDatePickRegistration('paid')}>
              <Form.Item>
                {getFieldDecorator('range-picker', this.state.config)(<RangePicker format={dateFormat} showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}/>)}
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={this.state.SummaryVisible}
            title="Summary Export"
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel} loading={this.state.exporting}>
            Return
              </Button>,
              <Button type="primary" onClick={() => this.onDatePickSummary('paid')} loading={this.state.exporting}>
            Ok
              </Button>
            ]}>
            เวลาจากวันที่จ่ายสำเร็จ (payment_date)
            <Form onSubmit={() => this.onDatePickSummary('paid')}>
              <Form.Item>
                {getFieldDecorator('range-picker', this.state.config)(<RangePicker format={dateFormat} showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}/>)}
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={this.state.DeliverySummaryVisible}
            title="Delivery Summary Export"
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel} loading={this.state.exporting}>
            Return
              </Button>,
              <Button type="primary" onClick={() => this.onDatePickDeliverySummary('paid')} loading={this.state.exporting}>
            Ok
              </Button>
            ]}>
            เวลาจากวันที่จ่ายสำเร็จ (payment_date)
            <Form onSubmit={() => this.onDatePickDeliverySummary('paid')}>
              <Form.Item>
                {getFieldDecorator('range-picker', this.state.config)(<RangePicker format={dateFormat} showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}/>)}
              </Form.Item>
            </Form>
          </Modal>
        </Layout>

      )
    }
  }
}

export default withTranslation() (EventSummary)
