import React, { useState, useMemo } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Button, Modal, Tabs } from 'antd'
import _get from 'lodash/get'
// import isSendingAllEmailEventQuery from "~/graphql/queries/isSendingAllEmailEvent.gql";
import sendAllPaidEmailOnEventMutation from '~/graphql/mutations/sendAllPaidEmailOnEvent.gql'
import sendRegistrationPaymentEmailMutation from '~/graphql/mutations/sendRegistrationPaymentEmail.gql'

const SendAllEmailBtn = (props) => {
  const {eventId} = props
  const [clickedLoading, setLoading]= useState(false)
  const [progress, setProgress] = useState(0)
  const eventProps = _get(props, 'eventProps')
  const [sendAllPaidEmailOnEvent] = useMutation(sendAllPaidEmailOnEventMutation)
  const [sendRegistrationPaymentEmail] = useMutation(sendRegistrationPaymentEmailMutation)
  const handleClick = async () => {
    setLoading(true)
    await sendAllPaidEmailOnEvent({
      variables: {
        eventId
      }
    }).then(async res => {
      console.log('res', eventId, res)
      const records = res.data.sendAllPaidEmailOnEvent
      console.log(records,'records');
      const progressSendAllPaid = res.data.sendAllPaidEmailOnEvent.length
      console.log(progressSendAllPaid,'progressSendAllPaid');


      while(records.length > 0){
        await Promise.all(records.splice(0,50).map(async (rec) => {
          return sendRegistrationPaymentEmail({
            variables: {
              _id : rec._id
            }
          }).catch((e) => {
            console.error(e)
          })
        }))
        setProgress(((progressSendAllPaid - records.length) * 100) / progressSendAllPaid)
      }
      setProgress(100)
      setLoading(false)

    }).catch(e => {
      console.log('err', e)
    })
  }
  
  const loading = _get(eventId, 'loading')
  const isLoading = useMemo(() => setIsLoading({
    loading, clickedLoading
  }),
  [loading, clickedLoading])
  console.log('isLoading', isLoading, clickedLoading,)
  
  return (<div className='eventDownloadBox'>

              <Button
                loading={isLoading}
                icon="export"
                onClick={handleClick}
                >
                Send all paid email  {progress ? `${progress.toFixed(2)} %` : ''} 
              </Button>        
            </div>
          )  
}
const setIsLoading = ({loading, clickedLoading}) => {
  if(loading) return loading
  if(clickedLoading) return clickedLoading
}
export default SendAllEmailBtn