import React from 'react'
import {
    //Card,
    Table,
} from 'antd'

import '../Styles/Custom.css'
import { useTranslation } from 'react-i18next'
//const { Meta } = Card

const GraphApex = ({param}) => {
    const { t } = useTranslation()
    const datasource = param.datasource 
    const chartRes = param.chart  
    
    const registrationTypeCountColumns = [
            {
                title: 'REGISTRATION TYPE NAME',
                dataIndex: 'name.en',
                key: `registrationTypeName`,
                className: 'align-left'
            }, {
                title: 'DRAFT',
                dataIndex: 'draft',
                key: `registrationTypeDraft`,
                className:"amount center"
            }, {
                title: 'SUBMITTED',
                dataIndex: 'submitted',
                key: `registrationTypeSubmitted`,
                className:"amount center"
            }, {
                title: 'PAID',
                dataIndex: 'paid',
                key: `registrationTypePaid`,
                className:"amount center"
            }, {
                title: 'TOTAL',
                dataIndex: 'total',
                key: `registrationTypeTotal`,
                className:"amount center"
            }
        ]

    return (
            <div hoverable className='fgma-chart' style={{ backgroundColor:'#FFF', border:'1px solid #EBEBEB', boxShadow:'0px 0px 1px rgb(0,0,0,0.1)' }}>
                <div style={{ margin: '20px', marginBottom: '25px', fontSize: '1.3em', paddingLeft: '5px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>
                <Table
                    style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF'}}
                    scroll={{ x: true }}
                    className="eventFgma"
                    columns={registrationTypeCountColumns}
                    dataSource={datasource}
                    loading={param.loading}
                    locale={{emptyText: `${t('locale.notFound')}`}}
                    rowKey={record => record._id}
                    pagination={false}
                />
                <div>&nbsp;</div>
            </div>
    )


}

export default GraphApex
