import React, { useState } from 'react'
import {
  Button,
  InputNumber,
  message,
  Form,
  Input
} from 'antd'
import _get from 'lodash/get'
import updateFixedExpenseQbSkuMutations from '~/graphql/mutations/qb/updateFixedExpenseQbSku.gql'
import { useMutation } from '@apollo/react-hooks'

const FixedExpenseSku = ({eventId, fixedExpenseQbSku}) => {
  const [loading, setLoading] = useState(false)
  const [qbSku, setQbSku] = useState(_get(fixedExpenseQbSku, 'qbSku'))
  const [qbAmount, setQbAmount] = useState(_get(fixedExpenseQbSku, 'amount'))
  const [qbName, setQbName] = useState(_get(fixedExpenseQbSku, 'qbName'))
  const [validateStatus, setValidateStatus] = useState(_get(fixedExpenseQbSku, 'qbId')?'success':'warning')
  const [updateFixedExpenseQbSku] = useMutation(updateFixedExpenseQbSkuMutations)
  const handleChangeMakeMerit = (e) => {
    const sku = e.target.value
    setValidateStatus('warning')
    setQbSku(sku)
  }
  const handleChangeAmount = (amount) => {
    setQbAmount(amount)
    setValidateStatus('warning')
  }
  const handleSubmit = () => {
    console.log({qbSku, qbAmount, qbName})
    updateFixedExpenseQbSku({ variables: { qbSku, eventId, amount: qbAmount } })
      .then(res => {
        setLoading(false)
        setQbName(_get(res, 'data.updateFixedExpenseQbSku.qbName'))
        message.success('success')
        setValidateStatus('success')
      })
      .catch(err => {
        message.error(JSON.stringify(err))
        setValidateStatus('warning')
        setLoading(false)
      })
  }

  return (<Form layout="inline">
    <Form.Item
      key='makeMerit'
      validateStatus={validateStatus}
      hasFeedback
      help={qbName || 'incorrect sku !!!'}
    > 
      <Input
        placeholder="Sku"
        defaultValue={qbSku}
        style={{ width: 300 }}
        onChange={handleChangeMakeMerit}
      />
    </Form.Item>
    <Form.Item 
      validateStatus={validateStatus}
      help={'บาท'}
      hasFeedback
    >
      <InputNumber 
        placeholder="Amount"
        min={0} 
        defaultValue={qbAmount}
        onChange={handleChangeAmount}
      />
    </Form.Item>
    <Form.Item
    >
      <Button 
        type="primary"
        loading= {loading}
        onClick= {handleSubmit}
      >
        Save
      </Button>
      </Form.Item>
  </Form>)
}

export default FixedExpenseSku
// 197