import React from 'react'
import {
    //Card,
    Table,
    Switch
} from 'antd'

import '../Styles/Custom.css'
import _orderBy from 'lodash/orderBy'
//const { Meta } = Card

const GraphApex = ({param, loading}) => {

    //const datasource = param.datasource 
    const dataquery = param.dataqueries     
    const chartRes = param.chart
    let datasource = dataquery

    let _showDetail = param.tableEvent['ticketTypeDetail'] === undefined ? false : param.tableEvent['ticketTypeDetail']   
    //console.log("_showDetail", _showDetail)
    const opt   = { 
      title: chartRes.caption,
      subtitle: param.subTitle,
      width: param.width,
      height: (param.height ? param.height : '300px'),
    }    

    if (!(!loading && param.event && dataquery /* && orderItemCount */)) {
      return <Loading />
    } else {
      // Modify TicketTypeCount
      datasource = _orderBy(dataquery, ['order', 'price', 'distance'], ['asc', 'asc', 'desc'])
      const totalTTC = {
        _id: 'total',
        name: {
          en: 'TOTAL'
        },
        draft: 0,
        submitted: 0,
        pending: 0,
        expired: 0,
        payment_verifying: 0,
        cancelled: 0,
        paid: 0,
        total: 0
      }
      datasource.forEach((tt) => {
        totalTTC.draft += tt.draft
        totalTTC.submitted += tt.submitted
        totalTTC.pending += tt.pending
        totalTTC.expired += tt.expired
        totalTTC.payment_verifying += tt.payment_verifying
        totalTTC.cancelled += tt.cancelled
        totalTTC.paid += tt.paid
        totalTTC.total += tt.total
      })
      datasource = datasource.concat(totalTTC)
    }    

    if (!datasource) return 'loading...'
    
    const columns = (_showDetail) ? [{
        title: 'DISTANCE',
        dataIndex: 'distance',
        key: `distance`,
        className: 'left'
      }, {
        title: 'DRAFT',
        dataIndex: 'draft',
        key: `draft`,
        className : 'center amount vvsmall'    
      }, {
        title: () => (<div style={{textAlign:'center'}}>
          SUBMITTED
          {/* <div style={{fontSize: 'smaller'}}>
            (expired + <br/> payment_verifying + <br/> cancelled)
          </div> */}
          <div>
            (PENDIND&nbsp;+&nbsp;EXPIRED&nbsp;+&nbsp;PAYMENT&nbsp;VERIFYING&nbsp;+&nbsp;CANCELLED)
          </div>
        </div>),
        dataIndex: 'submitted',
        key: `submitted`,
        className:"center",
        render: (submitted, { pending, expired, payment_verifying, cancelled }) => (<div style={{}}>
          {submitted}
          <div style={{fontSize: 'smaller'}}>
            {/* ({expired} + {payment_verifying} + {cancelled}) */}
            ({pending} + {expired} + {payment_verifying} + {cancelled})
          </div>
        </div>),
        className : 'amount vsmall center'  
      }, {
        title: 'PAID',
        dataIndex: 'paid',
        key: `paid`,
        className : 'amount vvsmall center'        
      }, {
        title: 'TOTAL',
        dataIndex: 'total',
        key: `total`,
        className : 'amount vvsmall center'       
      }] : 
      [{
        title: 'DISTANCE',
        dataIndex: 'distance',
        key: `distance`,
        className: 'left'
      }, {
        title: 'PAID',
        dataIndex: 'paid',
        key: `paid`,
        className : 'amount vvsmall center'        
      }]
      

    let rowData = 0  
    const raceSummary = Object.values(datasource.reduce((result, row) => {
      rowData++
    result[row.distance] = result[row.distance] || {}
    result[row.distance]['_id'] = row._id || '_'
    result[row.distance]['distance'] = ((row.distance || row.distance === 0) && row.distanceUnit && `${row.distance} ${row.distanceUnit}`) || 'Total'
    result[row.distance]['draft'] = (result[row.distance]['draft'] || 0) + row.draft
    result[row.distance]['submitted'] = (result[row.distance]['submitted'] || 0) + row.submitted
    result[row.distance]['pending'] = (result[row.distance]['pending'] || 0) + row.pending
    result[row.distance]['expired'] = (result[row.distance]['expired'] || 0) + row.expired
    result[row.distance]['payment_verifying'] = (result[row.distance]['payment_verifying'] || 0) + row.payment_verifying
    result[row.distance]['cancelled'] = (result[row.distance]['cancelled'] || 0) + row.cancelled
    result[row.distance]['paid'] = (result[row.distance]['paid'] || 0) + row.paid
    result[row.distance]['total'] = (result[row.distance]['total'] || 0) + row.total

    return result
    }, {}))

    return (
            <div hoverable class='fgma-card' style={{ backgroundColor:'#FFF', border:'1px solid #EBEBEB', boxShadow:'0px 0px 1px rgb(0,0,0,0.1)' }}>
                <div class='fgma-chart' style={{ margin: '20px', marginBottom: '25px', fontSize: '1.3em', paddingLeft: '5px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        {(rowData>0) && <div style={{display:'flex', float:'right', justifyContent: 'flex-end', marginTop:'5px'}}>
                            <Switch checkedChildren="Details" unCheckedChildren="Paid Only" onChange={param.eventHandle} /> 
                        </div>}                            
                        </div>                    
                <Table
                    style={{whiteSpace: 'nowrap', borderTop:'1px solid #EFEFEF', minHeight: opt.height}}
                    scroll={{ x: true }}
                    className="eventFgma"
                    columns={columns}
                    dataSource={raceSummary}
                    loading={param.loading}
                    locale={{emptyText: <div style={{fontSize:"10px"}}>NO DATA</div>}}
                    rowKey={record => record._id}
                    pagination={false}                    
                />
              <div>&nbsp;</div>
            </div>
    )


}

export default GraphApex
