import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { inject, observer } from 'mobx-react'
import { Button, message, Modal } from 'antd'
import eventPaymentStatisticsQuery from '~/graphql/queries/eventPaymentStatistics.gql'
import _get from 'lodash/get'
import eventPaymentStatisticsExportMutation from '~/graphql/mutations/eventPaymentStatisticsExport.gql'
import eventDeliveryStatisticsExportMutation from '~/graphql/mutations/eventDeliveryStatisticsExport.gql'
import eventProductStatisticsExportMutation from '~/graphql/mutations/eventProductStatisticsExport.gql'
import Loading from '../../../Loading/index'
import ExpandedCouponTypeSummaryTable from './ExpandedCouponTypeSummaryTable'

@inject('router')
@graphql(eventPaymentStatisticsQuery, {
  options: ({
    eventId,
    paymentMethod
  }) => ({
    variables: {
      eventId,
      paymentMethod
    }
  }),
  props: ({ data: { loading, eventPaymentStatistics, eventProductStatistics, eventWithdrawRequests, sumTransaction } }) => ({
    loading: loading,
    eventPaymentStatistics: eventPaymentStatistics,
    eventWithdrawRequests: eventWithdrawRequests,
    eventProductStatistics: eventProductStatistics,
    // eventSumTicketPromoDiscount: eventSumTicketPromoDiscount,
    sumTransaction
  })
})
@observer
@withApollo
class MethodsTable extends Component {
  state = {
    exporting: false
  }
  handleTicketExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventPaymentStatisticsExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventPaymentStatisticsExport.url')
        const fileName = _get(data, 'eventPaymentStatisticsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleDeliveryExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventDeliveryStatisticsExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventDeliveryStatisticsExport.url')
        const fileName = _get(data, 'eventDeliveryStatisticsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  handleProductExport = async () => {
    this.setState({ exporting: true })
    const eventId = _get(this.props, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventProductStatisticsExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          errors.forEach(error => message.error(error.message))
          this.setState({ exporting: false })
          return
        }
        const url = _get(data, 'eventProductStatisticsExport.url')
        const fileName = _get(data, 'eventProductStatisticsExport.fileName')
        if (url) {
          Modal.success({
            width: 500,
            icon: 'file-excel',
            className: 'export-modal',
            content: <a href={url} download>
              <Button type="primary" icon="download"
                style={{maxWidth: '250px', overflow: 'hidden'}}
              >
                {fileName}
              </Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ exporting: false })
      }
    }
  }
  sumProductStatistics = ({eventProductStatistics}) => {
    return eventProductStatistics.reduce((result, current) => {
      result.count = result.count + current.count
      result.amount = result.amount + current.amount
      result.gatewayFee = result.gatewayFee + current.gatewayFee
      result.feesAmount = result.feesAmount + current.feesAmount
      result.charge = result.charge + current.charge
      result.organizerBalance = result.organizerBalance + current.charge
      return result
    }, {
      count: 0,
      amount: 0,
      gatewayFee: 0,
      feesAmount: 0,
      charge: 0,
      organizerBalance: 0
    })
  }

  render () {
    const { loading, eventPaymentStatistics,
      eventProductStatistics, eventWithdrawRequests,
      sumTransaction,
      paymentMethod, event
      // , eventSumTicketPromoDiscount 
    } = this.props
    if (loading) {
      return <Loading />
    }
    console.log('eventPaymentStatistics', eventPaymentStatistics)
    return <div>
      {paymentMethod !== 'transfer' && <div className="filterTools">
      </div>}
      <ExpandedCouponTypeSummaryTable
        eventPaymentStatistics={eventPaymentStatistics}
        eventProductStatistics={eventProductStatistics}
        // eventSumTicketPromoDiscount={eventSumTicketPromoDiscount}
        sumTransaction={sumTransaction}
        eventWithdrawRequests={eventWithdrawRequests}
        event={event}/>
    </div>
  }
}

export default MethodsTable
