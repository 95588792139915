import React, { Component } from 'react'
import { Modal, Input, Form } from 'antd'
import _get from 'lodash/get'

class SendEmailModal extends Component {
  state = {
    email: ''
  }

  componentWillReceiveProps (nextProps) {
    const { registration } = nextProps
    const email = _get(registration, 'user.email')
    this.setState({
      email
    })
  }

  render () {
    const { mode, registration, onSend, onClose, ...modalProps } = this.props
    return (
      <Modal
        {...modalProps}
        title={
          {
            submission: 'Registration Confirmation Email',
            payment: 'Payment Confirmation Email'
          }[mode]
        }
        okText="Send"
        onOk={() =>
          onSend({
            emailMode: mode,
            registrationId: registration._id,
            toEmail: this.state.email
          })}
        onCancel={onClose}
      >
        <Form layout="inline">
          <Form.Item label="Send Email to">
            <Input
              defaultValue={_get(registration, 'user.email')}
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default SendEmailModal

// const SendEmailModal = ({ registration, onSend, ...modalProps }) =>
//   <Modal {...modalProps} okText="Send" onOk={() => onSend(mode, email)}>
//   </Modal>
