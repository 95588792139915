import moment from 'moment-timezone'

const getAgeCategory = ({
  race: { startTime, ageCategories, ageCategoryDiff },
  birthDate,
  gender
}) => {
  // TODO: important use race
  const $birthDate = moment(birthDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  const {
    months, date, hours, minutes, seconds, milliseconds
  } = $birthDate.toObject()
  let $startTime = moment(startTime).set({
    months, date, hours, minutes, seconds, milliseconds
  })
  if (ageCategoryDiff === 'day') {
    $startTime = moment(startTime).set({
      hours, minutes, seconds, milliseconds
    })
  }
  const fullAge = $startTime.diff($birthDate, 'year') // moment(startTime).year() - moment(birthDate).year()
  return {
    category: ageCategories.filter(cat => cat.gender === gender || !cat.gender).find(cat => {
      return (
        (!cat.min && cat.max >= fullAge) ||
        (!cat.max && cat.min <= fullAge) ||
        (cat.min <= fullAge && cat.max >= fullAge)
      )
    }),
    gender
  }
}

export default getAgeCategory
