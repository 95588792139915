import React, { Component } from 'react'
import { Switch, Form, Input, InputNumber, Button, message, Modal } from 'antd'
import { GenerateCouponCodesFormStyle } from '~/styles'
import _get from 'lodash/get'
import { graphql } from 'react-apollo'
import generateCouponCodesMutation from '~/graphql/mutations/generateCouponCodes.gql'
import { withTranslation } from 'react-i18next'

const FormItem = Form.Item
@Form.create()
@graphql(generateCouponCodesMutation, { name: 'generateCouponCodesMutation' })
class GenerateCouponCodesForm extends Component {
  state = {
    loading: false,
    visible: false
  }
  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, value) => {
      this.setState({ loading: true })
      const couponCodeTypeId = _get(this.props, 'couponCodeType._id')
      if (err) {
        message.error(err)
        this.setState({ loading: false })
        return
      }

      const args = {
        ...value,
        couponCodeTypeId
      }
      this.props.generateCouponCodesMutation({
        variables: args
      }).then((response) => {
        const newCouponCodes = _get(response, 'data.generateCouponCodes')
        message.success(`create successfully`)
        if (newCouponCodes && newCouponCodes.length > 0) {
          this.props.onGenerateCouponCodes(newCouponCodes)
          // this.props.onGenerateCouponCodes()
          this.props.form.resetFields()
          this.setState({ loading: false })
          this.setState({ visible: false })
        }
      }).catch((e) => {
        message.error(e.message)
        this.setState({ loading: false })
      })
    })
  }

  render () {
    const { couponCodeType, t } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    const prefixDecorator = getFieldDecorator('prefix', {
      rules: [{ required: true, message: 'Please input prefix' }],
      placeholder: 'prefix'
    })
    const countDecorator = getFieldDecorator('count', {
      rules: [{ required: true, message: 'Please input count' }],
      initialValue: 1,
      placeholder: 'count'
    })
    const copiesDecorator = getFieldDecorator('copies', {
      rules: [{ required: true, message: 'Please input copies' }],
      initialValue: 1,
      placeholder: 'copies'
    })
    const lengthDecorator = getFieldDecorator('length', {
      rules: [{ required: true, message: 'Please input length' }],
      initialValue: 10,
      placeholder: 'length'
    })
    const isRandomDecorator = getFieldDecorator('isRandom', {
      initialValue: true,
      valuePropName: 'checked'
    })
    const couponCodeTypeName = `Create couponCode: ${_get(couponCodeType, 'name.en') || _get(couponCodeType, 'name.th')}`
    return <GenerateCouponCodesFormStyle>
      <Form>
        <FormItem>
          {/* <Button.Group> */}
          <Button type="primary" onClick={this.showModal}>
              {t('couponCode.create')}
          </Button>
          {/* <Button
              onClick={this.handleExport}
              icon="export"
              loading={this.state.exporting}
            >
            Export
            </Button>
          </Button.Group> */}
          <Modal
            title={couponCodeTypeName}
            visible={this.state.visible}
            onOk={this.handleSubmit}
            okText="Create"
            onCancel={this.handleCancel}
          >
            <Form layout="vertical">
              <Form.Item label={t('couponCode.codePrefix')}>
                {prefixDecorator(<Input placeholder='FREE:... , PAY:...' required/>)}
              </Form.Item>
              <Form.Item label={t('couponCode.random')}>
                {isRandomDecorator(<Switch />)}
              </Form.Item>
              {getFieldValue('isRandom') && <Form.Item label={t('couponCode.codeAmount')}>
                {countDecorator(<InputNumber min={1} step={1} placeholder='1, 100...' required/>)}
              </Form.Item>}
              <Form.Item label={t('couponCode.redemption')}>
                {copiesDecorator(<InputNumber min={1} step={1} placeholder='1, 100...' required/>)}
              </Form.Item>
              {getFieldValue('isRandom') && <Form.Item label={t('couponCode.codeLenght')}>
                {lengthDecorator(<InputNumber min={10} step={1} placeholder='1, 100...' required/>)}
              </Form.Item>}
            </Form>
          </Modal>

        </FormItem>
      </Form>
    </GenerateCouponCodesFormStyle>
  }
}

export default withTranslation() (GenerateCouponCodesForm)
