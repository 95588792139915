import React from 'react'
import ReactDOM from 'react-dom'
import Login from './Login'
import App from './App'

import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { Helmet } from 'react-helmet'

import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
// import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Router } from 'react-router-mobx'
import createHistory from 'history/createBrowserHistory'
import enUS from 'antd/lib/locale-provider/en_US'
import { LocaleProvider } from 'antd'

import routerStore from '~/stores/router'
import clientAuth from './utils/client-auth'
// import Error
import Error404 from './components/Error/404'
import './i18n'

import config from './config'

import moment from 'moment-timezone'
// import './App.css'
moment.tz.setDefault(config.timezone)

const history = createHistory()

const clientId = process.env.REACT_APP_CLIENT_ID
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
  headers: {
    ...(clientId && { 'X-ThaiRun-Client-ID': clientId }),
    'X-RunX-Platform': config.platform
  }
  // credentials: 'omit'
})

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')
  if (token) {
    operation.setContext({
      headers: {
        credentials: 'omit',
        authorization: `Bearer ${token}`,
        ...(clientId && { 'X-ThaiRun-Client-ID': clientId }),
        'X-RunX-Platform': config.platform
      }
    })
  }
  return forward(operation)
})
const errorLink = onError(({ networkError = {}, graphQLErrors }) => {
  if (networkError.statusCode === 401) {
    // userStore.logout()
    clientAuth.logout()
    history.push('/login')
    // routerStore.push('/login')
  }
})

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      if ([
        'PropertyQuestionItems', 'ShirtCount',
        'EBibFonts', 'EBibQuestions', 'PropertyQuestionItemsValue',
        'PropertyQuestionCondition', 'EventPeriodsName', 'RegistrationTypeName',
        'TicketTypeName', 'RaceName', 'Event', 'EventSettings', 'EventSettingsPayment'
        ]
          .includes(object.__typename)) {
        return null
      }
      // return defaultDataIdFromObject(object)
      if (object._id) {
        return `${object.__typename}:${object._id}`
      }
      return null
    }
  })
})

// const { email, role } = jwtDecode(localStorage.getItem('token')) // try catch undefined?

const PrivateRoute = ({ component, ...rest }) => (
  <Route {...rest} render={props => {
    const { email, roles } = clientAuth.login()
    return email
      ? React.createElement(component, Object.assign({}, props, { email, roles }))
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }} />
)
// import 'grommet/grommet.min.js'

const stores = {
  router: routerStore
}

ReactDOM.render(
  <LocaleProvider locale={enUS}>
    <ApolloProvider client={client} >
      <Helmet>
        <meta name="description" content={config.title} />
        <title>{config.title}</title>
      </Helmet>
      <ApolloHooksProvider client={client}>
        <Provider {...stores}>
          <Router routerStore={routerStore} component={BrowserRouter}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={App} />
              <Route exact path="*" component={Error404} />
            </Switch>
          </Router>
        </Provider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </LocaleProvider>,
  document.getElementById('root')
)
