import React, { Component } from 'react'
import _get from 'lodash/get'
import { Button, Modal, Tabs, Popconfirm } from 'antd'
import mongoid from 'mongoid-js'
import { inject, observer } from 'mobx-react'
import { graphql } from 'react-apollo'
import ShirtItem from '~/components/Events/Event/Shirts/Shirts/Item'
import ShirtForm from '~/components/Events/Event/Shirts/Shirts/Form'
import FinisherShirtItem from '~/components/Events/Event/Shirts/FinisherShirts/Item'
import FinisherShirtForm from '~/components/Events/Event/Shirts/FinisherShirts/Form'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
import { withTranslation } from 'react-i18next'
const { TabPane } = Tabs

@inject('router')
@graphql(updateEventMutation, { name: 'updateEvent' })
@observer
class Shirts extends Component {
  state = {
    loading: false
  }
  removeTypename = (i) => {
    delete i.__typename
    delete i.name.__typename
    return i
  }
  handleEditShirt = (shirtTypeId) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      shirtTypeId
    }
  }
  handleEditFinisherShirt = (finisherShirtTypeId) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      activeTab: 'finishers',
      finisherShirtTypeId
    }
  }
  handleCreateShirtType = () => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      shirtTypeId: 'new'
    }
  }
  handleCreateFinisherShirtType = () => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      activeTab: 'finishers',
      finisherShirtTypeId: 'new'
    }
  }
  handleCloseShirtModal = () => {
    const eventId = this.props.data.event._id
    this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts`)
  }
  handleCloseFinisherShirtModal = () => {
    const eventId = this.props.data.event._id
    this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts?activeTab=finishers`)
  }
  handleDeleteShirtType = ({shirtType}) => {
    const eventId = this.props.data.event._id
    const shirtTypes = _get(this.props, 'data.event.shirtTypes')
      .filter(st => st._id !== shirtType._id)
      .map(i => this.removeTypename(i))
    const shirts = _get(this.props, 'data.event.shirts')
      .filter(s => s.shirtTypeId !== shirtType._id)
      .map(i => this.removeTypename(i))
    Modal.confirm({
      title: 'Delete',
      content: `Delete "${shirtType.name.th}" ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.props.updateEvent({
          variables: {
            record: {
              _id: eventId,
              shirtTypes,
              shirts
            }
          }
        }).then(res =>
          Modal.success({
            title: 'Updated',
            onOk: () => {
              this.setState({ loading: false })
              this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts`)
            }
          })
        ).catch(err => {
          this.setState({ loading: false })
          Modal.error({
            title: 'Error',
            content: err,
            onOk: () => {
            }
          })
        })
      }
    })
  }
  handleCloneShirt = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const { shirtTypeId } = this.props.router.location.query
    console.log('==>', shirtTypeId && shirtTypeId !== 'new')
    if(shirtTypeId && shirtTypeId !== 'new') {
      const { form } = this.formRef.props
      const eventId = this.props.data.event._id
      form.validateFieldsAndScroll((err, record) => {
        delete record.temp
        if (err) {
          this.setState({ loading: false })
          Modal.error({
            title: 'Error',
            content: JSON.stringify(err),
            onOk: () => {
            }
          })
          return ''
        }
        if (record.shirts) {
          record.shirts = record.shirts.filter(s => !s.__deleted)
        }
        const shirts = _get(record, 'shirts')
        const shirtTypes = _get(record, 'shirtTypes')
        let cloneShirtType = null
        let cloneShirts = null
        if(_get(shirtTypes, 'length') > 0) {
          cloneShirtType = shirtTypes.find(st => `${st._id}` === `${shirtTypeId}`)
          cloneShirts = shirts.filter(s => `${s.shirtTypeId}` === `${shirtTypeId}`)
          if(cloneShirtType && _get(cloneShirts, 'length') > 0) {
            console.log('shirts', cloneShirtType, cloneShirts)
            cloneShirtType = {
              ...cloneShirtType,
              name: {
                th: 'Clone_' + cloneShirtType.name.th,
                en: 'Clone_' + cloneShirtType.name.en,
                zh: 'Clone_' + cloneShirtType.name.zh
              },
              _id: mongoid()
            }
            cloneShirts = cloneShirts.map(s => ({
              ...s,
              shirtTypeId: cloneShirtType._id,
              _id: mongoid()
            }))
            // console.log('cloneShirtType=>', cloneShirtType)
            // console.log('cloneShirts=>', cloneShirts)
            record.shirts = [...cloneShirts, ...record.shirts]
            record.shirtTypes = [cloneShirtType, ...record.shirtTypes]
          }
        }
        // return this.setState({ loading: false })
        this.props.updateEvent({
          variables: {
            record: {
              _id: eventId,
              ...record
            }
          }
        }).then(res =>
          Modal.success({
            title: 'Updated',
            onOk: () => {
              this.setState({ loading: false })
              this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts`)
              window.location.reload()
            }
          })
        ).catch(err => {
          this.setState({ loading: false })
          Modal.error({
            title: 'Error',
            content: JSON.stringify(err),
            onOk: () => {
            }
          })
        })
      })
    }
  }
  handleSubmitShirt = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const { form } = this.formRef.props
    const eventId = this.props.data.event._id
    form.validateFieldsAndScroll((err, record) => {
      delete record.temp
      if (err) {
        this.setState({ loading: false })
        Modal.error({
          title: 'Error',
          content: JSON.stringify(err),
          onOk: () => {
          }
        })
        return ''
      }
      if (record.shirts) {
        record.shirts = record.shirts.filter(s => !s.__deleted)
      }
      this.props.updateEvent({
        variables: {
          record: {
            _id: eventId,
            ...record
          }
        }
      }).then(res =>
        Modal.success({
          title: 'Updated',
          onOk: () => {
            this.setState({ loading: false })
            this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts`)
            window.location.reload()
          }
        })
      ).catch(err => {
        this.setState({ loading: false })
        Modal.error({
          title: 'Error',
          content: JSON.stringify(err),
          onOk: () => {
          }
        })
      })
    })
  }
  handleDeleteFinisherShirtType = ({finisherShirtType}) => {
    const eventId = this.props.data.event._id
    const finisherShirtTypes = _get(this.props, 'data.event.finisherShirtTypes')
      .filter(st => st._id !== finisherShirtType._id)
      .map(i => this.removeTypename(i))
    const finisherShirts = _get(this.props, 'data.event.finisherShirts')
      .filter(s => s.shirtTypeId !== finisherShirtType._id)
      .map(i => this.removeTypename(i))
    Modal.confirm({
      title: 'Delete',
      content: `Delete "${finisherShirtType.name.th}" ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.props.updateEvent({
          variables: {
            record: {
              _id: eventId,
              finisherShirtTypes,
              finisherShirts
            }
          }
        }).then(res =>
          Modal.success({
            title: 'Updated',
            onOk: () => {
              this.setState({ loading: false })
              this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts?activeTab=finishers`)
            }
          })
        ).catch(err => {
          this.setState({ loading: false })
          Modal.error({
            title: 'Error',
            content: err,
            onOk: () => {
            }
          })
        })
      },
      onCancel () {}
    })
  }
  handleSubmitFinisherShirt = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const { form } = this.formRef.props
    const eventId = this.props.data.event._id
    form.validateFieldsAndScroll((err, record) => {
      delete record.temp
      if (err) {
        this.setState({ loading: false })
        Modal.error({
          title: 'Error',
          content: JSON.stringify(err),
          onOk: () => {
          }
        })
        return ''
      }
      if (record.finisherShirts) {
        record.finisherShirts = record.finisherShirts.filter(s => !s.__deleted)
      }
      this.props.updateEvent({
        variables: {
          record: {
            _id: eventId,
            ...record
          }
        }
      }).then(res =>
        Modal.success({
          title: 'Updated',
          onOk: () => {
            this.setState({ loading: false })
            this.props.history.push(`/fetchPage?link=/events/${eventId}/edit:shirts?activeTab=finishers`)
            window.location.reload()
          }
        })
      ).catch(err => {
        this.setState({ loading: false })
        Modal.error({
          title: 'Error',
          content: JSON.stringify(err),
          onOk: () => {
          }
        })
      })
    })
  }
  render () {
    const { router, data: {event} , t} = this.props
    const { shirtTypeId, finisherShirtTypeId, activeTab } = router.location.query
    const shirts = _get(event, 'shirts', []) || []
    const shirtTypes = _get(event, 'shirtTypes', []) || []
    const finisherShirts = _get(event, 'finisherShirts', []) || []
    const finisherShirtTypes = _get(event, 'finisherShirtTypes', []) || []
    const _activeTab = activeTab === 'finishers' ? activeTab : 'shirts'
    return <div>
      <Tabs defaultActiveKey={_activeTab}
        onTabClick={(key) => {
          const { location } = this.props.router
          location.query = {
            ...location.query,
            activeTab: key
          }
        }}>
        <TabPane tab={t('events.edit.shirt.normal')} key="shirts">
          <div style={{ padding: '8px', textAlign: 'right' }}>
            <Button
              onClick={this.handleCreateShirtType}
              icon="plus-circle"
              className="create action inline"
              loading={this.state.loading}
            >
          {t('events.edit.shirt.add')}
            </Button>
            <Button
              style={{marginLeft: '10px'}}
              onClick={() => this.props.history.push(`/events/${event._id}/edit:sortable-shirts`)}
              className="action inline" icon="sort-ascending"
              loading={this.state.loading}
              />
          </div>
          {shirtTypes.map(shirtType => <ShirtItem key={shirtType._id}
            loading={this.state.loading}
            shirtType={shirtType}
            shirts= {shirts}
            onShirtClick={this.handleEditShirt}
            event={event} onDeleteShirtType={this.handleDeleteShirtType} />)}
          <Modal visible={!!shirtTypeId} onOk={this.handleSubmitShirt} onCancel={this.handleCloseShirtModal} maskClosable={false}
            footer={[
              <Button key="back" onClick={this.handleCloseShirtModal}
                loading={this.state.loading}
                icon="arrow-left">
                {t('events.edit.shirt.back')}
              </Button>,
              <Popconfirm title="Clone this item?" onConfirm={this.handleCloneShirt}>
                <Button icon="copy" loading={this.state.loading}>
                {t('events.edit.shirt.clone')}
                </Button>
              </Popconfirm>,
              <Button key="submit" type="primary" loading={this.state.loading}
                onClick={this.handleSubmitShirt}
                icon="save">
              {t('events.edit.shirt.save')}
              </Button>
            ]}>
            <ShirtForm shirtTypeId={shirtTypeId !== 'new' && shirtTypeId}
              event={event}
              wrappedComponentRef={ref => { this.formRef = ref }} />
          </Modal>
        </TabPane>
        <TabPane tab="Finisher" key="finishers">
          <div style={{ padding: '8px', textAlign: 'right' }}>
            <Button
              onClick={this.handleCreateFinisherShirtType}
              icon="plus-circle"
              className="create action inline"
              loading={this.state.loading}
            >
          {t('events.edit.shirt.add')}
            </Button>
            <Button
              style={{marginLeft: '10px'}}
              onClick={() => this.props.history.push(`/events/${event._id}/edit:sortable-finishershirts`)}
              className="action inline" icon="sort-ascending"
              loading={this.state.loading}
              />
          </div>
          {finisherShirtTypes.map(finisherShirtType => <FinisherShirtItem key={finisherShirtType._id}
            loading={this.state.loading}
            finisherShirtType={finisherShirtType}
            finisherShirts={finisherShirts}
            onFinisherShirtClick={this.handleEditFinisherShirt}
            event={event} onDeleteFinisherShirtType={this.handleDeleteFinisherShirtType} />)}
          <Modal visible={!!finisherShirtTypeId} onOk={this.handleSubmitFinisherShirt} onCancel={this.handleCloseFinisherShirtModal} maskClosable={false}
            footer={[
              <Button key="back" onClick={this.handleCloseFinisherShirtModal}
                loading={this.state.loading}
                icon="arrow-left">
                {t('events.edit.shirt.back')}
              </Button>,
              <Button key="submit" type="primary" loading={this.state.loading}
                onClick={this.handleSubmitFinisherShirt}
                icon="save">
              {t('events.edit.shirt.save')}
              </Button>
            ]}>
            <FinisherShirtForm finisherShirtTypeId={finisherShirtTypeId !== 'new' && finisherShirtTypeId}
              event={event}
              wrappedComponentRef={ref => { this.formRef = ref }} />
          </Modal>
        </TabPane>
      </Tabs>
    </div>
  }
}
export default withTranslation() (Shirts)
