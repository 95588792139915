import React, { Component } from 'react'
import localeGet from '~/utils/localeGet'
import { Modal, Form, InputNumber, Select, Tag, Button, Tooltip, Icon, Radio } from 'antd'
import eventProducts from '~/graphql/queries/addOrderItemModal/eventProducts.gql'
import { graphql } from 'react-apollo'
import _get from 'lodash/get'
import compare from '~/utils/compare'
import renderQuestionInput from '~/utils/renderQuestionInput'
import Loading from '../Loading'
import Error404 from '../Error/404'
import _sortBy from 'lodash/sortBy'
const locale = 'th'

const inputCols = {
  labelCol: {span: 24},
  wrapperCol: {span: 24}
}

@Form.create()
@graphql(eventProducts, {
  options: ({ registration }) => ({
    variables: ({
      eventId: registration.eventId
    })
  })
})
class AddOrderItemsModal extends Component {
  handleCancel = () => {
    const {onCancel, form} = this.props
    form.resetFields()
    onCancel()
  }
  onOk = () => {
    const { form, onAddOrderItem } = this.props
    form.validateFields((err, orderItem) => {
      if (err) {
        this.setState({submiting: false})
        return console.error(err)
      }

      onAddOrderItem({orderItem, form})
    })
  }
  handleProductChange = ({questions}) => {
    const { resetFields } = this.props.form
    resetFields([
      `orderitemtypeId`
    ])
    questions.forEach((question, questionIndex) => {
      const optionField = {
        Open: 'value',
        Choice: 'itemId',
        MultiChoice: 'itemIds'
      }[question.kind]
      resetFields([
        `options[${questionIndex}].${optionField}`
      ])
    })
  }
  render () {
    const {
      masterId, masterType, data, registration,
      saleType, visible, form, submitting,
      form : {resetFields, setFieldsValue} } = this.props
    if (data.loading) return <Loading />
    if (!data.products) return <Error404 />
    const { getFieldDecorator, getFieldValue } = form
    const { participants } = registration
    const products = data.products.filter(p => !!_get(p, saleType))
    console.log({ products, saleType })
    const event = data.event
    const shirtTypes = _get(event, 'shirtTypes', [])
    const shirtCounts = _get(event, 'shirtCounts', [])
    getFieldDecorator(`eventId`, {
      initialValue: registration.eventId
    })
    getFieldDecorator(`registrationId`, {
      initialValue: registration._id
    })
    getFieldDecorator(`masterId`, {
      initialValue: masterId
    })
    getFieldDecorator(`masterType`, {
      initialValue: masterType
    })
    const quantity = getFieldValue('quantity') || 0
    const productId = getFieldValue('productId')
    const seletedProduct = productId ? products.find(p => p._id === getFieldValue('productId')) : undefined
    const questions = _sortBy(_get(seletedProduct, 'questions', []) ,
      ['order']
    )
    const participantDecorator = getFieldDecorator('participantId', {
      initialValue: participants.length === 1 ? _get(participants, [0, '_id']) : undefined,
      rules: [{
        required: true
      }]
    })
    const productDecorator = getFieldDecorator('productId', {
      // initialValue: products.length === 1 ? _get(products, [0, '_id']) : undefined,
      rules: [{
        required: true
      }],
      onChange: () => this.handleProductChange({questions})
    })
    const orderItemTypes = _get(seletedProduct, 'orderItemTypes')
    const orderItemType = orderItemTypes && orderItemTypes.find(o => o._id === getFieldValue('orderitemtypeId'))
    const openorderitemtype = _get(seletedProduct, 'openorderitemtype')
    const price = openorderitemtype ? _get(orderItemType, 'price') || 0 : _get(seletedProduct, 'price') || 0
    const amount = price * quantity

    return <Modal title={`Add ${saleType.replace(/([A-Z])/g, ' $1').trim().replace(/^\w/, c => c.toUpperCase())} Order Items`}
      visible={visible}
      onOk={this.onOk}
      onCancel={this.handleCancel}
      footer={[
        <Button key="back" onClick={this.handleCancel}>Back</Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={this.onOk}>
        Submit
        </Button>
      ]}>
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label='เลือกสินค้า' {...inputCols}>
          {productDecorator(
            <Select>
              {products.map(product =>
                <Select.Option key={product._id}>
                  {_get(product, 'name.th')}{' '}
                  {!_get(product, 'openorderitemtype') && <Tag color="blue">
                    {product.price} THB
                  </Tag>}
                </Select.Option>
              )}
            </Select>
          )}
        </Form.Item>
        {seletedProduct && openorderitemtype && orderItemTypes &&
            <Form.Item label={'ประเภทรายการ'} {...inputCols}>
              {getFieldDecorator('orderitemtypeId', {
                rules: [{
                  required: true
                }]
              })(
                <Select>
                  {orderItemTypes && orderItemTypes.map(orderItemType =>
                    <Select.Option key={orderItemType._id}>
                      <Tag color="blue">
                        {_get(orderItemType, 'price')} THB
                      </Tag>
                      {_get(orderItemType, 'name.en')}
                    </Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>
        }
        {seletedProduct && <Form.Item label='จำนวน' {...inputCols}>
          {getFieldDecorator(`quantity`, {
            initialValue: 1,
            rules: [{
              required: true
            }]
          })(
            <InputNumber min={1} />
          )}
        </Form.Item>}
        {seletedProduct && seletedProduct.deliveryOption === 'none' &&
            <Form.Item label='ผู้สมัครที่จะรับใน Race Kit' {...inputCols}>
              {participantDecorator(
                <Select>
                  {participants.map(participant =>
                    <Select.Option key={participant._id}>
                      {_get(participant, 'profile.firstName')} {_get(participant, 'profile.lastName')}
                    </Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>
        }
        {seletedProduct && <Form.Item>
            ราคา: {amount} THB
        </Form.Item>}
        {seletedProduct && <React.Fragment>
          {questions.map((question, questionIndex) => {
            getFieldDecorator(`options[${questionIndex}].questionId`, {
              initialValue: question._id
            })
            if (_get(question, 'inputType') === 'shirtTypes') {
              const shirtTypeIds = _get(question, `shirtTypeIds`, []) || []
              const availableShirtTypes = shirtTypes.filter(type => shirtTypeIds.length === 0 || shirtTypeIds.includes(type._id))
              // let shirtTypeId = _get(option, `shirtTypeId`)
              let shirtTypeId = getFieldValue(`options[${questionIndex}].shirtTypeId`)
              // if (!shirtTypeId) {
              //   shirtTypeId = availableShirtTypes.length === 1 ? _get(availableShirtTypes, [0, '_id']) : getFieldValue(`options[${questionIndex}].shirtTypeId`)
              // }
              // shirtTypeId = getFieldValue(`options[${questionIndex}].shirtTypeId`) || shirtTypeId
              const shirtType = availableShirtTypes.find(type => type._id === shirtTypeId)
              const shirtIds = _get(question, `shirtIds`, []) || []
              return (<div key={`options[${questionIndex}]`}>
                <Form.Item
                  label={
                    <span>
                      {localeGet(question, 'name', locale)}
                      {question.infoMessage &&
                      <Tooltip title={localeGet(question, 'infoMessage', locale)} placement="rightBottom">
                        &nbsp;<Icon type="question-circle-o" style={{ fontSize: 'large' }} />
                      </Tooltip>
                      }
                    </span>
                  }
                  {...inputCols}
                  extra={localeGet(question, 'extraMessage', locale)}
                  key={questionIndex}
                >
                  {getFieldDecorator(
                    `options[${questionIndex}].shirtTypeId`,
                    {
                      // initialValue: shirtTypeId,
                      rules: [{
                        required: true,
                        message: 'required field'
                      }]
                    }
                  )(<Radio.Group onChange={() => resetFields([`options[${questionIndex}].shirtId`]) || setFieldsValue({
                    [`options[${questionIndex}].shirtId`]: null
                  })} >
                    {availableShirtTypes.map(type =>
                      <Radio key={type._id} value={type._id} >
                        {localeGet(type, 'name', locale)}
                      </Radio>
                    )}
                  </Radio.Group>)}
                  {(shirtType && shirtType.imageUrl) &&
                  <div>
                    <img src={shirtType.imageUrl} alt="shirt type" style={{ maxWidth: '300px' }} />
                  </div>
                  }
                </Form.Item>
                {shirtTypeId && <Form.Item
                  label={'Shirt Size'}
                  {...inputCols}
                >
                  {getFieldDecorator(`options[${questionIndex}].shirtId`, {
                    // initialValue: _get(option, 'shirtId'),
                    rules: [{
                      required: true,
                      message: 'required field'
                    }]
                  })(
                    <Select >
                      {shirtCounts.filter(s => s.shirtTypeId === shirtTypeId && (shirtIds.length === 0 || shirtIds.includes(s._id))).map(shirt => {
                        let full = shirt.quantity && shirt.count >= shirt.quantity
                        if (full === 0) full = false
                        return <Select.Option key={shirt._id} value={shirt._id} disabled={full}>
                          {localeGet(shirt, 'name', locale)}{' '}
                          {full && <Tag color="red">
                            {{ en: 'Full', zh: 'Full', th: 'หมด' }[locale]}
                          </Tag>}
                        </Select.Option>
                      })}
                    </Select>
                  )}
                </Form.Item>}
              </div>)
            } else {
            const optionField = {
              Open: 'value',
              Choice: 'itemId',
              MultiChoice: 'itemIds'
            }[question.kind]
            const optionDecorator = getFieldDecorator(`options[${questionIndex}].${optionField}`, {
              rules: [{ required: question.required, message: `${question.name.th} is Required` }]
            })
            const condition = _get(question, 'condition')
            if (_get(condition, 'questionId')) {
              const condQuestionIndex = questions.findIndex(q => q._id === condition.questionId)
              const condQuestion = questions[condQuestionIndex]
              const condOptionField = {
                Open: 'value',
                Choice: 'itemId',
                MultiChoice: 'itemIds'
              }[condQuestion.kind]
              const value = getFieldValue(`options[${condQuestionIndex}].${condOptionField}`)

              if (!compare(value, condition.operator, condition.value)) {
                getFieldDecorator(`options[${questionIndex}].${optionField}`, { rules: [{ required: false }] })
                return <div key={questionIndex} />
              }
            }
            return <Form.Item
              label={
                <span>
                  {question.name.en}
                </span>
              }
              {...inputCols}
              key={question._id}
            >
              {optionDecorator(renderQuestionInput({ question }))}
            </Form.Item>}
          })}
        </React.Fragment>}
      </Form>
    </Modal>
  }
}

export default AddOrderItemsModal
